
var _      = require('lodash');
var dottie = require('dottie');

function AdminMembersCtrl($rootScope, $scope, $location, $window, $log, $filter, $q, UserFactory, EmployeeFactory, DependentFactory, UtilFactory, NgTableParams) {
  var Ctrl = this;

  Ctrl.$location = $location;
  Ctrl.$scope = $scope;
  Ctrl.$log = $log;

  this.export = function AdminMembersCtrl__export() {
    UserFactory.getActiveUser().then(function AdminMembersCtrl__export__getExportData(res) {
      EmployeeFactory.exportRoster(res).then(function AdminMembersCtrl__export__getExportData__exportRoster(res) {
        window.location = "/downloadExport/roster/" + res.data;
      }, $log.error);
    }, $log.error);
  };

  this.search = {
    query:  '',
    type:   'lastName',
    placeholders: {
      lastName: 'example: Smith',
      ssn:      'example: 1234',
      dob:      'example: mm/dd/yyyy'
    }
  };

  this.users        = [];
  this.userCount    = 0;
  Ctrl.showFilters  = false;

  Ctrl.filterOptions = {
    division: [],
    status:   ['ACTIVE', 'PENDING_ACTIVE', 'PENDING_INACTIVE', 'INACTIVE', 'WAIVER', 'COBRA']
  }

  /**
   * Resets `filterSelections`
   */
  this.clearFilterOptions = function AdminMembersCtrl__clearFilterOptions() {
    Ctrl.filterSelections = {
      division: ['all'],
      status:   ['all']
    };
  }

  // Get selected filters from sessionStorage if they exist and set them so they appear when the page loads
  if (UtilFactory.storageAvailable('sessionStorage')) {
    var rosterFilterSelectionsString = sessionStorage.getItem('rosterFilterSelections');
    if (rosterFilterSelectionsString) {
      Ctrl.filterSelections = JSON.parse(rosterFilterSelectionsString);
    } else {
      this.clearFilterOptions();
    }
  } else {
    this.clearFilterOptions();
  }

  Ctrl.bodyTag          = angular.element('body');
  Ctrl.filterOptionsTag = document.querySelector('.filter-options');

  var queryObj = {
    user: UserFactory.getActiveUser(),
    roster: UserFactory.getRoster()
  };

  $q.all(queryObj).then(function AdminMembersCtrl__getRoster_success(res) {
    Ctrl.users = dottie.get(res, 'roster.data.data', []);

    var divisions = dottie.get(res, 'user.divisions', []);
    Ctrl.filterOptions.division = _.map(divisions, function(division) {
      return division.name;
    });

    // If we have 0 or 1 division options, show all
    if (Ctrl.filterOptions.division.length < 2) {
      Ctrl.filterSelections.division = ['all'];
    }

    Ctrl.tableParams = new NgTableParams({
      page: 1,
      count: 50,
      sorting: {
        lastName: 'asc',
        status: 'desc'
      },
      filter: {}
    }, {
      counts: [],
      paginationMaxBlocks: 14,
      paginationMinBlocks: 2,
      total: (Ctrl.users || []).length,
      getData: function (params) {
        var paramsFilter  = params.filter();
        var filteredData  = [];
        var queryTime     = moment(Ctrl.search.query, [
          'MMM. D, YYYY',
          'MMM. DD, YYYY',
          'MM/DD/YYYY',
          'MM/D/YYYY',
          'M/D/YYYY',
          'MM-DD-YYYY',
          'MM-D-YYYY',
          'M-D-YYYY',
          'MMM D, YYYY',
          'MMM DD, YYYY',
          'MMMM DD, YYYY',
          'MMMM D, YYYY',
          'MMM D YYYY'
        ], true);

        var day   = queryTime.date();
        var year  = queryTime.year();
        var month = queryTime.month();

        // ssn and dob need special filtering options
        // due to not being a 1:1 filter
        if (paramsFilter.hasOwnProperty('dob')) {
          filteredData = paramsFilter && queryTime.isValid() ?
              $filter('filter')(Ctrl.users, function (value) {
                var dob = moment(value.dob, 'YYYY-MM-DD');

                var notSameYear   =  isNaN(year)  || dob.isSame(queryTime, 'year');
                var notSameMonth  =  isNaN(month) || dob.isSame(queryTime, 'month');
                var notSameDay    =  isNaN(day)   || dob.isSame(queryTime, 'day');

                if (!notSameYear || !notSameMonth || !notSameDay) {
                  return false;
                }

                return true;
              }) : (paramsFilter.dob === "" ? Ctrl.users : []);
        }
        else {
          filteredData = paramsFilter ?
              $filter('filter')(Ctrl.users, paramsFilter) : Ctrl.users;
        }

        if (paramsFilter.hasOwnProperty('ssn')) {
          filteredData = $filter('filter')(filteredData, function (user) {
            var lastFour = user.ssn.toString().slice(-4);
            return lastFour === paramsFilter.ssn;
          });
        }

        if (Ctrl.filterSelections.division.length > 0 && Ctrl.filterSelections.division.indexOf('all') === -1) {
          filteredData = $filter('filter')(filteredData, function (value) {
            return Ctrl.filterSelections.division.indexOf(value.divisionId) !== -1;
          });
        }

        if (Ctrl.filterSelections.status.length > 0 && Ctrl.filterSelections.status.indexOf('all') === -1) {
          filteredData = $filter('filter')(filteredData, function (value) {
            return Ctrl.filterSelections.status.indexOf(value.status) !== -1;
          });
        }

        var orderedData = params.sorting() ?
            $filter('orderBy')(filteredData, params.orderBy()) : filteredData;

        Ctrl.userCount = orderedData.length;
        params.total(orderedData.length);
        return orderedData.slice((params.page() - 1) * params.count(), params.page() * params.count());
      }
    });
  }, error);

  // until we have a proper 500 error page
  function error() {
    $log.error(arguments);
  }

  // todo: Clean this function
  this.toggleSelect = function AdminMembersCtrl__toggleSelect(e) {
    var Ctrl = this;

    // target of the element selected
    var $target = angular.element(e.target);

    // toggle the selected class of the element
    $target.toggleClass('selected');

    // determine the reference type of the element
    // ie: division or status
    var whichone = $target.attr('data-ref');

    // determine the relevant data of the element
    var data = $target.attr('data-rel');

    // index of the value selected
    var idx = Ctrl.filterSelections[whichone].indexOf(data);

    // if we selected the "all" element
    if (data === 'all') {
      // ...and it currently has a 'selected' class
      // add all of the available options to the filterSelections array
      if (whichone === 'status') {
        Ctrl.filterSelections[whichone] = ['all'];
      } else {
        if (idx === -1) {
          Ctrl.filterSelections[whichone] = ['all'];
        } else {
          Ctrl.filterSelections[whichone] = [];
        }
      }
    }
    else { // otherwise, we are working with a single element
      // if it is currently part of the filterSelections array...remove it
      if (idx > -1) {
        Ctrl.filterSelections[whichone].splice(idx, 1);
        Ctrl.filterSelections[whichone] = Ctrl.filterSelections[whichone].filter(function (row) {
          return row !== 'all';
        })

        if (Ctrl.filterSelections[whichone].length < 1) {
          Ctrl.filterSelections[whichone].push('all');
        }
      }
      else { // otherwise...add it
        Ctrl.filterSelections[whichone].push(data);
        Ctrl.filterSelections[whichone] = Ctrl.filterSelections[whichone].filter(function (row) {
          return row !== 'all';
        })
      }
    }

    // Save selected filters in local storage so we can reload them when we come back to this page
    if (UtilFactory.storageAvailable('sessionStorage')) {
      sessionStorage.setItem('rosterFilterSelections', JSON.stringify(Ctrl.filterSelections));
    }
  };
}

// IT-7136 Remove Proper Casing on Statuses in Roster & Dependent List
// AdminMembersCtrl.prototype.formatStatus = function(status) {
//   var words = status.split('_');
//   words = _.map(words, function(word) {
//     return word.charAt(0).toUpperCase() + word.slice(1)
//   });
//   return words.join(' ');
// }

AdminMembersCtrl.prototype.formatStatus = function(status) {
    return status.replace('_',' ');
}

AdminMembersCtrl.prototype.startSearch = function() {
  var filter = {};
  filter[this.search.type] = this.search.query;
  angular.extend(this.tableParams.filter(), filter);

  // reset the optional filters
  this.filterSelections.division = [];
  this.filterSelections.status = [];
  this.tableParams.page(1)
  // run the search
  this.tableParams.reload();
};

AdminMembersCtrl.prototype.startFilteredSearch = function() {
  var Ctrl = this;
  Ctrl.showFilters = false;
  this.tableParams.page(1)
  this.tableParams.reload();
};

AdminMembersCtrl.prototype.clearSearch = function() {
  this.search.query = '';
  this.search.type = 'lastName';

  this.tableParams.filter({});
  this.tableParams.sorting('lastName', 'asc');

  this.clearFilterOptions();
  this.tableParams.reload();
};

AdminMembersCtrl.prototype.toggleFilters = function() {
  var Ctrl = this;

  this.showFilters = !this.showFilters;

  function toggleFilterList(e) {
    var isSelf = e.target === Ctrl.filterOptionsTag;
    var isFilterLink = e.target.classList.contains('filter-options') || e.target.classList.contains('filter-options-icon') || e.target.classList.contains('filter-icon');

    if (!isSelf && !Ctrl.filterOptionsTag.contains(e.target) && !isFilterLink) {
      Ctrl.showFilters = false;
      Ctrl.bodyTag.unbind('click');

      // until we can get rid of jquery...
      Ctrl.$scope.$apply();
    }
  }

  if (Ctrl.showFilters && Ctrl.filterOptionsTag) {
    Ctrl.bodyTag.bind('click', toggleFilterList);
  }
};

AdminMembersCtrl.prototype.viewEmployeeProfile = function (employeeID) {
  employeeID = parseInt(employeeID);

  if (typeof employeeID === 'number' && employeeID > 0) {
    this.$location.path('employee-profile/' + employeeID);
  } else {
    this.$log.error("viewEmployeeProfile failed. Invalid ID: ", employeeID);
  }
};

module.exports = ['$rootScope', '$scope', '$location', '$window', 'LogFactory',
  '$filter', '$q', 'UserFactory', 'EmployeeFactory', 'DependentFactory', 'UtilFactory', 'NgTableParams', AdminMembersCtrl];
