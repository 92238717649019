
var _ = require('lodash');
var dottie = require('dottie');

function LoadInterceptor($cookies, $log, $q, $rootScope, httpBuffer) {
  var publicMethods = {},
  pendingRequests = 0;

  // Public methods that will be returned to Angular
  publicMethods.request = function (config) {
//     if ($rootScope.xsrf) {
//       config.headers = config.headers || {};
//       config.headers['X-XSRF-TOKEN'] = $rootScope.xsrf;
//     }

    if (dottie.get(config, 'url', '').indexOf('/api/user/login/refreshToken') > -1) {
      return config;
    }

    ++pendingRequests;
    $rootScope.$broadcast('httpRequestStarted', config);

    if (config.url && config.url.indexOf('uib') === 0) {
      config.url = config.url.replace(/^uib/, '');
    }

    return config;
  };

  publicMethods.response = function (resp) {
//     if (resp.config.headers['X-XSRF-TOKEN']) {
//       resp.headers = resp.headers || {};
//       $rootScope.xsrf = resp.config.headers['X-XSRF-TOKEN'];
//     }

    var tokens = dottie.get(resp, 'data.data.token');

    if (_.isObject(tokens)) {
      if (tokens.expires_in) {
        $rootScope.expiresIn = tokens.expires_in;
      }
    }

    if (resp.config.url.indexOf('/api/user/login/refreshToken') > -1) {
      return resp;
    }

    --pendingRequests;
    if (pendingRequests === 0) {
      $rootScope.$broadcast('httpRequestEnded', resp, false);
    }
    return resp;
  };

  publicMethods.responseError = function (res) {
    if (dottie.get(res, 'config.url', '').indexOf('/api/user/login/refreshToken') > -1) {
      return $q.reject(res);
    }

    --pendingRequests;
    if (pendingRequests === 0) {
      $rootScope.$broadcast('httpRequestEnded', res, true);
    }
    return $q.reject(res);
  };

  return publicMethods;
}

module.exports = ['$cookies', 'LogFactory', '$q', '$rootScope', 'HttpBuffer', LoadInterceptor];
