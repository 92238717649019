
var _ = require('lodash');

function EditDependentCtrl ($rootScope, $scope,
  $location, $timeout, WizardService, EmployeeFactory, $log, DependentFactory, UtilFactory, FormFactory) {

  var Ctrl = this;

  Ctrl.wizard = WizardService;
  Ctrl.wizard.showFinalConfirmScreen = false;
  Ctrl.wizard.modalTitle = 'Dependent Information';
  Ctrl.wizard.addingEmployee = false;
  Ctrl.wizard.terminatedDep = false;

  Ctrl.excludedSSN = [];
  Ctrl.excludeIDS = [];
  Ctrl.employeeEffectiveDate = null;
  Ctrl.employeeId = parseInt(WizardService.data.employeeInfo.id);

  DependentFactory.findByEmployee(Ctrl.employeeId).then(function(deps) {
    for (var i = 0; i < deps.length; i++) {
      Ctrl.excludedSSN.push(deps[i].ssn);
      Ctrl.excludeIDS.push(deps[i].id);
    }
  });

  EmployeeFactory.find(Ctrl.employeeId).then(function(res) {
    Ctrl.excludedSSN.push(res.ssn)
    Ctrl.excludeIDS.push(res.id);
    Ctrl.employeeEffectiveDate = moment(res.effectiveDate, 'YYYY-MM-DD');
  });

  Ctrl.isDefaultSSN = function EditDependentCtrl__isDefaultSSN() {
    return false;
  }

  /**
   * Reset the state of the modal and all data associated with modal forms
   */
  Ctrl.reset = function EditDependentCtrl__reset() {
    WizardService.resetData();
    Ctrl.wizard.showSummaryConfirmScreen  = false;
    Ctrl.wizard.showFinalConfirmScreen    = false;
    Ctrl.wizard.showFinalSummaryFooter    = false;
    Ctrl.wizard.showSummaryView           = false;
  };

  /**
   * Initializes data and gets qualifying events
   */
  Ctrl.init = function EditDependentCtrl__init() {
    WizardService.data.updatedDep = null;
    Ctrl.wizard.hideSidebar = true;
    Ctrl.isActive = true;
    Ctrl.activeTabIndex = 0;
    
    // Add meta-properties necessary for displaying the form data
    this.selectedDep = this.addSelectedDepProps(WizardService.data.selectedDep);

    this.originalStatus = this.selectedDep.status;

    this.reasonCodes = [
      { code:'readd', description:'Re-Activate' },
      { code:'reinstate', description:'Reinstate' },
    ];

    if (this.originalStatus=='active' || this.originalStatus=='cobra') {
       EmployeeFactory.getReasonCodes().then(setQualifyingEvents);
    } else {
       Ctrl.isActive = false;
       Ctrl.activeTabIndex = 1;
       EmployeeFactory.getQualifyingEvents().then(setQualifyingEvents);
    }

    function setQualifyingEvents (resp) {
      Ctrl.qualifyingEvents = resp;

      Ctrl.wizard.sidebar = [
        {name: 'Edit Dependent'},
        {name: 'Summary'}
      ];

      Ctrl.wizard.currentStep = Ctrl;
    }
  };

  /**
   * Enables the `showFinalConfirmScreen` property/sets the value to true
   */
  Ctrl.showConfirmView = function EditDependentCtrl__showConfirmView() {
    var dep = this.selectedDep;

    this.wizard.data.employeeName = dep.firstName + ' ' + dep.lastName;
    this.wizard.data.newDependent = true;
    this.wizard.showFinalConfirmScreen = true;
  };

  /**
   * Formats dependent data correctly for the PUT request
   */
  function formatDependentData() {
    Ctrl.selectedDep.dob = UtilFactory.dropdownValuesToDateString(Ctrl.selectedDep.dobDropdown);

    switch (Ctrl.selectedDep.relationship) {
    case "spouse":
      Ctrl.selectedDep.relationship = "SP";
      break;
    case "child":
      Ctrl.selectedDep.relationship = "CH";
      break;
    }
  }

  /**
   * Saves our dependent object
   */
  Ctrl.saveEdits = function EditDependentCtrl__saveEdits(self, next) {
    Ctrl.selectedDep.relationshipNoun = DependentFactory.getRelationshipNounByCode(Ctrl.selectedDep.relationship);

    if (this.deactivatingDependent() || this.readdingDependent()) {
      var terminationDate = UtilFactory.dropdownValuesToDateString(Ctrl.selectedDep.dateOfEvent);
      Ctrl.selectedDep.effectiveTerminationDate = UtilFactory.getFormattedCensusDate(terminationDate);
    }

    if (this.deactivatingDependent()) {
      var errors = FormFactory.validate(Ctrl.wizard.form, {
        validations: [
          FormFactory.validations.date(Ctrl.selectedDep.dateOfEvent, {key: 'eventDate', after: 'eventDateAfterEffective', compareDate: Ctrl.employeeEffectiveDate}),
          FormFactory.validations.date(Ctrl.selectedDep.dateOfEvent, {key: 'eventDate', before: 'eventDatePast', compareDate: moment().add(3, 'months')}),
          FormFactory.validations.date(Ctrl.selectedDep.dateOfEvent, {key: 'eventDate', after: 'eventDate', compareDate: moment().subtract(3, 'months')})
        ]
      });

      if (_.keys(errors.errors).length > 0) {
        Ctrl.friendlyErrors  = errors.friendlyErrors;
        Ctrl.errors = errors.errors;
        return false;
      }
      next();
    } else if (this.activatingDependent()) {
      if (this.reinstatingDependent()) {
        next();
      } else if (this.readdingDependent()) {
        var errors = FormFactory.validate(Ctrl.wizard.form, {
          validations: [
            FormFactory.validations.date(Ctrl.selectedDep.dateOfEvent, {key: 'eventDate', after: 'eventDateAfterEffective', compareDate: Ctrl.employeeEffectiveDate}),
            FormFactory.validations.date(Ctrl.selectedDep.dateOfEvent, {key: 'eventDate', before: 'eventDatePast', compareDate: moment().add(3, 'months')}),
            FormFactory.validations.date(Ctrl.selectedDep.dateOfEvent, {key: 'eventDate', after: 'eventDate', compareDate: moment().subtract(3, 'months')})
          ]
        });

        if (_.keys(errors.errors).length > 0) {
          Ctrl.friendlyErrors  = errors.friendlyErrors;
          Ctrl.errors = errors.errors;
          return false;
        }

        postData = {
          qualifyingEventTypeCode: Ctrl.selectedDep.qualifyingLifeEvent.eventCode,
          effectiveDate: UtilFactory.dropdownValuesToDateString(Ctrl.selectedDep.dateOfEvent)
        };
        if (Ctrl.wizard.data.employeeInfo.isInEligiblePeriod) {
          // we'll re-add the dependent after they go through the summary
          next();
        } else {
          // re-add the dependent now and go through benefits wizard
          DependentFactory.readd(Ctrl.employeeId, Ctrl.selectedDep.id, postData).then(function() {
            Ctrl.wizard.hideSidebar = false;
            Ctrl.wizard.data.newDep = Ctrl.selectedDep;
            Ctrl.wizard.data.reactivatingDependent = true;
            Ctrl.wizard.stepIndex++;
            Ctrl.wizard.subStepIndex = 0;
          }, handleErrors);
        }
      }
    } else {
      formatDependentData();

      var errors = FormFactory.validate(Ctrl.wizard.form, {
        validations: [
          FormFactory.validations.ssn(Ctrl.selectedDep.ssn, _.filter(Ctrl.excludedSSN, function(val, idx) {
            return Ctrl.selectedDep.id === Ctrl.excludeIDS[idx] ? false : true;
          })),
          FormFactory.validations.date(Ctrl.selectedDep.dobDropdown, {past: 'DOBPast'}),
          FormFactory.validations.date(Ctrl.selectedDep.dobDropdown, {after: 'DOBWithin', compareDate: moment().subtract(100, 'years')})
        ]
      });

      if (_.keys(errors.errors).length > 0) {
        Ctrl.friendlyErrors  = errors.friendlyErrors;
        Ctrl.errors = errors.errors;
        return false;
      }

      var dependentUpdate = _.pick(Ctrl.selectedDep, ['id', 'ssn', 'dob', 'gender', 'firstName', 'middleName1', 'lastName', 'relationship', 'effectiveDate']);

      DependentFactory.update(Ctrl.employeeId, dependentUpdate).then(function () {
        WizardService.data.updatedDep = Ctrl.selectedDep;
        Ctrl.showConfirmViewAndClose();
      }, handleErrors);
    }
  };

  Ctrl.removeError = function() {
    for (var i = 0; i < arguments.length; i++)
      if (Ctrl.errors && Ctrl.errors[arguments[i]])
        Ctrl.errors[arguments[i]] = false;
  };

  Ctrl.updateDependent = function EditDependentCtrl__updateDependent() {
    if (this.deactivatingDependent()) {
      DependentFactory.remove(Ctrl.employeeId, Ctrl.selectedDep).then(function () {
        WizardService.terminatedDep = true;
        Ctrl.showConfirmViewAndClose();
      }, handleErrors);
    } else if (this.activatingDependent()) {
      var postData;
      if (this.reinstatingDependent()) {
        // We are reinstating the dependent
        postData = {
          forceReinstate: 1
        };
      } else {
        postData = {
          qualifyingEvent: Ctrl.selectedDep.qualifyingLifeEvent.eventCode,
          effectiveDate: UtilFactory.dropdownValuesToDateString(Ctrl.selectedDep.dateOfEvent)
        };
      }
      DependentFactory.readd(Ctrl.employeeId, Ctrl.selectedDep.id, postData).then(Ctrl.showConfirmViewAndClose, handleErrors);
    }
  }

  /**
   * Checks to see if a dependent is valid or not
   * @param  {Object}  dep Dependent's data object
   * @return {Boolean}
   */
  Ctrl.isValidDep = function EditDependentCtrl__isValidDep(dep) {
    dep.selectedDep = angular.copy(Ctrl.selectedDep);

    return DependentFactory.isValid(dep, Ctrl.deactivatingDependent());
  };

  Ctrl.deactivatingDependent = function EditDependentCtrl__deactivatingDependent() {
    return this.originalStatus !== 'inactive' && this.selectedDep.status === 'inactive';
  };

  Ctrl.activatingDependent = function EditDependentCtrl__activatingDependent() {
    return this.originalStatus === 'inactive' && this.selectedDep.status !== 'inactive';
  };

  Ctrl.readdingDependent = function EditDependentCtrl__readdingDependent() {
    return this.activatingDependent() && this.selectedDep.reasonCode && this.selectedDep.reasonCode.code === 'readd';
  };

  Ctrl.reinstatingDependent = function EditDependentCtrl__reinstatingDependent() {
    return this.activatingDependent() && this.selectedDep.reasonCode && this.selectedDep.reasonCode.code === 'reinstate';
  };

  /**
   * Adds some controller specific metadata to dependents
   * @param  {Object} dep Dependent's object
   * @return {Object}     Dependent's object with new mapped data
   */
  Ctrl.addSelectedDepProps = function EditDependentCtrl__addSelectedDepProps(dep) {
    var dob = moment(dep.dob, ['YYYY-MM-DD']);

    // Populate date dropdown
    dep.dobDropdown = {};
    dep.dobDropdown.dateSeg1 = dob.format('MM');
    dep.dobDropdown.dateSeg2 = dob.format('DD');
    dep.dobDropdown.dateSeg3 = dob.format('YYYY');

    dep.dateOfEvent = {};

    return dep;
  };

  Ctrl.closeModalAndRedirect = function EditDependentCtrl__closeModalAndRedirect(route) {
    this.closeModal(WizardService.data.updatedDep);
    $location.path(route);
  };

  Ctrl.closeModal = function EditDependentCtrl__closeModal() {
    var dep = WizardService.data.updatedDep;
    if (Ctrl.wizard.showFinalConfirmScreen) {
      dep = WizardService.data.updatedDep || Ctrl.selectedDep;
    }
    Ctrl.reset();
    $scope.$close(dep);
  };
  Ctrl.cancel = Ctrl.closeModal;

  Ctrl.init();

  Ctrl.showConfirmViewAndClose = function EditDependentCtrl__showConfirmViewAndClose() {
    Ctrl.showConfirmView();

    $timeout(function () {
      $scope.$close(Ctrl.selectedDep);
    }, 5000);
  }

  handleErrors = function(res) {
    if (res) {
      var errors = $log.errorHandler(res);
      Ctrl.errors = errors.errors;
      Ctrl.friendlyErrors = errors.friendlyErrors;
      if (res.data){
        Ctrl.errorMessage = res.data.message;
      }
    }
  }

  function EditDependentCtrl__modal_hide(e, employee) {
    closeModalInstance(employee);
  }

  $rootScope.$on("modal:hide", EditDependentCtrl__modal_hide);

  // Prevent memory leaks caused by listening to $rootScope
  // (http://stackoverflow.com/questions/18856341/how-can-i-unregister-a-broadcast-event-to-rootscope-in-angularjs)
  // $scope.$on("$destroy", function EditDependentCtrl__destroy() {
  //   EditDependentCtrl__modal_hide();
  // });

  function closeModalInstance(employee) {
    if ($scope.$close && _.isFunction($scope.$close)) {
      var data = employee;

      if (_.isObject(Ctrl.wizard.data.modalMetaData)) {
        data = Ctrl.wizard.data.modalMetaData;
        data.employee = employee;
      }

      Ctrl.reset();
      $scope.$close(data);
    }
  }
}

module.exports = ['$rootScope', '$scope', '$location',
  '$timeout', 'WizardService', 'EmployeeFactory', 'LogFactory', 'DependentFactory', 'UtilFactory', 'FormFactory', EditDependentCtrl];
