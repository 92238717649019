
var _ = require('lodash');

function EditSalaryModalCtrl ($rootScope, $scope, $timeout, WizardService, SALARY_FREQUENCIES, $log, PendingChangeDecorator, FormFactory, EmployeeFactory, UtilFactory) {

  var Ctrl = this;
  PendingChangeDecorator(Ctrl, $rootScope, $scope);
  Ctrl.wizard = WizardService;
  Ctrl.wizard.modalTitle = "Salary (Pending)";
  Ctrl.editTabType = "Salary";
  Ctrl.headerType = "Salary";
  Ctrl.step = 0;

  /**
   * Reset the state of the modal and all data associated with modal forms
   */
  Ctrl.reset = function EditSalaryModalCtrl__reset() {
    WizardService.resetData();
    Ctrl.step = 0;
  };

  /**
   * Initializes data and gets qualifying events
   */
  Ctrl.init = function EditSalaryModalCtrl__init() {
    this.employeeInfo = Ctrl.wizard.data.employeeInfo;
    this.pendingChange = Ctrl.wizard.data.pendingChange.originalData;
    _.remove(SALARY_FREQUENCIES, function(object) {
     return	object.description == '--'
     });
    var today = moment();
    Ctrl.formData = {
      salaryObj: {
        salary: this.pendingChange.salary,
        freqObj: _.find(SALARY_FREQUENCIES, { code: this.pendingChange.salaryFreq || 'A' }),
        weeklyHours: this.employeeInfo.weeklyHours
      },
    requestedChangeDateDropDown: {}
    };

    Ctrl.cancelFormData = {
      cancelPendingChange: false
    };
    
    if (Ctrl.pendingChange.requestedChangeDate) {
       Ctrl.formData.requestedChangeDateDropDown = UtilFactory.dateStringToDropdownObj(Ctrl.pendingChange.requestedChangeDate);
    }

    Ctrl.originalFormData = _.cloneDeep(Ctrl.formData);
    Ctrl.originalCancelFormData = _.clone(Ctrl.cancelFormData);
  };

  Ctrl.onSalaryChange = function EditSalaryModalCtrl__onSalaryChange() {
    Ctrl.formData.salaryObj.prevSalaryObj.salary = Ctrl.formData.salaryObj.salary;
    Ctrl.handleEditFormChange();
    
    if(Ctrl.formData.salaryObj.salary==0){
       Ctrl.removeError('salaryFreq')
       Ctrl.formData.salaryObj.freqObj = {
        code: 'A', 
        description: 'Annually' 
       }
     }
  }

  Ctrl.showSummary = function EditSalaryModalCtrl__showSummary() {
    var errors = this.validateForm();

    if (_.keys(errors.errors).length > 0) {
      Ctrl.friendlyErrors  = errors.friendlyErrors;
      Ctrl.formData.errors = errors.errors;
      return false;
    }

    if (!Ctrl.cancelFormData.cancelPendingChange) {
      Ctrl.summaryObj = {
        salaryPreference: Ctrl.formData.salaryObj.freqObj.description,
        dollarAmmount: Ctrl.formData.salaryObj.salary,
        requestedChangeDate: UtilFactory.dropdownValuesToDateString(Ctrl.formData.requestedChangeDateDropDown)
      }
    }

    Ctrl.step = 1; // There are no form errors, so advance to the summary/confirmation
  }

  Ctrl.savePendingChange = function EditSalaryModalCtrl__savePendingChange() {
    if (Ctrl.cancelFormData.cancelPendingChange) {
      EmployeeFactory.deletePendingSalaryChange(Ctrl.employeeInfo.id, Ctrl.pendingChange.id).then(onSuccess, populateErrors);
      return;
    }

    var postData = {
      salary: Ctrl.formData.salaryObj.salary,
      salaryFreq: Ctrl.formData.salaryObj.freqObj.code,
      requestedChangeDate: Ctrl.summaryObj.requestedChangeDate
    }

    EmployeeFactory.updatePendingSalaryChange(Ctrl.employeeInfo.id, Ctrl.pendingChange.id, postData).then(onSuccess, populateErrors);

    function onSuccess(success) {
      Ctrl.step = 2; // The update was successfull, so advance to the final confirmation message
      $timeout(function() {
        Ctrl.closeModal();
      }, 5000);
    }

    function populateErrors(res) {
      var errors = $log.errorHandler(res);
      Ctrl.formData.errors = errors.errors;
    }
  }

  Ctrl.validateForm = function EditSalaryModalCtrl__validateForm() {
    return FormFactory.validate(Ctrl.wizard.form, {
      validations: [
        FormFactory.validations.editSalary(Ctrl.formData.salaryObj.salary),
        FormFactory.validations.date(Ctrl.formData.requestedChangeDateDropDown, {key: 'requestedChangeDate', after: 'rcdPast3Months', compareDate: moment().subtract(3, 'months'), condition: !Ctrl.isInEligiblePeriod}),
        FormFactory.validations.date(Ctrl.formData.requestedChangeDateDropDown, {key: 'requestedChangeDate', before: 'rcdPast', compareDate: moment().add(3, 'months'), condition: !Ctrl.isInEligiblePeriod})
      ]
    });
  }

  Ctrl.hasEditFormChanged = function EditSalaryModalCtrl__hasEditFormChanged() {
    if (!Ctrl.onEditTab) return false;

    if (Ctrl.formData.salaryObj) {
      if (Ctrl.formData.salaryObj.salary) {
        if (parseFloat(Ctrl.formData.salaryObj.salary) !== parseFloat(Ctrl.originalFormData.salaryObj.salary)) return true;
      }

      if (Ctrl.formData.salaryObj.freqObj && Ctrl.formData.salaryObj.freqObj.code) {
        if (Ctrl.formData.salaryObj.freqObj.code !== Ctrl.formData.salaryObj.freqObj.code) return true;
      }
    }
    
    if (Ctrl.formData.requestedChangeDateDropDown) {
      if (!UtilFactory.dateDropDownsAreEqual(Ctrl.formData.requestedChangeDateDropDown, Ctrl.originalFormData.requestedChangeDateDropDown)) {
          return true;
        }
     }

    return false;
  }

  Ctrl.init();
}

module.exports = ['$rootScope', '$scope', '$timeout', 'WizardService', 'SALARY_FREQUENCIES', 'LogFactory', 'PendingChangeDecorator', 'FormFactory', 'EmployeeFactory', 'UtilFactory', EditSalaryModalCtrl];
