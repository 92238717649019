
var _ = require('lodash');

function FormFactory(LogFactory) {
    /**
     * Checks to see if the form has any required fields missing...
     * it's a helper/wrapper function around populateErrors for
     * handling Angular form errors
     * @param  {Service} form    AngularJS form directive
     * @param  {Object}  options Custom validations to invoke, etc.
     * @return {Object}          An object of mapped errors
     */
    this.validate = function FormFactory__validate(form, options, isValidateOptionsOnly) {
        var errors = {};
        var hasErrors =
            _.has(form, '$error')
            && (_.has(form.$error, 'required') || _.has(form.$error, 'pattern'))
            && (_.keys(form.$error.required).length > 0 || _.keys(form.$error.pattern).length > 0);

        if (form && form.$invalid && hasErrors && !isValidateOptionsOnly) {
            errors = LogFactory.populateErrors(mappedErrors(form.$error.required, form.$error.pattern));
        }

        if (_.isObject(options) && _.isArray(options.validations) && options.validations.length > 0) {
            _.each(options.validations, function (validate) {
                validate = !_.isObject(validate) ? {} : validate;
                errors = _.merge(errors, validate);
            });
        }

        return {
            errors: errors,
            friendlyErrors: LogFactory.getFriendlyErrors(errors).filter(function(val, idx, arr) {
              for (var i = 0; i < arr.length; i++)
                if (arr[i] === val && i > idx)
                  return false;
              return true;
            })
        };
    }

    this.validations = {};

    this.validations.date = function FormFactory__validations_date(date, options) {
        var obj = {};
        var valid = 'YYYY-MM-DD';

        if (_.isArray(date)) {
          valid = date[0];
          date = date[1];
        }

        options = _.isObject(options) ? options : {};
        options.key = options.key || 'dob';

        if (options.condition === false) {
          return obj;
        }

        if (_.isObject(date)) {
            _.each(['1', '2', '3'], function (idx) {
                if (_.isUndefined(date['dateSeg' + idx]) || (date['dateSeg' + idx] + '').trim() === '') {
                  obj[options.key] = true;
                }
            });

            date = [date.dateSeg3, date.dateSeg1, date.dateSeg2].join('-');
        }

        var compiledDate = moment(date, [valid]);
        var compareDate = null;

        if (options.compareDate) {
          if (_.isString(options.compareDate)) {
            compareDate = moment(options.compareDate, [valid]);
          } else if (_.isObject(options.compareDate) && options.compareDate.dateSeg1) {
            compareDate = moment([options.compareDate.dateSeg3, options.compareDate.dateSeg1, options.compareDate.dateSeg2].join('-'), [valid]);
          } else {
            compareDate = options.compareDate;
          }
        }
        if (!obj[options.key] && (!compiledDate.isValid() || (compareDate !== null && typeof compareDate.isValid === 'function' && !compareDate.isValid()))) {
          obj[options.key] = true;
        }

        var acceptSameDay = typeof options.acceptSameDay === 'boolean' ? options.acceptSameDay : true;
        var sameDay = compareDate !== null ? compiledDate.isSame(compareDate, 'day') : compiledDate.isSame(moment(), 'day');
        if (_.isString(options.past) && (compiledDate.isAfter(moment(), 'day') || (sameDay && !acceptSameDay))) {
          obj[options.past] = true;
        }
        else if (_.isString(options.future) && (compiledDate.isBefore(moment(), 'day') || (sameDay && !acceptSameDay))) {
          obj[options.future] = true;
        }
        else if (_.isString(options.before) && compareDate !== null && (compiledDate.isAfter(compareDate, 'day') || (sameDay && !acceptSameDay))) {
          obj[options.before] = true;
        }
        else if (_.isString(options.after) && compareDate !== null && (compiledDate.isBefore(compareDate, 'day') || (sameDay && !acceptSameDay))) {
          obj[options.after] = true;
        }
        return obj;
    }

    this.validations.ssn = function FormFactory__validations_ssn(ssn, excludes) {
        var obj = {};
       if(!_.isNil(ssn) && ssn != "") {
        if (!_.isString(ssn) && !_.isObject(ssn)) {
            obj.ssn = true;
            return obj;
        }

        if (_.isObject(ssn)) {
            _.each(['1', '2', '3'], function (idx) {
                if (!_.isString(ssn['ssnSeg' + idx]) || ssn['ssnSeg' + idx].trim() === '') {
                    obj.ssn = true;
                }
            });
            ssn = _.values(_.pick(ssn, ['ssnSeg1', 'ssnSeg2', 'ssnSeg3'])).join('-');
        }

        if (!obj.ssn && ssn.match(/\b(?!000)(?!666)[0-8]\d{2}[- ]?(?!00)\d{2}[- ]?(?!0000)\d{4}\b/) === null) {
            obj.ssn = true;
            return obj;
        }

        var firstSet = parseInt(ssn.ssnSeg1), secondSet = parseInt(ssn.ssnSeg2), thirdSet = parseInt(ssn.ssnSeg3);

        if (firstSet >= 900 && firstSet <= 999) {
          obj.ssn = true;
          return obj;
        }

        if (firstSet === 987 && secondSet === 65 && (thirdSet >= 4320 && thirdSet <= 4329)) {
          obj.ssn = true;
          return obj;
        }

        if (_.isArray(excludes)) {
          for (var i = 0; i < excludes.length; i++) {
            var num = excludes[i];
            if (_.isObject(num)) {
              num = _.values(num).join('-')
            }
            if (num.replace(/\-/g, '') === ssn.replace(/\-/g, '')) {
              return {
                userExists: true
              };
            }
          }
        }
       }
        return obj;
    }

    this.validations.salary = function FormFactory__validations_salary(sal) {
      var obj = {};
     if(!_.isNil(sal) && !_.isNaN(sal) && sal != ""){
      if (!_.isString(sal) && !_.isNumber(sal)) {
        obj.salary = true;
        return obj;
      }

      if ((typeof sal === 'string' && sal.length > 9) || (typeof sal === 'number' && sal > 999999999)) {
        obj.salaryTooLarge = true;
        return obj;
      }

      var salVal = typeof sal === 'number' ? sal : parseInt(sal) || 0;     
 // IT-1963: To allow '0' salaries in EAC
 /*     if (salVal === 0) {
        obj.salaryGreaterThanZero = true;
      }*/
     }
      return obj;
     
    }
    
    this.validations.editSalary = function FormFactory__validations_editSalary(sal) {
        var obj = {};     
       // IT-1963: To allow '0' salaries in EAC
        if (_.isNaN(sal) || _.isNull(sal) || _.isUndefined(sal)) {       
          obj.salaryGreaterThanZero = true;
          return obj;
        }
        
        if (!_.isString(sal) && !_.isNumber(sal)) {
          obj.salary = true;
          return obj;
        }

        if ((typeof sal === 'string' && sal.length > 9) || (typeof sal === 'number' && sal > 999999999)) {
          obj.salaryTooLarge = true;
          return obj;
        }   
      }

    this.validations.hours = function FormFactory__validations_hours(hour) {
      var obj = {};

      if (!_.isString(hour) && !_.isNumber(hour)) {
        obj.weeklyHours = true;
        return obj;
      }

      var hourVal = typeof hour === 'number' ? hour : parseFloat(hour) || 0;
   // IT-1963: To allow '0' work hours in EAC
      /*if (hourVal === 0) {
        obj.weeklyHoursGreaterThanZero = true;
      } else */
      
      if (hourVal > 80) {
        obj.weeklyHoursLessThanEighty = true;
      }

      return obj;
    }

    this.validations.phone = function FormFactory__validations_phone(number) {
      var obj = {};

      if (_.isObject(number)) {
        if (number.hasOwnProperty('phoneSeg1') && number.hasOwnProperty('phoneSeg2') && number.hasOwnProperty('phoneSeg3')) {
          number = [number.phoneSeg1, number.phoneSeg2, number.phoneSeg3].join('');
        } else if (!number.hasOwnProperty('phoneSeg1') && !number.hasOwnProperty('phoneSeg2') && !number.hasOwnProperty('phoneSeg3')) {
          return obj;
        } else {
          for (var i = 1, temp_num = ''; i <= 3; i++) {
            temp_num += number.hasOwnProperty('phoneSeg' + i) ? number['phoneSeg' + i] : '';
            if (i === 3)
              number = temp_num;
          }
        }
      }

      if (number === '' || number === null || number === undefined) {
        return obj;
      }

      if (number.match(/[0-9]{10}/) === null) {
        obj.phone = true;
      }

      return obj;
    }

    this.validations.email = function FormFactory__validations_email(add) {
      var obj = {};

      if (add === '' || add === null || add === undefined) {
        return obj;
      }

      if (typeof add !== 'string') {
        obj.email = true;
        return obj;
      }

      if (add.match(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/) === null) {
        obj.email = true;
      }

      return obj;
    }

    this.validations.class = function FormFactory__validations_class(classId, options) {
      var obj = {};

      if (options && options.condition === false) {
        return obj;
      }

      if (!_.isString(classId) && !_.isNumber(classId)) {
        obj.classId = true;
        return obj;
      }

      return obj;
    }

    this.validations.integer = function FormFactory__validations_class(volume, options) {
      var obj = {};

      if (options && options.condition === false) {
        return obj;
      }

      volume = parseInt(volume);

      if (!volume || !_.isInteger(volume)) {
        obj.volume = true;
        return obj;
      }

      return obj;
    }
    
    this.validations.occupation = function FormFactory__validations_occupation(occupation) {
      var obj = {};
       if(_.isNil(occupation)) {
    	 obj.occupation = true;
         return obj;
       }
       return obj;
     }
    
    // # private

    // maps AngularJS form errors into our own custom error handler
    function mappedErrors(errors, patterns) {
        var obj = {};
        _.forEach(errors, function FormFactory__mappedErrors__map_errors(error) {
            obj[error.$name] = true;
        });
        _.forEach(patterns, function FormFactory__mappedErrors__map_errors(pattern) {
            obj[pattern.$name] = true;
        });
        return obj;
    }

    return this;
}

module.exports = ['LogFactory', FormFactory];
