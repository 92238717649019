
var _ = require('lodash');

/**
 * Provides checking for expiration/the date in which we should attempt to refresh our token
 * @param {Service} $rootScope AngularJS $rootScope
 * @param {Service} $location  AngularJS $location
 * @param {Service} $interval  AngularJS $interval
 * @param {Service} $log       AngularJS $log
 * @param {Constant} IDLE_TIME Number of seconds of when we're considered idle
 */
function RefreshTokenCheck($rootScope, $location, $timeout, $interval, $log, IDLE_TIME) {
  var expiredTimeout;
  var internalTimer;

  // this can either stay here or thrown into it's own file.. but it's very small...
  // just keeps location.path() for views/layout.pug
  $rootScope.$on('$routeChangeSuccess', function RefreshTokenCheck__routeChangeSuccess() {
    $rootScope.currentLocation = $location.path();
  });

  $rootScope.$on('token:expires', function RefreshTokenCheck__tokenExpires($event, expiresIn) {
    if (_.isString(expiresIn)) {
      expiresIn = moment().diff(expiresIn, 'seconds');
    }

    if (_.isNumber($rootScope.expiresIn)) {
      $rootScope.expiresIn = moment().add($rootScope.expiresIn, 'seconds');
    }

    // prevent less typing
    $rootScope.expiresIn = moment($rootScope.expiresIn);

    if (expiresIn < 1 && $rootScope.expiresIn.isAfter(moment(), 'seconds')) {
      expiresIn = $rootScope.expiresIn.diff(moment(), 'seconds');
    }

    var ttl = expiresIn - 120; // 2 minutes - will try and refresh the token 2 minutes before expiration
    $log.info('token:expires called -- %j', {expiresIn: expiresIn, '$rootScope.$expiresIn': $rootScope.expiresIn.toDate()});

    $log.info('token:expires ttl:%s', ttl);

    var expiredAlready = expiresIn <= 0 || ($rootScope.expiresIn.isValid() && $rootScope.expiresIn.isAfter(moment()));

    if (_.isUndefined(internalTimer) && $rootScope.expiresIn.isValid() && expiredAlready) {
      $log.info('token:expires found $cookies.expires_in %s', $rootScope.expiresIn);
      ttl = $rootScope.expiresIn.diff(moment(), 'seconds') - 120;
    }

    if (expiresIn > 0 && $rootScope.expiresIn.isValid() && $rootScope.expiresIn.isBefore(moment(), 'seconds')) {
      $rootScope.expiresIn = expiresIn;
    }

    if (ttl < 0 || isNaN(ttl)) {
      ttl = 2;
    }

    $log.info('token:expires final values: expiresIn:%s -- ttl:%s -- $rootScope.expiresIn:%s', expiresIn, ttl, $rootScope.expiresIn.toDate());

    // if a timer already exists no need for this one...
    if (!_.isUndefined(internalTimer)) {
      $interval.cancel(internalTimer);
    }

    // wait for a total of 5 seconds to see if we have an access token and then execute the expiredTimeout ticker
    internalTimer = $interval(function RefreshTokenCheck__internalTimer() {
      if (_.isObject($rootScope.expiresIn)) {
        $log.info('Found $rootScope.expiresIn');
        $log.info('token:expires Set $interval for event:loginRequired at rate: 1 req/%ss', ttl);
        if (!_.isUndefined(internalTimer)) {
          $interval.cancel(internalTimer);
        }
        setExpiredTimeout();
      }
      return $rootScope.expiresIn;
    }, 500, 10);

    function setExpiredTimeout() {
      if (angular.isDefined(expiredTimeout)) {
        $log.info('token:expires expiredTimeout was predefined');
        $interval.cancel(expiredTimeout);
      }

      expiredTimeout = $interval(function RefreshTokenCheck__expiredTimeout() {
        $rootScope.$broadcast('event:loginRequired');
        cancelInterval();
      }, (ttl * 1000), 1).then(function () {
        $log.info('token:expires expiredTimeout flushed');
      }, function() {
        $log.info('token:expires expiredTimeout cancelled');
      });

      $log.info('token:expires expiredTimeout is set', ttl);
    }
  });

  $rootScope.$on("token:cancelRefresh", cancelInterval);

  function cancelInterval() {
    if (!_.isUndefined(internalTimer)) {
      $interval.cancel(internalTimer);
    }

    if (!_.isUndefined(expiredTimeout)) {
      var cancelled  = $interval.cancel(expiredTimeout);
      expiredTimeout = undefined;
      $log.info('token:expires interval was ' + (cancelled ? 'not ' : '') + 'cancelled');
      $log.info('Cancelling token:expires');
      //why is it hardcoding the time to check?
      //$rootScope.$broadcast('token:expires', IDLE_TIME);
    } else {
      $log.info('token:expires expiredTimeout is undefined -- reiniaiting');
      //$rootScope.$broadcast('token:expires', IDLE_TIME);
    }
  }
}

module.exports = ['$rootScope', '$location', '$timeout', '$interval', '$log', 'IDLE_TIME', RefreshTokenCheck];
