
/**
  * @ngdoc mustMatch
  * @name mustMatch
  * @restrict A
  *
  * @description
  * Creates a custom validation for matching the value of another `ng-model`
  *
  *
  * @param {object} mustMatch The model to match it's value against
  *
  * @example
      <input type="text" name="confirmEmail" required class="form-control" ng-model="Ctrl.confirmEmail" must-match="Ctrl.newEmail">
*/

function MustMatchDirective() {
  return {
    require:  'ngModel',
    scope: {
      matchModel: '=mustMatch'
    },
    restrict: 'A',
    link: function (scope, elm, attrs, ctrl) {
      var caseSensitive = angular.isUndefined(attrs.caseSensitive) || [true, 'true', 't'].indexOf(attrs.caseSensitive) > -1;

      ctrl.$validators.mustMatch = function(modelValue) {
        if (!angular.isString(modelValue) || !angular.isString(scope.matchModel)) {
          return false;
        }

        return ((!caseSensitive ? modelValue.toLowerCase() : modelValue) === (!caseSensitive ? scope.matchModel.toLowerCase() : scope.matchModel));
      };

      scope.$watch('matchModel', function() {
        ctrl.$validate();
      });
    }
  };
}

module.exports = MustMatchDirective;
