
var _ = require('lodash');

function RoleService() {
  return {
    toHuman: function(Role, multiUser) {
      Role = _.isString(Role) ? Role : '';
      var role = '';

      switch (Role.toLowerCase()) {
        case 'primaryadmin':
          role = 'Primary Administrator';
          break;
        case 'secondaryadmin':
          role = 'Secondary Administrator';
          break;
        case 'lifemapadmin':
          role = 'LifeMap Administrator'
          break;
        default:
          throw new TypeError('Unknown user role.');
      }

      multiUser = _.isBoolean(multiUser) ? multiUser : false;

      if (multiUser && role == 'Primary Administrator') {
        role = 'Third-Party Administrator'
      }

      return role;
    }
  };
}

module.exports = RoleService;
