
/**
  * @ngdoc mobTooltipDateOfHire
  * @name any[mobTooltipDateOfHire]
  * @restrict A
  *
  * @description
  * Creates a tooltip specifically for the Date of Hire requirements
  *
  *
  * @example
       <a mob-tooltip-date-of-hire>?</a>
*/

var content = [
  '<ul>',
    '<li>Please enter the Date of Hire unless your policy stipulates the eligibility effective date is determined by employer, then enter the Eligibility Effective Date.</li>',
    '<li>In the event that your employee is not in an eligible class on the date of hire, enter the date the employee moved in to the eligible class.</li>',
    '<li>Effective date of coverage will be calculated using this date and the waiting period as reflected in your policy.</li>',
  '</ul>'
].join('');

function TooltipDateOfHireDirective() {
  return {
    restrict: 'A',
    scope: false,
    replace: true,
    template: '<div mob-tooltip="" mob-tooltip-title="Date of Hire Requirements" mob-tooltip-content="' + content + '"><img src="/static/images/QuestionMark_tooltip.png" alt="?" title="Date of Hire Requirements"></div>'
  };
}
  
module.exports = TooltipDateOfHireDirective;
