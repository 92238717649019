function numberFormat($filter) {
  return {
    restrict: "A", // Only usable as an attribute of another HTML element
    require: "?ngModel",
    scope: true,
    link: function (scope, element, attr, ngModel) {
      if (ngModel) {
        element.bind('keyup', function() {
          element.val($filter('number')(ngModel.$modelValue, 0));
        });
        ngModel.$formatters.push(function(val) {
          val = $filter('number')(val, 0);
          return val;
        });
        ngModel.$parsers.push(function(val) {
          return parseInt(val.replace(/(\,|\.)/g, '') || 0);
        });
      }
    }
  }
}

module.exports = ['$filter', numberFormat];
