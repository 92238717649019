
var _      = require('lodash');
var dottie = require('dottie');

function EditDependentModalCtrl($modalInstance, $rootScope, $scope, $location, WizardService, $timeout) {
  var Ctrl = this;

  this.wizard = WizardService;
  this.wizard.hasLoaded = true;

  // If we aren't reactivating an employee we're adding a new one so reset all the form data
  if (this.wizard.addingNew) {
    this.wizard.resetData();
  }
  // The summary that appears just before the final confirm screen
  // features different buttons in the footer.
  this.wizard.showSummaryConfirmScreen  = false;
  this.wizard.showFinalSummaryFooter    = false;
  this.wizard.showFinalConfirmScreen    = false;
  this.wizard.data.userExists           = false;
  this.wizard.data.employeeProfileId    = 0;

  this.showCancel = false;

  this.modalAction = this.wizard.modalAction;

  /**
   * Shows our confirmation screen
   */
  this.showConfirmScreen = function EditDependentModalCtrl__showConfirmScreen() {
    this.wizard.showFinalSummaryFooter = false;
    this.wizard.showFinalConfirmScreen = true;
  };

  /**
   * Reset the state of the modal and all data associated with modal forms
   */
  this.reset = function EditDependentModalCtrl__reset() {
    this.wizard.resetData();
    this.wizard.showSummaryConfirmScreen  = false;
    this.wizard.showFinalConfirmScreen    = false;
    this.wizard.showFinalSummaryFooter    = false;

    this.showCancel = false;
  };

  /**
   * Closes the modal and redirects us to `route`
   * @param  {String} route Path to redirect towards
   */
  this.closeModalAndRedirect = function EditDependentModalCtrl__closeModalAndRedirect(route) {
    this.closeModal();
    $location.path(route);
  };

  /**
   * Wizard's hook function for returning the next step
   * @param  {String}   method Current step's method
   * @param  {Function} fn     Callback function (optional).
   */
  this.hook = function EditDependentModalCtrl__hook(method, fn) {
    if (!_.isFunction(WizardService.currentStep[method])) {
      return fn();
    }

    WizardService.currentStep[method](WizardService.currentStep, fn);
  };

  /**
   * This function gets called whenever we've completed a step
   * when filling out the form
   */
  this.completeStep = function EditDependentModalCtrl__completeStep() {
    if (!WizardService.steps[WizardService.stepIndex+1]) {
      // don't use "this" since we lose scope on the return function
      Ctrl.closeModal();
      return;
    }

    WizardService.subStepIndex = 0;
    WizardService.stepIndex++;
  };

  /**
   * This function gets called whenever we've completed a sub-step (e.g. benefits)
   */
  this.completeSubStep = function EditDependentModalCtrl__completeSubStep() {
    WizardService.skip = false;
    WizardService.subStepIndex++;
    $rootScope.$broadcast("coverage:init");
  };

  /**
   * Wizard's `continue` hook function, runs logic for Wizard as to which hook we should proceed towards next
   * @param  {String} action Which hook/method/action
   */
  this.continue = function EditDependentModalCtrl__continue(action) {
    if (!action || !_.isString(action)) {
      action = 'finished';
    }

    var completedStep = !WizardService.sidebar || WizardService.subStepIndex >= (WizardService.sidebar.length - 1);

    // if we've completed the entire step, return a function that will be invoked for the "completed" state
    // otherwise just increment the subStepIndex
    var callback = completedStep ? function() { return Ctrl.hook('completed', Ctrl.completeStep); } : this.completeSubStep;
    this.hook(action, callback);
  };

  /**
   * Runs our `continue` function whenever we want to skip a certain step
   * such as adding new dependents
   */
  this.skip = function EditDependentModalCtrl__skip() {
    this.continue('skipped');
  };

  /**
   * Closes modal or pops up confirmation based off of our
   * current location within filling out the form
   * @param  {Boolean} confirmation If false, skips asking for confirmation as to whether or not we want to cancel
   */
  this.cancel = function EditDependentModalCtrl__cancel(confirmation) {
    closeModalInstance();
  };

  this.closeModal = function EditDependentModalCtrl__closeModal() {
    closeModalInstance();
  };

  function EditDependentModalCtrl__modal_hide(e) {
    closeModalInstance();
  }

  $rootScope.$on("modal:hide", EditDependentModalCtrl__modal_hide);

  // Prevent memory leaks caused by listening to $rootScope
  // (http://stackoverflow.com/questions/18856341/how-can-i-unregister-a-broadcast-event-to-rootscope-in-angularjs)
  $scope.$on("$destroy", function EditDependentModalCtrl__destroy() {
    EditDependentModalCtrl__modal_hide();
  });

  function closeModalInstance() {
    if (!_.isUndefined(WizardService.timeout)) {
      $timeout.cancel(WizardService.timeout);
      WizardService.timeout = undefined;
    }

    if ($modalInstance && _.isFunction($modalInstance.close)) {
      Ctrl.reset();
      $modalInstance.close();
    }
  }
}

module.exports = ['$uibModalInstance', '$rootScope', '$scope', '$location', 'WizardService', '$timeout', EditDependentModalCtrl];
