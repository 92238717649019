/**
* A skeleton prototype of UserFactory. The service handles
* HTTP requests related to fetching, creating and updating user data.
*/

var _ = require('lodash');
var dottie = require('dottie');

function UserFactory($rootScope, $q, $http, $log, $filter, RoleService, RequestFactory, UtilFactory) {
	var service = {};

  service.basicOptions = {
    headers: {},
    ignoreAuth: true
  };

  /**
   * Removes all of the cookies associated with your tokens and removes details from currentUser
   */
  service.forgetMe = function UserFactory__forgetMe() {
    $rootScope.currentUser = null;
  }

  /**
   * Returns the correct path depending on our role
   * @param  {String} user User's data/object
   * @return {String}      The redirect path/location
   */
  service.roleHomePath = function UserFactory__roleHomePath(user) {
    if (_.isString(user.role)) {
      if ( user.role.toLowerCase() === 'lifemapadmin' ) {
        $rootScope.hideGroup = true;
        return '/chooseGroup';

      } else if (user.role.toLowerCase() === 'primaryadmin' && user.multiUser) {
        $rootScope.hideGroup = true;
        return '/chooseGroup';

      } else {
        $rootScope.hideGroup = false;
        return '/manageEmployees';
      }
    } else {
      this.logout;
    }
  }

  // Given a name such as Michael Smith or Michael Anthony Smith,
  // return "Smith, Michael"
  service.flipName = function UserFactory__flipName(userName) {
    var nameParts, totalNames;

    if (typeof userName !== 'string' || userName.length === 0) {
      $log.error('Expected user name to be non-empty string.');
      return false;
    }

    nameParts = userName.split(' ');
    totalNames = nameParts.length;

    if (totalNames < 2) {
      $log.error('Expected at least a first and last name.');
      return false;
    }

    return $filter('capitalize')(nameParts[totalNames - 1] + ', ' + nameParts[0]);
  };

  /**
   * Sends a request to invite an admin
   * @param  {Object} user New admin's information
   * @return {Promise}     RequestFactory's `response` key
   */
  service.inviteAdmin = function UserFactory__inviteAdmin(user) {
    return RequestFactory.send({
      url: '/api/user/invite',
      method: 'POST',
      data: user
    });
  };

  /**
   * Sends a request to delete a user
   * @param  {String} userID User's userName
   * @return {Promise}       RequestFactory's `response` key
   */
  service.deleteUser = function UserFactory__deleteUser(userID) {
    return RequestFactory.send({
      method: "DELETE",
      url: "/api/user/" + UtilFactory.encodeURLParam(userID)
    });
  };

  /**
   * Sends a request to list all of our users
   * @return {Promise} RequestFactory's `response` key
   */
  service.getAdminsList = function UserFactory__getAdminsList() {
    return RequestFactory.send("/api/user/listusers");
  };

  // These should be broken into standalone methods if they become complex
  /**
   * Sends a request to login
   * @param  {String} username Our username
   * @param  {String} password Our password
   * @return {Promise}         RequestFactory's `response.data` key
   */
  service.login = function UserFactory__login(username, password) {
    var opts = this.basicOptions;

    opts.method = 'POST';
    opts.data = {
      username: username,
      password: password,
    };
    opts.url    = "/api/user/login?grant_type=password";

    return RequestFactory.send(opts, 'data');
  };

  /**
   * Sends a request for checking our email
   * @param  {String} email Our email address
   * @return {Promise}      RequestFactory's `response` key
   */
  service.checkEmail = function UserFactory__checkEmail(email) {
    return RequestFactory.send({
      url: "/api/user/forgotPassword",
      method: 'POST',
      data: {email: email},
      ignoreAuth: true
    });
  };

  /**
   * Sends a request to verify new email address
   * @param  {String} token         Verify Email token
   * @return {Promise}              RequestFactory's `response` key
   */
  service.verifyEmail = function UserFactory__verifyEmail(token) {
    return RequestFactory.send({
      url: "/api/user/verifyemail/" + UtilFactory.encodeURLParam(token),
      ignoreAuth: true
    });
  };

  /**
   * Sends a request to verify SSN for email update
   * @param  {String} token  Verify Email token
   * @param  {String} SSN    Last 4 digits
   * @return {Promise}       RequestFactory's `response` key
   */
  service.verifySSN = function UserFactory__verifySSN(token, SSN) {
    return RequestFactory.send({
      url: "/api/user/verifyemail/" + UtilFactory.encodeURLParam(token),
      method: 'POST',
      data: {ssn: SSN}
    });
  };

  /**
   * Sends a request to log out the current user
   * @param  {String} userId Our Username
   * @return {Promise}       RequestFactory's `response` key
   */
  service.logout = function UserFactory__logout(userId) {
    return RequestFactory.send({
      url: '/api/user/' + UtilFactory.encodeURLParam(userId) + '/signout',
      method: 'POST'
    });
  };

  /**
   * Sends a request to refresh our token
   * @param  {String} token Our refresh_token value
   * @return {Promise}      $http's Promise
   */
  service.refreshToken = function UserFactory__refreshToken() {
    return RequestFactory.send({
      url: '/api/user/login/refreshToken',
      method: 'GET'
    });

  };

  // Add properties such as a friendly date and role description to a user object
  /**
   * Adds metadata properties to a user
   * @param {Object} user User's data/object
   */
  service.addMetaProps = function UserFactory__addMetaProps(user) {
    var date, month;

    // TODO: The server should probably return a timestamp with the /api/users
    // call. This date is a placeholder until that is added.
    var timestampExists = true;

    if (timestampExists) {
      date = new Date();
      month = 'Jan,Feb,Mar,Apr,May,Jun,Jul,Aug,Sep,Oct,Nov,Dec'.split(',')[date.getMonth()];
      user.friendlyDate = [month, ' ', date.getDate(), ', ', date.getFullYear()].join('');
    }

    user.flippedName = service.flipName(user.firstName + ' ' + user.lastName);

    user.friendlyRole = '';
    switch (user.role.toLowerCase()) {
      case 'primary':
      case 'primaryadmin':
        user.friendlyRole = 'Primary';
        break;
      case 'secondary':
      case 'secondaryadmin':
        user.friendlyRole = 'Secondary';
        break;
    }

    if (user.multiUser) {
      user.friendlyRole = 'Third-Party';
    }

    if (angular.isDefined(user.invitedOn)) {
      user.invitedOn = moment(user.invitedOn).toDate();
    }

    return user;
  };

  /**
  * @return {Array} All the divisions to which a user may belong
  */
  service.getDivisions = function UserFactory__getDivisions() {
    var deferred = $q.defer();

    service.getActiveUser().then(function (user) {
      return deferred.resolve(user.divisions);
    }, function (error) {
      $log.error('Error while retrieving active user.', error);
      return deferred.reject(error);
    });

    return deferred.promise;
  };

  /**
   * Sends a request to retrieve our roster
   * @param  {Object} options  ssnMask options. Not really applicable.
   * @return {Promise}         Returns a list of mapped user data
   */
  service.getRoster = function UserFactory__getRoster(options) {
    var deferred = $q.defer();

    if (!angular.isObject(options)) {
      options = {};
    }

    if (!angular.isString(options.ssnMask)) {
      options.ssnMask = '#';
    }

    $http({
      method: 'GET',
      url: '/api/employee/roster'
    }).then(success, deferred.reject);

    function replaceSSN(number, i) {
      if (number !== '-' && i < 5) {
        return options.ssnMask;
      }

      return number;
    }

    function mapUser(user) {
      user.maskedSSN = '';
      user.maskedSSNDisplay ='';

      // show nothing if there is no SSN
      if (user.ssn) {
        user.maskedSSN = user.ssn.split('').map(replaceSSN).join('');
        user.maskedSSNDisplay = '###-##-' + user.maskedSSN.replace(/[^0-9]/g, '');
      }
      user.divisionId = user.division;

      return user;
    }

    function success(res) {
      if (!res || !res.data || !angular.isArray(res.data.data)) {
        return deferred.resolve(res);
      }

      res.data.data = res.data.data.map(mapUser);

      deferred.resolve(res);
    }

    return deferred.promise;
  };

  /**
   * Sends a request to retrieve information for a specific user
   * @param  {String} id User's ID/username
   * @return {Promise}   RequestFactory's `response.data` key
   */
  service.getUser = function UserFactory__getUser(id) {
    return RequestFactory.send('/api/user/' + UtilFactory.encodeURLParam(id), 'data');
  };

  /**
   * Sends a request to get the current active user
   * @return {Promise} RequestFactory's `response.data`
   */
  service.getActiveUser = function UserFactory__getActiveUser() {
    var deferred = $q.defer();
    var defaultRes = angular.copy((angular.isArray($http.defaults.transformResponse) ? $http.defaults.transformResponse : []));

    RequestFactory.send({
      url: '/api/user',
      method: 'GET',
      transformResponse: defaultRes.concat(function (res) {
        var user = dottie.get(res, 'data', res);
        if (!angular.isObject(user) || angular.isUndefined(user.role)) {
          return res;
        }

        user.roleHuman = RoleService.toHuman(user.role, user.multiUser);

        $rootScope.currentUser = user;
        return res;
      })
    }, 'data').then(deferred.resolve, deferred.reject);

    return deferred.promise;
  };

  /**
   * Sends a request to send an email
   * @param  {Object} data Email properties/data/object
   * @return {Promise}     RequestFactory's `response` key
   */
  service.sendEmail = function UserFactory__sendEmail(data) {
    return RequestFactory.send({
      url: '/sendEmail',
      method: 'POST',
      data: data
    });
  };

  /**
   * Sends a request to send a verification
   * @param  {Object} data     Verification's data
   * @param  {String} username Username
   * @return {Promise}         RequestFactory's `response` key
   */
  service.sendVerifyEmail = function UserFactory__sendVerifyEmail(data, username) {
    return RequestFactory.send({
      url: '/api/user/' + UtilFactory.encodeURLParam(username) + '/sendverify',
      method: 'POST',
      data: data
    });
  };

  /**
  * Two different updateUser functions were added to UserFactory.
  * One accepted userID and userObject as arguments; the other simply
  * accepted userObject. This method accommodates both types of calls.
  */
  service.updateUser = function UserFactory__updateUser() {
    var id = 0;
    var userObj = {};
    var totalArgs = arguments.length;

    switch (totalArgs) {
      case 1:
        userObj = arguments[0];
        id = userObj.userName;
      break;
      case 2:
        id = arguments[0];
        userObj = arguments[1];
      break;
    }

    return RequestFactory.send({
      url: '/api/user/' + UtilFactory.encodeURLParam(id),
      method: 'PUT',
      data: userObj
    });
  };

	service.updateUserPassword = function UserFactory__updateUserPassword(user) {
		return RequestFactory.send({
			url: '/api/user/' + UtilFactory.encodeURLParam(user.userName),
			method: 'PUT',
			data: {
				oldpassword: user.oldpassword,
				password: user.password,
				confirmPassword: user.confirmPassword
			}
		});
	}

  /**
   * Sends a request to check a reset link
   * @param  {String} token         Forgot Password reset token
   * @return {Promise}              RequestFactory's `response` key
   */
  service.checkResetLink = function UserFactory__checkResetLink(token) {
    return RequestFactory.send("/api/user/forgotPassword/" + UtilFactory.encodeURLParam(token));
  };

  /**
   * Sends a request to update our password
   * @param  {String} token           Forgot Password reset token
   * @param  {String} newPassword     New password
   * @param  {String} confirmPassword Confirmation of new password
   * @return {Promise}                RequestFactory's `response` key
   */
  service.updatePassword = function UserFactory__updatePassword(token, newPassword, confirmPassword) {
    return RequestFactory.send({
      url: '/api/user/forgotPassword/' + UtilFactory.encodeURLParam(token),
      method: 'POST',
      data: {
        newPassword: newPassword,
        confirmPassword: confirmPassword
      }
    });
  };

  /**
   * Get's the groups belonging to the user
   * @return {Promise} RequestFactory's `response` key
   */
  service.getGroups = function UserFactory_getGroups () {
    return RequestFactory.send('/api/groups');
  }

  /**
   * Sets the user's group
   * @param {Object} user  User's object/data
   * @param {Object} group Group's object/data
   * @return {Promise} RequestFactory's `response` key
   */
  service.setGroup = function UserFactory_setGroup (user, group) {
    if (!user || !user.userName || !group || !group.groupNumber) {
      throw new TypeError('Invalid arguments.');
    }

    return RequestFactory.send({
      url: '/api/user/' + UtilFactory.encodeURLParam(user.userName) + '/setGroup',
      method: 'PUT',
      data: {
        groupNumber: group.groupNumber
      }
    });
  }

  return service;
}

module.exports = ['$rootScope', '$q', '$http', 'LogFactory', '$filter', 'RoleService', 'RequestFactory', 'UtilFactory', UserFactory];
