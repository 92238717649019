
/**
  * @ngdoc tobacco
  * @name tobacco
  * @restrict E
  *
  * @description
  * Renders a yes/no questionare for tobacco smoking
  *
  *
  * @param {object}   formdata  Two-way binding ng-model for the tobacco data
  * @param {boolean}  require   Boolean value as to whether or not we should require this input
  * @param {function} clearfn   Function to execute when a change has occurred, useful for removing/clearing an error
  *
  * @example
      <tobacco formdata="Ctrl.formData" required="Ctrl.showTobacco"></tobacco>
*/

function tobaccoDirective() {
  return {
    restrict: "E",
    templateUrl: "/partials/partials/tobacco",
    scope: {
      formdata:     "=",
      required:     "=",
      setDisabled:  "=",
      clearfn:      "&?"
    }
  };
}

module.exports = tobaccoDirective;
