
var _       = require('lodash');
var dottie  = require('dottie');
var routeIsUnauthorized = require('../utils/route-auth');

/**
 * Attempts to refresh our token whenever `event:loginRequired` is emitted
 * @param {Service} $location   AngularJS $location
 * @param {Service} $log        AngularJS $log
 * @param {Service} $rootScope  AngularJS $rootScope
 * @param {Service} httpBuffer  Out httpBuffer service
 * @param {Service} UserFactory Our UserFactory service
 */
function RefreshToken($location, $log, $rootScope, httpBuffer, UserFactory) {
  $rootScope.$on("event:loginRequired", function RefreshToken__rootScope_loginRequired($e, options) {
    options = _.isObject(options) ? options : {};
    options.refresh = _.has(options, 'refresh') ? options.refresh : true;
    if ($rootScope.currentUser && !_.isUndefined($rootScope.expiresIn)) {
      if (options.refresh) {
        UserFactory.refreshToken().then(function RefreshToken__rootScope_loginRequired__refreshToken_success(res) {
            console.log('REFRESH TOKEN res', res);
            $rootScope.expiresIn     = dottie.get(res, 'data.data.token.expires_in', 0);
            // After token is refreshed, update the timer with the proper time
            $rootScope.$broadcast("token:expires", res.data.data.token.expires_in);

          var updater = function(config) {
            return config;
          };

          httpBuffer.retryAll(updater);
        }, function RefreshToken__rootScope_loginRequired__refreshToken_error(res) {
          console.log('REFRESH TOKEN ERROR res', res);

          $log.error(res);
          if (!routeIsUnauthorized($location.path())) {
            UserFactory.forgetMe();
            $rootScope.sessionExpired = !_.isObject($rootScope.expiresIn);
            $location.url("/login");
          }
        });
      }
    } else {
      if (!routeIsUnauthorized($location.path())) {
        $rootScope.sessionExpired = !_.isObject($rootScope.expiresIn);
        $location.url("/login");
      }
    }
  });
}

module.exports = ['$location', '$log', '$rootScope', 'HttpBuffer', 'UserFactory', RefreshToken];
