
var _      = require('lodash');
var dottie = require('dottie');

function EditEmailSecurityCheckCtrl ($location, $routeParams, $route, $rootScope, $http, $log, UserFactory, $timeout, $cookieStore) {
  var Ctrl = this;

  this.title              = '';
  this.ssn                = '';
  this.userName           = '';
  this.ssnVerified        = false;
  this.message            = '';
  this.startSecurityCheck = false;
  this.user               = {ssn: ''};
  this.showSSNError       = false;
  this.step               = 'confirm';

  // End the user's session
  $timeout(function EditEmailSecurityCheckCtrl__timeout() {
    if ($rootScope.currentUser && $rootScope.currentUser.userName) {
      UserFactory.logout($rootScope.currentUser.userName).then(function () {
        $rootScope.currentUser = null;
        $cookieStore.remove('expires_in');
        $cookieStore.remove('access_token');
        $cookieStore.remove('refresh_token');

        verifyEmailLink();
      }, angular.noop);
    } else {
      verifyEmailLink();
    }
  }, 0);

  function verifyEmailLink() {
    UserFactory.verifyEmail($routeParams.token).then(function EditEmailSecurityCheckCtrl__verifyEmailLink__verifyEmail_success(res) {
      var userName = dottie.get(res, 'data.data.userName');
      if (_.isString(userName)) {
        Ctrl.userName = userName;
      }

      securityCheck();
    }, function EditEmailSecurityCheckCtrl__verifyEmailLink__verifyEmail_error() {
      $location.url("/link/expired");
    });
  }

  /**
   * Show the confirm SSN view
   */
  function securityCheck() {
    Ctrl.startSecurityCheck = true;
    Ctrl.title    = 'Confirm Your Email Address';
    Ctrl.message  = 'For security purposes we need to confirm your identity.';
  }

  this.nextStep = function EditEmailSecurityCheckCtrl__nextStep(user) {
    user = user || {};

    if (this.ssnVerified) {
      $location.path('/login');
      return;
    }

    this.verifySSN(user.ssn);
  };

  this.goToLogin = function EditEmailSecurityCheckCtrl__goToLogin() {
    $location.path('/login');
  };

  function editSuccess() {
    Ctrl.step = 'success';
    Ctrl.ssnVerified = true;

    $rootScope.currentUser = null;
    $cookieStore.remove('expires_in');
    $cookieStore.remove('access_token');
    $cookieStore.remove('refresh_token');

    Ctrl.title    = 'Email Successfully Updated';
    Ctrl.message  = 'Congratulations! Your email has been successfully ' +
      'changed. Please click "Okay" and sign into the system to continue.';
    Ctrl.okButtonText = 'OK';
  }

  function handleVerifySSNResp (resp) {
    var respCode = resp.status;

    Ctrl.userName = resp.userName;

    switch (parseInt(respCode, 10)) {
      case 200: editSuccess(); break;
      case 400: Ctrl.showSSNError = true; break;
      case 403: Ctrl.showSSNError = true; break;
      case 409: Ctrl.showSSNError = true; break;
    }
  }

  this.reset = function EditEmailSecurityCheckCtrl__reset() {
    Ctrl.showSSNError = false;
  };

  /**
   * Verifies our ssn
   * @param  {String|Number} ssn Social securty number
   */
  this.verifySSN = function EditEmailSecurityCheckCtrl__verifySSN(ssn) {
    this.showSSNError = false;
    UserFactory.verifySSN($routeParams.token, ssn).then(handleVerifySSNResp, handleVerifySSNResp);
  };
}

module.exports = ["$location", "$routeParams", "$route", "$rootScope", "$http", "LogFactory", "UserFactory", "$timeout", "$cookieStore", EditEmailSecurityCheckCtrl];
