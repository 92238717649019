
var _ = require('lodash');

/**
  * @ngdoc hasValue
  * @name $filter:hasValue
  *
  * @description
  * Flters for objects that contain a certain value
  *
  *
  * @example
      <div ng-repeat="list | hasValue:'mainKey':'secondKey'"></div>
*/

function hasValue() {
  return function (inputs, mainKey, key, val) {
    var output = [];
    _.each(inputs, function (input) {
      _.each(input[mainKey], function (_input) {
        if (_input[key] && val.indexOf(_input[key]) !== -1) {
          output.push(input);
        }
      });
    });

    return output;
  };
}

module.exports = hasValue;
