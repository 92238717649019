// Array of salary frequencies
MOB.app.constant('SALARY_FREQUENCIES', [
  { code: '', description: '--' },                                      
  { code: 'A', description: 'Annually' },
  { code: 'B', description: 'Bi-weekly' },
  { code: 'H', description: 'Hourly' },
  { code: 'M', description: 'Monthly' },
  { code: 'S', description: 'Semi-monthly' },
  { code: 'W', description: 'Weekly' }
]);

MOB.app.constant('PENDING_CHANGE_METADATA', {
  'HD':   { description: 'Hire Date',               template: 'hire-date',    controller: 'EditHireDateModalCtrl' },
  'HDC':  { description: 'Hire Date Change',        template: 'hire-date',    controller: 'EditHireDateModalCtrl' },
  'HDR':  { description: 'Rehire',                  template: 'hire-date',    controller: 'EditHireDateModalCtrl' },
  'SA':   { description: 'Salary',                  template: 'salary',       controller: 'EditSalaryModalCtrl'   },
  'SAI':  { description: 'Salary Change',           template: 'salary',       controller: 'EditSalaryModalCtrl'   },
  'SAD':  { description: 'Salary Change',           template: 'salary',       controller: 'EditSalaryModalCtrl'   },
  'WH':   { description: 'Work Hour Change',        template: 'work-hour',    controller: 'EditWorkHourModalCtrl' },
  'WHI':  { description: 'Work Hour Increase',      template: 'work-hour',    controller: 'EditWorkHourModalCtrl' },
  'WHD':  { description: 'Work Hour Decrease',      template: 'work-hour',    controller: 'EditWorkHourModalCtrl' },
  'CS':   { description: 'Division/Class',          template: 'class',        controller: 'EditClassModalCtrl'    },
  'MT':   { description: 'Member Termination',      template: 'status',       controller: 'EditStatusModalCtrl'   },
  'CO':   { description: 'Coverage Change',         template: 'coverage',     controller: 'EditCoverageModalCtrl' },
  'COA':  { description: 'Coverage Added',          template: 'coverage',     controller: 'EditCoverageModalCtrl' },
  'COD':  { description: 'Coverage Deleted',        template: 'coverage',     controller: 'EditCoverageModalCtrl' },
  'CP':   { description: 'Covered Person Change',   template: 'coverage',     controller: 'EditCoverageModalCtrl' },
  'CPA':  { description: 'Added Covered Person',    template: 'coverage',     controller: 'EditCoverageModalCtrl' },
  'CPD':  { description: 'Remove Covered Person',   template: 'coverage',     controller: 'EditCoverageModalCtrl' },
  'CV':   { description: 'Coverage Volume Change',  template: 'coverage',     controller: 'EditCoverageModalCtrl' },
  'OC':   { description: 'Occupation Change',       template: 'occupation',   controller: 'EditOccupationModalCtrl'}
});

MOB.app.constant('STATE_LIST', [
  '--','AL','AK','AZ','AR','CA','CO','CT','DE','FL','GA',
       'HI','ID','IL','IN','IA','KS','KY','LA','ME','MD',
       'MA','MI','MN','MS','MO','MT','NE','NV','NH','NJ',
       'NM','NY','NC','ND','OH','OK','OR','PA','RI','SC',
       'SD','TN','TX','UT','VT','VA','WA','WV','WI','WY', 
       'AS','DC','FM','GU','MH','MP','PW','PR','VI','UM',
       'AA','AE','AP'

]);
