
/**
  * @ngdoc fileModel
  * @name fileModel
  * @restrict A
  *
  * @description
  * Provides a basic file upload field
  *
  *
  * @param {string}   fileModel     File model name to retrieve file data
  *
  * @example
      <input type="file" file-model="myFile"/>
*/

function fileModelDirective($q, $parse) {
  return {
    restrict: "A",
    link: function(scope, element, attrs) {
      var model = $parse(attrs.fileModel);
      var modelSetter = model.assign;
      
      element.bind('change', function() {
        scope.$apply(function() {
          var file = element[0].files[0];
          getFileBuffer(file).then(function(resp) {
            modelSetter(scope, resp);
          });
        });
      });
    }
  };

  function getFileBuffer(file) {
    var deferred = new $q.defer();
    var reader = new FileReader();
    reader.onloadend = function(e) {
      deferred.resolve(e.target.result.split(',').pop());
    };
    reader.onerror = function(e) {
      deferred.reject(e.target.error);
    };
    reader.readAsDataURL(file);
    return deferred.promise;
  }
}

module.exports = ['$q', '$parse', fileModelDirective];
