
/**
  * @ngdoc isEmptyObj
  * @name $filter:isEmptyObj
  *
  * @description
  * Flters for objects that are empty
  *
  *
  * @example
      <div ng-repeat="list | isEmptyObj"></div>
*/

function IsEmptyObj() {
  return function (obj) {
    var bar;

    for (bar in obj) {
      /* istanbul ignore else */
      if (obj.hasOwnProperty(bar)) {
        return false;
      }
    }
    return true;
  };
}

module.exports = IsEmptyObj;
