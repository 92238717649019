
var _ = require('lodash');

function EditStatusModalCtrl ($rootScope, $scope, $timeout, WizardService, $log, PendingChangeDecorator, FormFactory, EmployeeFactory, UtilFactory) {

  var Ctrl = this;
  PendingChangeDecorator(Ctrl, $rootScope, $scope);
  Ctrl.wizard = WizardService;
  Ctrl.editTabType = "Status";
  Ctrl.step = 0;

  /**
   * Reset the state of the modal and all data associated with modal forms
   */
  Ctrl.reset = function EditStatusModalCtrl__reset() {
    WizardService.resetData();
    Ctrl.step = 0;
  };

  /**
   * Initializes data and gets qualifying events
   */
  Ctrl.init = function EditStatusModalCtrl__init() {
    Ctrl.employeeInfo = Ctrl.wizard.data.employeeInfo;
    Ctrl.pendingChange = Ctrl.wizard.data.pendingChange.originalData;
    Ctrl.reasonCodes = Ctrl.wizard.data.reasonCodes;

    Ctrl.memberType = Ctrl.pendingChange.effectedPeople[0].relationship == "EE" ? "Employee": "Dependent";

    Ctrl.wizard.modalTitle = Ctrl.memberType + " Status (Pending)";
    Ctrl.headerType = Ctrl.memberType=="Employee"?"Status":"Dependent's Status";

    var requestedChangeDate = moment(this.pendingChange.requestedChangeDate);
    var terminationDateDropDown = {
      dateSeg1: requestedChangeDate.format('MM'),
      dateSeg2: requestedChangeDate.format('DD'),
      dateSeg3: requestedChangeDate.format('YYYY')
    };

    Ctrl.formData = {
      terminationTypeCodeDropDown: _.find(Ctrl.reasonCodes, { 'code': Ctrl.pendingChange.terminationTypeCode }),
      terminationDateDropDown: terminationDateDropDown
    };

    Ctrl.cancelFormData = {
      cancelPendingChange: false
    };

    Ctrl.originalFormData = _.cloneDeep(Ctrl.formData);
    Ctrl.originalCancelFormData = _.clone(Ctrl.cancelFormData);
  };

  Ctrl.showSummary = function EditStatusModalCtrl__showSummary() {
    if (!Ctrl.cancelFormData.cancelPendingChange) {
      var errors = this.validateForm();

      if (_.keys(errors.errors).length > 0) {
        Ctrl.friendlyErrors  = errors.friendlyErrors;
        Ctrl.formData.errors = errors.errors;
        return false;
      }

      Ctrl.summaryObj = {
        terminationDateString: UtilFactory.dropdownValuesToDateString(Ctrl.formData.terminationDateDropDown)
      };

      if (Ctrl.formData.terminationTypeCodeDropDown) {
        Ctrl.summaryObj.terminationTypeDescription = Ctrl.formData.terminationTypeCodeDropDown.description;
      }
    }

    Ctrl.step = 1; // There are no form errors, so advance to the summary/confirmation
  }

  Ctrl.savePendingChange = function EditStatusModalCtrl__savePendingChange() {
    if (Ctrl.cancelFormData.cancelPendingChange) {
      EmployeeFactory.deletePendingMemberTerminatedChange(Ctrl.pendingChange.id).then(onSuccess, populateErrors);
      return;
    }

    var postData = {
      requestedChangeDate: Ctrl.summaryObj.terminationDateString
    };

    if(Ctrl.formData.terminationTypeCodeDropDown && Ctrl.formData.terminationTypeCodeDropDown.code){
      postData.terminationTypeCode = Ctrl.formData.terminationTypeCodeDropDown.code;
    }

    EmployeeFactory.updatePendingMemberTerminatedChange(Ctrl.pendingChange.id, postData).then(onSuccess, populateErrors);

    function onSuccess(success) {
      Ctrl.step = 2; // The update was successfull, so advance to the final confirmation message
      $timeout(function() {
        Ctrl.closeModal();
      }, 5000);
    }

    function populateErrors(res) {
      var errors = $log.errorHandler(res);
      Ctrl.formData.errors = errors.errors;
    }
  }

  Ctrl.validateForm = function EditStatusModalCtrl__validateForm() {
    return FormFactory.validate(Ctrl.wizard.form, {
      validations: [
        FormFactory.validations.date(Ctrl.formData.terminationDateDropDown, {key: 'terminationDate', after: 'dotAfterHire', compareDate: Ctrl.employeeInfo.hireDate}),
        FormFactory.validations.date(Ctrl.formData.terminationDateDropDown, {key: 'terminationDate', after: 'dotPast3Months', compareDate: moment().subtract(3, 'months')}),
        FormFactory.validations.date(Ctrl.formData.terminationDateDropDown, {key: 'terminationDate', before: 'dotBefore3Months', compareDate: moment().add(3, 'months')})
      ]
    });
  }

  Ctrl.hasEditFormChanged = function EditStatusModalCtrl__hasEditFormChanged() {
    if (!Ctrl.onEditTab) return false;

    if (Ctrl.formData.terminationTypeCodeDropDown && Ctrl.formData.terminationTypeCodeDropDown.code) {
      if (Ctrl.formData.terminationTypeCodeDropDown.code !== Ctrl.originalFormData.terminationTypeCodeDropDown.code) {
        return true;
      }
    }

    if (Ctrl.formData.terminationDateDropDown && !UtilFactory.dateDropDownsAreEqual(Ctrl.formData.terminationDateDropDown, Ctrl.originalFormData.terminationDateDropDown)) {
      return true;
    }

    return false;
  }

  Ctrl.init();
}

module.exports = ['$rootScope', '$scope', '$timeout', 'WizardService', 'LogFactory', 'PendingChangeDecorator', 'FormFactory', 'EmployeeFactory', 'UtilFactory', EditStatusModalCtrl];
