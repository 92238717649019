
var _ = require('lodash');

function EditOccupationModalCtrl ($rootScope, $scope, $timeout, WizardService, $log, PendingChangeDecorator, FormFactory, EmployeeFactory, UtilFactory) {

  var Ctrl = this;
  PendingChangeDecorator(Ctrl, $rootScope, $scope);
  Ctrl.wizard = WizardService;
  Ctrl.wizard.modalTitle = "Occupation (Pending)";
  Ctrl.editTabType = "Occupation";
  Ctrl.headerType = "Occupation";
  Ctrl.step = 0;

  /**
   * Reset the state of the modal and all data associated with modal forms
   */
  Ctrl.reset = function EditOccupationModalCtrl__reset() {
    WizardService.resetData();
    Ctrl.step = 0;
  };

  /**
   * Initializes data and gets qualifying events
   */
  this.init = function EditOccupationModalCtrl__init() {
    Ctrl.employeeInfo = Ctrl.wizard.data.employeeInfo;
    Ctrl.pendingChange = Ctrl.wizard.data.pendingChange.originalData;

    var today = moment();
    Ctrl.formData = {
      occupation: Ctrl.pendingChange.occupation,
      requestedChangeDateDropDown: {}
    };

    Ctrl.cancelFormData = {
      cancelPendingChange: false
    };
    
    if (Ctrl.pendingChange.requestedChangeDate) {
       Ctrl.formData.requestedChangeDateDropDown = UtilFactory.dateStringToDropdownObj(Ctrl.pendingChange.requestedChangeDate);
     }

    Ctrl.originalFormData = _.clone(Ctrl.formData);
    Ctrl.originalCancelFormData = _.clone(Ctrl.cancelFormData);
  };

  this.showSummary = function EditOccupationModalCtrl__showSummary() { 
	  
    var errors = this.validateForm(); 
	
	if (_.keys(errors.errors).length > 0) {
	   Ctrl.friendlyErrors  = errors.friendlyErrors;
	   Ctrl.formData.errors = errors.errors;
	   return false;
	}
	
    Ctrl.summaryObj = {
     requestedChangeDate: UtilFactory.dropdownValuesToDateString(Ctrl.formData.requestedChangeDateDropDown)
    }

    Ctrl.step = 1; 
  }

  this.savePendingChange = function EditOccupationModalCtrl__savePendingChange() {
    if (Ctrl.cancelFormData.cancelPendingChange) {
      EmployeeFactory.deletePendingOccupation(Ctrl.pendingChange.id).then(onSuccess, populateErrors);
      return;
    }

    var postData = {
      occupation: Ctrl.formData.occupation,
      requestedChangeDate: Ctrl.summaryObj.requestedChangeDate
    }

    EmployeeFactory.updatePendingOccupationChange(Ctrl.pendingChange.id, postData).then(onSuccess, populateErrors);

    function onSuccess(success) {
      Ctrl.step = 2; // The update was successfull, so advance to the final confirmation message
      $timeout(function() {
        Ctrl.closeModal();
      }, 5000);
    }
    

    function populateErrors(res) {
      var errors = $log.errorHandler(res);
      Ctrl.formData.errors = errors.errors;
    }
  }

  this.validateForm = function EditOccupationModalCtrl__validateForm() {
      return FormFactory.validate(Ctrl.wizard.form, {
        validations: [
          FormFactory.validations.occupation(Ctrl.formData.occupation),
          FormFactory.validations.date(Ctrl.formData.requestedChangeDateDropDown, {key: 'requestedChangeDate', after: 'rcdPast3Months', compareDate: moment().subtract(3, 'months'), condition: !Ctrl.isInEligiblePeriod}),
          FormFactory.validations.date(Ctrl.formData.requestedChangeDateDropDown, {key: 'requestedChangeDate', before: 'rcdPast', compareDate: moment().add(3, 'months'), condition: !Ctrl.isInEligiblePeriod})
        ]
      });
   }
  

  this.hasEditFormChanged = function EditOccupationModalCtrl__hasEditFormChanged() {
    if (!Ctrl.onEditTab) return false;

    if (Ctrl.formData.occupation !== Ctrl.originalFormData.occupation) {
      return true;
    }
    
    if (Ctrl.formData.requestedChangeDateDropDown) {
      if (!UtilFactory.dateDropDownsAreEqual(Ctrl.formData.requestedChangeDateDropDown, Ctrl.originalFormData.requestedChangeDateDropDown)) {
         return true;
        }
      }
    
    return false;
  }

  Ctrl.init();
}

module.exports = ['$rootScope', '$scope', '$timeout', 'WizardService', 'LogFactory','PendingChangeDecorator', 'FormFactory', 'EmployeeFactory', 'UtilFactory', EditOccupationModalCtrl];
