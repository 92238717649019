
function LoginPageCtlr($location, $log, $rootScope, $scope, $timeout, UserFactory) {
  var Ctrl = this;

  Ctrl.user         = {};
  Ctrl.unauthorized = false;
  Ctrl.errorMessage = '';

  if ($rootScope.sessionExpired) {
    Ctrl.sessionExpired = true;
    Ctrl.title  = "Your Session Has Expired";
    Ctrl.title2 = "Please Sign In to Continue";
  } else {
    Ctrl.sessionExpired = false;
    Ctrl.title  = "Please Sign In";
    Ctrl.title2 = "";

    $timeout(function LoginPageCtrl__sessionNotExpired_timeout() {
      if ($rootScope.currentUser && $rootScope.currentUser.role) {
        $location.path(UserFactory.roleHomePath($rootScope.currentUser));
      }
    }, 2 * 1000);
  }

  $scope.$on("$destroy", function() {
    $rootScope.sessionExpired = false;
  });

  /**
   * Submits login and adds login for the response
   * @param  {Object} user User's data object
   */
  Ctrl.submitLogin = function LoginPageCtlr__submitLogin(user) {
    UserFactory.login(user.username, user.password).then(function LoginPageCtlr__submitLogin_success(resp) {
      $rootScope.currentUser = resp.user;
      $rootScope.currentUser.group = resp.group;

      $rootScope.expiresIn    = resp.token.expires_in;

      $rootScope.$broadcast("token:expires", resp.token.expires_in);

      $location.path(UserFactory.roleHomePath(resp.user));
    }, function LoginPageCtlr__submitLogin_error(resp) {
      $log.error('Authentication failure.', resp);

      if (resp) {
          switch (parseInt(resp.status, 10)) {
          case 400:
            if (resp.message.indexOf('locked') !== -1) {
            
              Ctrl.errorMessage = 'Oops! Your account has been locked due too many failed log in attempts.<br><br>To unlock your account, please contact LifeMap Customer Service.';
            } else if (resp.message.indexOf('is disabled') !== -1) {
              Ctrl.errorMessage = 'Please contact LifeMap to complete your request to gain access to the Employer Admin Center.';
            } else {
              Ctrl.errorMessage = 'The email password combination does not match our records.';
            }
            break;
          case 401:
            Ctrl.errorMessage = 'The email password combination does not match our records.';
            break;
          case 403:
            if (resp.data.message.toLowerCase().indexOf('locked') !== -1) {
              Ctrl.errorMessage = 'Oops! Your account has been locked due too many failed log in attempts.<br><br>To unlock your account, please contact LifeMap Customer Service.';
            } else if (resp.data.message.toLowerCase().indexOf('disabled') !== -1) {
              Ctrl.errorMessage = 'Please contact LifeMap to complete your request to gain access to the Employer Admin Center.';
            } else {
              Ctrl.errorMessage = 'There was a problem logging in. Please refresh your page and try again. If the problem persists, please contact LifeMap.';
            }
            break;
          case 500:
            Ctrl.errorMessage = 'A server error has occurred. Please wait, and try logging in later. If the problem persists, please contact LifeMap.';
            break;
          default:
            Ctrl.errorMessage = 'An unknown error has occurred. Please try to log in again later. If the problem persists, please contact LifeMap.';
            break;
          }
      } else {
          Ctrl.errorMessage = 'There was a problem logging in. Please refresh your page and try again. If the problem persists, please contact LifeMap.';
      }

      Ctrl.unauthorized       = true;
      Ctrl.user.password      = "";
      $rootScope.currentUser  = null;
    });
  };

  /**
   * Sets `unathorized` flag to false
   * @return {[type]} [description]
   */
  Ctrl.reset = function LoginPageCtrl__reset() {
    Ctrl.unauthorized = false;
  };
}

module.exports = ["$location", "LogFactory", "$rootScope", "$scope", "$timeout", "UserFactory", LoginPageCtlr];
