
var _ = require('lodash');
var dottie = require('dottie');
var routeIsUnauthorized = require('../utils/route-auth');

/**
 * Refreshes our currentUser if $cookies.access_token exists
 * @param {Service} $location   AngularJS $location
 * @param {Service} $log        AngularJS $log
 * @param {Service} $rootScope  AngularJS $rootScope
 * @param {Service} UserFactory Our user factory
 */
function AuthRefresh($location, $log, $rootScope, UserFactory) {
  UserFactory.getActiveUser().then(function AuthRefresh__getActiveUser_success(response) {
    response = response && _.isObject(response.data) ? response.data : response;
    $rootScope.expiresIn    = dottie.get(response, 'token.expires_in', 0);

    if (!_.isUndefined(response) && ['', '/login', '/'].indexOf($location.path()) > -1) {
      $location.path(UserFactory.roleHomePath(response.user));
    }
    $rootScope.$broadcast('token:expires', $rootScope.expiresIn);
  }, function AuthRefresh__getActiveUser_error(response) {
    $log.error(response);
    if (!routeIsUnauthorized($location.path())) {
      UserFactory.forgetMe();
      $rootScope.sessionExpired = false;
      $location.url('/login');
    }
  });
}

module.exports = ['$location', '$log', '$rootScope', 'UserFactory', AuthRefresh];
