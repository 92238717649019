
/**
  * @ngdoc validAddress
  * @name validAddress
  * @restrict A
  *
  * @description
  * Ensures that address and city fields conform to acceptable input
  *
  * @param {object}   ngModel  Optional. Used as a passthrough.
  *
  * @example
      <input type="text" name="addressLine1" valid-address ng-model="Ctrl.formData.addressLine1">
*/

function validAddress () {
  return {
    require: '?ngModel',
    link: function(scope, element, attrs, ngModelCtrl) {
      if(!ngModelCtrl) {
        return;
      }

      ngModelCtrl.$parsers.push(function(val) {
        if (angular.isUndefined(val) || val === null) {
          val = '';
        }

        // cast to string
        val = (val + '');

        var clean = val.replace(/[^A-Za-z0-9\u00C0-\u017F\-\'\#\/\.\, ]/g, '');
        if (val !== clean) {
          ngModelCtrl.$setViewValue(clean);
          ngModelCtrl.$render();
        }
        return clean;
      });
    }
  };
}

module.exports = validAddress;
