
function BillingCtrl($rootScope, $log, $window, $modal, BillingFactory) {
  var Ctrl = this;

  this.showBillError = false;

  Ctrl.bills = [];

  BillingFactory.getDivisionBills().then(function BillinCtrl__getDivisionBills__success(res) {
    BillingFactory.getPayments(res.data.data).then(function BillingCtrl__getPayments__success(bills) {
        // Counteract truncation of x_amounts ending in 0
        bills.forEach(function(el, idx, arr) {
          if (el.transaction) {
            if (el.transaction.x_amount) {
              el.transaction.x_amount = parseFloat(el.transaction.x_amount).toFixed(2);
            }
            if (el.transaction.autoPayMaxAmount) {
              Ctrl.autoPayLimitDisclaimer = el.transaction.autoPayMaxAmount;
            }
          }
        });
        Ctrl.bills = bills;
    }, $log.error);
  }, $log.error);

  /**
   * Refreshes the bill's transaction tokens for PayPal
   * @param  {Object} bill Billing data object
   */
  this.refreshToken = function BillingCtrl__refreshToken(bill) {
    BillingFactory.getPayment(bill.id).then(function BillingCtrl__refreshToken__getPayment__success(newBill) {
      angular.copy(newBill, bill.transaction);
    }, $log.error);
  }

  /**
   * Gets download link location for printing out the billing information
   * @param  {Object} bill Billing data object
   */
  this.printBill = function BillingCtrl__printBill(bill) {
    BillingFactory.getPrint(bill).then(function BillingCtrl__printBill__getPrint_success(data) {
      window.location = "/bill-download/" + data;
    }, function BillingCtrl__printBill__getPrint_error(data) {
      $log.error(data);

      Ctrl.showBillError = true;
    });
  }

  /**
   * Gets download link location for the export of the billing information
   * @param  {Object} bill Billing data object
   */
  this.exportBill = function BillingCtrl__exportBill(bill) {
    BillingFactory.getExport(bill).then(function BillingCtrl__exportBill__getExport_success(data) {
      window.location = "/bill-export/" + data;
    }, function BillingCtrl__exportBill__getExport_error(data) {
      $log.error(data);

      Ctrl.showBillError = true;
    });
  }

  this.closeBillError = function BillingCtrl__closeBillErrorModal() {
    Ctrl.showBillError = false;
  }
}

module.exports = ["$rootScope", "LogFactory", "$window", "$uibModal", "BillingFactory", BillingCtrl];
