var _ = require('lodash');

function BenefitElection(WizardService, CoverageFactory) {
  var checkedCoverages = WizardService.data.divisionCoverages.filter(findCheckedCoverages);
  var checkedAndEditableCoverages = checkedCoverages.filter(findEditableCoverages);
  var checkedAndUnEditableCoverages = checkedCoverages.filter(findUnEditableCoverages);

  // Add checked and uneditable coverages to employee
  if (checkedAndUnEditableCoverages.length > 0) {
    // If there is a newEmployeeRehireDate, that means we are rehiring an employee and we should use that date for the coverage effective date
    if (WizardService.data.newEmployeeRehireDate) {
      checkedAndUnEditableCoverages = _.map(checkedAndUnEditableCoverages, function(coverage) {
        coverage.effectiveDate = WizardService.data.newEmployeeRehireDate;
        return coverage;
      });
    }
    CoverageFactory.insert(checkedAndUnEditableCoverages, WizardService.data.employeeInfo.id);
  }

  if (_.isObject(WizardService.data.newDep)) {
    WizardService.data.newDep.coverages = checkedAndUnEditableCoverages;
  }

  var sidebar = checkedAndEditableCoverages.map(mapCoverages);

  sidebar.push({
    name:     'Summary',
    template: 'Summary',
    coverage: {}
  });

  function findCheckedCoverages(coverage) {
    return coverage.checked === true;
  }

  function findEditableCoverages(coverage) {
    return coverage.isEditable === 1;
  }

  function findUnEditableCoverages(coverage) {
    return coverage.isEditable === 0;
  }

  function mapCoverages(coverage) {
    return {
      name: coverage.benefitDescription,
      template: 'Benefit',
      coverage: coverage
    };
  }

  WizardService.sidebar = sidebar;

  // don't hide the required fields helper message
  WizardService.hideReqHelper = false;
}

module.exports = ['WizardService', 'CoverageFactory', BenefitElection];
