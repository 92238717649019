function routeIsUnauthorized (route) {
  var isUnauthorized = false;
  var unauthRoutes = [
    '/',
    '/login',
    '/register',
    '/invite/expired',
    '/link/expired',
    '/verifyemail',
    '/forgotPassword',
    '/resetPassword',
    '/terms-of-use'
  ];

  // Quick work around because the forget password link's path includes the encoded ID, it is not a query
  /* istanbul ignore next */
  if (route.indexOf("forgotPassword") !== -1) {
    isUnauthorized = true;
  }

  unauthRoutes.forEach(function (otherRoute) {
    /* istanbul ignore else */
    if (otherRoute.match(new RegExp('^' + route)) !== null) {
      isUnauthorized = true;
    }
  });

  return isUnauthorized;
}

module.exports = routeIsUnauthorized;
