
var _ = require('lodash');

function EditPasswordModalCtrl($scope, $rootScope, $log, $modalInstance, UserFactory) {
  var Ctrl = this;

  Ctrl.oldPass      = '';
  Ctrl.newPass      = '';
  Ctrl.confirmPass  = '';

  $scope.submitSuccess = false;

  /**
   * Adds logic/mappings for updating the user when submitting the form
   * @param  {String} oldPass     Original password
   * @param  {String} newPass     New password
   * @param  {String} confirmPass Confirmation of new password
   */
  $scope.submitForm = function EditPasswordModalCtrl__submitForm(oldPass, newPass, confirmPass) {
    var user = angular.copy($rootScope.currentUser);

    user.oldPassword = oldPass;
    user.password = newPass;
    user.confirmPassword = confirmPass;

    UserFactory.updateUser(user).then(function () {
      $scope.submitSuccess = true;
    }, $log.error);
  };

  /**
   * Closes the current modal
   */
  $scope.close = function EditPasswordModalCtrl__close() {
    $modalInstance.close();
  };

  /**
   * Toggles OK button based off of `editPasswordFormInvalid`
   * @param  {Boolean} editPasswordFormInvalid
   * @return {Boolean}
   */
  $scope.disableOKButton = function EditPasswordModalCtrl__disableOKButton(editPasswordFormInvalid) {
    return editPasswordFormInvalid;
  };

  function EditPasswordModalCtrl__modal_hide(e, employee) {
    closeModalInstance(employee);
  }

  $rootScope.$on("modal:hide", EditPasswordModalCtrl__modal_hide);

  // Prevent memory leaks caused by listening to $rootScope
  // (http://stackoverflow.com/questions/18856341/how-can-i-unregister-a-broadcast-event-to-rootscope-in-angularjs)
  $scope.$on("$destroy", function EditPasswordModalCtrl_destory() {
    EditPasswordModalCtrl__modal_hide();
  });

  function closeModalInstance(data) {
    if ($modalInstance && _.isFunction($modalInstance.close)) {
      $modalInstance.close(data);
    }
  }
}

module.exports = ["$scope", "$rootScope", "LogFactory", "$modalInstance", "UserFactory", EditPasswordModalCtrl];
