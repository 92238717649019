
/**
  * @ngdoc validInteger
  * @name validInteger
  * @restrict A
  *
  * @description
  * Ensures that only integer values can be typed within this form element
  *
  * @param {object}   ngModel  Optional. Used as a passthrough.
  *
  * @example
      <input type="text" name="ssnSeg1" minLength="3" maxlength="3" valid-integer ng-model="ssnobj.ssnSeg1">
*/

function validInteger () {
  return {
    restrict: 'A',
    require: '?ngModel',
    link: function(scope, element, attrs, ngModelCtrl) {
      if(!ngModelCtrl) {
        return;
      }

      ngModelCtrl.$parsers.push(function(val) {
        if (angular.isUndefined(val) || val === null) {
          val = '';
        }

        // cast to string
        val = (val + '');

        var clean = val === '0' ? val : val.replace(/[^0-9]+/g, '').replace(/^0+/, '');
        if (val !== clean) {
          ngModelCtrl.$setViewValue(clean);
          ngModelCtrl.$render();
        }
        return clean;
      });

      element.bind('keypress', function(event) {
        if(event.keyCode === 32) {
          event.preventDefault();
        }
      });
    }
  };
}

module.exports = validInteger;
