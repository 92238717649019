
var dottie  = require('dottie');

/**
  * @ngdoc dateDropdown
  * @name dateDropdown
  * @restrict E
  *
  * @description
  * Creates a dropdown list for selecting a month, day, and year
  *
  *
  * @param {string}   label         Text for the field's label
  * @param {object}   dateobj       Two-way binding model returns {dateSeg1: 'MM', dateSeg2: 'DD', dateSeg3: 'YYYY'}
  * @param {string}   year-min      Sets the minimum year explicitly
  * @param {string}   year-max      Sets the maximum year explicitly
  * @param {string}   year-minus    Sets minimum year currentYear - yearminus, default is setting the minimum year to 1900
  * @param {string}   year-plus     Sets maximum year currentYear + yearplus, default is setting the maximum year to currentYear + 1
  * @param {object}   errors        Two-way binding model for associating errors with the controller and this form element
  * @param {function} clearfn       Function to execute when a change has occurred, useful for removing/clearing an error
  * @param {boolean}  setDisabled   Whether the select input is disabled
  *
  * @example
      <date-dropdown label="Date of Birth:" dateobj="Ctrl.newDep.dob" errors="Ctrl.formData.errors" clearfn="Ctrl.removeError('dob')"></date-dropdown>
*/

function dateDropdownDirective($timeout) {
  return {
    restrict: "E",
    templateUrl: "/partials/partials/dateDropdown",
    scope: {
      label:        "@",
      dateobj:      "=",
      yearMin:      "=",
      yearMax:      "=",
      yearMinus:    "=",
      yearPlus:     "=",
      errors:       "=",
      setDisabled:  "=",
      required:     "=",
      clearfn:      "&"
    },
    link: function (scope) {
      scope.$on('uiSelect:changed:day', function (e, val, currentScope, element) {
        checkDate(e, 'dateSeg2', val, currentScope, element);
      });

      scope.$on('uiSelect:changed:month', function (e, val, currentScope, element) {
        checkDate(e, 'dateSeg1', val, currentScope, element);
      });

      function checkDate(e, key, val, currentScope, element) {
        var data = angular.copy(dottie.get(e, 'targetScope.dateobj', {}));

        data[key] = val;

        var currentDay    = parseInt((key !== 'dateSeg2' ? angular.copy(dottie.get(e, 'targetScope.dateobj.dateSeg2')) : data[key]), 10);
        var currentMonth  = parseInt((key !== 'dateSeg1' ? angular.copy(dottie.get(e, 'targetScope.dateobj.dateSeg1')) : data[key]), 10);
        var currentYear   = parseInt((key !== 'dateSeg3' ? angular.copy(dottie.get(e, 'targetScope.dateobj.dateSeg3')) : data[key]), 10);

        var hasMonth  = angular.isDefined(currentMonth) && !isNaN(currentMonth);
        var hasDay    = angular.isDefined(currentDay) && !isNaN(currentDay);
        var hasYear   = angular.isDefined(currentYear) && !isNaN(currentYear);

        function clearDay() {
          clearDate('dateSeg2');
        }

        function clearMonth() {
          clearDate('dateSeg1');
        }

        function clearDate(key) {
          e.targetScope.dateobj[key]  = '';
          // required for clearing the current selection
          currentScope.$select.search = '';

          $timeout(function() {
            angular.element(element).focus();
          }, 100);
        }

        // month
        if (hasMonth && (scope.getMonths().indexOf('0' + data.dateSeg1.toString()) === -1 && scope.getMonths().indexOf(data.dateSeg1.toString()) === -1)) {
          clearMonth();
        }

        var hasAllFields  = hasMonth && hasDay && hasYear;
        var invalidDay    = currentDay < 1 || currentDay > 31 || (hasAllFields && scope.getDays(data.dateSeg1, data.dateSeg3).indexOf(data.dateSeg2.toString()) === -1);

        if (hasMonth && hasDay && invalidDay) {
          clearDay();
        }
      }

      function getDays (month, year) {
        var days = [];
        var febMax = 28;

        month = month || '01';
        if (!year || year % 4 === 0)  febMax = '29'
        if (year && year % 100 === 0) febMax = '28';
        var monthMax = {
          '01': 31, // January
          '02': febMax, // February
          '03': 31, // March
          '04': 30, // April
          '05': 31, // May
          '06': 30, // June
          '07': 31, // July
          '08': 31, // August
          '09': 30, // September
          '10': 31, // October
          '11': 30, // November
          '12': 31, // December
        };

        for (var day = 1; day <= monthMax[month]; day++) {
          days.push((day < 10 ? '0' : '') + day);
        }

        return days;
      }

      scope.getMonths = function() {
        return ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
      };

      scope.getDays = getDays;

      scope.getYears = function(args) {
        var years = [],
          thisYear = new Date().getFullYear(),
          yearMin = args.yearMin || 1900,
          yearMax = args.yearMax || thisYear + 1,
          yearMinus = args.yearMinus,
          yearPlus = args.yearPlus;

        yearMin = yearMinus ? (thisYear - parseInt(yearMinus, 10)) : yearMin;
        yearMax = yearPlus ? (thisYear + parseInt(yearPlus, 10)) : yearMax;

        for (var year = yearMax; year >= yearMin; year--) {
          years.push(year);
        }

        return years;
      };

      scope.resetDays = function(item, model, type, dateObj) {
        var days;

        if (!dateObj || !dateObj.dateSeg2) {
          return;
        }

        if (type === "M") {
          days = getDays(item, dateObj.dateSeg3);
        }
        else {
          days = getDays(dateObj.dateSeg1, item);
        }

        if (days.indexOf(dateObj.dateSeg2) === -1) {
          dateObj.dateSeg2 = "";
        }
      };
    },
  };
}

module.exports = ['$timeout', dateDropdownDirective];
