
/**
  * @ngdoc dependentsChecklist
  * @name dependentsChecklist
  * @restrict E
  *
  * @description
  * Creates a list of dependents with checkboxes
  *
  *
  * @param {object}   employee   Employee's object (usually given to us via GET /api/user)
  * @param {object[]} enrollees  List of dependents
  * @param {object}   coverage   Coverage's data/object
  *
  * @example
      <dependents-checklist employee="Ctrl.employeeInfo" enrollees="Ctrl.employeeInfo.dependents" coverage="Ctrl.coverage"></dependents-checklist>
*/

function dependentsChecklistDirective() {
  return {
    restrict: "E",
    templateUrl: "/partials/partials/dependentsChecklist",
    scope: {
      employee:   "=",
      enrollees:  "=",
      coverage:   "=",
      dependentChanged: "&"
    }
  }
}

module.exports = dependentsChecklistDirective;
