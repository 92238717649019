
var _       = require('lodash');
var dottie  = require('dottie');

function EmployeeInfoCtrl($rootScope, $scope, $log, $timeout, SALARY_FREQUENCIES, STATE_LIST, UtilFactory, WizardService, EmployeeFactory, CoverageFactory, FormFactory) {
  var Ctrl = this;

  $scope.parseInt = parseInt;

  Ctrl.wizard = WizardService;
  Ctrl.wizard.form = {};
  Ctrl.wizard.data = Ctrl.wizard.data || {};
  Ctrl.currentUser = Ctrl.wizard.currentUser || {};
  Ctrl.wizard.data.employeeInfo = Ctrl.wizard.data.employeeInfo || {};
  Ctrl.wizard.data.divisionCoverages = Ctrl.wizard.data.divisionCoverages || [];
  Ctrl.wizard.modalTitle = 'Employee Profile';
  Ctrl.formData = Ctrl.formData || {};
  Ctrl.originalFormData = {};
  Ctrl.divisions = dottie.get(Ctrl.currentUser, 'divisions', []);
  Ctrl.groupClasses = dottie.get(Ctrl.currentUser, 'group.class', []);
  Ctrl.onEditProfileTab = true; // The Edit Profile tab is open by default

  Ctrl.wizard.modalTitle = 'Employee Profile';

  Ctrl.reasonCodes = [];
  if (_.isArray(Ctrl.wizard.data.reasonCodes) && Ctrl.wizard.data.reasonCodes.length > 0) {
    Ctrl.reasonCodes = Ctrl.wizard.data.reasonCodes;
  }

  Ctrl.qualifyingEvents = [];
  if (_.isArray(Ctrl.wizard.data.qualifyingEvents) && Ctrl.wizard.data.qualifyingEvents.length > 0) {
    Ctrl.qualifyingEvents = Ctrl.wizard.data.qualifyingEvents;
  }


  if (Ctrl.wizard.reactivating && _.isObject(Ctrl.wizard.data.currentEmployee)) {
    formatEmployeeDetails(Ctrl.wizard.data.currentEmployee);
  } else if (Ctrl.wizard.addingEmployee) {

    if (_.isEmpty(Ctrl.qualifyingEvents)) {
      EmployeeFactory.getQualifyingEvents().then(function (results) {
        Ctrl.qualifyingEvents = results;
      });
    }

    Ctrl.formData.salaryObj = {
      salary: null,
      freqObj: { code: '', description: '--' }
    };
    if (Ctrl.divisions && Ctrl.divisions.length) {
      Ctrl.formData.divisionObj = Ctrl.divisions[0];
      setClassesAndClassObj(Ctrl.formData.divisionObj.id);
    } else {
      Ctrl.formData.divisionObj = {};
      setClassesAndClassObj();
    }
    if (!Ctrl.formData.classObj.requireEnrollingCoverageType) {
      Ctrl.formData.enrollingType = 'NH';
    }
  }

  /**
   * Sets editing employee information
   */
  Ctrl.editingEmployee = function EmployeeInfoCtrl__editingEmployee() {
    Ctrl.wizard.sidebar    = [];
    Ctrl.statusChangeDateLabel = "Termination Date";

    if (_.isObject(Ctrl.wizard.data.currentEmployee)) {
      Ctrl.isActive = true;
      Ctrl.activeTabIndex = 0;
      Ctrl.currentStatus = Ctrl.wizard.data.currentEmployee.status;
      if (Ctrl.currentStatus === 'inactive') {
        Ctrl.isActive = false;
        Ctrl.activeTabIndex = 2;
        Ctrl.statusChangeDateLabel = "Rehire Date";
      }
      Ctrl.isEditStatusDisabled = false;
      if (Ctrl.currentStatus === 'pending_inactive' || Ctrl.wizard.data.isPendingRehire ) {
        Ctrl.isEditStatusDisabled = true;
      }
      if (Ctrl.currentStatus === 'waiver') {
        Ctrl.activeTabIndex = 1;
      }
      Ctrl.formatEmployeeDetails(Ctrl.wizard.data.currentEmployee);
    }
  }

  Ctrl.occupationalChanged = function EmployeeInfoCtrl__occupationalChanged() {
    var divisionChanged = false, classChanged = false, salChanged  = false, salFreqChanged  = false, hoursChanged  = false, occupationChanged= false;
    if (Ctrl.wizard.data.currentEmployee) {
      if (Ctrl.formData.divisionObj) {
        divisionChanged = Ctrl.formData.divisionObj.id !== Ctrl.wizard.data.currentEmployee.divisionId;
      }
      if (Ctrl.formData.classObj) {
        classChanged = Ctrl.formData.classObj.id !== Ctrl.wizard.data.currentEmployee.classId;
      }
      if (Ctrl.formData.salaryObj.salary >= 0) {
    	salChanged   = Ctrl.formData.salaryObj.salary !== Ctrl.wizard.data.currentEmployee.salary;  	
      }
      if (Ctrl.formData.salaryObj.freqObj.code) {
      	salFreqChanged = Ctrl.formData.salaryObj.freqObj.code !== Ctrl.wizard.data.currentEmployee.salaryFreq;
      }
    
      if(Ctrl.formData.salaryObj.weeklyHours){
    	hoursChanged = Ctrl.formData.salaryObj.weeklyHours !== Ctrl.wizard.data.currentEmployee.weeklyHours;
      }
      
      if(Ctrl.formData.occupation){
    	occupationChanged = Ctrl.formData.occupation !== Ctrl.wizard.data.currentEmployee.occupation;
      }
      
      return divisionChanged || classChanged || salChanged || salFreqChanged || hoursChanged || occupationChanged;
    }
    return false;
  }

  /**
   * Updates employee with mapped data
   */
  Ctrl.updateEmployee = function EmployeeInfoCtrl__updateEmployee() {

    // reset error fields
    Ctrl.friendlyErrors  = [];
    Ctrl.occupationalErrors  = [];
    Ctrl.formData.errors = [];

    var employee = Ctrl.mapEmployeeData(Ctrl.formData);
    Ctrl.wizard.data.employeeName = employee.firstName + ' ' + employee.lastName;

    if (_.isObject(employee.primaryAddress)) {
      employee.address[0] = employee.primaryAddress;
      employee.address[0].type = 'EEMN';
      delete employee.primaryAddress;
    }

    employee.address = _.map(employee.address, function (address) {
      address = _.omit(address, ['phoneSeg1', 'phoneSeg2', 'phoneSeg3']);

      if(address.phone == ''){
        address = _.omit(address, ['phone']);
      }

      if(address.email == ''){
        address = _.omit(address, ['email']);
      }

      if (_.isUndefined(address.type)) {
        address.type = 'EEMN';
      }

      if (_.isUndefined(address.addressCountry)) {
        address.addressCountry = 'US';
      }

      return address;
    });

    var checkCondition = Ctrl.wizard.data.currentEmployee.hireDate !== UtilFactory.dropdownValuesToDateString(Ctrl.formData.doh);
    var isInactive = (employee.status === 'inactive');
    var occupChanged = Ctrl.occupationalChanged();
    var today = UtilFactory.newDate();
    var errors = {};
   
    if (!isInactive) {
      if (occupChanged || checkCondition) {	
        errors = FormFactory.validate(Ctrl.wizard.form, {
          validations: [
            FormFactory.validations.class(Ctrl.formData.classObj.id, {condition: Ctrl.classes.length && Ctrl.classes.length > 1}),
            FormFactory.validations.date(Ctrl.formData.doh, {key: 'hireDate'}),
            FormFactory.validations.date(Ctrl.formData.doh, {key: 'hireDate', after: 'hireDateWithin', compareDate: moment(Ctrl.wizard.data.currentEmployee.hireDate, ['YYYY-MM-DD']).subtract(3, 'month'), condition: checkCondition}),
            FormFactory.validations.date(Ctrl.formData.doh, {key: 'hireDate', before: 'hireDatePast', compareDate: moment(Ctrl.wizard.data.currentEmployee.hireDate, ['YYYY-MM-DD']).add(3, 'month'), condition: checkCondition}),
            FormFactory.validations.date(Ctrl.formData.doh, {key: 'hireDate', after: 'hireDateAfterBirthDay', compareDate: Ctrl.formData.dobDropdown, acceptSameDay: false}),
            FormFactory.validations.date(Ctrl.formData.effectiveDateDropDown, {key: 'effectiveDate', after: 'effectiveDateWithin', compareDate: moment().subtract(3, 'months'), condition: occupChanged}),
            FormFactory.validations.date(Ctrl.formData.effectiveDateDropDown, {key: 'effectiveDate', after: 'effectiveDateBefore', compareDate: Ctrl.wizard.data.currentEmployee.effectiveDate, condition: occupChanged}),
            FormFactory.validations.date(Ctrl.formData.effectiveDateDropDown, {key: 'effectiveDate', before: 'effectiveDatePast', compareDate: moment().add(3, 'months'), condition: occupChanged}),
            FormFactory.validations.salary(Ctrl.formData.salaryObj.salary),
            FormFactory.validations.hours(Ctrl.formData.salaryObj.weeklyHours),
          ]
        });
      }
      else {
    	  errors = FormFactory.validate(Ctrl.wizard.form, {
    	    validations: [
            FormFactory.validations.date(Ctrl.formData.dobDropdown, {past: 'DOBPast'}),
            FormFactory.validations.date(Ctrl.formData.dobDropdown, {after: 'DOBWithin', compareDate: moment().subtract(100, 'years')}),
            FormFactory.validations.date(Ctrl.formData.doh, {key: 'hireDate', after: 'hireDateAfterBirthDay', compareDate: Ctrl.formData.dobDropdown, acceptSameDay: false}),
            FormFactory.validations.ssn(Ctrl.formData.ssn),
          ]
        });
      }
      if (_.keys(errors.errors).length > 0) {
        Ctrl.friendlyErrors  = errors.friendlyErrors;
        Ctrl.occupationalErrors = errors.occupationalErrors;
        Ctrl.formData.errors = errors.errors;
        return false;
      }
    }

    EmployeeFactory.update(employee, isInactive, occupChanged).then(function (success) {
      Ctrl.wizard.showFinalConfirmScreen = true;

      if (isInactive) {
        Ctrl.wizard.data.currentEmployee.status = employee.status;
        Ctrl.wizard.data.employeeInfo = Ctrl.wizard.data.currentEmployee;
        success = Ctrl.wizard.data.employeeInfo;
      } else {
        success = _.merge(Ctrl.wizard.data.currentEmployee, success);
      }

      WizardService.timeout = $timeout(function() {
        //$rootScope.$broadcast('modal:hide', success);
        location.reload();
      }, 1000 * 5);
    }, employeeExists);
  };

  /**
   * Reactivates (reinstate or rehire) an employee
   */
  Ctrl.reactivateEmployee = function EmployeeInfoCtrl__reactivateEmployee() {
    if (Ctrl.formData.reasonCode.code === 'reinstate') {
      // force reinstate - user will not have to go through the wizard
      EmployeeFactory.reinstate(Ctrl.wizard.data.currentEmployee).then(handleReinstateResponse, handleError);
    } else {
      // rehire - client determines if user will need to go through wizard (if rehire is more than 6 months from termination)
      var postData = {
        rehireDate: UtilFactory.dropdownValuesToDateString(Ctrl.formData.statusChangeDate)
      };

      // Save the entered rehire date in the wizard so we can add it to any coverages added later
      Ctrl.wizard.data.newEmployeeRehireDate = postData.rehireDate;

      EmployeeFactory.rehire(Ctrl.wizard.data.currentEmployee, postData).then(handleRehireResponse, handleError);
    }

    function handleReinstateResponse(res) {
      Ctrl.wizard.reactivated = true;
      Ctrl.wizard.reactivatedType = "reinstated";

      WizardService.timeout = $timeout(function() {
        $rootScope.$broadcast('modal:hide', Ctrl.wizard.data.currentEmployee);
      }, 1000 * 5);
    }

    function handleRehireResponse(res) {
      if (res.rehire === 0) {
        // The employee is outside the group's rehire provision, so we need to go through the wizard and add coverages
        Ctrl.wizard.reactivated = true;
        Ctrl.wizard.startWizard = true;
        Ctrl.wizard.data.employeeInfo = Ctrl.wizard.data.currentEmployee;
        Ctrl.wizard.data.employeeName = Ctrl.wizard.data.employeeInfo.firstName + ' ' + Ctrl.wizard.data.employeeInfo.lastName;
      } else {
        // The employee is inside the group's rehire provision, just show the confirmation
        Ctrl.wizard.reactivated = true;
        Ctrl.wizard.reactivatedType = "rehired";

        WizardService.timeout = $timeout(function() {
          $rootScope.$broadcast('modal:hide', Ctrl.wizard.data.currentEmployee);
        }, 1000 * 5);
      }
    }

    function handleError(res) {
      var hasStringMessage = angular.isObject(res.data) && angular.isString(res.data.message);
      var rehirePast3MonthsWithinProvision = !hasStringMessage ? null : res.data.message.match(/Rehire Date must/ig);
      var rehirePast3MonthsOutsideProvision = !hasStringMessage ? null : res.data.message.match(/Rehire.*waiting/ig);

      if (hasStringMessage) {
        if (rehirePast3MonthsWithinProvision !== null) {
          Ctrl.formData.errors = $log.populateErrors([{'rehirePast3MonthsWithinProvision': res.data.message}]);
        } else if (rehirePast3MonthsOutsideProvision !== null) {
          Ctrl.formData.errors = $log.populateErrors([{'rehirePast3MonthsOutsideProvision': res.data.message}]);
        }
      } else {
        Ctrl.formData.errors = {};
      }

      if (res.status === 409) {
        Ctrl.formData.errors.dot = true;
      } else if (res.status === 500) {
        Ctrl.formData.errors.serverError = true;
      }
    }
  };

  Ctrl.getAvailableBenefits = function EmployeeInfoCtrl__getAvailableBenefits(self, next) {
    CoverageFactory.findAvailableCoverages(Ctrl.wizard.data.currentEmployee.id).then(function (res) {
      Ctrl.wizard.data.divisionCoverages = res;
      Ctrl.wizard.foundAvailableCoverages = true;
      next();
    }, $log.error);
  }

  /**
   * Toggles the summary page if applicable
   */
  Ctrl.showSummary = function EmployeeInfoCtrl__showSummary() {
    // termination
    if (((Ctrl.currentStatus === 'active' && Ctrl.formData.status === 'inactive') || (Ctrl.currentStatus === 'pending_active' && Ctrl.formData.status === 'inactive')) && !Ctrl.onEditProfileTab) {
      Ctrl.formData.terminationDate = UtilFactory.dropdownValuesToDateString(Ctrl.formData.statusChangeDate);

      var errors = FormFactory.validate(Ctrl.wizard.form, {
        validations: [
          FormFactory.validations.date(Ctrl.formData.statusChangeDate, {key: 'effectiveDate', after: 'dotAfterHire', compareDate: Ctrl.wizard.data.currentEmployee.hireDate}),
          FormFactory.validations.date(Ctrl.formData.statusChangeDate, {key: 'effectiveDate', after: 'dotPast3Months', compareDate: moment().subtract(3, 'months')}),
          FormFactory.validations.date(Ctrl.formData.statusChangeDate, {key: 'effectiveDate', before: 'dotBefore3Months', compareDate: moment().add(3, 'months')})
        ]
      }, true);

      if (_.keys(errors.errors).length > 0) {
        Ctrl.friendlyErrors  = errors.friendlyErrors;
        Ctrl.occupationalErrors = errors.occupationalErrors;
        Ctrl.formData.errors = errors.errors;
        return false;
      }
      Ctrl.wizard.showSummaryConfirmScreen = true;
    // rehire / reinstate
  } else if (Ctrl.currentStatus === 'inactive' && Ctrl.formData.status === 'active' && !Ctrl.onEditProfileTab) {
    if (Ctrl.formData.reasonCode.code == 'rehire') {
      var errors = FormFactory.validate(Ctrl.wizard.form, {
        validations: [
          FormFactory.validations.date(Ctrl.formData.statusChangeDate, {key: 'rehireDate', before: 'rehireBefore1Month', compareDate: moment().add(1, 'months')})
        ]
      }, true);
    }
    else{
      var errors = FormFactory.validate(Ctrl.wizard.form, {
        validations: [
          FormFactory.validations.date(Ctrl.wizard.data.currentEmployee.terminationDate, {key: 'reinstate', after: 'reinstatePast3Months', compareDate: moment().subtract(3, 'months')})
        ]
      }, true);
    }
    if (_.keys(errors.errors).length > 0) {
      Ctrl.friendlyErrors  = errors.friendlyErrors;
      Ctrl.occupationalErrors = errors.occupationalErrors;
      Ctrl.formData.errors = errors.errors;
      return false;
    }
      Ctrl.reactivateEmployee();
    // just editing census data on active employee
    } else {
      Ctrl.updateEmployee();
    }
  };

  Ctrl.disableSaveButton = function EmployeeInfoCtrl__disableSaveButton() {
    conditionallySetFormPristine();
    var statusChanged = Ctrl.currentStatus !== Ctrl.formData.status;
    Ctrl.saveButtonDisabled = (Ctrl.onEditProfileTab && Ctrl.wizard.form && Ctrl.wizard.form.$pristine)
        || (!Ctrl.onEditProfileTab && (!statusChanged || (Ctrl.wizard.form2 && Ctrl.wizard.form2.$invalid)));
    return Ctrl.saveButtonDisabled;
  };

  /**
   * Maps employee data for sending to the db
   * @param  {Object} employee Employee's data object
   * @return {Object}          Mapped values of employee's data object
   */
  Ctrl.mapEmployeeData = function EmployeeInfoCtrl__mapEmployeeData(employee) {
    if (!_.isArray(employee.address)) {
      employee.address = [];
    }

    if (employee.divisionObj) employee.divisionId = employee.divisionObj.id;
    if (employee.classObj) employee.classId = employee.classObj.id;
    if (employee.salaryObj) {
      if (employee.salaryObj.freqObj) employee.salaryFreq = employee.salaryObj.freqObj.code;
      employee.salary = employee.salaryObj.salary;
      employee.weeklyHours = employee.salaryObj.weeklyHours;
    }

    employee.primaryAddress.phone = (employee.primaryAddress.phoneSeg1 || '') + (employee.primaryAddress.phoneSeg2 || '') + (employee.primaryAddress.phoneSeg3 || '');

    var findPrimaryAddressIndex = _.findIndex(employee.address, function (address) {
      return address.type === "primary";
    });

    if (findPrimaryAddressIndex !== -1) {
      employee.address[findPrimaryAddressIndex] = _.omit(employee.primaryAddress, 'phoneSeg1', 'phoneSeg2', 'phoneSeg3');
    }

    employee.hireDate = UtilFactory.dropdownValuesToDateString(employee.doh);
    employee.dob = UtilFactory.dropdownValuesToDateString(employee.dobDropdown);
    employee.changeEffectiveDate  = UtilFactory.dropdownValuesToDateString(employee.effectiveDateDropDown);
    if (!employee.changeEffectiveDate) {
      employee.changeEffectiveDate = moment().format('YYYY-MM-DD');
    }

    if (employee.status.toLowerCase() === "inactive" && employee.reasonCode) {
      employee.terminationDate   = UtilFactory.dropdownValuesToDateString(Ctrl.formData.statusChangeDate);
      employee.terminationReason = employee.reasonCode.code;
    } else {
      employee = _.omit(employee, 'terminationDate', 'terminationReason');
    }

    employee = _.omit(employee,
      'divisionObj', 'classObj', 'salaryObj', 'doh', 'dot', 'dobDropdown', 'effectiveDateDropDown',
      'reasonCode', 'dependents', 'electedCoverages'
    );

    return employee;
  }

  Ctrl.formatEmployeeDetails = formatEmployeeDetails;

  function formatEmployeeDetails(res) {
    Ctrl.formData = angular.copy(res);
    Ctrl.formData.statusChangeDate = {};
    Ctrl.formData.effectiveDateDropDown = {};

    // Set Division and Class objects from user data
    Ctrl.formData.divisionObj  = getDivisionObj(Ctrl.formData.divisionId);
    setClassesAndClassObj(Ctrl.formData.divisionId, Ctrl.formData.classId);

    var dob = moment(res.dob, ['YYYY-MM-DD']);
    Ctrl.formData.dobDropdown = {};
    Ctrl.formData.dobDropdown.dateSeg1 = dob.format('MM');
    Ctrl.formData.dobDropdown.dateSeg2 = dob.format('DD');
    Ctrl.formData.dobDropdown.dateSeg3 = dob.format('YYYY');

    Ctrl.formData.salaryObj = {
      salary: Ctrl.formData.salary,
      freqObj: _.find(SALARY_FREQUENCIES, {code: res.salaryFreq || ''}),
      weeklyHours: Ctrl.formData.weeklyHours
    }

    var hire,
        dateSeg1,
        dateSeg2,
        dateSeg3;

    Ctrl.formData.doh = {};

    if (WizardService.reactivating && Ctrl.wizard.data.currentEmployee.rehireDate) {
      dateSeg1 = Ctrl.wizard.data.currentEmployee.rehireDate.dateSeg1;
      dateSeg2 = Ctrl.wizard.data.currentEmployee.rehireDate.dateSeg2;
      dateSeg3 = Ctrl.wizard.data.currentEmployee.rehireDate.dateSeg3;
    } else {
      if (angular.isDefined(res.hireDate)) {
        hire = moment(res.hireDate, ['YYYY-MM-DD']);
      } else {
        hire = moment(res.effectiveDate, ['YYYY-MM-DD']);
      }
      dateSeg1 = hire.format('MM');
      dateSeg2 = hire.format('DD');
      dateSeg3 = hire.format('YYYY');
    }

    Ctrl.formData.doh.dateSeg1 = dateSeg1;
    Ctrl.formData.doh.dateSeg2 = dateSeg2;
    Ctrl.formData.doh.dateSeg3 = dateSeg3;

    Ctrl.formData.middleName1 = Ctrl.formData.middleName1 || '';

    Ctrl.formData.primaryAddress = res.address.filter(function (address) {
      return address.type && address.type.toUpperCase() === "EEMN";
    });

    if (Ctrl.formData.primaryAddress.length > 0) {
      Ctrl.formData.primaryAddress = Ctrl.formData.primaryAddress[0];

      Ctrl.formData.primaryAddress.addressState = Ctrl.formData.primaryAddress.addressState || 'N/A'
      Ctrl.formData.primaryAddress.addressLine2 = Ctrl.formData.primaryAddress.addressLine2 || '';
      Ctrl.formData.primaryAddress.email = Ctrl.formData.primaryAddress.email || '';

      var phone = Ctrl.formData.primaryAddress.phone;

      Ctrl.formData.primaryAddress.phoneSeg1 = '';
      Ctrl.formData.primaryAddress.phoneSeg2 = '';
      Ctrl.formData.primaryAddress.phoneSeg3 = '';

      if (_.isString(phone) && phone.match(/^\d{10}$/)) {
        Ctrl.formData.primaryAddress.phoneSeg1 = phone.substr(0, 3);
        Ctrl.formData.primaryAddress.phoneSeg2 = phone.substr(3, 3);
        Ctrl.formData.primaryAddress.phoneSeg3 = phone.substr(6);
      }
    } else {
      Ctrl.formData.primaryAddress = {
        addressLine1: '',
        addressLine2: '',
        addressCity: '',
        addressState: '',
        addressZipCode: '',
        phoneSeg1: '',
        phoneSeg2: '',
        phoneSeg3: '',
        email: ''
      }; // for Angular binding
    }

    // let mobile override
    if (Ctrl.formData.mobile) {
      var mobile = Ctrl.formData.mobile;

      if (mobile.match(/^\d{10}$/)) {
        Ctrl.formData.primaryAddress.phoneSeg1 = Ctrl.formData.mobile.substr(0, 3);
        Ctrl.formData.primaryAddress.phoneSeg2 = Ctrl.formData.mobile.substr(3, 3);
        Ctrl.formData.primaryAddress.phoneSeg3 = Ctrl.formData.mobile.substr(6);
      }
    }

    if (Ctrl.formData.classObj) {
      Ctrl.showTobacco = Ctrl.formData.classObj.requireTobacco;
      Ctrl.showEnrollingType = Ctrl.formData.classObj.requireEnrollingCoverageType;
    }

    if (Ctrl.wizard.reactivating) {
      var primaryAddress = Ctrl.formData.primaryAddress;
      Ctrl.formData.addressLine1    = primaryAddress.addressLine1;
      Ctrl.formData.addressLine2    = primaryAddress.addressLine2;
      Ctrl.formData.addressCity     = primaryAddress.addressCity;
      Ctrl.formData.addressState    = primaryAddress.addressState;
      Ctrl.formData.addressZipCode  = primaryAddress.addressZipCode;

      Ctrl.formData.phoneSeg1 = primaryAddress.phoneSeg1;
      Ctrl.formData.phoneSeg2 = primaryAddress.phoneSeg2;
      Ctrl.formData.phoneSeg3 = primaryAddress.phoneSeg3;

      Ctrl.formData.email = primaryAddress.email;

      // Ctrl.division = {
      //   id: Ctrl.formData.divisionId
      // };
    }

    if (Ctrl.formData.enrollingType === 'QE') {
      var effectiveDate = moment(res.dateOfEvent, ['YYYY-MM-DD']);
      Ctrl.formData.dateOfEvent = {};
      Ctrl.formData.dateOfEvent.dateSeg1 = effectiveDate.format('MM');
      Ctrl.formData.dateOfEvent.dateSeg2 = effectiveDate.format('DD');
      Ctrl.formData.dateOfEvent.dateSeg3 = effectiveDate.format('YYYY');
    }

    // Copy formData so we can tell compare it with it's original state later to see if the form has changed
    Ctrl.originalFormData = _.cloneDeep(Ctrl.formData);
  }

  Ctrl.removeError = function() {
    for (var i = 0; i < arguments.length; i++)
      Ctrl.formData = $log.removeErrors(Ctrl.formData, arguments[i]);
  };

  // don't hide the required fields helper message
  Ctrl.wizard.hideReqHelper = false;

  // Data and functions for dates
  if (!Ctrl.wizard.reactivating) {
    Ctrl.formData.dobDropdown = {};
    Ctrl.formData.doh         = {};
    Ctrl.formData.dateOfEvent = {};
  }

  Ctrl.STATE_LIST = STATE_LIST;

  Ctrl.onDivisionSelect = function EmployeeInfoCtrl__onDivisionSelect() {
    if (Ctrl.formData.divisionObj && Ctrl.formData.divisionObj.id) {
      setClassesAndClassObj(Ctrl.formData.divisionObj.id);
    }
  }

  Ctrl.onClassSelect = function EmployeeInfoCtrl__onClassSelect() {
    if (Ctrl.formData.classObj) {
      Ctrl.showTobacco = Ctrl.formData.classObj.requireTobacco;
      Ctrl.showEnrollingType = Ctrl.formData.classObj.requireEnrollingCoverageType;
      if (!Ctrl.formData.classObj.requireEnrollingCoverageType) {
        Ctrl.formData.enrollingType = 'NH';
      }
    }
  }

  function getDivisionObj(divisionId) {
    if (Ctrl.divisions && Ctrl.divisions.length) {
      return _.find(Ctrl.divisions, { 'id': divisionId });
    }
  }

  function setClassesAndClassObj(divisionId, classId) {
    var divisionObj = getDivisionObj(divisionId);

    if (divisionObj && divisionObj["class"]) {
      Ctrl.classes = Ctrl.formData.divisionObj["class"];
    } else {
      Ctrl.classes = Ctrl.groupClasses;
    }

    if (Ctrl.classes) {
      if (classId) {
        Ctrl.formData.classObj = _.find(Ctrl.classes, { 'id': classId });
      } else {
        if (Ctrl.classes.length === 1) {
          Ctrl.formData.classObj = Ctrl.classes[0];
        } else {
          Ctrl.formData.classObj = {
            id: null,
            name: "Select Class",
            requireTobacco: null,
            requireEnrollingCoverageType: null
          };
        }
      }
    }

    if (Ctrl.formData.classObj) {
      Ctrl.showTobacco = Ctrl.formData.classObj.requireTobacco;
      Ctrl.showEnrollingType = Ctrl.formData.classObj.requireEnrollingCoverageType;
    }
  }

  Ctrl.onSalaryChange = function EmployeeInfoCtrl__onSalaryChange() {
    Ctrl.formData.salaryObj.prevSalaryObj.salary = Ctrl.formData.salaryObj.salary;
    
    if(Ctrl.formData.salaryObj.salary==0){
     Ctrl.removeError('salaryFreq')
     Ctrl.formData.salaryObj.freqObj = {
    	 code: 'A', 
    	 description: 'Annually' 
    	}
      }  
  }

  Ctrl.finished = function(self, next) {
    var isQualifyingEvent = (Ctrl.formData.enrollingType === 'QE');

    // reset error fields
    Ctrl.friendlyErrors  = [];
    Ctrl.formData.errors = [];
    Ctrl.occupationalErrors = [];

    var errors = FormFactory.validate(Ctrl.wizard.form, {
      validations: [
        FormFactory.validations.class(Ctrl.formData.classObj.id, {condition: Ctrl.classes.length && Ctrl.classes.length > 1}),
        FormFactory.validations.date(Ctrl.formData.dobDropdown, {past: 'DOBPast'}),
        FormFactory.validations.date(Ctrl.formData.dobDropdown, {after: 'DOBWithin', compareDate: moment().subtract(100, 'years')}),
        FormFactory.validations.date(Ctrl.formData.doh, {key: 'hireDate'}),
        FormFactory.validations.date(Ctrl.formData.doh, {key: 'hireDate', after: 'hireDateAfterBirthDay', compareDate: Ctrl.formData.dobDropdown, acceptSameDay: false}),
        FormFactory.validations.date(Ctrl.formData.doh, {key: 'hireDate', before: 'hireDatePast', compareDate: moment().add(1, 'months')}),
        FormFactory.validations.date(Ctrl.formData.dateOfEvent, {key: 'effectiveDate', condition: isQualifyingEvent}),
        FormFactory.validations.date(Ctrl.formData.dateOfEvent, {after: 'eventDatePast', compareDate: moment().subtract(3, 'months'), condition: isQualifyingEvent}),
        FormFactory.validations.date(Ctrl.formData.dateOfEvent, {before: 'eventDateFuture', compareDate: moment().add(3, 'months'), condition: isQualifyingEvent}),
        FormFactory.validations.date(Ctrl.formData.dateOfEvent, {after: 'eventDateAfterHire', compareDate: Ctrl.formData.doh, acceptSameDay: true, condition: isQualifyingEvent}),
        FormFactory.validations.ssn(Ctrl.formData.ssn),
        FormFactory.validations.salary(Ctrl.formData.salaryObj.salary),
        FormFactory.validations.hours(Ctrl.formData.salaryObj.weeklyHours),
        FormFactory.validations.phone(Ctrl.formData),
        FormFactory.validations.email(Ctrl.formData.email)
      ]
    });
    if (_.keys(errors.errors).length > 0) {
      Ctrl.friendlyErrors  = errors.friendlyErrors;
      Ctrl.occupationalErrors = errors.occupationalErrors;
      Ctrl.formData.errors = errors.errors;
      return false;
    }

    // format form data for POST
    Ctrl.wizard.data.employeeInfo = {
        firstName: Ctrl.formData.firstName
      , middleName1: Ctrl.formData.middleName1
      , lastName: Ctrl.formData.lastName
      , dob: UtilFactory.dropdownValuesToDateString(Ctrl.formData.dobDropdown)
      , gender: Ctrl.formData.gender
      , ssn: Ctrl.formData.ssn
      , hireDate: UtilFactory.dropdownValuesToDateString(Ctrl.formData.doh)
      , occupation: Ctrl.formData.occupation
      , divisionId: Ctrl.formData.divisionObj.id
      , classId: Ctrl.formData.classObj.id
      , salaryFreq: Ctrl.formData.salaryObj.freqObj.code
      , salary: Ctrl.formData.salaryObj.salary
      , address: [{
          type: 'EEMN'
        , addressLine1: Ctrl.formData.addressLine1
        , addressLine2: Ctrl.formData.addressLine2
        , addressCity: Ctrl.formData.addressCity
        , addressState: Ctrl.formData.addressState
        , addressZipCode: Ctrl.formData.addressZipCode
        , addressCountry: "US"
      }]
    };

    // Temp fix until backend allows empty phonenumber
    var completePhone = (Ctrl.formData.phoneSeg1 || '') + (Ctrl.formData.phoneSeg2 || '') + (Ctrl.formData.phoneSeg3 || '');
    if (completePhone !== "") {
      Ctrl.wizard.data.employeeInfo.address[0].phone = completePhone;
    }

    if (Ctrl.formData.email !== "") {
      Ctrl.wizard.data.employeeInfo.address[0].email = Ctrl.formData.email;
    }

    if (Ctrl.formData.tobaccoUse !== null) {
      Ctrl.wizard.data.employeeInfo.tobaccoUse = Ctrl.formData.tobaccoUse;
    }

    Ctrl.wizard.data.employeeInfo.effectiveDate = Ctrl.wizard.data.employeeInfo.hireDate;

    if (angular.isDefined(Ctrl.formData.salaryObj.weeklyHours)) {
      Ctrl.wizard.data.employeeInfo.weeklyHours = parseFloat(Ctrl.formData.salaryObj.weeklyHours);
    }

    // reset error fields (if any)
    Ctrl.friendlyErrors  = [];
    Ctrl.formData.errors = [];
    Ctrl.occupationalErrors = [];

    if (Ctrl.wizard.reactivating) {
      Ctrl.wizard.data.employeeName = Ctrl.wizard.data.employeeInfo.firstName + ' ' + Ctrl.wizard.data.employeeInfo.lastName;
      //Ctrl.wizard.data.employeeInfo.id     = Ctrl.wizard.data.currentEmployee.id;
      Ctrl.wizard.data.employeeInfo.divisionId = Ctrl.wizard.data.currentEmployee.divisionId;
      Ctrl.wizard.data.employeeInfo.status = 'active';

      // TODO: delete this block when https://mobiquity.jira.com/browse/LMPS-209 is resolved
      // Currently the server does not recognize the field 'weeklyHours' and this causes an error.
//       if (Ctrl.wizard.data.employeeInfo.weeklyHours) {
//         delete Ctrl.wizard.data.employeeInfo.weeklyHours;
//       }
//
      EmployeeFactory.update(Ctrl.wizard.data.employeeInfo).then(function (success) {
        Ctrl.wizard.data.employeeInfo = success;

        next();
      }, employeeExists);
    } else {
      Ctrl.wizard.data.employeeInfo.enrollingType = Ctrl.formData.enrollingType;

      if (isQualifyingEvent) {
        Ctrl.wizard.data.employeeInfo.effectiveDate = UtilFactory.dropdownValuesToDateString(Ctrl.formData.dateOfEvent);
        Ctrl.wizard.data.qualifyingEventEffectiveDate = Ctrl.wizard.data.employeeInfo.effectiveDate;
        Ctrl.wizard.data.employeeInfo.qualifyingEventTypeCode = Ctrl.formData.qualifyingLifeEvent.eventCode;
        Ctrl.wizard.data.qualifyingEventTypeCode = Ctrl.formData.qualifyingLifeEvent.eventCode;
      }

      EmployeeFactory.insert(Ctrl.wizard.data.employeeInfo).then(function (success) {
        var mandatoryCoverages = success.electedCoverages;
        _.each(mandatoryCoverages, function(coverage){
          var summary = {
            benefitDescription: coverage.benefitDescription,
            volume: coverage.volume || 'N/A',
            coveredPeople:coverage.coveredPeople
          };
          WizardService.addBenefit(summary);
        });

        Ctrl.wizard.data.enrollingType = Ctrl.formData.enrollingType;
        Ctrl.wizard.data.employeeName = Ctrl.formData.firstName + ' ' + Ctrl.formData.lastName;

        // reset error fields (if any)
        if (Ctrl.formData.errors) {
          delete Ctrl.formData.errors;
        }

        Ctrl.friendlyErrors = [];
        Ctrl.occupationalErrors = [];

        Ctrl.wizard.data.employeeInfo = success;

        next();
      }, employeeExists);
    }
  }
  
  function employeeExists(res) {
      var hasStringMessage = angular.isObject(res.data) && angular.isString(res.data.message);
      var ssnMatch = !hasStringMessage ? null : res.data.message.match(/SSN already exists/);
      var matchDOB = !hasStringMessage ? null : res.data.message.match(/DOB can not be a future date/);
      var fieldsRequired = !hasStringMessage ? null : _.endsWith(res.data.message,'required');
      var weeklyHours = !hasStringMessage ? null : res.data.message.match(/weeklyHours/);
      var hireDateAfter = !hasStringMessage ? null : res.data.message.match(/Date of Hire/ig);
      var annualEnrollmentDate = !hasStringMessage ? null: res.data.message.match(/Annual Enrollment/ig);
      var duplicateEmployee = !hasStringMessage ? null : res.data.message.match(/employee already exists/ig);
      var errors = [];
      var occupationalError = false;

      if  (hasStringMessage) {
        if (ssnMatch !== null) {
          populateErrors([{'userExists': res.data.message}]);
        }
        else if (matchDOB !== null) {
          populateErrors([{'DOBFuture': res.data.message}]);
        }
        else if (fieldsRequired !== null && fieldsRequired) {
          var errorMessageArray = _.split(res.data.message, '+');
          var errors = [];
          for (var i = 0; i < errorMessageArray.length; i++) {
         	 switch(errorMessageArray[i]){
         	  case "ssn":
               errors.push({'ssn':'Please enter a valid Social Security Number'});
          	   break;
         	  case "salary":
               errors.push({'salary':'Please enter a valid Salary'});
          	   break;
         	  case "salaryFreq":
               errors.push({'salaryFreq':'Please Select the Salary Frequency'});
               break;
         	  case "address":
               errors.push({'addressLine1':'Address is required'});
               break;
         	  case "city":
               errors.push({'addressCity':'City is required'});
               break;
         	  case "state":
               errors.push({'addressState':'State is required'});
               break;
         	  case "zip":
               errors.push({'addressZipCode':'Please enter a valid Zip Code'});
               break;
         	  case "occupation":
               errors.push({'occupation':'Occupation is required'});
               break;
         	  case "occupationalError":
               occupationalError = true;
               break;
              }
            }
            populateErrors(errors)
          }
        else if (weeklyHours !== null) {
          populateErrors([{'weeklyHours': 'Please enter Regularly Scheduled Hours Per Week'}]);
        }
        else if (hireDateAfter !== null) {
          populateErrors([{'hireDateAfter': res.data.message}]);
        }
        else if (annualEnrollmentDate !== null) {
          populateErrors([{'annualEnrollmentDate': res.data.message}]);
        }
        else if (duplicateEmployee !== null) {
          populateErrors([{'duplicateEmployee': res.data.message}]);
        }
        else {
          $log.error(res);

          var errorMessage = res.message;
          if (res.hasOwnProperty('data')) {
            errorMessage = res.data.message;
            Ctrl.errorMessage = errorMessage;
          }

          populateErrors(errorMessage);
        }
      }

      function populateErrors(errorMessage) {
        Ctrl.formData.errors = $log.populateErrors(errorMessage);
        if (occupationalError) {
          Ctrl.occupationalErrors  = $log.getFriendlyErrors(errorMessage);	
        }
        else {
          Ctrl.friendlyErrors  = $log.getFriendlyErrors(errorMessage);
        }   
      }
    }

  function hasFormChanged() {
    return Ctrl.originalFormData.enrollingType !== Ctrl.formData.enrollingType
      || (Ctrl.originalFormData.divisionObj && Ctrl.originalFormData.divisionObj.id !== Ctrl.formData.divisionObj.id)
      || (Ctrl.originalFormData.classObj && Ctrl.originalFormData.classObj.id !== Ctrl.formData.classObj.id)
      || Ctrl.originalFormData.occupation !== Ctrl.formData.occupation
      || Ctrl.originalFormData.salaryObj.freqObj.code !== Ctrl.formData.salaryObj.freqObj.code
      || Ctrl.originalFormData.salaryObj.salary !== Ctrl.formData.salaryObj.salary
      || parseFloat(Ctrl.originalFormData.salaryObj.weeklyHours) !== parseFloat(Ctrl.formData.salaryObj.weeklyHours)
      || parseInt(Ctrl.originalFormData.doh.dateSeg1) !== parseInt(Ctrl.formData.doh.dateSeg1)
      || parseInt(Ctrl.originalFormData.doh.dateSeg2) !== parseInt(Ctrl.formData.doh.dateSeg2)
      || parseInt(Ctrl.originalFormData.doh.dateSeg3) !== parseInt(Ctrl.formData.doh.dateSeg3)
      || Ctrl.originalFormData.firstName !== Ctrl.formData.firstName
      || Ctrl.originalFormData.middleName1 !== Ctrl.formData.middleName1
      || Ctrl.originalFormData.lastName !== Ctrl.formData.lastName
      || !angular.equals(Ctrl.originalFormData.primaryAddress, Ctrl.formData.primaryAddress)
      || Ctrl.originalFormData.ssn !== Ctrl.formData.ssn
      || Ctrl.originalFormData.gender !== Ctrl.formData.gender
      || parseInt(Ctrl.originalFormData.dobDropdown.dateSeg1) !== parseInt(Ctrl.formData.dobDropdown.dateSeg1)
      || parseInt(Ctrl.originalFormData.dobDropdown.dateSeg2) !== parseInt(Ctrl.formData.dobDropdown.dateSeg2)
      || parseInt(Ctrl.originalFormData.dobDropdown.dateSeg3) !== parseInt(Ctrl.formData.dobDropdown.dateSeg3)
      || Ctrl.originalFormData.tobaccoUse !== Ctrl.formData.tobaccoUse;
  }

  // Set the form to pristine condition if dependents haven't changed so that the save button remains disabled
  function conditionallySetFormPristine() {
    if (Ctrl.wizard.data.currentEmployee && !hasFormChanged() && !_.isEmpty(Ctrl.wizard.form)) {
      Ctrl.wizard.form.$setPristine();
    }
  }

  Ctrl.wizard.sidebar = [
    {name: 'Employee Information'},
    {name: 'Add Dependent(s)'},
    {name: 'Summary'}
  ];

  Ctrl.wizard.currentStep = this;
}

module.exports = ['$rootScope', '$scope', 'LogFactory', '$timeout', 'SALARY_FREQUENCIES', 'STATE_LIST', 'UtilFactory', 'WizardService', 'EmployeeFactory', 'CoverageFactory', 'FormFactory', EmployeeInfoCtrl];
