
var _ = require('lodash');

/**
 * Fetches the employee defined by $route.current.params.id
 * @param {Service} $route          AngularJS $route
 * @param {Service} $q              AngularJS $q
 * @param {Service} EmployeeFactory Our employee service
 * @param {Service} $location       AngularJS $location
 */

/* istanbul ignore next */
(function () {
  function FetchEmployee($route, $q, EmployeeFactory, $location) {
    var deferred = $q.defer();

    EmployeeFactory.find($route.current.params.id).then(function (res) {
      deferred.resolve(res);
    }, function (res) {
      var hasStatus         = res && res.status;
      var hasStringMessage  = res && res.data && _.isString(res.data.message);
      var userNotAvail      = hasStringMessage && res.data.message.match(/Required user is not found in logged in customer access/) !== null;
      var userNotFound      = hasStringMessage && res.data.message.toLowerCase().match(/employee not found/) !== null;

      if (hasStatus) {
        res.status = parseInt(res.status, 10);
      }

      if (hasStatus && hasStringMessage && (userNotFound || userNotAvail)) {
        $location.path('/manageEmployees');
      }

      deferred.reject(res);
    });

    return deferred.promise;
  }

  module.exports = ['$route', '$q', 'EmployeeFactory', '$location', FetchEmployee];
})();
