
/**
  * @ngdoc validNumber
  * @name validNumber
  * @restrict A
  *
  * @description
  * Ensures that only numerical values can be typed within this form element
  *
  * @param {object}   ngModel  Optional. Used as a passthrough.
  *
  * @example
      <input type="text" name="deposit" valid-number ng-model="Ctrl.deposit">
*/

function validNumber () {
  return {
    require: '?ngModel',
    link: function(scope, element, attrs, ngModelCtrl) {
      if(!ngModelCtrl) {
        return;
      }

      ngModelCtrl.$parsers.push(function(val) {
        if (angular.isUndefined(val) || val === null) {
          val = '';
        }

        // cast to string
        val = (val + '');

        var clean = val.replace(/[^0-9]+/g, '');
        if (val !== clean) {
          ngModelCtrl.$setViewValue(clean);
          ngModelCtrl.$render();
        }
        return clean;
      });

      element.bind('keypress', function(event) {
        if(event.keyCode === 32) {
          event.preventDefault();
        }
      });
    }
  };
}

module.exports = validNumber;
