
/**
  * @ngdoc enrollingTypeSelect
  * @name enrollingTypeSelect
  * @restrict E
  *
  * @description
  * Creates a template for an input group of two radio buttons: Annual Enrollment and New Hire
  *
  *
  * @param {object}   formdata  The employee's object to associate the `enrollingType` key to
  * @param {boolean}  require   Boolean value as to whether or not we should require this input
  * @param {object[]} items     List of qualifying life events
  * @param {function} clearfn   Function to execute when a change has occurred, useful for removing/clearing an error
  *
  * @example
      <enrolling-type-select formdata="Ctrl.formData" required="Ctrl.showEnrollingType"></enrolling-type-select>
*/

function enrollingTypeSelectDirective() {
  return {
    restrict: "E",
    templateUrl: "/partials/partials/enrollingTypeSelect",
    scope: {
      formdata:    "=",
      required:     "=",
      items:        "=",
      setDisabled:  "=",
      clearfn:      "&?"
    }
  };
}

module.exports = enrollingTypeSelectDirective;
