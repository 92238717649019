
var _      = require('lodash');
var dottie = require('dottie');

function AddEmployeeModalCtrl($modalInstance, $rootScope, $scope, $location, WizardService, $timeout) {
  var Ctrl = this;

  this.wizard = WizardService;
  this.wizard.hasLoaded = true;

  // If we aren't reactivating an employee we're adding a new one so reset all the form data
  if (this.wizard.addingNew) {
    this.wizard.resetData();
    this.wizard.addingEmployee = true;
  }
  // The summary that appears just before the final confirm screen
  // features different buttons in the footer.
  this.wizard.showSummaryConfirmScreen  = false;
  this.wizard.showFinalSummaryFooter    = false;
  this.wizard.showFinalConfirmScreen    = false;
  this.wizard.data.userExists           = false;
  this.wizard.data.employeeProfileId    = 0;

  this.showCancel = false;

  this.modalAction = this.wizard.modalAction;

  /**
   * Shows our confirmation screen
   */
  this.showConfirmScreen = function AddEmployeeModalCtrl__showConfirmScreen() {
    this.wizard.showFinalSummaryFooter = false;
    this.wizard.showFinalConfirmScreen = true;
  };

  /**
   * Reset the state of the modal and all data associated with modal forms
   */
  this.reset = function AddEmployeeModalCtrl__reset() {
    if (this.wizard.data.addingDependent) {
      this.wizard.resetGUI();
      this.wizard.data.employeeName = this.wizard.data.employeeInfo.firstName + ' ' + this.wizard.data.employeeInfo.lastName;
      this.wizard.data.divisionCoverages = [];
    }
    else if (!this.wizard.reactivating) {
      this.wizard.resetData();
    }
    this.wizard.showSummaryConfirmScreen  = false;
    this.wizard.showFinalConfirmScreen    = false;
    this.wizard.showFinalSummaryFooter    = false;

    this.showCancel = false;
  };

  /**
   * Closes the modal and redirects us to `route`
   * @param  {String} route Path to redirect towards
   */
  this.closeModalAndRedirect = function AddEmployeeModalCtrl__closeModalAndRedirect(route) {
    this.closeModal();
    $location.path(route);
  };

  /**
   * Wizard's hook function for returning the next step
   * @param  {String}   method Current step's method
   * @param  {Function} fn     Callback function (optional).
   */
  this.hook = function AddEmployeeModalCtrl__hook(method, fn) {
    if (!_.isFunction(WizardService.currentStep[method])) {
      return fn();
    }

    WizardService.currentStep[method](WizardService.currentStep, fn);
  };

  /**
   * This function gets called whenever we've completed a step
   * when filling out the form
   */
  this.completeStep = function AddEmployeeModalCtrl__completeStep() {
    if (!WizardService.steps[WizardService.stepIndex+1]) {
      // don't use "this" since we lose scope on the return function
      Ctrl.closeModal();
      return;
    }

    WizardService.subStepIndex = 0;
    WizardService.stepIndex++;
  };

  /**
   * This function gets called whenever we've completed a sub-step (e.g. benefits)
   */
  this.completeSubStep = function AddEmployeeModalCtrl__completeSubStep() {
    WizardService.skip = false;
    WizardService.subStepIndex++;
    $rootScope.$broadcast("coverage:init");
  };

  /**
   * Wizard's `continue` hook function, runs logic for Wizard as to which hook we should proceed towards next
   * @param  {String} action Which hook/method/action
   */
  this.continue = function AddEmployeeModalCtrl__continue(action) {
    if (!action || !_.isString(action)) {
      action = 'finished';
    }

    var completedStep = !WizardService.sidebar || WizardService.subStepIndex >= (WizardService.sidebar.length - 1);

    // if we've completed the entire step, return a function that will be invoked for the "completed" state
    // otherwise just increment the subStepIndex
    var callback = completedStep ? function() { return Ctrl.hook('completed', Ctrl.completeStep); } : this.completeSubStep;
    this.hook(action, callback);
  };

  /**
   * Runs our `continue` function whenever we want to skip a certain step
   * such as adding new dependents
   */
  this.skip = function AddEmployeeModalCtrl__skip() {
    this.continue('skipped');
  };

  /**
   * Closes modal or pops up confirmation based off of our
   * current location within filling out the form
   * @param  {Boolean} confirmation If false, skips asking for confirmation as to whether or not we want to cancel
   */
  this.cancel = function AddEmployeeModalCtrl__cancel(confirmation) {
    // if we're on the last summary page, just close...
    var isCoverageAddOrEdit = !!WizardService.data.coverage;
    var atFinalStep     = WizardService.stepIndex === WizardService.steps.length - 1;
    var atFinalSubStep  = _.isArray(WizardService.sidebar) && WizardService.subStepIndex === WizardService.sidebar.length - 1;

    var atLastStep = atFinalSubStep && atFinalStep;

    if (isCoverageAddOrEdit || atLastStep) {
      confirmation = true;
    }

    if (Ctrl.wizard.showFinalConfirmScreen) {
      confirmation = true;
    }

    if (WizardService.reactivating) {
      confirmation = true;
    }

    if (confirmation === false) {
      this.showCancel = false;
    } else if (confirmation === true) {
      // NEED TO RELOAD THE PAGE TO PREVENT IF CANCELLING THE REHIRE OUTSIDE OF 6 MONTHS TO PREVENT THE INVALID FORM ERROR
      if(WizardService.reactivating && !WizardService.reinstated){
        location.reload();
      }

      this.closeModal(this.wizard.data.currentUser);
    } else {
      this.showCancel = true;
    }
  };

  this.closeModal = function AddEmployeeModalCtrl__closeModal(employee) {
    closeModalInstance(employee);
  };

  function AddEmployeeModalCtrl__modal_hide(e, employee) {
    closeModalInstance(employee);
  }

  var cleanUpFunc = $rootScope.$on("modal:hide", AddEmployeeModalCtrl__modal_hide);

  // Prevent memory leaks caused by listening to $rootScope
  // (http://stackoverflow.com/questions/18856341/how-can-i-unregister-a-broadcast-event-to-rootscope-in-angularjs)
  $scope.$on("$destroy", function AddEmployeeModalCtrl__destroy() {
    cleanUpFunc();
  });

  function closeModalInstance(employee) {
    if(WizardService.reactivating){
      location.reload();
      return;
    } 

    if (!_.isUndefined(WizardService.timeout)) {
      $timeout.cancel(WizardService.timeout);
      WizardService.timeout = undefined;
    }

    if ($modalInstance && _.isFunction($modalInstance.close)) {
      var data = employee || dottie.get(WizardService, 'data.employeeInfo', {});

      if (_.isArray(WizardService.originalDependents)) {
        data.dependents = angular.copy(WizardService.originalDependents);
        WizardService.originalDependents = null;
      }

      if (_.isObject(Ctrl.wizard.data.modalMetaData)) {
        data = angular.copy(Ctrl.wizard.data.modalMetaData);
        data.employee = employee;
      }

      if (_.isObject(Ctrl.wizard.data.newDep) || Ctrl.wizard.addingSingleParentCoverage) {
        location.reload();
      }

      Ctrl.reset();

      $modalInstance.close(data);
    }
  }
}

module.exports = ['$uibModalInstance', '$rootScope', '$scope', '$location', 'WizardService', '$timeout', AddEmployeeModalCtrl];
