
var _ = require('lodash');

function BenefitsInfoCtrl($rootScope, $log, BenefitsInfoFactory, NgTableParams, $q) {
  var Ctrl = this;
  this.documentNotAvailable = true;
  this.documents  = [];

  this.benefitsInfoFactory = BenefitsInfoFactory;
  this.tableParams = [];

  /**
   * Retrieves a group's documents
   */
  this.retrieveDocuments = function BenefitsInfoFactory__retrieveDocuments() {
    Ctrl.documents = [];

    BenefitsInfoFactory.getDocuments().then(populateDocuments, $log.error);

    function populateDocuments(res) {
      var links = {};
      function fetchDownloadLink(folder) {
          Ctrl.documentNotAvailable = false;
        _.each(folder.documents, function (document) {
          links[document.id] = BenefitsInfoFactory.getDocumentDownloadLink(document.id);
        });

        $q.all(links).then(function (results) {
          // iterate through each result and find the matching document
          _.each(results, function (res, id) {
            var doc = _.findIndex(folder.documents, function (document) {
              return document.id === id;
            });

            // lodash returns -1 for no matches
            if (doc > -1) {
              folder.documents[doc].link = res;
            }
          });
        });
      }

      if (_.isArray(res.folders)) {
        Ctrl.documents = _.filter(res.folders, mustHaveContent);
        _.each(Ctrl.documents, fetchDownloadLink);
        _.each(Ctrl.documents, addPaging); 
      }
    }

    function mustHaveContent(folder) {
      return _.isArray(folder.documents) && folder.documents.length > 0;
    }

    function addPaging(folder) {
      Ctrl.tableParams.push(new NgTableParams({
        page: 1,
        count: 5,
      },{
        counts: [],
        paginationMaxBlocks: 14,
        paginationMinBlocks: 2,
        getData: function(params) {
          params.total(folder.documents.length);
          return folder.documents.slice((params.page() - 1) * params.count(), params.page() * params.count());
        }
      }));
    }
  }

  // initialize our first option/division
  this.retrieveDocuments();
}

module.exports = ['$rootScope', 'LogFactory', 'BenefitsInfoFactory', 'NgTableParams', '$q', BenefitsInfoCtrl];
