
var dottie = require('dottie');

function IdleCtrl($rootScope, $scope, $location, $log, $idle, UserFactory) {
  $scope.$on("$idleStart", IdleCtrl__logUserOut);

  function IdleCtrl__logUserOut() {
    $log.info('IdleCtrl: User has idled!');

    if ($rootScope.currentUser) {
      $log.info('IdleCtrl: User exists');

      var userName = dottie.get($rootScope, 'currentUser.userName');

      $log.info("Session timed out at " + new Date());
      $rootScope.sessionExpired = true;
      $rootScope.currentUser = null;

      // We don't need to wait for a valid response from the server do so anything.
      if (angular.isDefined(userName)) {
        UserFactory.logout(userName).then(angular.noop, angular.noop);
      }

      UserFactory.forgetMe();

      $rootScope.$broadcast("token:cancelRefresh");
      $rootScope.$broadcast("modal:hide");

      $location.url("/logout");
    }
  }

  $scope.$on('httpRequestStarted', function (config) {
    $scope.httpRequestPending = true;
  });

  /**
  * @param {Object} result The server's response
  * @param {Bool} error If an error occurred
  */
  $scope.$on('httpRequestEnded', function ($event, result, http) {
    $scope.httpRequestPending = false;
  });
}

module.exports = ["$rootScope", "$scope", "$location", "LogFactory", "$idle", "UserFactory", IdleCtrl];
