
var _ = require('lodash');
var moment = require('moment');

function UtilFactory($log) {
  /**
   * Returns comma separated, decimal (if applicable), and "$" of amount
   * @param  {Number|String} amount The amount to to format
   * @return {String}        Formatted amount in currency form
   */
  this.getFormattedCurrency = function UtilFactory__getFormattedCurrency(amount, isForPending) {
    if (!amount || isNaN(amount)) {
      return "N/A";
    }
    return ('$' + this.getNumberWithCommas(amount));
  }

  /**
   * Returns a number with commas
   * @param  {Number} number
   * @return {String} The number with commas as the delimiter
   */
  this.getNumberWithCommas = function UtilFactory__getNumberWithCommas(number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  /**
   * Formats salary into our currency form
   * @param  {Object} employee Employee's data object (looks for salary and salaryFreq)
   * @return {String}          Formatted salary with unit
   */
  this.getFormattedSalary = function UtilFactory__getFormattedSalary(employee) {
    if (!_.isObject(employee) && (!_.isNil(employee.salary) && !_.isNaN(employee.salary) && employee.salary != "")) {
      return '';
    }

    // Multiply the salary if necessary to make it annual, not weekly or monthly
    var multiplier = {A: 1, B: 26, H: 52 * employee.weeklyHours, M: 12, S: 24, W: 52 },
    annualSalary = (parseFloat(employee.salary) * multiplier[employee.salaryFreq]).toFixed(2);

    // Add commas to the number for display purposes
    var formattedValue = this.getFormattedCurrency(annualSalary);
    if (formattedValue.charAt(0) !== '$') {
      return '';
    }

    return formattedValue + ' / yr';
  }

  /**
   * Converts any salary into an annual salary
   * @param  {Number} salary Employee's Salary
   * @param  {String} Employee's salary frequency Code ('A', 'B', 'H', 'M', 'S', or 'W')
   * @param  {Number} (optional) The employee's hours worked per week
   * @return {Number} The employee's annual salary
   */
  this.convertSalaryToAnnual = function UtilFactory__convertSalaryToAnnual(salary, salaryFreqCode, weeklyHours) {
    var weeklyHours = weeklyHours || 40;
   if(!_.isNil(salary) && !_.isNaN(salary) && salary != "") {
    switch(salaryFreqCode) {
      // Annual
      case 'A':
        return salary;
        break;
      // Bi-weekly
      case 'B':
        return salary * 26;
        break;
      // Hourly
      case 'H':
        return salary * (52 * weeklyHours);
        break;
      // Monthly
      case 'M':
        return salary * 12;
        break;
      // Semi-monthly
      case 'S':
        return salary * 24;
        break;
      // Weekly
      case 'W':
        return salary * 52;
        break;
      default:
        return undefined;
        break;
    }
   }
  }

  /**
   * Converts an annual salary into the specified salary type
   * @param  {Number} salary Employee's Salary
   * @param  {String} Employee's salary frequency Code ('A', 'B', 'H', 'M', 'S', or 'W')
   * @param  {Number} (optional) The employee's hours worked per week
   * @return {Number} The employee's salary for the specified frequency
   */
  this.convertSalaryFromAnnual = function UtilFactory__convertSalaryFromAnnual(salary, salaryFreqCode, weeklyHours) {
    var weeklyHours = weeklyHours || 40;
   if(!_.isNil(salary) && !_.isNaN(salary) && salary != "") {
    switch(salaryFreqCode.toUpperCase()) {
      // Annual
      case 'A':
        return salary;
        break;
      // Bi-weekly
      case 'B':
        return (salary / 26).toFixed(2);
        break;
      // Hourly
      case 'H':
        return (salary / (52 * weeklyHours)).toFixed(2);
        break;
      // Monthly
      case 'M':
        return (salary / 12).toFixed(2);
        break;
      // Semi-monthly
      case 'S':
        return (salary / 24).toFixed(2);
        break;
      // Weekly
      case 'W':
        return (salary / 52).toFixed(2);
        break;
      default:
        return undefined;
        break;
    }
   }
  }

  /**
   * Returns last 4 digits of SSN
   * @param  {String} ssn Employee's/Dependent's SSN
   * @return {String}     Last 4 digits of SSN
   */
  this.getFormattedSSN = function UtilFactory__getFormattedSSN(ssn) {
    if (_.isUndefined(ssn)) {
      return undefined;
    }
    ssn = ('' + ssn);
    if (ssn.length > 4) ssn = ssn.slice(-4);
    return ssn;
  }

  /**
  * Returns formatted phone number
  * @param  {String} phoneNumber
  * @return {Mixed}  String on success; Boolean on failure
  */
  this.getFormattedCensusPhone = function UtilFactory__getFormattedCensusPhone(phoneNumber) {
    if (!phoneNumber) return '';

    // Convert phone number to a String
    phoneNumber += '';

    var result;

    var phoneRegex  = /^([0-9]{3})([0-9]{3})([0-9]{4})$/;
    var parts       = phoneNumber.match(phoneRegex);

    var phoneStripped = phoneNumber.replace(/[^0-9]/g, '');
    var strippedMatch = phoneStripped.match(phoneRegex);

    if (_.isArray(parts) && parts[1] && parts[2] && parts[3]) {
      result = ['(', parts[1], ') ', parts[2], '-', parts[3]].join('');
    }
    else if (_.isArray(strippedMatch) && strippedMatch[1] && strippedMatch[2] && strippedMatch[3]) {
      result = ['(', strippedMatch[1], ') ', strippedMatch[2], '-', strippedMatch[3]].join('');
    } else {
      result = '';
      $log.error('Received invalid phone number: ', phoneNumber);
    }

    return result;
  }

  /**
   * Returns a formatted census date
   * e.g. 1977-04-18 => Apr 18, 1977
   * @param  {String} serverDate Date format in a YYYY-MM-DD string
   * @return {String}            Returns formatted date
   */
  this.getFormattedCensusDate = function UtilFactory__getFormattedCensusDate(serverDate) {
    var dateParts = ('' + serverDate).split('-'),
    year = dateParts[0],
    month = dateParts[1],
    dayOfMonth = dateParts[2];

    month = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug',
      'Sep','Oct','Nov','Dec'][parseInt(month) - 1];

    return [month, ' ', dayOfMonth, ', ', year].join('');
  }

  /**
   * Returns true/false based on whether or not an SSN is a string/number
   * @param  {String|Number}  segment SSN number
   * @return {Boolean}
   */
  this.isValidSSNSegment = function UtilFactory__isValidSSNSegment(segment) {
    var badType = !_.isString(segment) && !_.isNumber(segment);
    return !badType;
  }

  /**
   * Returns a formatted string value for a date dropdown object
   * @param  {Object} dropdown Object with dateSeg1,2,3,etc.
   * @return {String}          YYYY-MM-DD format of the date/dropdown object
   */
  this.dropdownValuesToDateString = function UtilFactory__dropdownValuesToDateString(dropdown) {
    if (_.isEmpty(dropdown)) return undefined;
    return [(dropdown.dateSeg3 || ''), (dropdown.dateSeg1 || ''), (dropdown.dateSeg2 || '')].join('-');
  };

  /**
   * Converts a formatted date into a date dropdown object
   * @param  {String} dateStr String formatted date as YYYY-MM-DD
   * @return {Object}                Returns Object with the following structure: {dateSeg1: 'MM', dateSeg2: 'DD', dateSeg3: 'YYYY'}
   */
  this.dateStringToDropdownObj = function UtilFactory__formatDateDropDownObj(dateStr) {
    if (!_.isString(dateStr)) return undefined;
    var dateObj = moment(dateStr);
    return {
      dateSeg1: dateObj.format('MM'),
      dateSeg2: dateObj.format('DD'),
      dateSeg3: dateObj.format('YYYY')
    };
  }

  this.dateDropDownsAreEqual = function UtilFactory__dateDropDownsAreEqual(date1, date2) {
    return parseInt(date1.dateSeg1) === parseInt(date2.dateSeg1) &&
           parseInt(date1.dateSeg2) === parseInt(date2.dateSeg2) &&
           parseInt(date1.dateSeg2) === parseInt(date2.dateSeg2);
  }

  /**
   * Returns true if the specified storage is supported and available, otherwise false
   * @param  {type|String} the type of storage (can be 'localStorage' or 'sessionStorage')
   * @return {Boolean} Whether the specified storage is supported and available
   */
  this.storageAvailable = function UtilFactory__storageAvailable(type) {
  	try {
  		var storage = window[type],
  			x = '__storage_test__';
  		storage.setItem(x, x);
  		storage.removeItem(x);
  		return true;
  	}
  	catch(e) {
  		return false;
  	}
  }

  /**
   * Used for encoding a single URL parameter
   * @param {String} str The parameter to encode
   * @return {String} The encoded parameter
   */
  this.encodeURLParam = function UtilFactory__fixedEncodeURIComponent(str) {
    return encodeURIComponent(str).replace(/[!'()*]/g, function(c) {
      return '%' + c.charCodeAt(0).toString(16);
    });
  }

  // returns todays date
  this.newDate = function UtilFactory__newDate() {
    var newDate = new Date();
    var dd = newDate.getDate();
    var mm = newDate.getMonth()+1;
    var yyyy = newDate.getFullYear();

    if (dd < 10) {
      dd = '0' + dd;
    } 
    if (mm < 10) {
      mm = '0' + mm;
    } 

    var today = yyyy+'-'+mm+'-'+dd;

    return today;
  }
  
  return this;
}

module.exports = ['LogFactory', UtilFactory];
