
var _      = require('lodash');
var dottie = require('dottie');

function CoverageEditCtrl($rootScope, $scope, $log, $timeout, $q, WizardService, EmployeeFactory, CoverageFactory, DependentFactory, FormFactory, UtilFactory) {
  var Ctrl = this;

  Ctrl.wizard = WizardService;

  Ctrl.wizard.currentStep = Ctrl;

  // make sure formData is an object and set some defaults for Angular
  Ctrl.formData = _.isObject(Ctrl.formData) ? Ctrl.formData : {};
  Ctrl.formData.effectiveDate = {};
  Ctrl.formData.dateOfEvent   = {};

  Ctrl.volumes    = [];
  Ctrl.enrollees  = [];
  Ctrl.wizard.data.waitingCoverages = [];
  Ctrl.originalDependents = angular.copy(Ctrl.wizard.data.coverage);

  Ctrl.wizard.data.showConfirmationScreen = false;
  Ctrl.employeeInfo = Ctrl.wizard.data.employeeInfo;
  Ctrl.parentCoverage = Ctrl.wizard.data.parentCoverage;
  Ctrl.allowDependents    = false;
  Ctrl.onlyOneCoverageExists = false;
  Ctrl.showTobaccoWarning = false;
  Ctrl.currentTabIndex = 0;
  
  Ctrl.isEditingPeople = false;
  Ctrl.isEditingRider = false;

  Ctrl.qualifyingEvents   = Ctrl.wizard.data.qualifyingEvents;
  if (Ctrl.wizard.data.originalCoverage) {
    Ctrl.wizard.modalTitle  = Ctrl.wizard.data.originalCoverage.benefitDescription;
  }

  Ctrl.reasonCodes = [];
  if (_.isArray(Ctrl.wizard.data.reasonCodes) && Ctrl.wizard.data.reasonCodes.length > 0) {
    Ctrl.reasonCodes = Ctrl.wizard.data.reasonCodes;
  }

  /**
   * Initializes/resets coverage editing data
   */
  this.init = function CoverageEditCtrl__init() {
    var salaryType = '';

    Ctrl.wizard.form  = {};
    Ctrl.wizard.hideReqHelper = false;
    Ctrl.wizard.data.hideEmployeeName = true;

    // Ctrl.coverage is the original coverage in data.electedCoverages from GET /api/employee/:employeeId
    // It is also updated for displaying and editing in the modal
    Ctrl.coverage = angular.copy(Ctrl.wizard.data.originalCoverage);
    // Ctrl.coverageInfo is the general coverage info from /api/employee/:employeeId/benefitOptions/:benefitId
    Ctrl.coverageInfo = angular.copy(Ctrl.wizard.data.coverage);

    if (Ctrl.wizard.data.childCoverages && Ctrl.wizard.data.childCoverages.length) {
      Ctrl.wizard.hasRequiredChildCoverages = true;
    }

    Ctrl.errors               = {};
    Ctrl.formData.riders      = [];
    Ctrl.formData.autoRiders  = [];
    Ctrl.ableToRemove         = false;
    Ctrl.showEnrollees        = false;
    Ctrl.friendlyErrors       = [];

    if (!WizardService.data.originalDependents || !WizardService.data.originalDependents.length) {
      if (Ctrl.employeeInfo) {
        WizardService.data.originalDependents = angular.copy(Ctrl.employeeInfo.dependents);
      }
    } else {
      Ctrl.employeeInfo.dependents = angular.copy(WizardService.data.originalDependents);
    }

    WizardService.originalDependents = angular.copy(Ctrl.employeeInfo.dependents);

    Ctrl.initVolumeSelect();

    Ctrl.allowDependents = false;

    Ctrl.formData.status = 'active';

    var effectiveDate;
    if (isInEligiblePeriod()) {
      if (Ctrl.wizard.data.editMode) {
        // If the coverage being edited is in the eligible period, the effective date equals the original coverage effective date
        effectiveDate = moment(Ctrl.wizard.data.originalCoverage.effectiveDate);
      } else if (underwritingRequired()) {
        // New coverages use the hireDate if in eligible period
        Ctrl.formData.enrollingType = 'NH';
        effectiveDate = moment(Ctrl.employeeInfo.hireDate);
      }
    }

    if (effectiveDate) {
      Ctrl.formData.effectiveDate = {
        dateSeg1: effectiveDate.format('MM'),
        dateSeg2: effectiveDate.format('DD'),
        dateSeg3: effectiveDate.format('YYYY')
      };
    }

    Ctrl.disclaimers = CoverageFactory.populateDisclaimers(Ctrl.wizard.data.coverage, Ctrl.wizard.addingEmployee);
    Ctrl.wizard.data.dependentsChanged = false;
    Ctrl.wizard.data.enrolleesChanged = false;

    Ctrl.originalDependentsSet = false;

    Ctrl.originalDependents.dependents = [];

    Ctrl.coverage.salaryType = '';

    salaryType = CoverageFactory.getCoverageSalaryType(Ctrl.coverage);
    if (salaryType !== "annually") {
      Ctrl.coverage.salaryType = ' / ' + salaryType;
    }

    // If the coverage is for Spouse only and the coverage has 'requireTobacco' set to 'Y' then show the tobacco question
    if (Ctrl.coverage.coveredPersonType === "Spouse" && dottie.get(WizardService.data.coverage, 'requireTobacco') && !!WizardService.data.coverage.requireTobacco) {
      Ctrl.showTobaccoWarning = true;
    }

    Ctrl.coverage.displayRules = CoverageFactory.getCoverageDisplayRules(Ctrl.coverageInfo);

    populateDependents();
  };

  this.reset = function CoverageEditCtrl__reset() {
    // make sure formData is an object and set some defaults for Angular
    Ctrl.formData = {
      effectiveDate: {},
      dateOfEvent: {}
    };

    Ctrl.volumes    = [];
    Ctrl.enrollees  = [];
    Ctrl.originalDependents = angular.copy(Ctrl.wizard.data.coverage);

    Ctrl.wizard.data.showConfirmationScreen = false;
    Ctrl.allowDependents    = false;
    Ctrl.onlyOneCoverageExists = false;
    Ctrl.showTobaccoWarning = false;
    Ctrl.currentTabIndex = 0;

    Ctrl.qualifyingEvents   = Ctrl.wizard.data.qualifyingEvents;
    Ctrl.wizard.modalTitle  = Ctrl.wizard.data.originalCoverage.benefitDescription;

    Ctrl.reasonCodes = [];
    if (_.isArray(Ctrl.wizard.data.reasonCodes) && Ctrl.wizard.data.reasonCodes.length > 0) {
      Ctrl.reasonCodes = Ctrl.wizard.data.reasonCodes;
    }
  }

  /**
   * Get information about the currently selected line of coverage
   * and discard info leftover from previous lines of coverage
   * that used this template.
   */
  this.initVolumeSelect = function CoverageEditCtrl__initVolumeSelect() {
    // Get info about current line of coverage & update page heading
    Ctrl.coverageTypeHeading = Ctrl.coverage.productType;

    var displayRules            = CoverageFactory.getCoverageDisplayRules(Ctrl.coverageInfo);
    Ctrl.coverage.displayRules  = displayRules;
    Ctrl.originalDependents.displayRules = displayRules;

    if (!Ctrl.originalCoverage) {
      Ctrl.originalCoverage = angular.copy(Ctrl.coverage, {});
    }

    var noSpouse = !_.isObject(Ctrl.coverageInfo) || _.isUndefined(Ctrl.coverageInfo.allowSpouse)   || Ctrl.coverageInfo.allowSpouse   === 0;
    var noChild  = !_.isObject(Ctrl.coverageInfo) || _.isUndefined(Ctrl.coverageInfo.allowChildren) || Ctrl.coverageInfo.allowChildren === 0;

    if(Ctrl.coverage.requiresCoverage && Ctrl.coverage.requiresCoverage.length){
      //Ctrl.isOptionalRelationship = Ctrl.coverage.requiresCoverage[0].relationshipTypeCode.indexOf("OPT") != -1;
      Ctrl.isRequiredRelationship = Ctrl.coverage.requiresCoverage[0].relationshipTypeCode.indexOf("REQ") != -1;
    }

    Ctrl.updateVolumes(true, true);

    $timeout(function() {
      var hasPristine     = _.isFunction(dottie.get(Ctrl.wizard.form, '$setPristine'));

      if (hasPristine) {
        Ctrl.wizard.form.$setPristine();
      }
    }, 0);

    // This needs to happen before Ctrl.showRiders
    populateRidersLists();

    // Check again to see if special text, warnings or riders should appear
    Ctrl.showInfoText = false;


    Ctrl.showRiders         = ridersExist();
    Ctrl.showEnrollees      = !noChild || !noSpouse;

    Ctrl.allowDependents = CoverageFactory.isForRelatives(Ctrl.coverageInfo) || CoverageFactory.isForFamilies(Ctrl.coverageInfo);
    Ctrl.wizard.data.allowDependents = Ctrl.allowDependents;
  };

  /**
  * Update the display of volume numbers
  */
  this.updateVolumes = function CoverageEditCtrl__updateVolumes(display, initCall) {
    var guaranteedIssue = Ctrl.coverageInfo.guaranteedIssue;
    var salaryType = '';
    var displayRules = CoverageFactory.getCoverageDisplayRules(Ctrl.coverageInfo);
    var equalParentVolume = false;
    var capAtParentVolume = false;

    if (Ctrl.parentCoverage || (Ctrl.coverage.requiresCoverage && Ctrl.coverage.requiresCoverage.length && Ctrl.coverage.requiresCoverage[0].relationshipTypeCode)) {
      // This is a child coverage so we need to check the parent coverage's volumes
      if (Ctrl.coverage.requiresCoverage && Ctrl.coverage.requiresCoverage[0]) {
        var relationshipTypeCode = Ctrl.coverage.requiresCoverage[0].relationshipTypeCode;
        if (_.includes(['GPOPTMCH', 'GPREQMCH'], relationshipTypeCode)) {
          // Volume should equal parent volume
          equalParentVolume = true;
          // if a match volume, don't show dropdown as the user will not be able to change the volume
          displayRules.showIncrement = false;
          displayRules.flat = true;
        }
        else if (_.includes(['GPOPTVLLTE', 'GPREQVLLTE'], relationshipTypeCode)) {
          // Volume should be capped at parent volume
          capAtParentVolume = true;
          displayRules.showIncrement = true;
          displayRules.flat = false;
        }
      }
    }

    if (display && !displayRules.showIncrement) {
      salaryType               = CoverageFactory.getCoverageSalaryType(Ctrl.coverageInfo);
      if (!initCall || !Ctrl.wizard.data.editMode) {
        if (equalParentVolume) {
          var parentVolumeElected = _.find(Ctrl.employeeInfo.electedCoverages, {benefitID:Ctrl.parentCoverage.benefitID});
          Ctrl.coverage.volume = Ctrl.parentCoverage.volume || parentVolumeElected.volume;          
        } else {
          Ctrl.coverage.volume = CoverageFactory.findVolume(Ctrl.coverageInfo, Ctrl.employeeInfo, Ctrl.originalCoverage);
        }
      }
      Ctrl.coverage.salaryType = salaryType !== "annually" ? ' / ' + salaryType : '';
    }
    else if (_.has(Ctrl.coverageInfo, 'volumeIncrement')) {
      CoverageFactory.getVolumes(Ctrl.coverageInfo, Ctrl.employeeInfo).then(function (volumes) {
        Ctrl.volumes = volumes;

        if (capAtParentVolume) {
          Ctrl.volumes = _.filter(Ctrl.volumes, function(volume) {
            return volume <= Ctrl.parentCoverage.volume;
          });
        }

        // populate original volume if editing coverage
        if (Ctrl.wizard.data.editMode && _.isObject(Ctrl.wizard.data.originalCoverage)) {
          Ctrl.coverage.volume = Ctrl.wizard.data.originalCoverage.volume;
          // If the coverage is in it's eligible period, volume electives are capped at the original volume
          if (!isInEligiblePeriod()) {
            Ctrl.volumes = Ctrl.volumes.filter(function(val) {
              return parseInt(val, 10) <= parseInt(Ctrl.originalCoverage.volume, 10);
            });
          }
        } else {
          Ctrl.coverage.volume = '';
        }
      });
    }

    if (!isNaN(Ctrl.coverage.volume) && !Ctrl.wizard.data.editMode) {
      if (Ctrl.coverageInfo.productType == "LTD" && Ctrl.coverageInfo.volumeCalculationType == "S") {
        var newVolume = Ctrl.coverage.volume / Ctrl.coverageInfo.salaryMultiplier;
        newVolume = Math.ceil(newVolume);
        Ctrl.coverage.volume = newVolume;
      }
    }

    Ctrl.coverage.displayRules = displayRules;
  };

  this.init();

  /**
   * Changes our form status and checks for valid form
   * @param  {String} value Status value
   */
  this.changeStatus = function CoverageEditCtrl__changeStatus(value) {
    Ctrl.formData.status = value;
    Ctrl.checkForValidForm();
  }

  /**
   * Checks to see if the form is valid
   */
  this.checkForValidForm = function CoverageEditCtrl__checkForValidForm() {
    var originalCovExists   = _.isObject(Ctrl.originalCoverage);
    var validAngularForm    = _.isFunction(dottie.get(Ctrl.wizard.form, '$setPristine'));
    var originalCoverage    = originalCovExists && Ctrl.originalCoverage.id === Ctrl.coverage.id;
    var originalStatus      = originalCovExists && Ctrl.formData.status === 'active' && Ctrl.wizard.data.editMode;
    var originalDependents  = Ctrl.enrollees.length < 1 || (!Ctrl.wizard.data.dependentsChanged && Ctrl.enrollees.length > 0);
    var noEnrollee          = Ctrl.coverage.allOptions && Ctrl.originalDependents.dependents.length < 1;

    if ((originalCovExists && validAngularForm && originalCoverage && originalStatus && originalDependents && originalRidersExist()) || noEnrollee) {
      Ctrl.wizard.form.$setPristine();
    }
  }

  this.showEffectiveDate = function CoverageEditCtrl__showEffectiveDate() {
    var originalVolume = parseInt(Ctrl.originalCoverage.volume, 10),
        currentVolume = typeof Ctrl.originalCoverage.friendlyVolume === 'string' && parseInt(Ctrl.originalCoverage.friendlyVolume.replace(/[^\d]/g, ''), 10) !== Ctrl.originalCoverage.volume ? parseInt(Ctrl.coverage.volume * (Ctrl.coverage.salaryMultiplier || 1), 10) : parseInt(Ctrl.coverage.volume, 10),
        dependentsChanged = Ctrl.allowDependents && (Ctrl.wizard.data.dependentsChanged || Ctrl.wizard.data.enrolleesChanged),
        editingCoverageAndDependentsChanged = Ctrl.wizard.data.editMode && dependentsChanged && Ctrl.formData.status != 'inactive',
        isNotShowingQE = !Ctrl.showQualifyingLifeEvent();
    return (editingCoverageAndDependentsChanged || (originalVolume && originalVolume !== currentVolume) || !originalRidersExist() || !Ctrl.wizard.data.editMode) && isNotShowingQE && (!isInEligiblePeriod() || (!underwritingRequired() && !Ctrl.wizard.data.editMode));
  }

  this.showQualifyingLifeEvent = function CoverageEditCtrl__showQualifyingLifeEvent() {
    var dependentsChanged = Ctrl.allowDependents && (Ctrl.wizard.data.dependentsChanged || Ctrl.wizard.data.enrolleesChanged);
    return Ctrl.coverage.displayRules.qualifyingLifeEvent && (dependentsChanged || !Ctrl.wizard.data.editMode) && (!isInEligiblePeriod() || (!underwritingRequired() && !Ctrl.wizard.data.editMode));
  }

  this.showUneditableVolume = function CoverageEditCtrl__showUneditableVolume() {
    return !!Ctrl.coverage.volume && !Ctrl.volumes.length;
  }

  this.showVolumeSelect = function CoverageEditCtrl__showVolumeSelect() {
    return Ctrl.coverage.displayRules.showIncrement && Ctrl.volumes.length > 0;
  }

  this.showNewHireEnrollingType = function CoverageEditCtrl__showNewHireEnrollingType() {
    return isInEligiblePeriod();
  }

  // Checks if the checked dependents differs from the original list for this coverage
  this.dependentChanged = function CoverageEditCtrl__dependentChanged() {
    $timeout(function() {
      var currentDepIds = _.sortBy(_.chain(Ctrl.originalDependents.dependents).mapValues('id').values().value());
      Ctrl.wizard.data.dependentsChanged = !_.isEqual(currentDepIds, Ctrl.initialDependentIds);
      conditionallySetPristine();
    },0);
  }

  this.enrolleeStatusChanged = function CoverageEditCtrl__enrolleeStatusChanged(enrollee) {
    Ctrl.isEditingPeople = true;
    Ctrl.wizard.data.enrolleesChanged = _.some(Ctrl.enrollees, function(enrollee) {
      return enrollee.editAction !== "Select";
    });
    conditionallySetPristine();
  }

  this.toggleRider = function CoverageEditCtrl__toggleRider(rider, isInput) {
    if (!rider.disabled && !isInput && !Ctrl.isEditingPeople) {
      rider.checked = !rider.checked;
    }
    Ctrl.isEditingRider = !originalRidersExist();
  }

  // Set the form to pristine condition if dependents haven't changed so that the save button remains disabled
  function conditionallySetPristine() {
    if (!Ctrl.wizard.data.dependentsChanged && !Ctrl.wizard.data.enrolleesChanged) {
      Ctrl.isEditingPeople = false;
      if (!_.isEmpty(Ctrl.wizard.form)) {
        Ctrl.wizard.form.$setPristine();
      }
    }
  }

  function populateRidersLists () {
    // Reset the lists
    Ctrl.formData.autoRiders = [];
    Ctrl.formData.riders     = [];
    Ctrl.originalRiders = [];

    var currentCoverageRiders = [];
    var newCoverageRiders     = CoverageFactory.getRiders(Ctrl.coverageInfo);
    var arr = [];
    var disableRiders = false;
    if (Ctrl.coverage && Ctrl.coverage.hasRiderPendingChange === 1) {
      disableRiders = true;
    }

    if (Ctrl.wizard.data.editMode) {
      currentCoverageRiders = CoverageFactory.getRiders(Ctrl.coverage).map(function (coverage) {
        coverage.checked = true;
        coverage.disabled = disableRiders;
        return coverage;
      });
    }

    arr = _.uniqWith(currentCoverageRiders.concat(newCoverageRiders), idIsEqual);

    function idIsEqual(rider1, rider2) {
      return rider1.id === rider2.id;
    }

    // Separate the list of riders into auto-displayed vs. non-auto displayed
    _.each(arr, function (rider) {
      var auto  = (rider.autoSelect === true || rider.autoSelect === 1);
      var array = auto ? Ctrl.formData.autoRiders : Ctrl.formData.riders;

      array.push(rider);
    });

    angular.copy(Ctrl.formData.riders, Ctrl.originalRiders);
  }

  // Function to populate dependents in proper format
  function populateDependents () {
    Ctrl.enrollees = [];

    var isForSpouse = CoverageFactory.isForSpouse(Ctrl.coverageInfo),
        isForChildren = CoverageFactory.isForChildren(Ctrl.coverageInfo),
        isForDependents = CoverageFactory.isForDependents(Ctrl.coverageInfo),
        isForFamilies = CoverageFactory.isForFamilies(Ctrl.coverageInfo),
        isForEmployee = CoverageFactory.isForEmployee(Ctrl.coverageInfo),
        isForEmployeeDependents = CoverageFactory.isForEmployeeDependents(Ctrl.coverageInfo);

    if (isForSpouse || isForChildren || isForDependents || isForFamilies || isForEmployeeDependents) {
      Ctrl.allowDependents = true;
    }

    if (!Ctrl.wizard.data.editMode) {
      if (isForFamilies || isForEmployeeDependents) {
        var employee = Ctrl.employeeInfo;

  //       Ctrl.enrollees.push({
  //         id: parseInt(employee.memberEntityId, 10),
  //         type: 'Employee',
  //         firstName: employee.firstName,
  //         lastName: employee.lastName,
  //         relationship: "EE",
  //         editable: true
  //       })
      }

      if (isForSpouse || isForDependents || isForFamilies || isForEmployeeDependents) {
        var editable = true;
        // If the coverage is only for spouse, we can't remove the spouse
        if (isForSpouse) {
          editable = false;
        }

        var spouse = DependentFactory.findSpouse(Ctrl.employeeInfo.dependents);

        if (spouse.length && spouse[0].status && spouse[0].status.toUpperCase() === "ACTIVE") {
          Ctrl.enrollees.push({
            id:   parseInt(spouse[0].memberEntityId, 10),
            type: CoverageFactory.getRelationshipByCode(spouse[0].relationship.toUpperCase()),
            firstName: spouse[0].firstName,
            lastName: spouse[0].lastName,
            relationship: "SP",
            editable: editable
          });
        }
      }

      if (isForChildren || isForDependents || isForFamilies || isForEmployeeDependents) {
        var children = DependentFactory.findChildren(Ctrl.employeeInfo.dependents);

        if (children.length) {
          children.forEach(function(child) {
            if (child.status && child.status.toUpperCase() === "ACTIVE") {
              Ctrl.enrollees.push({
                id:   parseInt(child.memberEntityId, 10),
                type: CoverageFactory.getRelationshipByCode(child.relationship.toUpperCase()),
                firstName: child.firstName,
                lastName: child.lastName,
                relationship: "CH",
                editable: true
              });
            }
          });
        }
      }
    } else {
      if (isForEmployee || isForEmployeeDependents || isForFamilies) {
        Ctrl.enrollees.push({
          id: parseInt(Ctrl.employeeInfo.memberEntityId, 10),
          type: 'Employee',
          firstName: Ctrl.employeeInfo.firstName,
          lastName: Ctrl.employeeInfo.lastName,
          relationship: "EE",
          editable: isForFamilies ? true : false
        });
      }

      var activeDependents = _.map(Ctrl.employeeInfo.dependents, function(dep){
        return dep.status.toLowerCase() == "active"? dep:false;
      });

      for (var i=0; i < activeDependents.length; i++) {
        var isSpouse = activeDependents[i].relationship == "SP",
            isChild = activeDependents[i].relationship == "CH";
        var type, isEditable = false;

        if (isSpouse){
          type = "Spouse";
          if (!isForSpouse) {
            isEditable = true;
          }
        } else if (isChild) {
          type = "Child";
          isEditable = true;
        }

        if (((isForSpouse || isForEmployeeDependents || isForFamilies) && isSpouse) ||
            ((isForDependents || isForEmployeeDependents || isForFamilies || isForChildren) && isChild)) {
          Ctrl.enrollees.push({
            id: parseInt(activeDependents[i].memberEntityId, 10),
            type: type,
            firstName: activeDependents[i].firstName,
            lastName: activeDependents[i].lastName,
            relationship: activeDependents[i].relationship,
            editable: isEditable
          });
        }
      }

      Ctrl.enrollees = _.map(Ctrl.enrollees, function (enrollee) {
        var findDep = _.find(Ctrl.coverage.coveredPeople, function (dep) {
          return dep.memberEntityId === enrollee.id;
        });

        if (findDep && findDep.memberEntityId !== Ctrl.employeeInfo.memberEntityId) {
          Ctrl.originalDependents.dependents.push(enrollee);
        }

        enrollee.editAction = "Select";
        enrollee.editActions = ["Select"];

        if (findDep) {
          enrollee.isCovered = true;
          enrollee.editActions.push("Remove");
        } else {
          enrollee.isCovered = false;
          enrollee.editActions.push("Add");
        }

        return enrollee;
      });

      // Create Ctrl.initialDependents to later determine if the list of dependents has been updated when user clicks on a dependent checkbox
      Ctrl.initialDependentIds = _.sortBy(_.chain(angular.copy(Ctrl.originalDependents.dependents)).mapValues('id').values().value());

      Ctrl.coverage.dependents = angular.copy(Ctrl.enrollees);
    }
  }

  Ctrl.removeError = function() {
    for (var i = 0; i < arguments.length; i++)
      if (Ctrl.formData.errors && Ctrl.formData.errors[arguments[i]])
        Ctrl.formData.errors[arguments[i]] = false;
  };

  Ctrl.tabSelected = function CoverageEditCtrl__tabSelected(tabIndex) {
    Ctrl.currentTabIndex = tabIndex;
  }

  Ctrl.queueCoverage = function CoverageEditCtrl__queueCoverage() {
    var coverageData = WizardService.data.updateCoverage(true);
    if (!coverageData) return false;
    coverageData.id = Ctrl.coverage.id;
    Ctrl.wizard.data.waitingCoverages.push(coverageData);
    if (Ctrl.wizard.data.waitingCoverages.length === 1) {
      Ctrl.parentCoverage = coverageData;
    }
    // Get the next required child coverage and set it as the current coverage
    if (Ctrl.wizard.data.childCoverages && Ctrl.wizard.data.childCoverages.length) {
      Ctrl.wizard.data.originalCoverage = Ctrl.wizard.data.childCoverages.shift();
      Ctrl.wizard.data.coverage = Ctrl.wizard.data.originalCoverage;
      Ctrl.reset();
      Ctrl.init();
    } else {
      // There are no more required child coverages to add, so show the summary
      Ctrl.wizard.data.showConfirmationScreen = true;
      Ctrl.wizard.modalTitle = "";
    }
  }

  Ctrl.sendCoverages = function CoverageEditCtrl__sendCoverages() {
    CoverageFactory.insert(Ctrl.wizard.data.waitingCoverages, Ctrl.employeeInfo.id).then(function (covRes) {
      coverageAddOrUpdateSuccessfull();
    }, populateErrors);
  }

  WizardService.data.disableSaveBtn = function WizardService__Data__disableSaveBtn() {
    //return Ctrl.wizard.form.$invalid || (Ctrl.wizard.data.editMode && Ctrl.wizard.form.$pristine)
    return (Ctrl.currentTabIndex === 0 && Ctrl.wizard.form.$invalid) || (Ctrl.currentTabIndex === 1 && Ctrl.wizard.form2.$invalid);
  }

  WizardService.data.updateCoverage = function WizardService__Data__updateCoverage(hasRequiredChildCoverages) {
    var validations = [];

    if (Ctrl.showVolumeSelect()) {
      validations.push(FormFactory.validations.integer(Ctrl.coverage.volume));
    }

    // Qualifying Event
    if (Ctrl.coverage.displayRules.qualifyingLifeEvent && Ctrl.formData.enrollingType == 'QE') {
      // Date of Event
      if (_.isObject(Ctrl.formData.dateOfEvent) && !_.isUndefined(Ctrl.formData.dateOfEvent.dateSeg1)) {
        validations.push(
          FormFactory.validations.date(Ctrl.formData.dateOfEvent, {key: 'effectiveDate', before: 'effectiveDatePast', compareDate: moment().add(3, 'months')}),
          FormFactory.validations.date(Ctrl.formData.dateOfEvent, {key: 'effectiveDate', after: 'effectiveDateAfter', compareDate: moment().subtract(3, 'months')})
        );
      }
      if (Ctrl.wizard.data.editMode) {
        validations = validations.concat([
          FormFactory.validations.date(Ctrl.formData.dateOfEvent, {key: 'effectiveDate', after: 'effectiveDateAfterEffective', compareDate: Ctrl.wizard.data.originalCoverage.effectiveDate, acceptSameDay: false})
        ]);
      } else {
        validations = validations.concat([
          FormFactory.validations.date(Ctrl.formData.dateOfEvent, {key: 'effectiveDate', after: 'effectiveDateAfterHire', compareDate: Ctrl.employeeInfo.hireDate, acceptSameDay: true})
        ]);
      }
    }

    if (_.isObject(Ctrl.formData.effectiveDate) && !_.isUndefined(Ctrl.formData.effectiveDate.dateSeg1)) {
      // This is Effective Date if terminating a coverage
      validations.push(
          FormFactory.validations.date(Ctrl.formData.effectiveDate, {key: 'effectiveDate', before: 'effectiveDateBefore', compareDate: moment().add(3, 'months')}),
          FormFactory.validations.date(Ctrl.formData.effectiveDate, {key: 'effectiveDate', after: 'effectiveDateAfter', compareDate: moment().subtract(3, 'months')})
        );

      if (Ctrl.wizard.data.editMode) {
        validations = validations.concat([
          FormFactory.validations.date(Ctrl.formData.effectiveDate, {key: 'effectiveDate', after: 'effectiveDateAfterEffective', compareDate: Ctrl.coverage.effectiveDate, acceptSameDay: true})
        ]);
      } else {
        validations = validations.concat([
          FormFactory.validations.date(Ctrl.formData.effectiveDate, {key: 'effectiveDate', after: 'effectiveDateAfterHire', compareDate: Ctrl.employeeInfo.hireDate, acceptSameDay: true}),
        ]);
      }
    }

    var errors = FormFactory.validate(Ctrl.wizard.form, {validations: validations});

    if (_.keys(errors.errors).length > 0) {
      Ctrl.friendlyErrors  = errors.friendlyErrors;
      Ctrl.formData.errors = errors.errors;
      return false;
    }

    if (!Ctrl.wizard.data.showConfirmationScreen) {
      if (Ctrl.formData.enrollingType === 'NH') {
        Ctrl.formData.effectiveDate = Ctrl.employeeInfo.hireDate;
        Ctrl.formData.effectiveDateFriendly = moment(Ctrl.formData.effectiveDate).toDate();
      } else if (Ctrl.formData.enrollingType === 'QE') {
        Ctrl.formData.effectiveDate = Ctrl.formData.dateOfEvent;
      }

      if (_.isObject(Ctrl.formData.effectiveDate) && !_.isUndefined(Ctrl.formData.effectiveDate.dateSeg1)) {
        Ctrl.formData.effectiveDateFriendly = moment(UtilFactory.dropdownValuesToDateString(Ctrl.formData.effectiveDate), ['YYYY-MM-DD']).toDate();
      }

      if (!Ctrl.coverage.friendlyVolume) {
        Ctrl.coverage.friendlyVolume = Ctrl.coverage.volume;
        if (!isNaN(Ctrl.coverage.friendlyVolume)) {
          if (Ctrl.coverage.productType == "LTD" && Ctrl.coverage.volumeCalculationType == "S") {
            var newVolume = (Ctrl.coverage.friendlyVolume)/(Ctrl.coverage.salaryMultiplier);
            newVolume = Math.ceil(newVolume);
            Ctrl.coverage.friendlyVolume = newVolume;
          }
        }
      }

      var isForSpouse = CoverageFactory.isForSpouse(Ctrl.coverageInfo),
          isForEmployee = CoverageFactory.isForEmployee(Ctrl.coverageInfo),
          isForFamilies = CoverageFactory.isForFamilies(Ctrl.coverageInfo),
          isForEmployeeDependents = CoverageFactory.isForEmployeeDependents(Ctrl.coverageInfo);

      if (isForEmployee || isForEmployeeDependents || (isForFamilies && Ctrl.originalDependents.displayRules.employeeCheckboxChecked) ) {
        Ctrl.originalDependents.dependents.push({
          firstName: Ctrl.employeeInfo.firstName,
          id: parseInt(Ctrl.employeeInfo.memberEntityId, 10),
          type: "Employee"
        });
      }

      if (!Ctrl.wizard.data.editMode && isForSpouse) {
        var spouse = _.find(Ctrl.employeeInfo.dependents, function (obj) {
          return ['H','W','SP'].indexOf(obj.relationship.toUpperCase()) > -1;
        });
        Ctrl.originalDependents.dependents.push({
          firstName: spouse.firstName,
          id: parseInt(spouse.memberEntityId, 10),
          type: "Spouse",
          relationship: "SP"
        });
      }

      if (Ctrl.wizard.data.editMode && _.isArray(Ctrl.enrollees)) {
        var enrolled = _.filter(Ctrl.enrollees, function(enrollee) {
          return (enrollee.isCovered && enrollee.editAction === "Select") || enrollee.editAction === "Add";
        });
        Ctrl.coverage.whosEnrolled = CoverageFactory.getDependentsAsString(enrolled, Ctrl.coverage);
        
        var addedMembers = [];
        var removedMembers = [];
        
        _.forEach(Ctrl.enrollees, function(member){
          var memberInUpdate = _.find(enrolled, {id: member.id});          
          if(memberInUpdate && !member.isCovered){
            addedMembers.push(member);
          }
          if(!memberInUpdate && member.isCovered){
            removedMembers.push(member);
          }
        });
        Ctrl.coverage.whosAdded = CoverageFactory.getDependentsAsString(addedMembers, Ctrl.coverage);
        Ctrl.coverage.whosRemoved = CoverageFactory.getDependentsAsString(removedMembers, Ctrl.coverage);
      } else {
        Ctrl.coverage.whosEnrolled = CoverageFactory.getDependentsAsString(Ctrl.originalDependents.dependents, Ctrl.coverage);
      }

      Ctrl.riders = CoverageFactory.getCheckedRiders(Ctrl.formData.riders, Ctrl.formData.autoRiders);
      Ctrl.riderTypes = CoverageFactory.getRidersAsString(Ctrl.riders);

      if (hasRequiredChildCoverages) {
        return WizardService.data.updateCoverageConfirm(hasRequiredChildCoverages);
      }

      Ctrl.wizard.data.showConfirmationScreen = true;
      Ctrl.wizard.hideReqHelper               = true;
      return true;
    }

    WizardService.data.updateCoverageConfirm();
  }

  WizardService.data.updateCoverageConfirm = function WizardService__Data__updateCoverageConfirm(hasRequiredChildCoverages) {
    var volume;

    if (CoverageFactory.isSalaryBased(Ctrl.coverageInfo)) {
      volume = null;
    } else {
      volume = Ctrl.coverage.volume;
      // Remove non-numeric characters
      if (_.isString(volume)) {
        volume = parseFloat(volume.replace(/[^0-9\.]+/g, ''));
      }
      volume = isNaN(volume) ? 0 : volume;
    }

    // If the tobaccoUse property is set, that means it's a coverage for a spouse and we should update the spouse's profile with the tobacco use value.
    if (Ctrl.coverage.tobaccoUse) {
      var spouse = _.find(Ctrl.employeeInfo.dependents, function (obj) {
        return ['H','W','SP'].indexOf(obj.relationship.toUpperCase()) > -1;
      });
      if (spouse) {
        var employeeId = parseInt(Ctrl.employeeInfo.id, 10);
        DependentFactory.update(employeeId, {id:spouse.id, tobaccoUse:Ctrl.coverage.tobaccoUse, effectiveDate:spouse.effectiveDate, firstName:spouse.firstName, lastName:spouse.lastName, relationship:spouse.relationship, ssn:spouse.ssn||"", middleName1:spouse.middleName1||""});
      }
    }

    var effectiveDate = Ctrl.formData.effectiveDate;
    if (_.isObject(effectiveDate)) {
      effectiveDate = UtilFactory.dropdownValuesToDateString(effectiveDate);
    }

    var coverageData = {
      coverageCode:       Ctrl.coverage.coverageCode,
      benefitID:          Ctrl.coverage.benefitID,
      benefitDescription: Ctrl.coverage.benefitDescription,
      volume:             volume,
      effectiveDate:      effectiveDate,
      riders:             Ctrl.riders,
      productType:        Ctrl.coverage.productType,
      coveredPersonType:  Ctrl.coverage.coveredPersonType,
      coveredPeople: [],
      removedPeople: [],
      whosEnrolled:       Ctrl.coverage.whosEnrolled
    };

    if (!_.isUndefined(Ctrl.formData.enrollingType)) {
      coverageData.enrollingType = Ctrl.formData.enrollingType;

      // Qualifying Event
      if (coverageData.enrollingType === 'QE') {
        if (!_.isUndefined(Ctrl.formData.qualifyingLifeEvent)) {
          coverageData.qualifyingEventTypeCode = Ctrl.formData.qualifyingLifeEvent.eventCode;
        }
      }
    }

    var method = Ctrl.wizard.data.editMode ? 'update' : 'insert';
    var covId  = Ctrl.coverage.id;

    if (method === 'insert' && Ctrl.originalDependents.dependents) {
      coverageData.coveredPeople = angular.copy(Ctrl.originalDependents.dependents)
    } else if (method === 'update' && Ctrl.enrollees) {
      coverageData.coveredPeople = _.filter(Ctrl.enrollees, function(enrollee) {
        return enrollee.editAction === "Add" || (enrollee.isCovered && enrollee.editAction === "Select");
      });
      coverageData.removedPeople = _.filter(Ctrl.enrollees, function(enrollee) {
        return enrollee.editAction === "Remove";
      });

      // TEMP FIX - Backend requires an effectiveDate property
      if (!coverageData.effectiveDate) {
        if (coverageData.coveredPeople.length) {
          coverageData.effectiveDate = UtilFactory.dropdownValuesToDateString(coverageData.coveredPeople[0].requestedChangeDate);
        } else if (coverageData.removedPeople.length) {
          coverageData.effectiveDate = UtilFactory.dropdownValuesToDateString(coverageData.removedPeople[0].requestedChangeDate);
        }
      }
    }

    Ctrl.wizard.data.employeeName  = Ctrl.employeeInfo.firstName + ' ' + Ctrl.employeeInfo.lastName;
    Ctrl.wizard.data.modalMetaData = Ctrl.wizard.data.modalMetaData || {};
    Ctrl.wizard.data.modalMetaData.employeeName = angular.copy(Ctrl.wizard.data.employeeName);

    Ctrl.errors         = {};
    Ctrl.friendlyErrors = [];

    if (method === 'update' && Ctrl.formData.status === 'inactive') {
      method        = 'remove';
      coverageData  = coverageData.effectiveDate;
      covId         = WizardService.data.originalCoverage.id;
    }

    if (hasRequiredChildCoverages) {
      return coverageData;
    }

    CoverageFactory[method](coverageData, Ctrl.employeeInfo.id, covId).then(function (covRes) {
      coverageAddOrUpdateSuccessfull();
    }, populateErrors);
  }

  function coverageAddOrUpdateSuccessfull() {
    Ctrl.wizard.showFinalConfirmScreen = true;
    WizardService.timeout = $timeout(function() {
      if (_.isFunction(WizardService.data.finishCoverageModal)) {
        WizardService.data.finishCoverageModal();
      }
    }, 1000 * 4);
  }

  function populateErrors(res) {
    var errors          = $log.errorHandler(res);
    Ctrl.errors         = errors.errors;
    Ctrl.friendlyErrors = errors.friendlyErrors;
    if (res.status >= 400) {
      Ctrl.showError = true;

      if (res.data.message =="Termination date should not be 3 months prior and 3 months after todays date") {
        Ctrl.isTermError = true;
      } else if (res.data.message.match(/Annual Enrollment/ig)) {
        Ctrl.isAnnualError = true;
        Ctrl.showError = false;
      }
    }
  }

  WizardService.data.finishCoverageModal = function() {
    $rootScope.$broadcast('modal:hide', Ctrl.employeeInfo);
    $timeout.cancel(WizardService.timeout);
  }

  $scope.$watch('Ctrl.formData', function() {
    Ctrl.checkForValidForm();
  }, true);

  function isInEligiblePeriod() {
    if (dottie.get(Ctrl, 'wizard.data.originalCoverage.isInEligiblePeriod')) {
      return Ctrl.wizard.data.originalCoverage.isInEligiblePeriod === 1;
    }
    return undefined;
  }

  function underwritingRequired() {
    if (dottie.get(Ctrl, 'wizard.data.originalCoverage.underwritingRequired')) {
      return Ctrl.wizard.data.originalCoverage.underwritingRequired === 1;
    }
    return undefined;
  }

  function ridersExist () {
    return Ctrl.formData.riders.length > 0 || Ctrl.formData.autoRiders.length > 0;
  }

  function originalRidersExist() {
    return _.isEqualWith(Ctrl.originalRiders, Ctrl.formData.riders, function(origRider, rider) {
      return origRider.id && rider.id && origRider.id === rider.id && origRider.checked === rider.checked;
    });
  }
}

module.exports = ['$rootScope', '$scope', 'LogFactory', '$timeout', '$q', 'WizardService', 'EmployeeFactory', 'CoverageFactory', 'DependentFactory', 'FormFactory', 'UtilFactory', CoverageEditCtrl];
