
/**
  * @ngdoc relationshipSelect
  * @name relationshipSelect
  * @restrict E
  *
  * @description
  * Renders a template for selecting what kind of relationship this dependent is from the employee's perspective.
  *
  *
  * @param {object}   obj     Two-way binding ng-model for the relationship data
  * @param {function} clearfn Triggers this function whenever a change occurs
  *
  * @example
      <relationship-select obj="Ctrl.dependent" clearfn="Ctrl.removeError('relationship')"></relationship-select>
*/

function relationshipSelectDirective() {
  return {
    restrict: "E",
    templateUrl: "/partials/partials/relationshipSelect",
    scope: {
      obj: "=",
      clearfn: "&"
    }
  };
}

module.exports = relationshipSelectDirective;
