
/**
  * @ngdoc animateScrollTo
  * @name any[animateScrollTo]
  * @restrict A
  *
  * @description
  * Scrolls to an element in an animated fasion
  *
  *
  * @param {string} animate-scroll-to A string for querySelector/jQuery.find
  *
  * @example
      <a animate-scroll-to="#some-content">Go to some content!</a>
*/

function animateScrollToDirective() {
  return {
    scope: {
      scrollToEl: '=scrollToElement'
    },
    restrict: 'A',
    link: function (scope, elm, attrs) {
      var $page         = $("body, html");

      elm.click(function () {
        var $scrollTarget = $(attrs.animateScrollTo);
        $page.animate({scrollTop: $scrollTarget.offset().top }, 1000);
      });
    }
  };
}

module.exports = animateScrollToDirective;
