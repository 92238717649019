
/**
 * Gets groups from user factory/service
 * @param {Service} $http       AngularJS $http
 * @param {Service} $rootScope  AngularJS $rootScope
 * @param {Service} $log        AngularJS $log
 * @param {Service} UserFactory Our user service
 */

/* istanbul ignore next */
(function() {
  function GetGroups($http, $rootScope, $log, UserFactory) {
    return UserFactory.getGroups().then(function GetGroups_success (response) {
      return response.data.data.groups;
    }, function GetGroups_error (error) {
      $log.error('Error while trying to get available groups.', error);
    });
  }

  module.exports = ['$http', '$rootScope', '$log', 'UserFactory', GetGroups];
})();
