
var _ = require('lodash');

function ProductsCtrl($log, WizardService, EmployeeFactory, DependentFactory, CoverageFactory) {
  var Ctrl      = this;

  Ctrl.hasSpouse = false;
  Ctrl.hasChild  = false;
  Ctrl.isEmployee = false;

  this.wizard       = WizardService;
  this.wizard.form  = {};

  this.wizard.hideReqHelper = true; // hide the required fields helper message
  this.wizard.sidebar       = false;
  this.wizard.currentStep   = this;
  this.wizard.isProductsNext = false;

  this.showUnderwritingDisclaimer = false;
  this.showChildCoverageDisclaimer = false;
  this.noAvailableCoverages = false;

  if (_.isObject(Ctrl.wizard.data.newDep)) {
    Ctrl.hasSpouse = ['H','W','S','spouse', 'SP'].indexOf(Ctrl.wizard.data.newDep.relationship) !== -1;
    Ctrl.hasChild = !Ctrl.hasSpouse;

    if (!_.isUndefined(Ctrl.wizard.employeeInfo) && _.isUndefined(Ctrl.wizard.data.employeeInfo)) {
      Ctrl.wizard.data.employeeInfo = Ctrl.wizard.employeeInfo;
    }

    Ctrl.wizard.data.employeeName = Ctrl.wizard.data.employeeInfo.firstName + ' ' + Ctrl.wizard.data.employeeInfo.lastName;

    Ctrl.wizard.data.successName = angular.copy(Ctrl.wizard.data.employeeName, "");
    Ctrl.wizard.data.employeeName += "'s " + (Ctrl.hasSpouse ? 'Spouse' : 'Child');
  } else {
    Ctrl.isEmployee = true;
  }

  this.coverages = {
    row1: [],
    row2: []
  };

  this.isRequired = true;

  if (_.isArray(this.wizard.data.divisionCoverages) && this.wizard.data.divisionCoverages.length > 0) {
    populateList();
  } else {
    if (this.wizard.addingEmployee) {
      CoverageFactory.findAvailableCoverages(this.wizard.data.employeeInfo.id).then(function (res) {
        Ctrl.wizard.data.divisionCoverages = res;
        populateList();
      }, $log.error);
    } else if (this.wizard.data.newDep) {
      // We're adding or re-adding a dependent to an existing employee, get available coverages for the specific dependent
      CoverageFactory.findAvailableCoveragesByDependent(this.wizard.data.newDep.id).then(function (res) {
        Ctrl.wizard.data.divisionCoverages = res;
        populateList();
      }, $log.error)
    }
  }

  /**
   * Checks to see if any coverages are required
   */
  this.checkForRequired = function ProductsCtrl__checkForRequired() {
    Ctrl.isRequired = true;
    _.each(Ctrl.coverages, function (rows) {
      _.each(rows, function (row) {
        if (row.checked === true) {
          Ctrl.isRequired = false;
        }
      });
    });
  }

  this.isAnyParentChecked = function ProductsCtrl__getCheckedState(childCoverage) {
    var parents = [];
    var relationshipTypeCode = childCoverage.requiresCoverage[0].relationshipTypeCode;

    _.forEach(childCoverage.requiresCoverage, function(reqCov) {
      var parent = _.find(Ctrl.coverages['row1'].concat(Ctrl.coverages['row2']), function(coverage) {
        var coverageBenefitId = coverage.benefitId ? coverage.benefitId : coverage.benefitID;
        var reqCovBenefitId = reqCov.benefitId ? reqCov.benefitId : reqCov.benefitID;
        
        return coverageBenefitId === reqCovBenefitId;
      });

      if (parent) {
        parents.push(parent);
      }
    });

    var noParentsChecked = _.every(parents, function(parent) {
      return !parent.checked;
    });

    if (noParentsChecked) {
      childCoverage.checked = false;
      childCoverage.disabled = true;
    } else {
      // If the coverage relationship code is 'GCREQ', 'GPREQMCH', or 'GPREQVLLTE'
      // then the child coverage is required when the parent is selected
      if (_.includes(['GCREQ', 'GPREQMCH', 'GPREQVLLTE'], relationshipTypeCode)) {
        childCoverage.checked = true;
        childCoverage.disabled = true;
      } else {
        childCoverage.disabled = false;
      }
    }

    if(childCoverage && (childCoverage.guaranteedIssue === 0 || childCoverage.guaranteedIssue == '0') ){
      childCoverage.disabled = true;
    }

    if (childCoverage && CoverageFactory.isMandatory(childCoverage)) {
        childCoverage.disabled = true;
        childCoverage.checked  = true;
    }

    return childCoverage.checked;
  }

  this.generateRequiredRelationships = function ProductsCtrl_generateRelationships(selectedCoverages) {

    WizardService.data.coverageRelationships = [];
    _.forEach(selectedCoverages, function(cov) {
      // Pull relationships for all coverages that are checked
      if(cov.checked && cov.requiresCoverage.length >0) {
          _.forEach(cov.requiresCoverage, function(reqCov) {
            var parentId = reqCov.id;
            var childId = cov.id;
            var relationshipType = reqCov.relationshipTypeCode;
            var childObject = {
                id: childId,
                relationshipType: relationshipType,
                isWaitingPlanCreated: false
            };
            if (_.isArray(WizardService.data.coverageRelationships) && WizardService.data.coverageRelationships.length > 0) {
              var parentCovIndex = _.findIndex(WizardService.data.coverageRelationships, {parentId:parentId} );
              if(parentCovIndex != -1){
                WizardService.data.coverageRelationships[parentCovIndex].childCoverages.push(childObject);
              } else {
                WizardService.data.coverageRelationships.push({parentId: parentId, childCoverages: [childObject]});
              }
            } else {
              WizardService.data.coverageRelationships = [{parentId: parentId, childCoverages: [childObject]}];
            }
          });
      }
    });

    // Only watch relatioships that are required. The required relationships will have to be sent together to ensure an employee's enrolled in all required coverages
    WizardService.data.coverageRelationships = _.filter(WizardService.data.coverageRelationships, function (covRelation) {
      var isReqValue = false;
      _.forEach(covRelation.childCoverages, function(childCov){
        if(_.includes(['GPREQMCH', "GCREQ", "GPREQ", "GPREQMCH","GPREQVLLTE"], childCov.relationshipType)){
          isReqValue = true;
        }
      });
      return isReqValue;
    });
  }

  /**
   * Adds new division coverages to Wizard
   * @param  {Prototype}   self this.prototype (we lose context from outside scope)
   * @param  {Function}    next Callback function
   */
  this.finished = function ProductsCtrl__finished(self, next) {
    var selectedCoverages = getVoluntaryCoverages(Ctrl.coverages.row1.concat(Ctrl.coverages.row2));
    this.generateRequiredRelationships(selectedCoverages);
    if(selectedCoverages.length > 0){
      WizardService.data.divisionCoverages = selectedCoverages;
      next();
    } else {
      //WizardService.showConfirmScreen();
      this.wizard.showFinalSummaryFooter = false;
      this.wizard.showFinalConfirmScreen = true;
    }
  }

  function getVoluntaryCoverages(allCoverages){
    var volCoverages = _.filter(allCoverages, function(cov){
      return cov.isMandatory !== 1;
    });
    return volCoverages;
  }

  function populateList() {
    if (!Ctrl.wizard.data.divisionCoverages || Ctrl.wizard.data.divisionCoverages.length === 0) {
      Ctrl.noAvailableCoverages = true;
      return;
    }

    var coverages = Ctrl.wizard.data.divisionCoverages;

    if (Ctrl.isEmployee) {
      _.each(Ctrl.wizard.data.employeeInfo.dependents, function (dependent) {
        if (dependent.status.toUpperCase() !== 'ACTIVE') return;

        var relationship = DependentFactory.getRelationshipNounByCode(dependent.relationship);
        if (relationship === 'Child') {
          Ctrl.hasChild = true;
        }
        else if (relationship === 'Spouse') {
          Ctrl.hasSpouse = true;
        }
      });
    }

    coverages = _.filter(coverages, filterAllowedCoverages);

    var notEmployee;

    if (_.isObject(Ctrl.wizard.data.newDep)) {
      var relationship = DependentFactory.getRelationshipNounByCode(Ctrl.wizard.data.newDep.relationship);
      notEmployee = (relationship === 'Child' || relationship === 'Spouse') ? true : false;

      coverages = _.filter(coverages, filterReqMetCoverages);

    } else {
      notEmployee = false;
    }

    coverages = _.map(coverages, function (coverage) {
      if (!notEmployee && CoverageFactory.isMandatory(coverage)) {
        coverage.disabled = true;
        coverage.checked  = true;
      }

      return coverage;
    });

    // Coverage should be disabled if the GI equals 0 or if enrollmentType is annual and requires underwriting
    coverages = _.map(coverages, function(coverage) {
      if ((!_.isUndefined(coverage.guaranteedIssue) && coverage.guaranteedIssue === 0) || 
          (Ctrl.wizard.data.enrollingType === "AE" && coverage.underwritingRequired === 1)){
        coverage.disabled = true;
        coverage.benefitDescription = coverage.benefitDescription + " *";
        Ctrl.showUnderwritingDisclaimer = true;
      }
      return coverage;
    });

    // If any coverage has the requiresCoverage array, show the child coverage disclaimer
    Ctrl.showChildCoverageDisclaimer = _.some(coverages, function(coverage) {
      return coverage.requiresCoverage && coverage.requiresCoverage.length > 0;
    });

    if (coverages.length === 0) {
      Ctrl.noAvailableCoverages = true;
      return;
    }

    if (_.isArray(coverages)) {
      var halfOfRows = Math.round(coverages.length / 2);

      _.each(coverages, function (coverage, idx) {
        var row = idx <= halfOfRows || _.isObject(Ctrl.wizard.data.newDep) ? '1' : '2';
        Ctrl.coverages['row' + row].push(coverage);
      });
    }
  }

  function filterAllowedCoverages (coverage) {
    var isForEmployee = CoverageFactory.isForEmployee(coverage),
        isForSpouse = CoverageFactory.isForSpouse(coverage),
        isForChild  = CoverageFactory.isForChildren(coverage),
        allowsFamilies = CoverageFactory.isForFamilies(coverage),
        allowsEmpDeps = CoverageFactory.isForEmployeeDependents(coverage),
        allowsDeps = CoverageFactory.isForDependents(coverage),
        allowsSpouse = isForSpouse || allowsDeps,
        allowsChild = isForChild || allowsDeps;

    return allowsFamilies || allowsEmpDeps || (Ctrl.isEmployee && isForEmployee)
          || (Ctrl.hasChild && allowsChild) || (Ctrl.hasSpouse && allowsSpouse);
  }

  function filterReqMetCoverages (coverage) {
    var allowsEmpDeps = CoverageFactory.isForEmployeeDependents(coverage),
        isRequiresCoverage = CoverageFactory.isRequiresCoverage(coverage);

    var isValid = (coverage.available == 1);
    if((allowsEmpDeps && !coverage.hasRequiresCoverages) || (isRequiresCoverage && !coverage.hasRequiresCoverages) ){
      isValid = false;
    }

    return isValid;
  }
}

module.exports = ['LogFactory', 'WizardService', 'EmployeeFactory', 'DependentFactory', 'CoverageFactory', ProductsCtrl];
