/**
* This file handles the site-wide configuration of
* Angular as well as URL routing.
*/

function angularConfig($routeProvider, $idleProvider, $httpProvider, $locationProvider, IDLE_TIME) {

  // This interceptor will put the access token on every http request
  $httpProvider.interceptors.push('AuthInterceptor');

  // This interceptor will handle loading graphics
  $httpProvider.interceptors.push('LoadInterceptor');

  $idleProvider.idleDuration(IDLE_TIME);
  $idleProvider.keepalive(false);

  $locationProvider.html5Mode(true).hashPrefix('!');

  var baseUrl = '/partials';

  function buildReq(controller, controllerAs, templateUrl) {
    var obj = {};

    switch(arguments.length) {
    case 1:
      obj = {
        templateUrl: controller.indexOf('/static/partials/') === -1 ? (baseUrl + controller) : controller
      };
      break;
    case 2:
      obj = {
        controller: controller,
        template: ''
      };
      break;
    default:
      obj = {
        controller:   controller,
        controllerAs: controllerAs,
        templateUrl:  templateUrl.indexOf('/static/partials/') === -1 ? (baseUrl + templateUrl) : templateUrl
      };
      break;
    }

    return obj;
  }

  $routeProvider
    // These are in alphebetic order but url to make them easier to find.
    // When you create a new one, please try to put it in the correct place.
    .when('/',              buildReq('LoginPageCtlr', 'login', '/login/login'))
    .when('/addEmployee',   buildReq('AddEmployeeCtrl', 'addEmployee', '/roster/add-employee'))
    .when('/benefits-info', buildReq('BenefitsInfoCtrl', 'benefitsCtrl', '/benefits-info'))
    .when('/billing',       buildReq('BillingCtrl', 'billing', '/billing/index'))
    .when('/chooseGroup', {
      controller: 'GroupChooserCtrl',
      templateUrl: baseUrl + '/choose-group/index',
      controllerAs: 'groupChooser',
      resolve: {
        groups: require('./utils/GetGroups')
      }
    })
    .when('/editAdmin/:adminID',  buildReq('EditAdminCtrl', 'editAdmin', '/manage-admins/editAdmin'))
    .when('/verifyemail',         buildReq('EditEmailSecurityCheckCtrl', 'editEmail', '/partials/editEmailSecurityCheck'))
    .when('/employee-profile/:id', {
      templateUrl: baseUrl + '/employee-profile/index',
      controller: 'EmployeeProfileCtrl',
      controllerAs: 'profile',
      resolve: {
        employee: require('./utils/FetchEmployee')
      }
    })
    .when('/forgotPassword',  buildReq('ForgotPasswordCtrl', 'forgotPassword', '/login/forgotPassword'))
    .when('/invite/expired',  buildReq('/invite/expired'))
    .when('/invite/:id',      buildReq('InviteCtrl', ''))
    .when('/link/expired',    buildReq('/expired/index'))
    .when('/login',           buildReq('LoginPageCtlr', 'login', '/login/login'))
    .when('/logout',          {
      controller: 'LogoutPageCtrl',
      template: '',
      controllerAs: 'logout'
    })
    .when('/manageAdmins',    buildReq('ManageAdminsCtrl', 'manageAdmins', '/manage-admins/index'))
    .when('/manageEmployees', buildReq('AdminMembersCtrl', 'adminMembers', '/roster/index'))
    .when('/myAccount',       buildReq('MyAccountCtrl', 'myAccount', '/my-account/index'))
    .when('/myAccount/confirmEmail/:email/:hash', buildReq('MyAccountCtrl', 'myAccount', '/my-account/index'))
    .when('/register',        buildReq('RegistrationCtrl', 'registration', '/register/index'))
    .when('/resetPassword',   buildReq('ResetPasswordCtrl', 'resetPassword', '/login/resetPassword'))
    .when('/bulkOperations',  buildReq('BulkOperationsCtrl', 'bulk', '/bulk-operations/index'))
  ;
}

module.exports = ['$routeProvider', '$idleProvider', '$httpProvider', '$locationProvider', 'IDLE_TIME', angularConfig];
