'use strict';

var _ = require('lodash');

function GroupChooserCtrl ($http, $location, $log, $timeout, $rootScope, groups, UserFactory) {
  var Ctrl = this;

  $rootScope.hideGroup = true;

  Ctrl.group      = null;
  Ctrl.searchBox  = '';
  Ctrl.filterText = '';
  Ctrl.timeout    = null;
  Ctrl.waiting    = false;
  Ctrl.overLimit  = false;
  Ctrl.limit      = 300;

  Ctrl.groupsSource = groups || [];
  Ctrl.groups       = Ctrl.groupsSource;

  /**
   * Sets the current user's group
   * @return {[type]} [description]
   */
  Ctrl.submit = function GroupChooserCtrl_submit () {
    UserFactory.setGroup($rootScope.currentUser, Ctrl.group).then(function GroupChooserCtrl_submit__success () {
      $location.url('/manageEmployees');
    }, $log.error);
  };

  Ctrl.pickGroup = function GroupChooserCtrl_pickGroup (group) {
    Ctrl.group      = group;
    Ctrl.searchBox  = group.groupNumber;

    $rootScope.currentUser.group = group;
    $rootScope.hideGroup = false;
    Ctrl.submit();
  }

  /**
   * Updates group list
   */
  Ctrl.updateList = function GroupChooserCtrl_updateList () {
    // Clear previous timeout
    if (Ctrl.timeout) {
      $timeout.cancel(Ctrl.timeout);
      Ctrl.timeout = null;
    }

    Ctrl.waiting = true;

    Ctrl.timeout = $timeout(function GroupChooserCtrl_updateList__doIt () {
      Ctrl.waiting    = false;
      Ctrl.filterText = Ctrl.searchBox;
      Ctrl.groups     = _.filter(Ctrl.groupsSource, function filterByNameNumber (group) {
        return !Ctrl.filterText ||
          ~group.groupNumber.toLowerCase().indexOf(Ctrl.filterText.toLowerCase()) ||
          ~group.name.toLowerCase().indexOf(Ctrl.filterText.toLowerCase());
      })
    }, 500);
  }
}

module.exports = ['$http', '$location', 'LogFactory', '$timeout', '$rootScope', 'groups', 'UserFactory', GroupChooserCtrl];
