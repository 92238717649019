
var _ = require('lodash');

function AddEmployeeCtrl($rootScope, $scope, $modal, UserFactory, WizardService) {
  var modalInstance;

  this.wizard = {
    show: false
  };

  this.begin = function AddEmployeeCtrl__begin() {
    UserFactory.getActiveUser().then(openModal);

    function openModal(currentUser) {
      WizardService.currentUser = currentUser;
      WizardService.modalAction = 'add';
      WizardService.reactivating = false;
      WizardService.addingNew = true;
      WizardService.addingEmployee = true;

      modalInstance = $modal.open({
        backdrop: 'static',
        keyboard: false,
        templateUrl: '/partials/roster/add-employee-modal',
        controller: 'AddEmployeeModalCtrl as ModalCtrl'
      });

      modalInstance.result.then(angular.noop, angular.noop);
    }
  };

  $rootScope.$on("modal:hide", AddEmployeeCtrl__modal_hide);

  function AddEmployeeCtrl__modal_hide() {
    if (!_.isUndefined(modalInstance)) {
      modalInstance.close();
    }
  }

  // Prevent memory leaks caused by listening to $rootScope
  // (http://stackoverflow.com/questions/18856341/how-can-i-unregister-a-broadcast-event-to-rootscope-in-angularjs)
  $scope.$on("$destroy", function() {
    AddEmployeeCtrl__modal_hide();
  });
}

module.exports = ['$rootScope', '$scope', '$uibModal', 'UserFactory', 'WizardService', AddEmployeeCtrl];
