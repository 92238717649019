
var _ = require('lodash');

function EditCoverageModalCtrl ($rootScope, $scope, $timeout, WizardService, $log, PendingChangeDecorator, FormFactory, EmployeeFactory, CoverageFactory, DependentFactory, UtilFactory) {

  var Ctrl = this;
  PendingChangeDecorator(Ctrl, $rootScope, $scope);
  Ctrl.wizard = WizardService;
  Ctrl.wizard.disableCancelTab = false;
  Ctrl.editTabType = "Coverage";
  Ctrl.headerType = "coverage";
  Ctrl.step = 0;
  Ctrl.onEditTab = true;

  /**
   * Reset the state of the modal and all data associated with modal forms
   */
  Ctrl.reset = function EditCoverageModalCtrl__reset() {
    WizardService.resetData();
    Ctrl.step = 0;
    Ctrl.onEditTab = true;
  };

  /**
   * Initializes data and gets qualifying events
   */
  Ctrl.init = function EditCoverageModalCtrl__init() {
    Ctrl.employeeInfo = Ctrl.wizard.data.employeeInfo;
    Ctrl.pendingChange = Ctrl.wizard.data.pendingChange.originalData;
    Ctrl.changeSubType = Ctrl.pendingChange.changeSubType;
    Ctrl.benefitInfo = Ctrl.wizard.data.benefitInfo;
    Ctrl.activeBenefit = Ctrl.wizard.data.activeBenefit;
    Ctrl.parentInfo = Ctrl.wizard.data.parentInfo;
    Ctrl.isRider = Ctrl.pendingChange.coverage && Ctrl.pendingChange.coverage.isRider === 1;

    // If this is a "Coverage Added" pending change and the coverage is mandatory, then disable the cancel tab
    Ctrl.wizard.disableCancelTab = (Ctrl.pendingChange.changeSubType === 'COA' && Ctrl.benefitInfo.isMandatory);
    if (Ctrl.pendingChange.coverage) {
      Ctrl.wizard.modalTitle = Ctrl.pendingChange.coverage.benefitDescription + ' (Pending)';
      Ctrl.isInEligiblePeriod = Ctrl.pendingChange.coverage.isInEligiblePeriod;
    }

    var displayRules = {
      showEnrollees: false,
      showRequestedChangeInfo: false,
      showVolume: false,
      showEffectiveDate: false
    };
    switch (Ctrl.changeSubType) {
      case 'COA':
        displayRules.showEnrollees = true;
        if(CoverageFactory.isForEmployee()  || CoverageFactory.isForSpouse()){
          displayRules.showEnrollees = false;
        }
        displayRules.showRequestedChangeInfo = true;
        displayRules.showVolume = true;
        displayRules.showEffectiveDate = true;
        break;
      case 'COD':
        displayRules.showRequestedChangeInfo = true;
        break;
      case 'CPA':
      case 'CPD':
        displayRules.showEnrollees = true;
        displayRules.showRequestedChangeInfo = true;
        break;
      case 'CV':
        displayRules.showVolume = true;
        displayRules.showRequestedChangeInfo = true;
        break;
    }
    Ctrl.displayRules = displayRules;

    Ctrl.formData = {
      requestedChangeDateDropDown: {}
    };

    Ctrl.cancelFormData = {
      cancelPendingChange: false
    }

    if (Ctrl.displayRules.showEnrollees) {
      if (Ctrl.changeSubType === 'COA') {
        Ctrl.formData.enrollees = generateAllowedDependents(Ctrl.employeeInfo, Ctrl.pendingChange, Ctrl.activeBenefit, true);
      }
      else if (Ctrl.changeSubType === 'CPA' || Ctrl.changeSubType === 'CPD') {
        Ctrl.formData.enrollees = generateAllowedDependents(Ctrl.employeeInfo, Ctrl.pendingChange, Ctrl.activeBenefit);
      }
    }

    // If the coverage is a rider, show un-editable enrollees
    if (Ctrl.isRider) {
      Ctrl.formData.enrollees = _.map(Ctrl.pendingChange.effectedPeople, function(enrollee) {
        enrollee.showCheck = true;
        enrollee.friendlyRelationship = CoverageFactory.getRelationshipByCode(enrollee.relationship);
        enrollee.editActions = "Enrolled";
        return enrollee;
      });
    }

    if (Ctrl.displayRules.showVolume) {
      calculateVolume(Ctrl.pendingChange, Ctrl.benefitInfo)
    }

    Ctrl.qualifyingEvents = [];
    if (_.isArray(Ctrl.wizard.data.qualifyingEvents) && Ctrl.wizard.data.qualifyingEvents.length > 0) {
      Ctrl.qualifyingEvents = Ctrl.wizard.data.qualifyingEvents;
    }
    if (Ctrl.pendingChange.qualifyingEventTypeCode) {
      Ctrl.formData.qualifyingEvent = _.find(Ctrl.qualifyingEvents, { 'eventCode': Ctrl.pendingChange.qualifyingEventTypeCode });
    }

    if (Ctrl.displayRules.showRequestedChangeInfo) {
      Ctrl.formData.requestedChangeDateDropDown = UtilFactory.dateStringToDropdownObj(Ctrl.pendingChange.requestedChangeDate);
    }

    if ((Ctrl.changeSubType=="COA") &&( (Ctrl.pendingChange.coverage && Ctrl.pendingChange.coverage.riders && Ctrl.pendingChange.coverage.riders.length) || (Ctrl.benefitInfo && Ctrl.benefitInfo.riders && Ctrl.benefitInfo.riders.length))) {

      var allRiders = [];
      allRiders = _.map(Ctrl.benefitInfo.riders, function(r) { 
        return _.extend({}, r, {checked: false});
      });

      _.forEach(Ctrl.pendingChange.coverage.riders, function(rider){
        var riderIndex = _.findIndex(allRiders, {id: rider.id});
        if(riderIndex!=-1) allRiders[riderIndex].checked = true;
      });
      
      Ctrl.formData.riders = allRiders;
    }

    Ctrl.originalFormData = _.cloneDeep(Ctrl.formData);
    Ctrl.originalCancelFormData = _.cloneDeep(Ctrl.cancelFormData);
  };

  function calculateVolume(pendingChange, coverage) {
    var guaranteedIssue = coverage.guaranteedIssue;
    var salaryType = '';
    // pendingChange.volume for pending change type 'CV', pendingChange.coverage.volume for pending change type 'COA'
    Ctrl.formData.volume = pendingChange.volume || pendingChange.coverage.volume;

    var matchParentVolume = Ctrl.parentInfo && Ctrl.parentInfo.matchParentVolume;

    var showIncrement = !matchParentVolume && coverage.volumeCalculationType &&
      (coverage.volumeCalculationType.toUpperCase() === 'U' || coverage.volumeCalculationType.toUpperCase() === 'M');

    if (showIncrement && _.has(coverage, 'volumeIncrement')) {
      CoverageFactory.getVolumes(coverage, Ctrl.employeeInfo).then(function (volumes) {
        Ctrl.formData.volumes = volumes;
        var volumeCap = coverage.guaranteedIssue || coverage.volumeMaximum;

        // If the coverage is not in it's eligible period, volume electives are capped at the original volume
        if (pendingChange.coverage && !pendingChange.coverage.isInEligiblePeriod) {
          volumeCap = Ctrl.formData.volume;
        }

        if (Ctrl.parentInfo && Ctrl.parentInfo.volume && Ctrl.parentInfo.capAtParentVolume) {
          volumeCap = Ctrl.parentInfo.volume;
        }

        Ctrl.formData.volumes = Ctrl.formData.volumes.filter(function(val) {
          return parseInt(val, 10) <= parseInt(volumeCap, 10);
        });

        if (Ctrl.formData.volumes && Ctrl.formData.volumes.length) {
          Ctrl.showVolumeSelect = true;
        }
      });
    }
  };

  Ctrl.showSummary = function EditCoverageModalCtrl__showSummary() {
    if (!Ctrl.cancelFormData.cancelPendingChange) {
      var errors = this.validateForm();

      if (_.keys(errors.errors).length > 0) {
        Ctrl.friendlyErrors  = errors.friendlyErrors;
        Ctrl.formData.errors = errors.errors;
        return false;
      }

      Ctrl.summaryData = {};

      if (Ctrl.displayRules.showEnrollees) {
        Ctrl.summaryData.enrollees = _.filter(Ctrl.formData.enrollees, function(enrollee) {
          return enrollee.editAction !== 'Select';
        });
      }

      if (Ctrl.displayRules.showRequestedChangeInfo) {
        if (Ctrl.formData.qualifyingEvent) {
          Ctrl.summaryData.qualifyingEventDescription = Ctrl.formData.qualifyingEvent.description;
        }
        Ctrl.summaryData.requestedChangeDate = UtilFactory.dropdownValuesToDateString(Ctrl.formData.requestedChangeDateDropDown);
      }
    }
    Ctrl.step = 1; // There are no form errors, so advance to the summary/confirmation
  }

  Ctrl.savePendingChange = function EditCoverageModalCtrl__savePendingChange() {
    if (Ctrl.cancelFormData.cancelPendingChange) {
      EmployeeFactory.deletePendingCoverageChange(Ctrl.pendingChange.id).then(onSuccess, populateErrors);
      return;
    }

    var postData = {
      requestedChangeDate: Ctrl.pendingChange.requestedChangeDate,
      coveredPeople: [],
      removedPeople: []
    };

    // If we're editing enrollees then separate them into coveredPeople and removedPeople arrays
    if (Ctrl.displayRules.showEnrollees && !Ctrl.isRider) {
      var coveredPeople, removedPeople;

      if (Ctrl.changeSubType === 'COA') {
        coveredPeople = _.filter(Ctrl.formData.enrollees, function(enrollee) {
          return enrollee.editAction.toUpperCase() === 'ADD';
        });
        removedPeople = _.filter(Ctrl.formData.enrollees, function(enrollee) {
          return enrollee.editAction.toUpperCase() === 'REMOVE';
        });
        if (coveredPeople) {
          postData.coveredPeople = _.map(coveredPeople, mapCOAperson);
        }
        if (removedPeople) {
          postData.removedPeople = _.map(removedPeople, mapCOAperson);
        }
      } else {
        // Ctrl.changeSubType === 'CPA' || Ctrl.changeSubType === 'CPD'
        coveredPeople = _.filter(Ctrl.formData.enrollees, function(enrollee) {
          return enrollee.editAction.toUpperCase() !== 'SELECT';
        });
        if (coveredPeople) {
          postData.coveredPeople = _.map(coveredPeople, mapCPperson);
        }
      }

      function mapCOAperson (person) {
        return {
          id: person.memberEntityId
        };
      }

      function mapCPperson (person) {
        var formattedPerson = {
          id: person.memberEntityId,
          requestedChangeDate: Ctrl.summaryData.requestedChangeDate
        };

        if (Ctrl.formData.qualifyingEvent) {
          formattedPerson.qualifyingEventTypeCode = Ctrl.formData.qualifyingEvent.eventCode;
        }

        return formattedPerson;
      }

      if ((postData.coveredPeople.length || postData.removedPeople.length) && (Ctrl.formData.riders && Ctrl.formData.riders.length)) {
        postData.riders = [];
         _.forEach(Ctrl.formData.riders, function(rider) {
          if(rider.checked){ 
            postData.riders.push({ id: rider.id });
          };
        });        
      }
    }

    if (Ctrl.displayRules.showRequestedChangeInfo) {
      postData.requestedChangeDate = Ctrl.summaryData.requestedChangeDate;
      if (Ctrl.formData.qualifyingEvent) {
        postData.qualifyingEventTypeCode = Ctrl.formData.qualifyingEvent.eventCode;
      }
    }

    if (Ctrl.displayRules.showVolume && Ctrl.formData.volumes && Ctrl.formData.volumes.length) {
      postData.volume = Ctrl.formData.volume;
    }

    EmployeeFactory.updatePendingCoverageChange(Ctrl.pendingChange.id, postData).then(onSuccess, populateErrors);

    function onSuccess(success) {
      Ctrl.step = 2; // The update was successfull, so advance to the final confirmation message
      WizardService.timeout = $timeout(function() {
        Ctrl.closeModal();
      }, 5000);
    }

    function populateErrors(res) {
      var errors = $log.errorHandler(res);
      Ctrl.formData.errors = errors.errors;
    }
  }

  Ctrl.validateForm = function EditCoverageModalCtrl__validateForm() {
    if(!Ctrl.showRequestedChangeDateDropDown()){
      return true;
    }
    return FormFactory.validate(Ctrl.form, {
      validations: [
        FormFactory.validations.date(Ctrl.formData.requestedChangeDateDropDown, {key: 'requestedChangeDate', after: 'rcdAfterHire', compareDate: Ctrl.employeeInfo.hireDate, acceptSameDay: true}),
        FormFactory.validations.date(Ctrl.formData.requestedChangeDateDropDown, {key: 'requestedChangeDate', after: 'rcdPast3Months', compareDate: moment().subtract(3, 'months')}),
        FormFactory.validations.date(Ctrl.formData.requestedChangeDateDropDown, {key: 'requestedChangeDate', before: 'rcdPast', compareDate: moment().add(3, 'months')})
      ]
    });
  }

  Ctrl.showRequestedChangeDateDropDown = function EditCoverageModalCtrl__showRequestedChangeDateDropDown() {
    return Ctrl.formData.requestedChangeDateDropDown && (Ctrl.pendingChange.requestedChangeDate != Ctrl.employeeInfo.hireDate);
  }

  Ctrl.isArray = angular.isArray;

  Ctrl.isString = angular.isString;

  Ctrl.hasEditFormChanged = function EditCoverageModalCtrl__hasEditFormChanged() {
    if (!Ctrl.onEditTab) return false;

    if (Ctrl.formData.enrollees && Ctrl.formData.enrollees.length) {
      if (_.some(Ctrl.formData.enrollees, function(enrollee) {
        return enrollee.editAction !== "Select";
      })) {
        return true;
      }
    }

    if (Ctrl.formData.volumes && Ctrl.formData.volumes.length) {
      if (Ctrl.formData.volume !== Ctrl.originalFormData.volume) return true;
    }

    if (Ctrl.formData.qualifyingEvent) {
      if (Ctrl.formData.qualifyingEvent !== Ctrl.originalFormData.qualifyingEvent) return true;
    }

    if (Ctrl.formData.requestedChangeDateDropDown) {
      if (!UtilFactory.dateDropDownsAreEqual(Ctrl.formData.requestedChangeDateDropDown, Ctrl.originalFormData.requestedChangeDateDropDown)) {
        return true;
      }
    }

    return false;
  }

  // Generate an array of allowed dependents based on the employee's dependents
  function generateAllowedDependents(employee, pendingChange, activeBenefit, isCOA) {
    var isForEmployee = CoverageFactory.isForEmployee(Ctrl.benefitInfo),
        isForSpouse = CoverageFactory.isForSpouse(Ctrl.benefitInfo),
        isForChildren = CoverageFactory.isForChildren(Ctrl.benefitInfo),
        isForDependents = CoverageFactory.isForDependents(Ctrl.benefitInfo),
        isForFamilies = CoverageFactory.isForFamilies(Ctrl.benefitInfo),
        isForEmployeeDependents = CoverageFactory.isForEmployeeDependents(Ctrl.benefitInfo),
        isForSpouseAndChildren = isForDependents || isForFamilies || isForEmployeeDependents;

    var activeDependents = _.filter(employee.dependents, function(dep) { return dep.status.toUpperCase() === 'ACTIVE' });
    var allowedDependents = [];

    allowedDependents = _.filter(activeDependents, function(dep) {
      var spouseOK = DependentFactory.isSpouse(dep) && (isForSpouse || isForSpouseAndChildren);
      var childOK = DependentFactory.isChild(dep) && (isForChildren || isForSpouseAndChildren);
      return spouseOK || childOK;
    });

    // Format the allowed dependents
    allowedDependents = _.map(allowedDependents, function(dependent) {
      var isCovered;
      var isSpouse = DependentFactory.isSpouse(dependent);
      if (activeBenefit) {
        isCovered = isDependentCovered(dependent.id, activeBenefit.coveredPeople);
      }
      var isPending = isDependentPending(dependent.memberEntityId, pendingChange.effectedPeople);
      return {
        id: dependent.id,
        memberEntityId: dependent.memberEntityId,
        showCheck: isCOA ? isPending : isCovered,
        firstName: dependent.firstName,
        lastName: dependent.lastName,
        friendlyRelationship: CoverageFactory.getRelationshipByCode(dependent.relationship),
        editActions: (isForSpouse && isSpouse) ? undefined : generateEditActions(isCovered, isPending),
        editAction: 'Select'
      };
    });

    // Add the employee to beginning of the list of allowed dependents
    if (isForEmployee || isForFamilies || isForEmployeeDependents) {
      var isCovered;
      if (activeBenefit) {
        isCovered = isDependentCovered(employee.id, activeBenefit.coveredPeople);
      }
      var isPending = isDependentPending(employee.memberEntityId, pendingChange.effectedPeople);
      allowedDependents.unshift({
        id: employee.id,
        memberEntityId: employee.memberEntityId,
        showCheck: isCOA ? isPending : isCovered,
        firstName: employee.firstName,
        lastName: employee.lastName,
        relationship: 'EM',
        friendlyRelationship: 'Employee',
        editActions: (isForEmployee || isForEmployeeDependents) ? undefined : generateEditActions(isCovered, isPending),
        editAction: 'Select'
      });
    }

    return allowedDependents;
  }

  function isDependentCovered(depId, coveredPeople) {
    return _.findIndex(coveredPeople, { id: depId }) !== -1;
  }

  function isDependentPending(memberEntityId, effectedPeople) {
    return _.findIndex(effectedPeople, { id: memberEntityId }) !== -1;
  }

  function generateEditActions(isCovered, isPending) {
    var editActions = ['Select'];

    if (Ctrl.changeSubType === 'COA') {
      if (isPending) {
        editActions.push('Remove');
      } else {
        editActions.push('Add');
      }
    }
    else if (Ctrl.changeSubType === 'CPA') {
      if (isCovered) {
        return;
      }
      else if (isPending) {
        editActions = "Pending Addition";
      }
      else {
        editActions.push('Add');
      }
    }
    else if (Ctrl.changeSubType === 'CPD') {
      if (!isCovered) {
        return;
      }
      else if (isPending) {
        editActions = "Pending Removal";
      }
      else {
        editActions.push('Remove');
      }
    }

    return editActions;
  }

  Ctrl.init();
}

module.exports = ['$rootScope', '$scope', '$timeout', 'WizardService', 'LogFactory', 'PendingChangeDecorator', 'FormFactory', 'EmployeeFactory', 'CoverageFactory', 'DependentFactory', 'UtilFactory', EditCoverageModalCtrl];
