
var _ = require('lodash');

function EditEmailModalCtrl($rootScope, $scope, $log, $modalInstance, UserFactory) {
  $scope.title  = 'Change Personal Information';
  $scope.form   = {};
  $scope.user   = {};
  $scope.submitted = false;

  // Step of the modal process
  $scope.step = 'enterNewName';

  $scope.okButtonText     = 'Save';
  $scope.cancelButtonText = 'Cancel';

  $scope.close = function EditEmailModalCtrl__close() {
    $modalInstance.close();
  };

  function editSuccess() {
    $scope.submitted    = true;
    $scope.message      = 'You have successfully changed your personal information.<br>' +
      'Click "Okay" to return to your account.';
    $scope.okButtonText = 'OKAY';
  }

  function editFailure() {
    $scope.step  = 'failure';
    $scope.title = "Invalid first and/or last name.";
  }

  /**
   * Adds logic/mapping for updating user when submitting the form
   */
  $scope.formSubmission = function EditEmailModalCtrl__formSubmission() {
    if ($scope.form.editNameForm.$invalid) {
      return false;
    }

    // Don't modify the real $rootScope.currentUser unless the new
    // user data saves successfully
    var user = angular.copy($rootScope.currentUser);
    user.firstName = $scope.user.firstName;
    user.lastName  = $scope.user.lastName;

    UserFactory.updateUser(user).then(function () {
      $rootScope.currentUser.firstName = $scope.user.firstName;
      $rootScope.currentUser.lastName  = $scope.user.lastName;
      editSuccess();
    }, function(resp, code) {
      $log.error(resp, code);
      editFailure();
    });
  };

  /**
   * Proceeds to the next step or closes
   */
  $scope.nextStep = function EditEmailModalCtrl__nextStep() {
    if ($scope.step === 'enterNewName' && !$scope.submitted) {
      $scope.formSubmission();
    } else {
      $scope.close();
    }
  };

  function EditEmailModalCtrl__modal_hide(e, employee) {
    closeModalInstance(employee);
  }

  $rootScope.$on("modal:hide", EditEmailModalCtrl__modal_hide);

  // Prevent memory leaks caused by listening to $rootScope
  // (http://stackoverflow.com/questions/18856341/how-can-i-unregister-a-broadcast-event-to-rootscope-in-angularjs)
  $scope.$on("$destroy", function EditEmailModalCtrl__destroy() {
    EditEmailModalCtrl__modal_hide();
  });

  function closeModalInstance(data) {
    if ($modalInstance && _.isFunction($modalInstance.close)) {
      $modalInstance.close(data);
    }
  }
}

module.exports = ['$rootScope', '$scope', 'LogFactory', '$modalInstance', 'UserFactory', EditEmailModalCtrl];
