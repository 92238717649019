
/**
 * Sets the uiSelectConfig options
 * @param {Object} uiSelectConfig uiSelect's configuration object
 */
function setUiSelectConfig(uiSelectConfig) {
  uiSelectConfig.theme            = 'selectize';
  uiSelectConfig.resetSearchInput = true;
}

module.exports = ['uiSelectConfig', setUiSelectConfig];
