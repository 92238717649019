
/**
  * @ngdoc salarySelect
  * @name salarySelect
  * @restrict E
  *
  * @description
  * Creates a dropdown list of salary frequencies
  *
  *
  * @param {object}   coverage      The coverage data/object
  * @param {object[]} items         List of coverage's benefits
  * @param {string}   label         Text for the field's label
  * @param {string}   dropdown      Boolean value ('true' or 'false') for whether or not to show the dropdown
  * @param {function} updatevolumes Execute this function each time we change our class
  *
  * @example
      <class-select label="* Class:" items="Ctrl.coverage.classes" coverage="Ctrl.coverage" updatevolumes="Ctrl.updateVolumes()"></class-select>
*/

var _ = require('lodash');

function salaryFrequencySelectDirective(UtilFactory, SALARY_FREQUENCIES) {
  return {
    restrict: "E",
    templateUrl: "/partials/partials/salaryFrequencySelect",
    scope: {
      salaryObj:          "=",
      disabled:           "=",
      label:              "@"
    },
    controller: ['$scope', function($scope) {
      $scope.frequencies = SALARY_FREQUENCIES;
      $scope.salaryObj.prevSalaryObj = angular.copy($scope.salaryObj);

      $scope.onFrequencyChange = function() {

      };
    }]
  };
}

module.exports = ['UtilFactory', 'SALARY_FREQUENCIES', salaryFrequencySelectDirective];
