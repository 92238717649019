
var _ = require('lodash');

function EditAdminCtrl ($routeParams, $log, $location, UserFactory) {
  var Ctrl = this;

  // All divisions
  this.divisions  = [];
  this.errors     = {};
  
  this.selected = {};

  this.editedReadOnly = false;
  // Divisions to which the edited user belongs
  this.userDivisions        = [];
  this.editedUserDivisions  = [];

  /**
   * Resets our edit status
   */
  this.reset = function EditAdminCtrl__reset() {
    this.userEdited = false;
    this.editedReadOnly = Ctrl.user.readOnly;
    this.editedUserDivisions = angular.copy(this.userDivisions);
  };

  /**
   * Checks to see if a user is within a certain division
   * @param  {Object} division Division data object
   * @return {Boolean}
   */
  this.userInDivision = function EditAdminCtrl__userInDivision(division) {
    return !_.isUndefined(_.find(this.editedUserDivisions, function (d) {
      return _.isObject(d) ? d.id === division.id : d === division.id;
    }));
  };

  /**
   * Returns the current status of the check division boxes
   * @return {Object}
   */  
  this.getCurrentDivisions = function EditAdminCtrl__getCurrentDivisions() {  
    var divisions = [];

    if (Ctrl.user.friendlyRole === 'Secondary') {
      angular.forEach(this.selected, function(value, key) {
        if (value)
          divisions.push({id:key});
      });
    }
    
    return divisions;
  }

  /**
   * Goes back to manageAdmins
   */
  this.goBack = function EditAdminCtrl__goBack() {
    $location.path('/manageAdmins/');
  };

  /**
   * Deletes a user
   */
  this.delete = function EditAdminCtrl__delete() {
    UserFactory.deleteUser(Ctrl.user.userName).then(function () {
      Ctrl.goBack();
    });
  };

  /**
   * Updates the user
   */
  this.save = function EditAdminCtrl__save() {
    Ctrl.user.readOnly = this.editedReadOnly;
    // Add the new division choices onto the user object
    Ctrl.user.divisions = this.getCurrentDivisions();
    
    var userData = {
      userName:   Ctrl.user.userName,
      readOnly:   Ctrl.user.readOnly,
      group:      { groupNumber: Ctrl.user.group.groupNumber },
      divisions:  Ctrl.user.divisions
    };

    UserFactory.updateUser(userData).then(function EditAdminCtrl__save__updateUser_success() {
      Ctrl.goBack();
    }, function EditAdminCtrl__save__updateUser_error(res) {
      $log.error(res);
      Ctrl.errors.readOnly = true;
      Ctrl.errors.divisions = true;
    });
  };

  /**
   * Toggles the user's division and checks to see if the form is valid.
   * @param  {Object} $e       $event emitted from click event
   */
  this.toggleOptions = function EditAdminCtrl__toggleOptions() {
    this.editedUserDivisions = Ctrl.getCurrentDivisions();
    this.userEdited = (this.editedReadOnly != Ctrl.user.readOnly || this.editedUserDivisions.length > 0);
  };

  UserFactory.getDivisions().then(function EditAdminCtrl__getDivisions_success(divisions) {
    Ctrl.divisions = divisions;
  }, $log.error);

  UserFactory.getUser($routeParams.adminID).then(function EditAdminCtrl__getUser_success(resp) {
    var userDivs = _.map(resp.divisions, function EditAdminCtrl__getUser_success__map_divisions(divObj) {
      return divObj.id;
    });

    Ctrl.user = UserFactory.addMetaProps(resp);
    Ctrl.editedReadOnly = resp.readOnly;
    Ctrl.userDivisions = userDivs;
    Ctrl.editedUserDivisions = angular.copy(userDivs);
    _.map(Ctrl.userDivisions, function(div){
      Ctrl.selected[div] = true;
    });

  }, $log.error);
}

module.exports = ['$routeParams','LogFactory', '$location',
  'UserFactory', EditAdminCtrl];
