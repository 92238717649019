
var _ = require('lodash');

function BenefitsInfoFactory($q, RequestFactory) {

  /**
   * Gets a document by division ID
   * @param  {String|Integer} divisionId Division's ID
   * @return {Promise}        Returns the value for response's `data` key
   */
  this.getDocuments = function BenefitsInfoFactory__getDocuments() {
    return RequestFactory.send('/api/documents', 'data');
  }

  /**
   * Gets a document by it's ID
   * @param  {String|Integer} docId Document's ID
   * @return {Promise}        Returns the value for response's `data` key
   */
  this.getDocument = function BenefitsInfoFactory__getDocument(docId) {
    return RequestFactory.send('/api/documents/contents/' + docId, 'data');
  }

  /**
   * Gets a document's download link
   * @param  {String|Integer} docId Document's ID
   * @return {Promise}        Returns the value for response's `data` key
   */
  this.getDocumentDownloadLink = function BenefitsInfoFactory__getDocumentDownloadLink(docId) {
    var deferred = $q.defer();

    this.getDocument(docId).then(documentFound, deferred.reject);

    function documentFound(res) {
      if (_.isString(res.downloadLink)) {
        return deferred.resolve(res.downloadLink);
      }

      deferred.reject(res);
    }

    return deferred.promise;
  }

  return this;
}

module.exports = ['$q', 'RequestFactory', BenefitsInfoFactory];
