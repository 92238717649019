
var _      = require('lodash');
var dottie = require('dottie');

/**
 * Service that provides a common wrapper around $log
 * and a few additional utility functions
 */
function LogFactory($log, DEBUG) {
  var service = this;

  // Wrap around $log
  ['info', 'error', 'warn', 'debug', 'log'].forEach(function LogFactory__log_forEach(method) {
    service[method] = function() {
      /* istanbul ignore next */
      if (DEBUG) {
        $log[method].apply($log, Array.prototype.slice.call(arguments));
      }
    }
  });

  /**
   * Removes `property` key from `formData` object
   * @param  {Object} formData Form data
   * @param  {String} property Key to remove
   * @return {Object}          New formData
   */
  service.removeErrors = function LogFactory__removeErrors(formData, property) {
    var omitKeys = [property];

    if (!_.isObject(formData) || !_.isString(property)) {
      return formData;
    }

    if (_.isObject(formData.errors)) {
      switch(property.toLowerCase()) {
      case 'dob':
      case 'dobpast':
      case 'dobfuture':
        omitKeys.push('dob');
        omitKeys.push('DOBPast');
        omitKeys.push('DOBFuture');
        break;
      case 'dot':
        omitKeys.push('dot');
        break;
      case 'ssn':
      case 'ssnmatch':
      case 'userexists':
        omitKeys.push('ssn');
        omitKeys.push('ssnMatch');
        omitKeys.push('userExists');
        break;
      case "occupation":
        omitKeys.push('occupation');
    	break;
      }

      formData.errors = _.omit(formData.errors, omitKeys);
    }

    return formData;
  }

  /**
   * Handles errors coming in from RequestFactory
   * @param  {Object}  res The response object returned from RequestFactory
   * @param  {Object}  [options={}] Object of settings
   * @param  {Boolean} [options.allow409=false] Sometimes we need to allow 409s to be considered into fetching string errors (set this true if so)
   * @return {Object} Mapped object of errors
   */
  service.errorHandler = function LogFactory__errorHandler(res, options) {
    options = !_.isObject(options) ? {} : /* istanbul ignore next */ options;

    if (_.isUndefined(res) || _.isUndefined(res.status)) {
      return populateErrors([]);
    }

    res.status = parseInt(res.status, 10);

    // sometimes the backend will give us the message directly...
    /* istanbul ignore else */
    if (_.isString(res.message) && (!res.data || !res.data.message)) {
      res.data         = res.data || {};
      res.data.message = res.message;
    }

    if (!_.isNumber(res.status) || (res.status !== 409 && options.allow409 !== true) || !_.isString(res.data.message)) {
      return populateErrors(dottie.get(res, 'data.message', []));
    }

    // attempt to see if the message is a JSON object or not
    var errors = service.populateErrors(res.data.message);
    /* istanbul ignore next */
    if (_.keys(errors).length > 0) {
      return populateErrors(res.data.message);
    }

    // reset back to an array
    errors = [];

    var msg             = ('' + (res.data.message || /* istanbul ignore next */ '')).toLowerCase();
    var match           = res.data.message.match(/The ssn is already in use.with employee\s*(\d+)\s*$/);
    var ssnExists       = msg.indexOf('the ssn is already in use.');
    var matchDOB        = msg.indexOf('dob can not be a future date');
    var effectiveMatch  = msg.indexOf('effective date must be on or after');
    var spouseMatch     = msg.indexOf('spouse already exist');
    var confirmPass     = msg.indexOf('confirm password does not match with password')
    var confirmPass2    = msg.indexOf('password does not match confirm password');
    var confirmPass3    = msg.trim() === 'password does not match';
    var passwordMatch   = msg.indexOf('password pattern is wrong');
    var pinMatch        = msg.indexOf('pin does not match');
    var oldPassword     = msg.indexOf('please enter correct old password');
    var ssnMatch        = msg.indexOf('ssn does not match');
    var ssnInUse        = msg.indexOf('ssn already exists');
    var phoneMatch      = msg.indexOf('valid phone number');
    var emailMatch      = msg.indexOf('not a well-formed email address');
    var depTermed       = msg.indexOf('dependent already terminated');
    var firstLastDob    = msg.indexOf('first name, last name and birth date already exist');

    /* istanbul ignore next */
    if (match !== null && match[1]) {
      errors.push({'userExists': match[1]});
    }

    if (ssnInUse !== -1) {
      errors.push({'userExists': res.data.message});
    }

    if (ssnExists !== -1) {
      errors.push({'userExists':res.data.message});
    }

    /* istanbul ignore next */
    if (effectiveMatch !== -1) {
      errors.push({'effectiveDate': res.data.message});
    }

    /* istanbul ignore next */
    if (spouseMatch !== -1) {
      errors.push({'relationship': res.data.message});
    }

    /* istanbul ignore next */
    if (matchDOB !== -1) {
      errors.push({'DOBFuture': res.data.message});
    }

    /* istanbul ignore next */
    if (confirmPass !== -1 || confirmPass2 !== -1 || confirmPass3) {
      errors.push({'confirmPassword': res.data.message});
    }

    /* istanbul ignore next */
    if (passwordMatch !== -1) {
      errors.push({'newPassword': res.data.message});
    }

    /* istanbul ignore next */
    if (oldPassword !== -1) {
      errors.push({'currentPassword': res.data.message});
    }

    /* istanbul ignore next */
    if (pinMatch !== -1) {
      errors.push({'pinMatch': res.data.message});
    }

    /* istanbul ignore next */
    if (ssnMatch !== -1) {
      errors.push({'ssnMatch': res.data.message});
    }

    /* istanbul ignore next */
    if (phoneMatch !== -1) {
      errors.push({'phone': res.data.message});
    }

    /* istanbul ignore next */
    if (emailMatch !== -1) {
      errors.push({'email': res.data.message});
    }

    /* istanbul ignore next */
    if (depTermed !== -1) {
      errors.push({'update': res.data.message});
    }

    /* istanbul ignore next */
    if (firstLastDob !== -1) {
      errors.push({'firstLastDobDuplicate': res.data.message});
    }

    function populateErrors(errorMessage) {
      var errMsg = dottie.get(errorMessage, 'data.message', errorMessage);

      return {
        errors: service.populateErrors(errMsg),
        friendlyErrors: service.getFriendlyErrors(errMsg)
      };
    }

    return populateErrors(errors);
  };

  /**
   * Populates generic errors
   * @param  {String|Object} errors List of errors
   * @return {Object}        List of errors
  */
  service.populateErrors = function LogFactory__populateErrors(errors) {
    // try to convert into JSON if errors is a string
    if (_.isString(errors)) {
      try {
        errors = angular.fromJson(errors.replace(/^"/, '').replace(/"$/, ''));
      } catch(e){
        //return errors;
      }
    }

    if (!_.isArray(errors) && !_.isObject(errors)) {
      return {};
    }

    var errorsObj = {};

    _.each(errors, function (error, k) {
      if (_.isObject(error)) {
        var val = Object.keys(error)[0];
      } else {
        val = k;
      }
      // remove common 'address[0].' prefix
      var key = val.replace(/^address\[0\]\./, '');
      errorsObj[key] = true;
    });

    return errorsObj;
  };

  /**
   * Get an array of friendly error messages
   * @param  {String|String[]} errors List of errors
   * @return {String[]}        List of errors
   */
  service.getFriendlyErrors = function LogFactory__getFriendlyErrors(errors) {
    // try to convert into JSON if errors is a string
    if (_.isString(errors)) {
      try {
        errors = angular.fromJson(errors.replace(/^"/, '').replace(/"$/, ''));
      } catch(e){}
    }

    if (!_.isArray(errors) && !_.isObject(errors)) {
      return [];
    }

    var errorsArr = [];
    _.each(errors, function (err, key) {
      if (_.isObject(err)) {
        var val = Object.keys(err)[0];
      } else {
        val = key;
      }

      // remove common 'address[0].' prefix
      var k = val.replace(/^address\[0\]\./, '');

      switch(k) {
      case "enrollingType":
        errorsArr.push("Enrolling Type");
        break;
      /* istanbul ignore next */
      case "firstName":
        errorsArr.push("First Name");
        break;
      /* istanbul ignore next */
      case "middleName1":
        errorsArr.push("Middle Initial");
        break;
      /* istanbul ignore next */
      case "lastName":
        errorsArr.push("Last Name");
        break;
      /* istanbul ignore next */
      case "gender":
        errorsArr.push("Gender");
        break;
      /* istanbul ignore next */
      case "dob":
      case "DOBFuture":
      case "DOBPast":
        errorsArr.push("Date of Birth");
        break;
      /* istanbul ignore next */
      case "addressLine1":
        errorsArr.push("Address (Line 1)");
        break;
      /* istanbul ignore next */
      case "addressLine2":
        errorsArr.push("Address (Line 2)");
        break;
      /* istanbul ignore next */
      case "addressCity":
        errorsArr.push("City");
        break;
      /* instanbul ignore next */
      case "addressState":
        errorsArr.push("State");
        break;
      /* istanbul ignore next */
      case "addressZipCode":
        errorsArr.push("Zip Code");
        break;
      /* istanbul ignore next */
      case "phone":
        errorsArr.push("Phone Number");
        break;
      /* istanbul ignore next */
      case "email":
        errorsArr.push("Email");
        break;
      case "ssn":
      case "ssnMatch":
      case "userExists":
        errorsArr.push("Social Security Number");
        break;
      /* istanbul ignore next */
      case "hireDate":
        errorsArr.push("Date of Hire");
        break;
      case "hireDateWithin":
        errorsArr.push("Date of Hire");
        break;
      case "hireDateBefore":
        errorsArr.push("Date of Hire");
        break;
      /* istanbul ignore next */
      case "divisionId":
        errorsArr.push("Division");
        break;
      /* istanbul ignore next */
      case "salaryFreq":
        errorsArr.push("Salary Frequency");
        break;
      case "salary":
        errorsArr.push("Salary");
        break;
      /* istanbul ignore next */
      case "weeklyHours":
        errorsArr.push("Hours Worked Per Week");
        break;
      /* istanbul ignore next */
      case "tobaccoUse":
        errorsArr.push("Tobacco Use");
        break;
      /* istanbul ignore next */
      case "confirmPassword":
        errorsArr.push("Confirm Password");
        break;
      /* istanbul ignore next */
      case "phone":
        errorsArr.push("Phone Number");
        break;
      /* istanbul ignore next */
      case "email":
        errorsArr.push("Email");
        break;
      /* istanbul ingnore next */
      case "effectiveDateBefore":
        errorsArr.push("Effective date");
        break;
      case "occupation":
        errorsArr.push("Occupation");
        break;
      }
    });
    
    return errorsArr;
  };

  return service;
}

module.exports = ['$log', 'DEBUG', LogFactory];
