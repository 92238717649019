
/**
 * Emits/broadcasts a message that indicates we've blurred out of a ui-select field
 * @param {provideService} $provide Angularjs $provide service
 */
function UISelectChange($provide) {
  $provide.decorator( "uiSelectDirective", ['$delegate', function ($delegate) {
    var directive = $delegate[ 0 ];

    directive.compile = function compile() {
      return {
        pre: function preLink(scope, iElement, iAttrs) {
          if (angular.isDefined(iAttrs.nameSpace)) {
            jQuery(iElement).bind('focusout', function() {
              var el  = iElement.find('input.ui-select-search:first');
              var val = el ? el.val() : '';

              scope.$parent.$broadcast('uiSelect:changed:' + iAttrs.nameSpace, val, scope, el);
            });
          }
        },
        post: directive.link
      }
    };

    return $delegate;
  }]);
}

module.exports = ['$provide', UISelectChange];
