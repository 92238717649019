
var _ = require('lodash');

function ManageAdminsCtrl($rootScope, $scope, $location, $log, $routeParams, UserFactory) {
  var Ctrl = this;

  this.showSuccess          = false;
  this.showMultiUserSuccess = false;
  this.pendingInvites       = [];
  this.admins               = [];
  this.divisions            = []; // All divisions

  // The divisions that should belong to a newly invited admin
  this.newAdminDivisions = [];  
  this.groupname      = '';

  Ctrl.isConfirmInviteModalOpen = false;

  Ctrl.templates = {
    confirmInviteModal: '/partials/partials/confirmInviteModal'
  }

  Ctrl.inviteModalDefaults = {
    title: 'Invite Summary:',
    friendlyRole: '',
    okButtonText: 'Confirm',
    cancelButtonText: 'Cancel',
  };

  this.inviteIncludesDivision = function ManageAdminsCtrl__inviteIncludesDivision(division) {
    return this.newAdminDivisions.indexOf(division) > -1;
  };

  this.toggleInviteDivision = function ManageAdminsCtrl__toggleInviteDivision(division) {
    var divIndex = this.newAdminDivisions.indexOf(division);

    // Remove the division if it has already been added. Add
    // it if it has not been added.
    if (divIndex > -1) {
      this.newAdminDivisions.splice(divIndex, 1);
    } else {
      this.newAdminDivisions.push(division);
    }

    //$log.log(this.newAdminDivisions);
  };

  this.formComplete = function ManageAdminsCtrl__formComplete(user, $invalid) {
    // See if Angular's built-in validation has raised a flag
    if ($invalid === true) {
      return false;
    }

    // The form still may be incomplete if the invite is for a secondary
    // admin and the divisions have been left blank
    if (user.role === 'SecondaryAdmin' && (this.divisions.length>0 && this.newAdminDivisions.length === 0) ) {
      return false;
    }

    return true;
  };

  this.editAdmin = function ManageAdminsCtrl__editAdmin(userID) {
    $location.path('/editAdmin/' + userID);
  };

  this.removeAdminByID = function ManageAdminsCtrl__removeAdminByID(adminList, id) {
    return _.filter(adminList, function ManageAdminsCtrl__removeAdminByID__filter_list(admin) {
      return admin.userName !== id;
    });
  };

  this.removeAdminByUser = function ManageAdminsCtrl__removeAdminByUser(adminList, userName) {
    return _.filter(adminList, function ManageAdminsCtrl__cancelInvite__filter_list(admin) {
      return admin.userName !== userName;
    });
  };

  this.cancelInvite = function ManageAdminsCtrl__cancelInvite(userID) {
    UserFactory.deleteUser(userID).then(function ManageAdminsCtrl__cancelInvite__deleteUser(resp) {
      if (parseInt(resp.data.status, 10) === 200) {
        getAdminList();
      }
    }, $log.error);
  };

  this.removeError = function ManageAdminsCtrl__removeError(property) {
    if (property === 'email') {
      Ctrl.emailExists = false;
      Ctrl.register = false;
    }
    if (Ctrl.errors && Ctrl.errors[property]) {
      Ctrl.errors[property] = false;
    }
  };

  this.openConfirmInviteModal = function ManageAdminsCtrl__openConfirmInviteModal(user) {
    this.inviteModalOptions.friendlyRole = Ctrl.friendlyRoleShort(user.role);
    this.isConfirmInviteModalOpen  = true;
  };

  this.friendlyRoleShort = function ManageAdminsCtrl__friendlyRoleShort(role) {
    var roleString = '';
    switch(role.toLowerCase()) {
      case 'primaryadmin':
        roleString = 'Primary';
        break
      case 'secondaryadmin':
        roleString = 'Secondary';
        break
      case 'thirdparty':
        roleString = 'Third-Party';
        break
    }
    return roleString;
  }

  this.handleAdminInviteReq = function ManageAdminsCtrl__handleAdminInviteReq(user) {

    // set user.role and multiUser indicator
    if (user.role === 'ThirdParty') {
      user.role = 'PrimaryAdmin';
      user.multiUser = true;
    } else {
      user.multiUser = false;
    }

    if (!user.readOnly) {
      user.readOnly = false;
    }

    // Add the selected divisions to the user
    if (user.role === 'PrimaryAdmin') {
      user.divisions = this.divisions;
    } else {
      user.divisions = this.newAdminDivisions;
    }

    // The server will return an error if this field is sent
    delete user.confirmEmail;

    user.divisions = _.map(user.divisions, function(div){
      return {id: div.id};
    });

    UserFactory.inviteAdmin(user).then(function ManageAdminsCtrl__handleAdminInviteReq__invite_success() {
      user    = UserFactory.addMetaProps(user);
      user.id = user.email;

      Ctrl.pendingInvites.push(user);
      Ctrl.closeConfirmInviteModal();

      Ctrl.showSuccess = true;

      if (user.multiUser) {
        Ctrl.showMultiUserSuccess = true;
      } else {
        Ctrl.showMultiUserSuccess = false;
      }
      

      $scope.user = {
        role: "PrimaryAdmin"
      };

      // Reset the user form
      $scope.divisionsForm.$setPristine();
      $scope.inviteAdmin.$setPristine();

      Ctrl.newAdminDivisions = [];
      getAdminList();
    }, function ManageAdminsCtrl__handleAdminInviteReq__invite_error(error) {
      Ctrl.errors = $log.populateErrors(error.data.message);
      if (error.status === 404) {
        Ctrl.emailExists  = true;  
      } else if ( error.status === 409) {
        Ctrl.register  = true;
      }
      Ctrl.closeConfirmInviteModal();
    });
  };

  this.closeConfirmInviteModal = function ManageAdminsCtrl__closeConfirmInviteModal() {
    this.isConfirmInviteModalOpen = false;
  };

  UserFactory.getDivisions().then(function ManageAdminsCtrl__getDivisions(divisions) {
    Ctrl.divisions = divisions;
  }, $log.error);

  function getAdminList() {
    UserFactory.getAdminsList().then(function ManageAdminsCtrl__getAdminList__success(resp) {
      resp.data.data.invitations = _.filter(resp.data.data.invitations, function(invite) {
        if (moment().diff(moment(invite.invitedOn), 'days') >= 42 ) {
          UserFactory.deleteUser(invite.userName);
          return false;
        }
        return true;
      });
      var invites = resp.data.data;
      if (parseInt(resp.status, 10) === 200) {
        Ctrl.pendingInvites = _.map(invites.invitations, UserFactory.addMetaProps);

        var currentAdmin = _.filter(invites.admins, function ManageAdminsCtrl__getAdminList__filter_admins(admin) {
          return admin.userName === $rootScope.currentUser.userName;
        });

        Ctrl.admins = currentAdmin.concat(Ctrl.removeAdminByID(invites.admins, $rootScope.currentUser.userName))
                                  .map(UserFactory.addMetaProps);
      }

      Ctrl.showThirdParty = ($rootScope.currentUser.role === 'LifeMapAdmin');
      Ctrl.groupname = $rootScope.currentUser.group.name
    }, $log.error);
  }
  
  function resetInviteForm() {
    Ctrl.inviteModalOptions = angular.copy(Ctrl.inviteModalDefaults);
    Ctrl.showSuccess = false;
    Ctrl.showMultiUserSuccess = false;
    Ctrl.isConfirmInviteModalOpen = false;
  }

  resetInviteForm();
  UserFactory.getActiveUser().then(getAdminList);
}

module.exports = ["$rootScope", "$scope", "$location", "LogFactory", "$routeParams", "UserFactory", ManageAdminsCtrl];
