
/**
  * @ngdoc validFloat
  * @name validFloat
  * @restrict A
  *
  * @description
  * Ensures that only integer values can be typed within this form element
  *
  * @param {object}   ngModel  Optional. Used as a passthrough.
  *
  * @example
      <input type="text" name="ssnSeg1" minLength="3" maxlength="3" valid-integer ng-model="ssnobj.ssnSeg1">
*/

function validFloat () {
  return {
    restrict: 'A',
    scope: {
      step: '@'
    },
    require: '?ngModel',
    link: function(scope, element, attrs, ngModelCtrl) {
      if(!ngModelCtrl) {
        return;
      }

      ngModelCtrl.$parsers.push(function(val) {
        if (angular.isUndefined(val) || val === null) {
          val = '';
        }

        var firstDecimal = true;
        function removeExtraDecimalPoints(match, i, original) {
          if (firstDecimal) {
            firstDecimal = false;
            return match;
          } else {
            return '';
          }
        }

        // cast to string
        val = (val + '');

        var clean = val === '0' ? val : val.replace(/[^0-9.]+/g, '') // Allow only numbers and decimal points
                                           .replace(/\./g, removeExtraDecimalPoints); // No more than one decimal point

        // No more than 2 decimal places
        if (clean.length) {
          cleanArray = clean.match(/(\d*\.?\d{0,2})/);
          if (cleanArray && cleanArray.length) {
            clean = cleanArray[0];
          }
        }

        if (val !== clean) {
          ngModelCtrl.$setViewValue(clean);
          ngModelCtrl.$render();
        }
        return clean;
      });

      element.bind('keypress', function(event) {
        if(event.keyCode === 32) {
          event.preventDefault();
        }
      });
    }
  };
}

module.exports = validFloat;
