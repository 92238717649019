
var _ = require('lodash');

var routeIsUnauthorized = require('../utils/route-auth');

function authInterceptor ($q, $rootScope, httpBuffer, $location, $injector, $log) {
  var modalOpened = false,
      modalInstance;

    // If the browser back button is clicked, close the modal
    $rootScope.$on('$routeChangeStart', function() {
      if (modalInstance) {
        modalInstance.dismiss('cancel');
      }
    });

  return {
    responseError: function authInterceptor_response (res) {
      $injector.invoke(['$uibModal', function ($modal) {
        // Handle 401 responses by refreshing token if applicable
        if (angular.isObject(res) && angular.isObject(res.data) && res.data.status) {
          res.status = parseInt(res.data.status, 10);
        }

        var isAuthCall = res.config.url.indexOf('/api/user/login') === 0;
        var isAuthError = res.status === 401;
        var hasRetried = res.config.retries > 0;
        var deferred = $q.defer();

        // when we've made an unauthorized call from a page that doesn't require authorization
        // don't attempt to log us out or do anything else...
        if (routeIsUnauthorized($location.path())) {
          return $q.reject(res);
        }

        if (isAuthError && !isAuthCall && !hasRetried) {
          res.config.retries = res.config.retries ? res.config.retries++ : 1;
          httpBuffer.append(res.config, deferred);

          $rootScope.$broadcast('event:loginRequired');
          $log.info('Login required for this action, retrying...');

          httpBuffer.retryAll();
          return deferred.promise;
        } else if (isAuthError && hasRetried) {
          $rootScope.currentUser = null;
          $location.url('/login');
          $log.error('Retried authentication failed: ', res);

          return $q.reject(res);
        } else {
          $log.error('General error: ', res);

          // For other errors, return response normally

          // If we've been forbidden by the backend API, simply redirect back
          // to our main page.
          if (res.status === 403) {
            $location.url('/');
          }

          var codes = [400, 405, 406, 408, 500, 501, 502];

          // add 401 to codes if we're not trying to login...
          if (res.config.url.match(/^api\/user\/login/) === null) {
            codes.push(401);
          }

          if (!modalOpened && !_.isUndefined(res.status) && codes.indexOf(res.status) > -1) {
            modalOpened = true;
            modalInstance = $modal.open({
              templateUrl: '/partials/partials/genericError',
              backdrop: 'static',
              keyboard: false,
              controller: ['$scope', '$modalInstance', function ($scope, $modalInstance) {
                var hasErrorMessage  = _.isObject(res.data) && _.isString(res.data.message);
                $scope.customMessage = hasErrorMessage ? res.data.message : '';

                $scope.cancel = function () {
                  modalOpened = false;
                  $modalInstance.dismiss('cancel');
                };
              }]
            });

            modalInstance.result.then(function () {
              modalOpened = false;
            }, function () {
              modalOpened = false;
            });
          }

          return $q.reject(res);
        }
      }]);
    }
  };
}

module.exports = ['$q', '$rootScope', 'HttpBuffer', '$location', '$injector', 'LogFactory', authInterceptor];
