/* Tell JSHint about global variables: */
/* global MOB */

MOB.app.controller('AddDependentModalCtrl', require('./modals/AddDependentModalCtrl'));
MOB.app.controller('AddDependentSummaryCtrl', require('./modals/AddDependentSummaryCtrl'));
MOB.app.controller('AddEmployeeCtrl', require('./AddEmployeeCtrl'));
MOB.app.controller('AddEmployeeModalCtrl', require('./modals/AddEmployeeModalCtrl.js'));
MOB.app.controller('AdminMembersCtrl', require('./AdminMembersCtrl'));
MOB.app.controller('BenefitElectionCtrl', require('./modals/BenefitElectionCtrl'));
MOB.app.controller('BenefitsInfoCtrl', require('./BenefitsInfoCtrl'));
MOB.app.controller('BenefitSummaryCtrl', require('./modals/BenefitSummaryCtrl'));
MOB.app.controller('BillingCtrl', require('./BillingCtrl'));
MOB.app.controller('BulkOperationsCtrl', require('./BulkOperationsCtrl'));
MOB.app.controller('CoverageEditCtrl', require('./modals/CoverageEditCtrl'));
MOB.app.controller('EditAdminCtrl', require('./EditAdminCtrl'));
MOB.app.controller('EditDependentCtrl', require('./modals/EditDependentCtrl'));
MOB.app.controller('EditDependentModalCtrl', require('./modals/EditDependentModalCtrl'));
MOB.app.controller('EditEmailSecurityCheckCtrl', require('./EditEmailSecurityCheckCtrl'));
MOB.app.controller('EditNameModalCtrl', require('./modals/EditNameModalCtrl'));
MOB.app.controller('EditPasswordModalCtrl', require('./modals/EditPasswordModalCtrl'));
MOB.app.controller('EmployeeDependentsCtrl', require('./modals/EmployeeDependentsCtrl'));
MOB.app.controller('EmployeeInfoCtrl', require('./modals/EmployeeInfoCtrl'));
MOB.app.controller('EmployeeProfileCtrl', require('./EmployeeProfileCtrl'));
MOB.app.controller('EmployeeSummaryCtrl', require('./modals/EmployeeSummaryCtrl'));
MOB.app.controller('ForgotPasswordCtrl', require('./ForgotPasswordCtrl'));
MOB.app.controller('GroupChooserCtrl', require('./GroupChooserCtrl'));
MOB.app.controller('IdleCtrl', require('./IdleCtrl'));
MOB.app.controller('InviteCtrl', require('./InviteCtrl'));
MOB.app.controller('LoginPageCtlr', require('./LoginPageCtlr.js'));
MOB.app.controller('LogoutPageCtrl', require('./LogoutPageCtrl'));
MOB.app.controller('ManageAdminsCtrl', require('./ManageAdminsCtrl'));
MOB.app.controller('MyAccountCtrl', require('./MyAccountCtrl.js'));
MOB.app.controller('ProductsModalCtrl', require('./modals/ProductsModalCtrl'));
MOB.app.controller('RegistrationCtrl', require('./RegistrationCtrl'));
MOB.app.controller('ResetPasswordCtrl', require('./ResetPasswordCtrl'));
MOB.app.controller('VolumeSelectCtrl', require('./modals/VolumeSelectCtrl'));

/* pending changes */
MOB.app.controller('EditClassModalCtrl', require('./modals/pending-changes/EditClassModalCtrl'));
MOB.app.controller('EditCoverageModalCtrl', require('./modals/pending-changes/EditCoverageModalCtrl'));
MOB.app.controller('EditHireDateModalCtrl', require('./modals/pending-changes/EditHireDateModalCtrl'));
MOB.app.controller('EditOccupationModalCtrl', require('./modals/pending-changes/EditOccupationModalCtrl'));
MOB.app.controller('EditSalaryModalCtrl', require('./modals/pending-changes/EditSalaryModalCtrl'));
MOB.app.controller('EditStatusModalCtrl', require('./modals/pending-changes/EditStatusModalCtrl'));
MOB.app.controller('EditWorkHourModalCtrl', require('./modals/pending-changes/EditWorkHourModalCtrl'));
