
var _ = require('lodash');

function DependentFactory(RequestFactory, UtilFactory, CoverageFactory) {
  var Factory = this;

  /**
   * Get's an employee's dependents
   * @param  {String} employeeId Employee's ID
   * @return {Promise}           RequestFactory's `response.data.dependents` key
   */
  this.findByEmployee = function DependentFactory__findByEmployee(employeeId) {
    return RequestFactory.send('/api/employee/' + employeeId + '/dependents', 'data');
  }

  /**
   * Sends a request to add a dependent
   * @param {String|Number} employeeId Employee's ID
   * @param {Object}        data       Dependent's data
   */
  this.insert = function DependentFactory__insert(employeeId, data) {
    if (data.relationship) {
      data.relationship = this.getRelationshipCodeByNoun(data.relationship);
    }
    if (_.isString(data.ssn) && data.ssn == '') {
      data = _.omit(data, 'ssn');
    }

    return RequestFactory.send({
      url: '/api/employee/' + employeeId + '/dependents',
      method: 'PUT',
      data: data
    }, 'data');
  };

  /**
   * Sends a request to update a dependent
   * @param  {String} employeeId Employee's ID
   * @param  {Object} dependent Dependent's data
   * @return {Promise}          RequestFactory's `response.data` key
   */
  this.update = function DependentFactory__update(employeeId, dependent) {
    return RequestFactory.send({
      url: '/api/employee/' + employeeId + '/dependents',
      method: 'PUT',
      data: dependent
    }, 'data');
  };

  /**
   * Sends a request to readd a dependent
   * @param  {String} employeeId Employee's ID
   * @param  {String} dependentId Dependent's ID
   * @param  {Object} the data to send to this endpoint
   * @return {Promise}          RequestFactory's `response.data` key
   */
  this.readd = function DependentFactory__update(employeeId, dependentId, data) {
    return RequestFactory.send({
      url: '/api/employee/' + employeeId + '/dependents/' + dependentId + '/readd',
      method: 'POST',
      data: data
    }, 'data');
  };

  /**
   * Sends a request to remove a dependent
   * @param  {String} employeeId Employee's ID
   * @param  {Object} dependent Dependent's data
   * @return {Promise}          RequestFactory's `response` key
   */
  this.remove = function DependentFactory__remove(employeeId, dependent) {
    var data = {
      realMethod: 'delete',
      terminationDate: UtilFactory.dropdownValuesToDateString(dependent.dateOfEvent)
    };

    return RequestFactory.send({
      url: '/api/employee/' + employeeId + '/dependents/' + dependent.id,
      method: 'POST',
      data: data
    });
  };

  /**
   * Gets relationship noun by code
   * @param  {String} code The relationship's code
   * @return {String}      Returns the relationship's noun otherwise blank
   */
  this.getRelationshipNounByCode = function Person_GetRelationshipNounByCode(code) {
    var nouns = {d: 'Child', h: 'Spouse', sp: 'Spouse', o: 'Other', s: 'Child', ch: 'Child', w: 'Spouse'};
    return nouns[('' + code).toLowerCase()];
  };

  /**
   * Gets relationship code by it's noun
   * @param  {String} noun The relationship noun
   * @return {String}      Returns the relationship code if the noun was found, otherwise returns the noun back
   */
  this.getRelationshipCodeByNoun = function DependentFactory__getRelationshipCodeByNoun(noun) {
    return CoverageFactory.getRelationshipCodeByNoun(noun);
  };

  /**
   * Maps common data for dependents/friendlier values
   * @param  {Object} dep Dependent's data
   * @return {Object}     New dependent data
   */
  this.mapCensusProperties = function DependentFactory__mapCensusProperties(dep) {
    dep.friendlyDob = UtilFactory.getFormattedCensusDate(dep.dob);

    // Could use a simple ternary operator here, but better to be explicit to
    // avoid assigning someone the wrong gender if there's an unexpected backend value
    switch (dep.gender) {
      case 'M': dep.friendlyGender = 'Male'; break;
      case 'F': dep.friendlyGender = 'Female'; break;
      case 'U': dep.friendlyGender = 'Other'; break;
    }

    dep.friendlyRelationship  = CoverageFactory.getRelationshipByCode(dep.relationship);
    dep.ssnLast4Digits        = UtilFactory.getFormattedSSN(dep.ssn);
    return dep;
  };

  /**
   * Checks to see if an object has the minimum requirements for being a dependent
   * @param  {Object}   dep      Dependent's data object
   * @param  {Boolean}  removing Whether or not we are removing this dependent
   * @return {Boolean}
   */
  this.isValid = function DependentFactory__isValid(dep, removing) {
	var parts       = dep.ssn;
	var validSSN    = true;
    var validName   = [dep.firstName, dep.lastName].filter(isNonEmptyString).length === 2;
    var validDOB    = dep.dob;
    var validGender = ['M','F','U'].indexOf(dep.gender) > -1;

    if (_.isObject(dep.dobDropdown)) {
      dep.dob = UtilFactory.dropdownValuesToDateString(dep.dobDropdown);
      validDOB = moment(dep.dob).isValid();
    }
    else if (_.isString(dep.dob)) {
      validDOB = moment(dep.dob).isValid();
    }
    
	if(!_.isUndefined(parts)){
	   parts  = dep.ssn.match(/^(\d{0,3})?(\d{0,2})?(\d{0,4})?$/);
	   validSSN    = parts.slice(1, parts.length).filter(UtilFactory.isValidSSNSegment).length === 3;
	   
	   if (dep.ssn.length !== 9) {
		  return false;
	   }
	}

    var invalidDoE = !_.isObject(dep.selectedDep.dateOfEvent) || !_.has(dep.selectedDep.dateOfEvent, 'dateSeg1') ||  !_.has(dep.selectedDep.dateOfEvent, 'dateSeg2') ||  !_.has(dep.selectedDep.dateOfEvent, 'dateSeg3');

    if (removing && invalidDoE) {
      return false;
    }

    return validSSN && validName && validDOB && validGender;
  };

  this.findSpouse = function DependentFactory__findSpouse(dependents) {
    var spouse = _.filter(dependents, function (dep) {
      return Factory.isSpouse(dep);
    });

    return spouse;
  }

  this.findChildren = function DependentFactory_findChildren(dependents) {
    var children = _.filter(dependents, function (dep) {
      return Factory.isChild(dep);
    });

    return children;
  }

  this.isSpouse = function DependentFactory__isSpouse(dependent) {
    return ['H','W','SP'].indexOf(dependent.relationship.toUpperCase()) > -1;
  }

  this.isChild = function DependentFactory__isChild(dependent) {
    return ['S','D','CH'].indexOf(dependent.relationship.toUpperCase()) > -1;
  }

  // # private

  function isNonEmptyString(data) {
    return _.isString(data) && data.trim() !== '';
  }

  return this;
}

module.exports = ['RequestFactory', 'UtilFactory', 'CoverageFactory', DependentFactory];
