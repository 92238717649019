
/**
  * @ngdoc disclaimerList
  * @name disclaimerList
  * @restrict E
  *
  * @description
  * Returns the appropriate text for each disclaimer
  *
  *
  * @param {object[]} disclaimers  A list of disclaimers
  *
  * @example
      <disclaimer-list disclaimers="Ctrl.disclaimers"></disclaimer-list>
*/

function disclaimerDirective () {
  return {
    restrict: "E",
    templateUrl: "/partials/partials/disclaimer",
    scope: {
      disclaimers: "="
    }
  };
}

module.exports = disclaimerDirective;
