
/**
  * @ngdoc ssnInput
  * @name ssnInput
  * @restrict E
  *
  * @description
  * Renders a template for inputting a social security number within three input fields
  *
  *
  * @param {object}   obj     Two-way binding ng-model for the ssn data
  * @param {object}   errors  Two-way binding ng-model of a list of errors (by Object.keys)
  * @param {function} clearfn Triggers this function whenever a change occurs
  *
  * @example
      <ssn-input ssnobj="Ctrl.dependent" errors="Ctrl.errors" clearfn="Ctrl.removeError('ssn')"></ssn-input>
*/

function ssnInputDirective() {
  return {
    restrict: "E",
    templateUrl: "/partials/partials/ssnInput",
    require: 'ngModel',
    scope: {
      clearfn: "&",
      errors: "=",
      ngRequired: "=",
      ngDisabled: "=",
    },
    link: function(scope, elem, attr, ngModelCtl) {

      scope.ssnSeg1 = '';
      scope.ssnSeg2 = '';
      scope.ssnSeg3 = '';

      // Overrides
      ngModelCtl.$isEmpty = function() {
        return scope.ssnSeg1 === '' && scope.ssnSeg2 === '' && scope.ssnSeg3 === '' ;
      };

      ngModelCtl.$render = function() {
        var parts = (ngModelCtl.$modelValue || '').match(/^(\d{0,3})(\d{0,2})(\d{0,4})$/) || ['','',''];
        scope.ssnSeg1 = parts[1] || '';
        scope.ssnSeg2 = parts[2] || '';
        scope.ssnSeg3 = parts[3] || '';
      };

      scope.update = function() {
        ngModelCtl.$setViewValue(scope.ssnSeg1 + scope.ssnSeg2 + scope.ssnSeg3);
        ngModelCtl.$commitViewValue();
      };

    }
  };
}

module.exports = ssnInputDirective;
