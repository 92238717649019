function httpBuffer($injector) {
  var buffer = [],
    $http;

  function retryHttpRequest(config, deferred) {
    function successCb(response) {
      deferred.resolve(response);
    }
    function errorCb(response) {
      deferred.reject(response);
    }
    $http = $http || $injector.get("$http");
    $http(config).then(successCb, errorCb);
  }

  return {
    append: function(config, deferred) {
      buffer.push({
        config: config,
        deferred: deferred
      });
    },

    rejectAll: function(reason) {
      if (reason) {
        buffer.forEach(function(request) {
          request.deferred.reject(reason);
        });
        buffer = [];
      }
    },

    retryAll: function(updater) {
      updater = updater || function (config) {return config;};

      buffer.forEach(function(request) {
        retryHttpRequest(updater(request.config), request.deferred);
      });
      buffer = [];
    }
  }
}

module.exports = ["$injector", httpBuffer];
