
/**
  * @ngdoc phoneNumber
  * @name phoneNumber
  * @restrict E
  *
  * @description
  * Renders a template for inputting a telephone number with three input fields
  *
  *
  * @param {object}   obj     Two-way binding ng-model for the telephone data
  * @param {object}   errors  Two-way binding ng-model of a list of errors (by Object.keys)
  * @param {function} clearfn Triggers this function whenever a change occurs
  *
  * @example
      <phone-number obj="Ctrl.formData" errors="Ctrl.errors" clearfn="Ctrl.removeError('phone')"></phone-number>
*/

function phoneNumberDirective() {
  return {
    restrict: "E",
    templateUrl: "/partials/partials/phoneNumber",
    scope: {
      obj:      "=",
      errors:   "=",
      clearfn:  "&"
    }
  };
}

module.exports = phoneNumberDirective;
