
function InviteCtrl($scope, $location, $routeParams, RegistrationFactory) {
  RegistrationFactory.getRegistration($routeParams.token, getUserSuccess, failedResponse);

  function getUserSuccess() {
    $location.path('/register').search('token', $routeParams.token);
  }

  function failedResponse() {
    $location.path('/invite/expired');
  }
}

module.exports = ['$scope', '$location', '$routeParams', 'RegistrationFactory', InviteCtrl];
