
var _ = require('lodash');

function EditHireDateModalCtrl ($rootScope, $scope, $timeout, WizardService, $log, PendingChangeDecorator, FormFactory, UtilFactory, EmployeeFactory) {

  var Ctrl = this;
  PendingChangeDecorator(Ctrl, $rootScope, $scope);
  Ctrl.wizard = WizardService;
  Ctrl.wizard.modalTitle = "Hire Date (Pending)";
  Ctrl.editTabType = "Hire Date";
  Ctrl.headerType = "Hire Date";
  Ctrl.step = 0;

  /**
   * Reset the state of the modal and all data associated with modal forms
   */
  Ctrl.reset = function EditHireDateModalCtrl__reset() {
    WizardService.resetData();
    Ctrl.step = 0;
  };

  /**
   * Initializes data and gets qualifying events
   */
  Ctrl.init = function EditHireDateModalCtrl__init() {
    Ctrl.employeeInfo = Ctrl.wizard.data.employeeInfo;
    Ctrl.pendingChange = Ctrl.wizard.data.pendingChange.originalData;
    
    Ctrl.isPendingRehire = Ctrl.pendingChange.changeSubType == "HDR";

    Ctrl.formData = {
      hireDateDropDown: UtilFactory.dateStringToDropdownObj(Ctrl.pendingChange.hireDate)
    };

    Ctrl.cancelFormData = {
      cancelPendingChange: false
    };

    Ctrl.originalFormData = _.cloneDeep(Ctrl.formData);
    Ctrl.originalCancelFormData = _.clone(Ctrl.cancelFormData);
  };

  Ctrl.showSummary = function EditHireDateModalCtrl__showSummary() {
    var errors = this.validateForm();

    if (_.keys(errors.errors).length > 0) {
      Ctrl.friendlyErrors  = errors.friendlyErrors;
      Ctrl.formData.errors = errors.errors;
      return false;
    }

    Ctrl.postData = {
      hireDate: UtilFactory.dropdownValuesToDateString(Ctrl.formData.hireDateDropDown)
    };

    Ctrl.step = 1; // There are no form errors, so advance to the summary/confirmation
  }

  Ctrl.savePendingChange = function EditHireDateModalCtrl__savePendingChange() {
    if (Ctrl.cancelFormData.cancelPendingChange) {
      EmployeeFactory.deletePendingHireDateChange(Ctrl.employeeInfo.id, Ctrl.pendingChange.id).then(onSuccess, populateErrors);
      return;
    }

    EmployeeFactory.updatePendingHireDateChange(Ctrl.employeeInfo.id, Ctrl.pendingChange.id, Ctrl.postData).then(onSuccess, populateErrors);

    function onSuccess(success) {
      Ctrl.step = 2; // The update was successfull, so advance to the final confirmation message
      $timeout(function() {
        Ctrl.closeModal();
      }, 5000);
    }

    function populateErrors(res) {
      var errors = $log.errorHandler(res);
      if(_.isString(errors.errors)){
        Ctrl.errorMessage = errors.errors;
      }
      Ctrl.formData.errors = errors.errors;
    }
  }

  Ctrl.validateForm = function EditHireDateModalCtrl__validateForm() {
    return FormFactory.validate(Ctrl.wizard.form, {
      validations: [

      ]
    });
  }

  Ctrl.hasEditFormChanged = function EditHireDateModalCtrl__hasEditFormChanged() {
    if (!Ctrl.onEditTab) return false;

    if (Ctrl.formData.hireDateDropDown && !UtilFactory.dateDropDownsAreEqual(Ctrl.formData.hireDateDropDown, Ctrl.originalFormData.hireDateDropDown)) {
      return true;
    }

    return false;
  }

  Ctrl.init();
}

module.exports = ['$rootScope', '$scope', '$timeout', 'WizardService', 'LogFactory', 'PendingChangeDecorator', 'FormFactory', 'UtilFactory', 'EmployeeFactory', EditHireDateModalCtrl];
