
/**
  * @ngdoc classSelect
  * @name classSelect
  * @restrict E
  *
  * @description
  * Creates a dropdown list of benefits/classes and triggers updatevolumes function
  *
  *
  * @param {object}   coverage      The coverage data/object
  * @param {object[]} items         List of coverage's benefits
  * @param {string}   label         Text for the field's label
  * @param {string}   dropdown      Boolean value ('true' or 'false') for whether or not to show the dropdown
  * @param {function} updatevolumes Execute this function each time we change our class
  *
  * @example
      <class-select label="* Class:" items="Ctrl.coverage.classes" coverage="Ctrl.coverage" updatevolumes="Ctrl.updateVolumes()"></class-select>
*/

function classSelectDirective() {
  return {
    restrict: "E",
    templateUrl: "/partials/partials/classSelect",
    scope: {
      coverage: "=",
      items:    "=",
      label:    "@",
      dropdown: "@",
      updatevolumes: "&"
    }
  };
}

module.exports = classSelectDirective;
