
var _ = require('lodash');

function RegistrationCtrl($rootScope, $cookieStore, $log, $scope, $location, $routeParams, UserFactory, RegistrationFactory) {
  var Ctrl = this;

  this.errorMessage = null;
  this.showSuccess  = false;
  this.user         = {};

  Ctrl.errors         = {};
  Ctrl.friendlyErrors = [];
  Ctrl.genericError   = false;
  Ctrl.showPasswordsDoNotMatch  = false;

  // if we're currently logged in as a user... log us out
  UserFactory.getActiveUser().then(function RegistrationCtrl__getActiveUser() {
    if (!$rootScope.currentUser || !$rootScope.currentUser.userName) {
      return getRegistration();
    }

    UserFactory.logout($rootScope.currentUser.userName).then(function RegistrationCtrl__getActiveUser__logout_success() {
      $cookieStore.remove('access_token');
      $cookieStore.remove('refresh_token');
      $cookieStore.remove('expires_in');
      $rootScope.currentUser = null;
      getRegistration();
    }, function RegistrationCtrl__getActiveUser__logout_error() {
      $log.error(arguments);
      getRegistration();
    });
  }, function RegistrationCtrl__getActiveUser__error() {
    $log.error(arguments);
    getRegistration();
  });

  function getRegistration() {
    RegistrationFactory.getRegistration($routeParams.token).then(getUserSuccess, inviteExpired);
  }

  Ctrl.removeError = function RegistrationCtrl__removeError(property) {
    var errors  = $log.removeErrors(Ctrl, property);
    Ctrl.errors = errors.errors;
  };

  this.submitForm = function RegistrationCtrl__submitForm() {
    if ($scope.registrationForm.$invalid) {
      return false;
    }

    // reset the error message whenever we re-submit
    Ctrl.errorMessage   = '';
    Ctrl.errors         = {};
    Ctrl.friendlyErrors = [];

    RegistrationFactory.register($routeParams.token, Ctrl.user).then(success, failedResponse);

    function success(res) {
      if (parseInt(res.data.status, 10) !== 200) {
        return failedResponse(res);
      }

      Ctrl.showSuccess = true;
    }
  };

  function getUserSuccess(res) {
    if (parseInt(res.data.status, 10) !== 200) {
      $location.path('/invite/expired');
      return failedResponse(res);
    }

    var userData = res.data.data;

    _.each(['firstName', 'lastName', 'email'], function (key) {
      if (_.has(userData, key)) {
        Ctrl.user[key] = userData[key];
      }
    });
  }

  function inviteExpired() {
    $location.path('/invite/expired');
  }

  function failedResponse(res) {
    var status = parseInt(res.data.status, 10);
    var errors = $log.errorHandler(res, {allow409: true});
    var passwordsDoNotMatch = _.startsWith(res.data.message,'Confirm Password does not match with Password');

    if (status === 404) {
      return inviteExpired();
    } else if (status === 409 && passwordsDoNotMatch) {
      Ctrl.showPasswordsDoNotMatch = true;
      Ctrl.genericError = false;
      Ctrl.errors = res.data;
    } else if (errors.errors.pinMatch || errors.errors.password || errors.errors.confirmPassword) {
      Ctrl.showPasswordsDoNotMatch = false;
      Ctrl.genericError = false;
      Ctrl.errors = errors.errors;
    } else {
      Ctrl.showPasswordsDoNotMatch = false;
      Ctrl.genericError = true;
      Ctrl.errors = errors.errors;
    }

    Ctrl.friendlyErrors = errors.friendlyErrors;
  }
}

module.exports = ['$rootScope', '$cookieStore', 'LogFactory', '$scope', '$location', '$routeParams', 'UserFactory', 'RegistrationFactory', RegistrationCtrl];
