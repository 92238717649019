
var _      = require('lodash');
var dottie = require('dottie');

function BenefitSummaryCtrl(WizardService, CoverageFactory, NgTableParams, $q, EmployeeFactory, $log, DependentFactory, UtilFactory) {
  var Ctrl = this;

  Ctrl.wizard     = WizardService;
  Ctrl.coverages  = [];

  // hide the required fields helper message
  Ctrl.wizard.hideReqHelper = true;

  // for hiding pagination
  Ctrl.tableParams =  new NgTableParams({
    count: 0
  },{
    counts: []
  });

  this.init = function BenefitSummaryCtrl__init() {
    var employeeID = WizardService.getCurrentEmployeeID();

    this.wizard.showFinalSummaryFooter = true;
    Ctrl.hideEnrolled = false;

    if (_.isObject(Ctrl.wizard.data.newDep)) {
      if (!Ctrl.wizard.data.reactivatingDependent) {
        Ctrl.hideEnrolled = true;
      }
      Ctrl.coverages = CoverageFactory.formatResponse(Ctrl.wizard.data.newDep.coverages);
      finished();
    }
    // Coverages will come from adding a new employee if applicable
    else if (_.isArray(dottie.get(Ctrl.wizard, 'data.benefits')) && Ctrl.wizard.data.benefits.length > 0) {
      Ctrl.coverages = CoverageFactory.formatResponse(Ctrl.wizard.data.benefits);
    } else { // Get a list of coverages belonging to the user
      CoverageFactory.findByEmployee(employeeID).then(Ctrl.loadCoverages);
    }
  };

  /**
  * Display coverages
  * @param {Object} Server API response
  * @return {Bool} Whether or not loading succeeded
  */
  this.loadCoverages = function (coverages) {
    Ctrl.coverages = CoverageFactory.formatResponse(coverages);
    return true;
  };

  function finished() {
    var employeeID = WizardService.getCurrentEmployeeID();
    if (_.isUndefined(employeeID) && !_.isUndefined(WizardService.employeeInfo)) {
      employeeID = WizardService.employeeInfo.id;
    }

    // for when we add another dep...
    if (_.isUndefined(Ctrl.wizard.employeeInfo) && _.isUndefined(Ctrl.wizard.data.employeeInfo)) {
      Ctrl.wizard.data.employeeInfo = Ctrl.wizard.employeeInfo;
    }

    Ctrl.wizard.data.realName = Ctrl.wizard.data.employeeName.replace(/(\'s Spouse|Child)$/, '').trim();
    Ctrl.wizard.data.realName = Ctrl.wizard.data.realName.replace(/('+[sS]+)$/, '');

    // for when we're adding a new employee versus adding a dep
    if (Ctrl.wizard.data.addingDependent && _.isObject(Ctrl.wizard.data.newDep.dateOfEvent)) {
      var newDep = _.pick(Ctrl.wizard.data.newDep, ['dob', 'firstName', 'lastName', 'gender', 'relationship']);
      newDep.ssn = Ctrl.wizard.data.newDep.ssn;
      newDep.effectiveDate = UtilFactory.dropdownValuesToDateString(Ctrl.wizard.data.newDep.dateOfEvent);

      DependentFactory.insert(employeeID, newDep).then(function(){/*noop*/}, $log.error);
    }
  }

  this.init();

  Ctrl.wizard.currentStep = Ctrl;
}

module.exports = ['WizardService', 'CoverageFactory', 'NgTableParams', '$q', 'EmployeeFactory', 'LogFactory', 'DependentFactory', 'UtilFactory', BenefitSummaryCtrl];
