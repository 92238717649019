
var _      = require('lodash');
var dottie = require('dottie');

function EmployeeDependentsCtrl($rootScope, $scope, $log, WizardService, EmployeeFactory, DependentFactory, FormFactory, UtilFactory) {

  var Ctrl = this;

  Ctrl.wizard = WizardService;
  Ctrl.wizard.form = {};
  Ctrl.wizard.data = Ctrl.wizard.data || {};
  Ctrl.wizard.data.employeeInfo = Ctrl.wizard.data.employeeInfo || {};
  Ctrl.wizard.data.employeeInfo.dependents = Ctrl.wizard.data.employeeInfo.dependents || [];

  Ctrl.formData         = Ctrl.formData || {
    dob: {}
  };
  Ctrl.dependents       = [];
  Ctrl.showAddDependent = false;

  Ctrl.friendlyErrors  = [];
  Ctrl.formData.errors = [];

  Ctrl.wizard.skip  = true; // show the "skip" button
  Ctrl.employeeName = Ctrl.wizard.data.employeeInfo.firstName + ' ' + Ctrl.wizard.data.employeeInfo.lastName;

  /**
   * show the "add another" button only if user has started to add dependent data
   */
  Ctrl.showAddDependentButton = function EmployeeDependentsCtrl__showAddDependentButton() {
    if (_.isString(Ctrl.formData.firstName) && Ctrl.formData.firstName.trim() !== '') {
      Ctrl.showAddDependent = true;
    }
  }

  /**
   * Resets our form data to the default settings
   */
  Ctrl.defaultForm = function EmployeeDependentsCtrl__defaultForm() {
    // reset the form data object
    Ctrl.formData = {
      dob: {}
    };

    // set the form status
    Ctrl.wizard.form.$setPristine();

    // hide the "add another" button
    Ctrl.showAddDependent = false;
  };

  /**
   * Adds a dependent and triggers the `EmployeeDependentsCtrl__defaultForm`() function
   */
  Ctrl.addAnother = function EmployeeDependentsCtrl__addAnother() {
    // reset error fields
    Ctrl.friendlyErrors  = [];
    Ctrl.formData.errors = [];

    var errors = FormFactory.validate(Ctrl.wizard.form, {
      validations: [
        FormFactory.validations.date(Ctrl.formData.dob, {past: 'DOBPast', key: 'DOB'}),
        FormFactory.validations.date(Ctrl.formData.dob, {after: 'DOBWithin', compareDate: moment().subtract(100, 'years')}),
        FormFactory.validations.ssn(Ctrl.formData.ssn, Ctrl.wizard.data.employeeInfo.dependents.map(_.property('ssn')).push(Ctrl.wizard.data.employeeInfo.ssn))
      ]
    });

    if (_.keys(errors.errors).length > 0) {
      Ctrl.friendlyErrors  = errors.friendlyErrors;
      Ctrl.formData.errors = errors.errors;
      return false;
    }

    var result = Ctrl.addDependent(Ctrl.formData);
    DependentFactory.insert(Ctrl.wizard.data.employeeInfo.id, result).then(function EmployeeDependentsCtrl__addAnother__insert(success) {
      Ctrl.wizard.data.employeeInfo.dependents.push(DependentFactory.mapCensusProperties(success));
      Ctrl.defaultForm();
    }, populateErrors);
  };

  /**
   * Adds a dependent
   * @param {Object}   data Dependent's data object
   */
  Ctrl.addDependent = function EmployeeDependentsCtrl__addDependent(data) {
    var dependent = _.pick(data, ['firstName', 'lastName', 'gender', 'relationship', 'dob']);
    dependent.dob = UtilFactory.dropdownValuesToDateString(data.dob);
    dependent.ssn = data.ssn;

    dependent.effectiveDate = dottie.get(Ctrl.wizard.data, 'employeeInfo.effectiveDate');
    if (!dependent.effectiveDate && Ctrl.newDep) {
      Ctrl.newDep.effectiveDate = WizardService.getEffectiveDate();
    }

    if (data.dateOfEvent) {
      if (_.isString(data.dateOfEvent)) {
        dependent.effectiveDate = data.dateOfEvent;
      }
      else if (_.isObject(data.dateOfEvent)) {
        dependent.effectiveDate = UtilFactory.dropdownValuesToDateString(data.dateOfEvent);
      }
      dependent.qualifyingEventDate = dependent.effectiveDate;
    }

    switch (data.relationship) {
    case "spouse":
      dependent.relationship = "SP";
      break;
    case "child":
      dependent.relationship = "CH";
      break;
    }

    return dependent;
  };

  Ctrl.removeError = function () {
    for (var i = 0; i < arguments.length; i++) {
      Ctrl.formData = $log.removeErrors(Ctrl.formData, arguments[i]);
    }
  };

  Ctrl.finished = function(self, next) {
    // reset error fields
    Ctrl.friendlyErrors  = [];
    Ctrl.formData.errors = [];

    var errors = FormFactory.validate(Ctrl.wizard.form, {
      validations: [
        FormFactory.validations.date(Ctrl.formData.dob, {past: 'DOBPast', key: 'DOB'}),
        FormFactory.validations.date(Ctrl.formData.dob, {after: 'DOBWithin', compareDate: moment().subtract(100, 'years')}),
        FormFactory.validations.ssn(Ctrl.formData.ssn, Ctrl.wizard.data.employeeInfo.dependents.map(_.property('ssn')).push(Ctrl.wizard.data.employeeInfo.ssn))
      ]
    });

    if (_.keys(errors.errors).length > 0) {
      Ctrl.friendlyErrors  = errors.friendlyErrors;
      Ctrl.formData.errors = errors.errors;
      return false;
    }

    // add current dependent
    var result = Ctrl.addDependent(Ctrl.formData);
    DependentFactory.insert(Ctrl.wizard.data.employeeInfo.id, result).then(function (success) {
      Ctrl.wizard.data.employeeInfo.dependents.push(DependentFactory.mapCensusProperties(success));
      next();
    }, populateErrors);
  };

  function populateErrors(res) {
    var errors            = $log.errorHandler(res, {allow409: true});
    Ctrl.formData.errors  = errors.errors;
    Ctrl.friendlyErrors   = errors.friendlyErrors;
  }

  Ctrl.wizard.sidebar = [
    {name: 'Employee Information'},
    {name: 'Add Dependent(s)'},
    {name: 'Summary'}
  ];

  Ctrl.wizard.currentStep = this;
}

module.exports = ['$rootScope', '$scope', 'LogFactory', 'WizardService', 'EmployeeFactory', 'DependentFactory', 'FormFactory', 'UtilFactory', EmployeeDependentsCtrl];
