
var _      = require('lodash');
var dottie = require('dottie');

function VolumeSelectCtrl($rootScope, $scope, $log, $filter, $timeout, $q, WizardService, UtilFactory, CoverageFactory, EmployeeFactory, DependentFactory) {
  var Ctrl = this;
  Ctrl.wizard = WizardService;

  this.coverage         = {};
  this.CoverageFactory  = CoverageFactory;

  /**
   * Get information about the currently selected line of coverage
   * and discard info leftover from previous lines of coverage
   * that used this template.
   */
  this.resetVolumeSelect = function VolumeSelectCtrl__resetVolumeSelect() {
    // Get info about current line of coverage & update page heading
    Ctrl.coverage = WizardService.getCurrentCoverage();
    if (_.isUndefined(Ctrl.coverage)) {
      Ctrl.coverage = {};
    }

    if(Ctrl.coverage.volume){
      Ctrl.coverage.flatVolume = Ctrl.coverage.volume;
    }

    Ctrl.coverageTypeHeading  = Ctrl.coverage.planType;

    // Reset the form selections
    Ctrl.showError            = false;
    Ctrl.errors               = {};
    Ctrl.friendlyErrors       = [];
    Ctrl.coverage.dependents  = [];
    Ctrl.coverage.volume      = '';

    if (WizardService.data.originalDependents) {
      WizardService.data.employeeInfo.dependents = angular.copy(WizardService.data.originalDependents);
    }

    Ctrl.matchParentVolume = false;
    Ctrl.capAtParentVolume = false;
    Ctrl.isWaitToSendCoverage = false;
    Ctrl.isLastInRelationship = false;
    Ctrl.isParentCoverage = false;
    Ctrl.isChildCoverage = false;
    Ctrl.parentCoverageId = null;

    var parentIndex = -1;

    //Check if this is a parent coverage in which you have to wait to send with a required coverage
    if (_.isArray(WizardService.data.coverageRelationships) &&  WizardService.data.coverageRelationships.length>0){
      parentIndex = _.findIndex(WizardService.data.coverageRelationships, {parentId:Ctrl.coverage.id});
      if( parentIndex != -1 ) {
        Ctrl.isParentCoverage = true;
        Ctrl.parentCoverageId = Ctrl.coverage.id;
        Ctrl.relationshipStatus = WizardService.data.coverageRelationships[parentIndex];
        Ctrl.isWaitToSendCoverage = true;
      }
    }

    // Check if this is a child coverage with a relationship code that alters its volume
    if (Ctrl.coverage.requiresCoverage && Ctrl.coverage.requiresCoverage[0]) {
      var relationshipTypeCode = Ctrl.coverage.requiresCoverage[0].relationshipTypeCode;
      if (_.includes(['GPOPTMCH', 'GPREQMCH'], relationshipTypeCode)) {
        // Volume should equal parent volume
        Ctrl.matchParentVolume = true;
      }
      else if (_.includes(['GPOPTVLLTE', 'GPREQVLLTE'], relationshipTypeCode)) {
        // Volume should be capped at parent volume
        Ctrl.capAtParentVolume = true;
      }

      if (!Ctrl.isParentCoverage) {
        Ctrl.isChildCoverage = true;
        Ctrl.parentCoverageId = Ctrl.coverage.requiresCoverage[0].id;
      }

      var isParentInList = _.findIndex(WizardService.data.coverageRelationships, {parentId:Ctrl.coverage.requiresCoverage[0].id} ) != -1;
      if (isParentInList || Ctrl.isParentCoverage) {
        Ctrl.isWaitToSendCoverage = true;
      }
      if (parentIndex != -1) {
        Ctrl.isLastInRelationship = true;
      }

      if (Ctrl.matchParentVolume || Ctrl.capAtParentVolume) {
        // Find parent volume
        var parentCoverageId = Ctrl.coverage.requiresCoverage[0].id;
          var parentCoverage = _.find(WizardService.data.benefits, ['id', parentCoverageId]);
          if (!parentCoverage) {
            parentCoverage = _.find(WizardService.data.waitingCoverages, ['id', parentCoverageId]);
            Ctrl.parentVolume =  parentCoverage.volume ;
          } else {
            Ctrl.parentVolume = parentCoverage.coverageDetail ? parentCoverage.coverageDetail.volume : null;
          }
          setVolume();
      } else {
        setVolume();
      }
    } else {
      setVolume();
    }
  };

  function setVolume() {
    var noSpouse = !angular.isObject(Ctrl.coverage) || !CoverageFactory.isForSpouse(Ctrl.coverage);
    var noChild  = !angular.isObject(Ctrl.coverage) || !(CoverageFactory.isForDependents(Ctrl.coverage) || CoverageFactory.isForChildren(Ctrl.coverage));

    var onlyChildren =  noSpouse && !noChild;

    Ctrl.coverage.allOptions = onlyChildren && Ctrl.coverage.selectionType === 'VolumeSelect' && CoverageFactory.isForDependents(Ctrl.coverage);

    Ctrl.updateVolumes(Ctrl.coverage.selectionType === 'VolumeDisplay');

    if (_.isFunction(dottie.get(WizardService, 'form.$setPristine'))) {
      WizardService.form.$setPristine();
    }

    // This needs to happen before Ctrl.showRiders
    populateRidersLists();

    // Check again to see if special text, warnings or riders should appear
    Ctrl.showInfoText = false;

    var displayRules = CoverageFactory.getCoverageDisplayRules(Ctrl.coverage);
    if (Ctrl.wizard.data.newDep) {
      // We're just adding/reactivating a dependent, so don't show the employee checkbox
      displayRules.employeeCheckbox = false;
      displayRules.employeeCheckboxChecked = false;
    }
    Ctrl.coverage.displayRules = displayRules;

    Ctrl.showRiders         = ridersExist() && !Ctrl.wizard.data.newDep;
    Ctrl.disclaimers        = CoverageFactory.populateDisclaimers(Ctrl.coverage, Ctrl.wizard.addingEmployee);
    if (Ctrl.wizard.data.addingDependent) {
      Ctrl.disclaimers.addDependentNew = false;
    }

    Ctrl.showEnrollees = CoverageFactory.isForFamilies(Ctrl.coverage) || CoverageFactory.isForEmployeeDependents(Ctrl.coverage) || CoverageFactory.isForDependents(Ctrl.coverage) || (WizardService.data.employeeInfo && (!noSpouse || !noChild));
  }

  /**
  * Update the display of volume numbers
  */
  this.updateVolumes = function VolumeSelectCtrl__updateVolumes(display) {
    var displayRules = CoverageFactory.getCoverageDisplayRules(Ctrl.coverage);
    if (Ctrl.wizard.data.newDep) {
      // We're just adding/reactivating a dependent, so don't show the employee checkbox
      displayRules.employeeCheckbox = false;
      displayRules.employeeCheckboxChecked = false;
    }
    Ctrl.coverage.displayRules = displayRules;

    if (display || displayRules.showIncrement === false) {
      Ctrl.coverage.volume = CoverageFactory.findVolume(Ctrl.coverage, WizardService.data.employeeInfo, undefined);
      if (Ctrl.coverage.id) {
        var salaryType = CoverageFactory.getCoverageSalaryType(Ctrl.coverage);
        if (salaryType !== "annually") {
          Ctrl.coverage.salaryType = ' / ' + salaryType;
        }
      }
    }
    else if (Ctrl.coverage.volumeIncrement) {
      Ctrl.coverage.volume  = '';
      CoverageFactory.getVolumes(Ctrl.coverage, WizardService.data.employeeInfo).then(function (volumes) {
        Ctrl.volumes = volumes;

        if (Ctrl.parentVolume) {
          if (Ctrl.matchParentVolume) {
            Ctrl.volumes = [];
            Ctrl.coverage.volume = Ctrl.parentVolume;
            Ctrl.coverage.friendlyVolume = Ctrl.coverage.volume;
            // if a match volume, don't show dropdown as the user will not be able to change the volume
            Ctrl.coverage.displayRules.showIncrement = false;
            Ctrl.coverage.displayRules.flat = true;
          }
          else if (Ctrl.capAtParentVolume) {
            Ctrl.volumes = _.filter(Ctrl.volumes, function(volume) {
              return volume <= Ctrl.parentVolume;
            });
            Ctrl.coverage.volume = '';
          }
        }

      });
    }

    Ctrl.coverage.friendlyVolume = Ctrl.coverage.volume;
    if (!isNaN(Ctrl.coverage.friendlyVolume)) {
      //var benefit = Ctrl.coverage.benefits[0];
      var benefit = Ctrl.coverage;
      if (benefit.productType == "LTD" && benefit.volumeCalculationType == "S") {
        var newVolume = (Ctrl.coverage.friendlyVolume)/(benefit.salaryMultiplier);
        newVolume = Math.ceil(newVolume);
        Ctrl.coverage.friendlyVolume = newVolume;
      }
    }

    if (Ctrl.coverage.allOptions) {
      // fetch again for special case scenario (children critical illness)
      CoverageFactory.getVolumes(Ctrl.coverage, WizardService.data.employeeInfo).then(function (volumes) {
        Ctrl.volumes = volumes;
      });
    }
  };

  this.init = function VolumeSelectCtrl__init() {
    Ctrl.riders         = [];
    Ctrl.autoRiders     = [];
    Ctrl.volumes        = [];
    Ctrl.showEnrollees  = false;

    Ctrl.resetVolumeSelect();

    if (!_.isUndefined(dottie.get(WizardService.data, 'employeeInfo.id'))) {
      EmployeeFactory.find(WizardService.data.employeeInfo.id).then(function (user) {
        WizardService.data.employeeInfo = user;
        if (WizardService.data.newDep) {
          Ctrl.enrollees = [{
            id: parseInt(WizardService.data.newDep.id, 10),
            memberEntityId: parseInt(WizardService.data.newDep.memberEntityId, 10),
            relationship: WizardService.data.newDep.relationship,
            type: CoverageFactory.getRelationshipByCode(WizardService.data.newDep.relationship.toUpperCase()),
            firstName: WizardService.data.newDep.firstName
          }];
        } else {
          populateDependents();
        }
      });
    }

    // timeout to allow angular to render/digest [this is needed]
    $timeout(function() {
      if (Ctrl.volume !== '' && _.isFunction(dottie.get(WizardService, 'form.$setDirty'))) {
        WizardService.form.$setDirty();
      }
    }, 0);

    Ctrl.disclaimers = CoverageFactory.populateDisclaimers(Ctrl.coverage, Ctrl.wizard.addingEmployee);
    if (Ctrl.wizard.data.addingDependent) {
      Ctrl.disclaimers.addDependentNew = false;
    }

    Ctrl.coverage.salaryType = '';

    if (Ctrl.coverage.id) {
      var salaryType = CoverageFactory.getCoverageSalaryType(Ctrl.coverage);
      if (salaryType !== "annually") {
        Ctrl.coverage.salaryType = ' / ' + salaryType;
      }
    }

    // If the coverage is for spouse only and requireTobacco=1, show tobacco warning
    if (Ctrl.coverage.coveredPersonType === "Spouse" && !!Ctrl.coverage.requireTobacco) {
      Ctrl.showTobaccoWarning = true;
    } else {
      Ctrl.showTobaccoWarning = false;
    }
  };

  this.init();

  // We need the rootScope init for coverages when adding dependents
  // since we don't fetch the coverage details per page load (we fetch it from a preexisting array)
  $rootScope.$on("coverage:init", VolumeSelectCtrl__coverageInit);

  function VolumeSelectCtrl__coverageInit() {
    Ctrl.init();
  }

  $scope.$on("$destroy", function() {
    VolumeSelectCtrl__coverageInit();
  });

  // Function to populate dependents in proper format
  function populateDependents () {
    Ctrl.enrollees = [];

    var isForSpouse = CoverageFactory.isForSpouse(Ctrl.coverage),
        isForChildren = CoverageFactory.isForChildren(Ctrl.coverage),
        isForDependents = CoverageFactory.isForDependents(Ctrl.coverage),
        isForFamilies = CoverageFactory.isForFamilies(Ctrl.coverage),
        isForEmployeeDependents = CoverageFactory.isForEmployeeDependents(Ctrl.coverage);

    checkForEmployeeIncluded()

    if (Ctrl.coverage && (isForSpouse || isForDependents || isForFamilies || isForEmployeeDependents) && WizardService.data.employeeInfo.dependents) {
      Ctrl.allowDependents = true;
      Ctrl.showDisclaimer = true;
      var spouse = angular.copy(WizardService.data.employeeInfo.dependents).filter(function(obj) {
        return ['H','W','SP'].indexOf(obj.relationship.toUpperCase()) > -1;
      });

      if (spouse.length) {
        Ctrl.enrollees.push({
          id: parseInt(spouse[0].id, 10),
          type: CoverageFactory.getRelationshipByCode(spouse[0].relationship.toUpperCase()),
          memberEntityId: parseInt(spouse[0].memberEntityId, 10),
          firstName: spouse[0].firstName
        });
      }
    }

    if (Ctrl.coverage && (isForChildren || isForDependents || isForFamilies || isForEmployeeDependents) && WizardService.data.employeeInfo.dependents) {
      Ctrl.allowDependents = true;
      Ctrl.showDisclaimer = true;
      var children = WizardService.data.employeeInfo.dependents.filter(function(obj) {
        return ['S','D','CH'].indexOf(obj.relationship.toUpperCase()) > -1;
      });

      if (children.length) {
        children.forEach(function(child) {
          Ctrl.enrollees.push({
            id: parseInt(child.id, 10),
            memberEntityId: parseInt(child.memberEntityId, 10),
            type: CoverageFactory.getRelationshipByCode(child.relationship.toUpperCase()),
            firstName: child.firstName
          });
        });
      }
    }

    if (WizardService.data.editMode) {
      Ctrl.enrollees = Ctrl.enrollees.map(function (enrollee) {
        var findDep = _.find(Ctrl.coverage.dependents, function (dep) {
          return dep.memberEntityId === enrollee.memberEntityId;
        });

        if (findDep) {
          Ctrl.originalDependents.dependents.push(enrollee);
        }

        return enrollee;
      });

      Ctrl.coverage.dependents = angular.copy(Ctrl.originalDependents.dependents);
    }
  }

  function ridersExist () {
    return Ctrl.riders.length > 0 || Ctrl.autoRiders.length > 0;
  }

  this.completed = function VolumeSelectCtrl__completed(self, next) {
    next();
  };

  function populateRidersLists () {
    // Reset the lists
    Ctrl.autoRiders = [];
    Ctrl.riders = [];

    // Separate the list of riders into auto-displayed vs. non-auto displayed
    getRiders(Ctrl.coverage).forEach(function (rider) {
      var array = (rider.autoSelect === true || rider.autoSelect === 1) ? Ctrl.autoRiders : Ctrl.riders;
      array.push(rider);
    });
  }

  function getRiders (coverage) {
    if (typeof coverage !== 'object' || !angular.isArray(coverage.riders)) {
      return [];
    }
    return coverage.riders.map(function (rider) {
      return rider;
    });
  }

  /**
  * @param {Array} riders Optional riders
  * @param {Array} autoRiders Riders that are auto-selected
  * @return {Array} List of all user-selected and auto-selected riders
  */
  function getCheckedRiders (riders, autoRiders) {
    var checkedRiders = riders.filter(function (rider) {
      return rider.checked === true;
    });
    return checkedRiders.concat(autoRiders);
  }

  // Checks if employee is being added to the covered people in the coverage
  this.dependentChanged = function VolumeSelectCtrl__dependentChanged() {
    checkForEmployeeIncluded()
  }

  this.finished = function VolumeSelectCtrl__finished(self, next) {
    var employeeId = WizardService.data.employeeInfo.id;
    var volume = Ctrl.coverage.volume;

    if (typeof volume === 'string') {
      // Remove non-numeric characters
      volume = parseFloat(volume.replace(/[^0-9\.]+/g, ''));
    }

    if(isNaN(volume)) {
      volume = 0;
    }

    // If the newEmployeeRehireDate exists, use that because we are rehiring an employee and need to use the new rehire date
    var effectiveDate = Ctrl.wizard.data.newEmployeeRehireDate || WizardService.data.employeeInfo.hireDate || WizardService.getEffectiveDate();

    var coverageData = {
      coverageCode:   Ctrl.coverage.coverageCode,
      benefitID:      Ctrl.coverage.benefitID || Ctrl.coverage.benefitId,
      volume:         !!volume || _.isNumber(volume) ? volume : 0,
      effectiveDate:  effectiveDate,
      riders:         getCheckedRiders(Ctrl.riders, Ctrl.autoRiders),
      coveredPeople:  []
    };

    if (Ctrl.coverage.qualifyingEventRequired) {
      coverageData.enrollingType = Ctrl.wizard.data.enrollingType;

      // Qualifying Event
      if (coverageData.enrollingType === 'QE') {
        if (Ctrl.wizard.data.qualifyingEvent) {
          coverageData.qualifyingEvent = Ctrl.wizard.data.qualifyingEvent;
        }
        
        if (Ctrl.wizard.addingEmployee) {
          coverageData.qualifyingEventTypeCode = Ctrl.wizard.data.qualifyingEventTypeCode;
          coverageData.effectiveDate = Ctrl.wizard.data.qualifyingEventEffectiveDate;
        }
      }
    }

    if (Ctrl.wizard.data.addingDependent || Ctrl.wizard.data.reactivatingDependent) {
      coverageData.effectiveDate = Ctrl.wizard.data.newDepEffectiveDate || UtilFactory.dropdownValuesToDateString(Ctrl.wizard.data.newDep.dateOfEvent);
    }

    coverageData.coveredPersonType = Ctrl.coverage.coveredPersonType;

    if (CoverageFactory.isForSpouse(Ctrl.coverage)) {
      var spouse = _.find(WizardService.data.employeeInfo.dependents, function(obj) {
        return ['H','W','SP'].indexOf(obj.relationship.toUpperCase()) > -1;
      });
      if (spouse) {
        spouse.id = parseInt(spouse.id, 10);
        Ctrl.coverage.dependents.push(spouse);

        // If the tobacco use question was answered, update the spouse's profile with the answer
        if (Ctrl.coverage.tobaccoUse) {
          var employeeId = parseInt(Ctrl.wizard.data.employeeInfo.id, 10);
          DependentFactory.update(employeeId, {id:spouse.id, tobaccoUse:Ctrl.coverage.tobaccoUse, effectiveDate:spouse.effectiveDate, firstName:spouse.firstName, lastName:spouse.lastName, relationship:spouse.relationship, ssn:spouse.ssn||"", middleName1:spouse.middleName1||""});
        }
      }
    }

    if (_.isArray(Ctrl.coverage.dependents)) {
      coverageData.coveredPeople = _.map(Ctrl.coverage.dependents, function (dep) {
        return {id: parseInt(dep.memberEntityId, 10)};
      });
    }

    if (CoverageFactory.isForEmployee(Ctrl.coverage) || WizardService.data.employeeInfo.includeEmployee) {
      coverageData.coveredPeople.push({id: parseInt(WizardService.data.employeeInfo.memberEntityId, 10)});
    }

    if (_.isObject(WizardService.data.newDep)) {
      WizardService.data.newDep.coverages = WizardService.data.newDep.coverages || [];
      WizardService.data.newDep.coverages.push(Ctrl.coverage);
      coverageData.coveredPeople = [{id: WizardService.data.newDep.memberEntityId}];
    }

    Ctrl.errors         = {};
    Ctrl.friendlyErrors = [];

    // If we're adding a new dependent or reactivating one
    if (Ctrl.wizard.data.addingDependent || Ctrl.wizard.data.reactivatingDependent) {
      CoverageFactory.insertDep(employeeId, WizardService.data.newDep.id, coverageData).then(function (resp) {
        if (_.isObject(resp) && parseInt(resp.status, 10) === 200) {
          if (resp.data.data && resp.data.data.length) {
            resp = resp.data.data[0]; // The coverages array will only have one element in this case
            var volToShow =  Ctrl.coverage.volume || 'N/A';

            resp.coveredPersonType  = Ctrl.coverage.coveredPersonType;
            resp.coverageDetail.volume = volToShow;

            Ctrl.wizard.addBenefit(resp);
          }
          // resetVolumeSelect must be called *after* next
          next();
          Ctrl.resetVolumeSelect();
        } else {
          handleError(resp);
        }
      }, handleError);
    } else {
      if(Ctrl.isWaitToSendCoverage){
        coverageData.id = Ctrl.coverage.id;

        if(_.isArray(Ctrl.wizard.data.waitingCoverages) && Ctrl.wizard.data.waitingCoverages.length>0){
          Ctrl.wizard.data.waitingCoverages.push(coverageData);
        } else {
          Ctrl.wizard.data.waitingCoverages = [coverageData];
        }

        //set status in relationship tracker
        var relationshipStatusIndex = _.findIndex(WizardService.data.coverageRelationships, {parentId: Ctrl.parentCoverageId});
        var relationshipStatus = WizardService.data.coverageRelationships[relationshipStatusIndex];

        if(Ctrl.isChildCoverage){
          var child = _.findIndex(WizardService.data.coverageRelationships[relationshipStatusIndex].childCoverages, {id:Ctrl.coverage.id});
          WizardService.data.coverageRelationships[relationshipStatusIndex].childCoverages[child].isWaitingPlanCreated= true;
        }

        if(CoverageFactory.isAllRequiredSatisfied(relationshipStatus)){
          var childCoverages = [Ctrl.parentCoverageId];
          _.forEach(relationshipStatus.childCoverages, function(childCov) {
            childCoverages.push(childCov.id);
          });

          var coveragesToSend = [];
          _.forEach( childCoverages, function (covToUse){
            var coverageIndex = _.findIndex(Ctrl.wizard.data.waitingCoverages, {id:covToUse});
            if(coverageIndex != -1){
              coveragesToSend.push(Ctrl.wizard.data.waitingCoverages[coverageIndex]);
            }
          });

          //send coverage
          CoverageFactory.insert(coveragesToSend, employeeId).then(function (resp) {
            var enrollees = _.isArray(Ctrl.coverage.dependents) ? Ctrl.coverage.dependents : [];
            if (_.isObject(resp) && parseInt(resp.status, 10) === 200) {
              if (resp.data.data && resp.data.data.length) {
                 _.each(resp.data.data, function(addedCoverage){
                  Ctrl.wizard.addBenefit(addedCoverage);
                });
              }
              // resetVolumeSelect must be called *after* next

              // clean up coverages that have been sent and remove relationship mappings that are no longer applicable
              delete WizardService.data.coverageRelationships[relationshipStatusIndex];
              _.forEach( Ctrl.wizard.data.waitingCoverages, function(createdCoverage){
                if(_.findIndex(childCoverages, {id:createdCoverage.id}) != -1){
                  delete Ctrl.wizard.data.waitingCoverages[k];
                }
              });

              next();
              Ctrl.resetVolumeSelect();
            } else {
              handleError(resp);
            }
          }, handleError);
        } else {
          next();
          Ctrl.resetVolumeSelect();
        }
      } else {
        CoverageFactory.insert(coverageData, employeeId).then(function (resp) {
          var enrollees = _.isArray(Ctrl.coverage.dependents) ? Ctrl.coverage.dependents : [];

          if (_.isObject(resp) && parseInt(resp.status, 10) === 200) {
            if (resp.data.data && resp.data.data.length) {
              resp = resp.data.data[0]; // The coverages array will only have one element in this case
              var volToShow =  Ctrl.coverage.volume || 'N/A';

              resp.enrollees          = enrollees;
              resp.coveredPersonType  = Ctrl.coverage.coveredPersonType;
              resp.coverageDetail.volume = volToShow;

              resp.riders = Ctrl.coverage.riders;
              resp.id = Ctrl.coverage.id;
              resp.isAdded = true;

              Ctrl.wizard.addBenefit(resp);
            }
            // resetVolumeSelect must be called *after* next
            next();
            Ctrl.resetVolumeSelect();
          } else {
            handleError(resp);
          }
        }, handleError);
      }
    }

    function handleError(res) {
      var errors          = $log.errorHandler(res, {allow409: true});
      Ctrl.errors         = errors.errors;
      Ctrl.friendlyErrors = errors.friendlyErrors;

      Ctrl.showError = true;
    }
  };

  $scope.$watch('Ctrl.coverage.volume', function () {
    if (Ctrl.coverage.allOptions && Ctrl.coverage.dependents.length < 1 && _.isFunction(dottie.get(WizardService, 'form.$setPristine'))) {
      WizardService.form.$setPristine();
    }
  });

  $scope.$watchCollection('Ctrl.coverage.dependents', function (newVal) {
    if (Ctrl.coverage.allOptions && newVal.length < 1 && _.isFunction(dottie.get(WizardService, 'form.$setPristine'))) {
      WizardService.form.$setPristine();
    }
  });

  WizardService.currentStep = this;

  function checkForEmployeeIncluded() {
    var isForFamilies = CoverageFactory.isForFamilies(Ctrl.coverage),
        isForEmployeeDependents = CoverageFactory.isForEmployeeDependents(Ctrl.coverage);

    if (isForFamilies || isForEmployeeDependents) {
      if (Ctrl.coverage.displayRules.employeeCheckboxChecked) {
        Ctrl.wizard.data.employeeInfo.includeEmployee = true;
      } else {
        Ctrl.wizard.data.employeeInfo.includeEmployee = false;
      }
    } else {
      Ctrl.wizard.data.employeeInfo.includeEmployee = false;
    }
  }
}

module.exports = ['$rootScope', '$scope', 'LogFactory', '$filter', '$timeout', '$q', 'WizardService', 'UtilFactory', 'CoverageFactory', 'EmployeeFactory', 'DependentFactory', VolumeSelectCtrl];
