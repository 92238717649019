
/**
  * @ngdoc error
  * @name error
  * @restrict E
  *
  * @description
  * Provides a basic error template for input groups
  *
  *
  * @param {string}  errorMessage Custom error message when ng-if is true, default is "Invalid Format"
  *
  * @example
      <error ng-if="Ctrl.errors.firstName"></error>
      <error ng-if="Ctrl.errors.lastName" error-message="Enter in your last name please!"></error>
*/

function errorDirective() {
  return {
    restrict: "E",
    templateUrl: "/partials/partials/error",
    scope: {
      errorMessage: '@'
    }
  };
}

module.exports = errorDirective;
