
var _ = require('lodash');

function BillingFactory($window, $q, RequestFactory, $log) {
  var service = {};

  /**
   * Returns the division's bills
   * @return {Promise} Return's the response from GET /api/bills
   */
  service.getDivisionBills = function BillingFactory__getDivisionBills() {
    return RequestFactory.send('/api/bills');
  };

  /**
   * Returns the URL for the latest bill
   * @param  {Object} bill The billing object
   * @return {Promise}     Return's the `url` key within the response from GET /api/documents/bill.divisionNumber/latestbill
   */
  service.getPrint = function BillingFactory__getPrint(bill) {
    return RequestFactory.send('/api/documents/bill/' + bill.invoiceId, 'url');
  };

  /**
   * Returns the URL for the latest bill export
   * @param  {Object} bill The billing object
   * @return {Promise}     Return's the `url` key within the response from GET /api/documents/bill.divisionNumber/latestbill
   */
  service.getExport = function BillingFactory__getExport(bill) {
    return RequestFactory.send('/api/documents/bill/' + bill.invoiceId + '/export', 'url');
  };

  /**
   * Gets a list of payments
   * @param  {Object[]} bills An array of billing objects
   * @return {Promise}        Returns an array of bill objects with new mapped values
   */
  service.getPayments = function BillingFactory__getPayments(bills) {
    var deferred = $q.defer();
    var _bills   = [];

    _.each(bills, addToQueue);

    function addToQueue(bill) {
      _bills.push(service.getPayment(bill.id));
    }

    $q.all(_bills).then(function (res) {
      res = _.map(res, addPaymentInfoToBill);

      function addPaymentInfoToBill(billInfo, idx) {
        bills[idx].transaction = _.isObject(bills[idx].transaction) ? /* istanbul ignore next */ bills[idx].transaction : billInfo;
        // bills[idx].transaction.x_relay_url = buildBillingURL();

        return bills[idx];
      }

      deferred.resolve(res);
    }, $log.error);

    return deferred.promise;
  }

  /**
   * Get a specific payment
   * @param  {String|Integer} billId The bill's ID
   * @return {Promise}        Returns the value for the response's key `transaction`
   */
  service.getPayment = function BillingFactory__getPayment(billId) {
    return RequestFactory.send({
      url: '/api/bill/' + billId + '/authorizeNet/createPayment',
      method: 'POST'
    }, 'data');
  };

  /**
   * Sends a payment response
   * @param  {String|Integer} billId The bill's ID
   * @return {Promise}        Returns the value for the response's key `data`
   */
  service.addPayment = function BillingFactory__addPayment(billId) {
    return RequestFactory.send({
      url: '/api/bill/' + billId + '/authorizeNet/paymentResponse',
      method: 'POST'
    }, 'data');
  };

  // # private

  function buildBillingURL() {
    var url = 'http://' + $window.location.hostname;

    /* istanbul ignore else */
    if ($window.location.port) {
      url += ':' + $window.location.port;
    }

    url += '/api/billing/paid';
    return url;
  }

  return service;
}

module.exports = ['$window', '$q', 'RequestFactory', 'LogFactory', BillingFactory];
