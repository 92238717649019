
var _       = require('lodash');
var dottie  = require('dottie');

// var defaultOptions = {
//   method: 'get',
//   url:    '',
//   data:   {},
//   responseType: 'json',
//   xsrfHeaderName: 'XSRF-TOKEN'
// };

var defaultOptions = {
  method: 'get',
  url:    '',
  data:   {},
  responseType: 'json'
};

function RequestFactory($http, $q, $log) {
  /**
   * Sends a request to the frontend's server
   * @param  {Object|String} options    Common $http `options` or a string if we're performing a common GET request
   * @param  {String}        returnKey  Optional. Attempts to retrieve the response's value from returnKey
   * @return {Promise}                  Returns `response[returnKey] || response`
   */
  this.send = function RequestFactory__send(options, returnKey) {
    var deferred = $q.defer();

    // if the first option is a string, presume GET /{options}
    if (_.isString(options)) {
      options = {url: options};
    }

    options = _.defaults(options, defaultOptions);

    $http(options).then(function RequestFactory__send_success(res) {
      $log.info('RequestFactory success: ', res);

      if (_.isUndefined(res)) {
        return deferred.reject(res);
      }

      // backend API is really weird.. sometimes res.data.status means user status
      // and the rest of the time.. it means the HTTP status
      var isLoggingIn = dottie.get(res, 'config.url', '') === '/api/user';
      var firstKey    = isLoggingIn ? 'status' : 'data.status';

      if (_.includes([200, 201, 202], parseInt(dottie.get(res, firstKey, res.status), 10))) {
        return deferred.resolve(_.isString(returnKey) ? dottie.get(res.data, returnKey, {}) : res);
      }

      res.status = parseInt(dottie.get(res, firstKey, dottie.get(res, 'status', 200)), 10);
      deferred.reject(res);
    }, deferred.reject);

    return deferred.promise;
  };

  return this;
}

module.exports = ['$http', '$q', 'LogFactory', RequestFactory];
