
/**
  * @ngdoc mobTooltip
  * @name any[mobTooltip]
  * @restrict A
  *
  * @description
  * Creates a tooltip
  *
  *
  * @param {string} mobTooltipTitle Assigns the tooltip's title
  * @param {string} mobTooltipContent Assigns the tooltip's content
  * @param {string=250} mobTooltipWidth Assigns the tooltip's width (in pixels)
  * @param {string=click} mobTooltipTrigger Assigns which event to listen to in order to show the tooltip
  *
  * @example
   	  <a mob-tooltip mob-tooltip-title="Hello" mob-tooltip-content="World">?</a>
*/

function TooltipDirective($compile) {
  return {
    // templateUrl: '/static/partials/tooltip.html',
    restrict: 'A',
    scope: {
      id: '@id',
      title: '@mobTooltipTitle',
      content: '@mobTooltipContent',
      trigger: '@mobTooltipTrigger',
      width: '@mobTooltipWidth',
      showTooltip: '=?',
      toggleTip: '&'
    },
    link: function (scope, elm) {
      scope.trigger = scope.trigger || 'click';
      scope.width   = scope.width   || 250;

      scope.showTooltip = false;

      var el = angular.element(elm);

      var tooltipTemplate = [
        '<div ng-cloak class="mob-tooltip--container" ng-class="{\'mob-tooltip--invisible\': !showTooltip}">',
          '<div class="header">',
            '<div class="title" ng-bind="title"></div>',
            '<div class="close"><img src="/static/images/close_icon.png" alt="X" title="Close"></div>',
          '</div>',
          '<div class="content">' + scope.content + '</div>', // scope.content is very trusting -- will eventually use $nce
          '<div class="arrow down"></div>',
        '</div>'
      ].join('');

      var $tooltip = $(tooltipTemplate);

      $tooltip.css('max-width', scope.width);

      // compile for grabbing the height of the title bar
      $compile($tooltip)(scope);

      // let's get the real height..
      var _tooltip = $tooltip.clone().css({
        visibility: 'visible',
        position: 'fixed',
        top: '100%'
      }).removeClass('mob-tooltip--invisible');

      _tooltip.appendTo('body');

      var officialHeight = _tooltip.outerHeight();

      _tooltip.remove();

      $tooltip.insertAfter(el);

      if ($tooltip.parent().hasClass("inline-tooltip")) {
        $tooltip.css({
          top: (-1 * officialHeight) + 13,
          right: 14
        });
      } else {
        $tooltip.css({
          top: (-1 * officialHeight) - 10,
          right: 0
        });
      }

      el.bind(scope.trigger, toggleTooltip);
      $tooltip.find('.close').click(toggleTooltip);

      function toggleTooltip($event) {
        if ($event && $event.preventDefault) {
          $event.preventDefault();
        }

        // efficiently apply the scope for compilation
        // prevents "timeout pause" from Angular
        scope.$apply(function() {
          scope.showTooltip = !scope.showTooltip;
          $compile($tooltip)(scope);
        });
      }
    }
  };
}

module.exports = ['$compile', TooltipDirective];
