
var _       = require('lodash');
var dottie  = require('dottie');

function AddDependentModalCtrl($rootScope, $location, $log, WizardService, EmployeeFactory, DependentFactory, FormFactory, UtilFactory) {
  var Ctrl = this;

  Ctrl.formData = {};
  Ctrl.newDep   = {dob: {}, dateOfEvent: {}};
  Ctrl.wizard   = WizardService;
  Ctrl.wizard.data.addingDependent = true;

  Ctrl.friendlyErrors  = [];
  Ctrl.formData.errors = [];

  Ctrl.qualifyingEvents   = Ctrl.wizard.data.qualifyingEvents;
  Ctrl.wizard.modalTitle  = 'Add a Dependent';
  Ctrl.wizard.addingEmployee = false;

  // for when we add another dependent
  if (_.isUndefined(Ctrl.qualifyingEvents)) {
    EmployeeFactory.getQualifyingEvents().then(function (results) {
      Ctrl.qualifyingEvents = results;
    });
  }

  /**
   * Resets our form / controller / wizard data
   */
  this.reset = function AddDependentModalCtrl__reset() {
    Ctrl.formData = {};
    Ctrl.newDep   = {dob: {}, dateOfEvent: {}};

    WizardService.resetData();
    Ctrl.wizard.data.employeeInfo         = {};
    Ctrl.wizard.data.addingDependent      = true;
    Ctrl.wizard.showSummaryConfirmScreen  = false;
    Ctrl.wizard.showFinalConfirmScreen    = false;
    Ctrl.wizard.showFinalSummaryFooter    = false;
    Ctrl.wizard.data.newDepEffectiveDate  = null;

  };

  /**
   * Closes modal and redirects to `route`
   * @param  {String} route Redirection's path
   */
  this.closeModalAndRedirect = function AddDependentModalCtrl__closeModalAndRedirect(route) {
    this.closeModal();
    $location.path(route);
  };

  /**
   * Wizard's continue function, runs the next action
   * @param  {String} action [description]
   */
  this.continue = function AddDependentModalCtrl__continue(action) {
    if (!action || typeof action !== 'string') {
      action = 'finished';
    }

    var completedStep = !WizardService.sidebar || WizardService.subStepIndex >= (WizardService.sidebar.length - 1);

    // if we've completed the entire step, return a function that will be invoked for the "completed" state
    // otherwise just increment the subStepIndex
    var callback = completedStep ? function() { return Ctrl.hook('completed', Ctrl.completeStep); } : this.completeSubStep;
    this.hook(action, callback);
  };

  /**
   * Wizard's finished function
   * Function gets called when all steps have been completed
   * @param  {Object}   self this.prototype that gets lost within context
   * @param  {Function} next Function to execute once the coverage has been added
   */
  this.finished = function AddDependentModalCtrl__finished(self, next) {
    // reset error fields
    Ctrl.friendlyErrors  = [];
    Ctrl.formData.errors = [];
    var employeeSSN = Ctrl.wizard.data.employeeInfo ? Ctrl.wizard.data.employeeInfo.ssn : Ctrl.wizard.employeeInfo.ssn;
    var dependentsSSNs = Ctrl.wizard.data.employeeInfo ? Ctrl.wizard.data.employeeInfo.dependents.map(_.property('ssn')) : Ctrl.wizard.employeeInfo.dependents.map(_.property('ssn'));

    var validations = [
      FormFactory.validations.ssn(Ctrl.newDep.ssn, dependentsSSNs.push(employeeSSN)),
      FormFactory.validations.date(Ctrl.newDep.dob, {past: 'DOBPast'}),
      FormFactory.validations.date(Ctrl.newDep.dob, {after: 'DOBWithin', compareDate: moment().subtract(100, 'years')}),
    ];

    // Qualifying Event
    if (Ctrl.newDep.enrollingType === 'QE') {
      validations.push(
        FormFactory.validations.date(Ctrl.newDep.dateOfEvent, {key: 'eventDate', before: 'eventDatePast', compareDate: moment().add(3, 'months'), condition: !Ctrl.wizard.data.employeeInfo.isInEligiblePeriod}),
        FormFactory.validations.date(Ctrl.newDep.dateOfEvent, {key: 'eventDate', after: 'eventDateAfter', compareDate: moment().subtract(3, 'months'), condition: !Ctrl.wizard.data.employeeInfo.isInEligiblePeriod})
      );

      if (Ctrl.newDep.qualifyingLifeEvent) {
        Ctrl.newDep.qualifyingEvent = Ctrl.newDep.qualifyingLifeEvent.eventCode;
      }

      if (Ctrl.newDep.dateOfEvent && Ctrl.newDep.dateOfEvent.dateSeg3) {
        Ctrl.newDep.effectiveDate = UtilFactory.dropdownValuesToDateString(Ctrl.newDep.dateOfEvent);
        Ctrl.wizard.data.newDepEffectiveDate =  Ctrl.newDep.effectiveDate;
      }
    }

    var errors = FormFactory.validate(Ctrl.wizard.form, {validations: validations});

    if (_.keys(errors.errors).length > 0) {
      Ctrl.friendlyErrors  = errors.friendlyErrors;
      Ctrl.formData.errors = errors.errors;
      return false;
    }

    var dob = dottie.get(Ctrl.newDep, 'dob', {});
    if (dob && dob.dateSeg3) {
      Ctrl.newDep.dobReal = UtilFactory.dropdownValuesToDateString(dob);
    }

    if (!_.isUndefined(Ctrl.wizard.employeeInfo) && _.isUndefined(Ctrl.newDep.effectiveDate)) {
      Ctrl.newDep.effectiveDate = dottie.get(Ctrl.wizard.data, 'employeeInfo.effectiveDate');
    }

    Ctrl.wizard.data.newDep = Ctrl.convertDep(Ctrl.newDep);

    // for when we add another dep...
    if (!_.isUndefined(Ctrl.wizard.employeeInfo) && _.isUndefined(Ctrl.wizard.data.employeeInfo)) {
      Ctrl.wizard.data.employeeInfo = Ctrl.wizard.employeeInfo;
    }

    if (_.isUndefined(Ctrl.wizard.employeeInfo)) {
      Ctrl.wizard.employeeInfo = angular.copy(Ctrl.wizard.data.employeeInfo);
    }

    DependentFactory.insert(Ctrl.wizard.data.employeeInfo.id, Ctrl.wizard.data.newDep).then(function(success) {
      // add meta data for the dependent
      Ctrl.wizard.data.employeeInfo.dependents.push(DependentFactory.mapCensusProperties(success));

      Ctrl.wizard.data.modalMetaData = Ctrl.wizard.data.modalMetaData || {};
      Ctrl.wizard.data.modalMetaData.employeeInfo = angular.copy(Ctrl.wizard.data.employeeInfo);
      Ctrl.wizard.data.newDep.id = success.id;
      Ctrl.wizard.data.newDep.memberEntityId = success.memberEntityId;
      next();
    }, populateErrors);

    function populateErrors(res) {
      var errors = $log.errorHandler(res, {allow409: true});
      Ctrl.newDep.errors   = errors.errors;
      Ctrl.formData.errors = errors.errors;
      Ctrl.friendlyErrors  = errors.friendlyErrors;

      if (res.status >= 400) {
        if (res.data.message.match(/Annual Enrollment/ig)) {
          Ctrl.formData.errors.annualEnrollmentDate = true;
        }
      }
    }
  };

  this.removeError = function() {
    for (var i = 0; i < arguments.length; i++) {
      Ctrl.formData = $log.removeErrors(Ctrl.formData, arguments[i]);
    }
  };

  /**
   * Resets our form to pristine
   */
  this.defaultForm = function AddDependentModalCtrl__defaultForm() {
    // reset the form data object
    Ctrl.newDep = {};

    // set the form status
    Ctrl.wizard.form.$setPristine();
  };

  /**
   * Map dependent object for the DependentFactory.insert function
   * @param  {Object} data The dependent's data object
   * @return {Object}      Mapped of new values from `data`
   */
  this.convertDep = function AddDependentModalCtrl__convertDep(data) {
    var dependent = _.pick(data, ['firstName', 'lastName', 'gender', 'relationship', 'memberEntityId']);
    dependent.ssn = data.ssn;
    dependent.dob = data.dobReal;

    dependent.effectiveDate = dottie.get(Ctrl.wizard.data, 'employeeInfo.effectiveDate');

    if (data.enrollingType) {
      WizardService.data.enrollingType = data.enrollingType;
      dependent.enrollingType = data.enrollingType;
    }

    if (data.qualifyingLifeEvent) {
      WizardService.data.qualifyingEvent = data.qualifyingLifeEvent.eventCode;
    }

    if (data.dateOfEvent) {
      if (_.isString(data.dateOfEvent)) {
        dependent.effectiveDate = data.dateOfEvent;
      }
      else if (_.isObject(data.dateOfEvent) && data.dateOfEvent.dateSeg1) {
        dependent.effectiveDate = UtilFactory.dropdownValuesToDateString(data.dateOfEvent);
      }
//       if (data.qualifyingLifeEvent && dependent.effectiveDate) {
//         dependent.qualifyingEventDate = dependent.effectiveDate;
//       }
    }

    switch (data.relationship) {
    case "spouse":
      dependent.relationship = "SP";
      break;
    case "child":
      dependent.relationship = "CH";
      break;
    }

    return dependent;
  }

  /**
   * Closes modal or pops up confirmation based off of our
   * current location within filling out the form
   * @param  {Boolean} confirmation If false, skips asking for confirmation as to whether or not we want to cancel
   */
  this.cancel = function AddDependentModalCtrl__cancel(confirmation) {
    // if we're on the last summary page, just close...
    var noSideBar       = !_.isArray(WizardService.sidebar) || WizardService.sidebar.length < 1;
    var atFinalStep     = WizardService.stepIndex    === WizardService.steps.length - 1;
    var atFinalSubStep  = WizardService.subStepIndex === WizardService.sidebar.length - 1;

    var atLastStep = atFinalSubStep && atFinalStep;

    if (noSideBar || atLastStep) {
      confirmation = true;
    }

    if (confirmation === false) {
      this.showCancel = false;
    }
    else if (confirmation === true) {
      this.closeModal();
    } else {
      this.showCancel = true;
    }
  };

  Ctrl.wizard.sidebar = [
    {name: 'Add Dependent'},
    {name: 'Summary'}
  ];

  Ctrl.wizard.currentStep = this;
}

module.exports = ['$rootScope', '$location', 'LogFactory', 'WizardService', 'EmployeeFactory', 'DependentFactory', 'FormFactory', 'UtilFactory', AddDependentModalCtrl];
