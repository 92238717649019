
"use strict";

global.$ = global.jQuery = window.$ = window.jQuery = require('jquery');
require('angular');
require('angular-route');
require('angular-resource');
require('angular-sanitize');
require('angular-mocks');
require('angular-cookies');
require('../lib/angular-shims-placeholder.min.js');
require('../lib/angular-idle.js');
require('../lib/checklist-model.js');
require('../lib/capitalize');
require('../lib/worker');

// MomentJS for Angular
global.moment = require('moment');
require('angular-moment');

// Angular directives for ngTables
require('ng-table/dist/ng-table');

// Angular directive for ngCurrency
require('ng-currency/dist/ng-currency');

// Angular directives for Bootstrap (e.g. for opening modals)
require('angular-ui-bootstrap/dist/ui-bootstrap');
//require('../lib/angular-bootstrap/ui-bootstrap-0.13.0-SNAPSHOT.min');

// Angular directives for ui-select
//require('ui-select/dist/select.js');
require('../lib/select');


// The global namespace
global.MOB     = window.MOB = global.MOB || window.MOB || {};
global.angular = window.angular;

window.assert = function assert(about, value, expected) {
  var message;
  expected = expected || true;
  message = "Test %verb: '%about'. Expected %expectedValue; received %value"
    .replace('%about', about)
    .replace('%expectedValue', expected)
    .replace('%value', value);

  if (value === expected) {
    console.log(message.replace('%verb', 'Passed'));
  } else {
    console.error(message.replace('%verb', 'Failed'));
  }
};

// Load the primary Angular module
MOB.app = angular.module('lifeMapGAPortal', ['ui.bootstrap', 'ngRoute', 'ngResource', 'ngSanitize', 'ngTable', 'ng-currency', 'angularMoment', 'ngCookies',
  'ngIdle', 'checklist-model', 'ui.select', 'angular-capitalize-filter', 'ng.shims.placeholder']);

// set idle time in seconds
MOB.app.constant('IDLE_TIME', (60 * 15));

// Enable/disable debug logs
MOB.app.constant('DEBUG', true);

require('./constants');
require('./controllers');
require('./directives');
require('./services');
require('./filters');

// Set up routes and site-wide Angular configuration
MOB.app.config(require('./angularConfig'));

// Setup uiSelectConfig
MOB.app.config(require('./config/SetUISelectConfig'));

// This will emit/broadcast an event whenever a value changes within ui-select from being blurred
MOB.app.config(require('./config/UISelectChange'));

// Watch for idle
MOB.app.run(['$idle', function($idle) {
  $idle.watch();
}]);

// Refresh our token when necessary
MOB.app.run(require('./config/RefreshTokenCheck'));

// Handle authentication failures by either refreshing access token or redirecting to login
MOB.app.run(require('./config/RefreshToken'));

// Refresh authentication token on page load if a session exists.
MOB.app.run(require('./config/AuthRefresh'));

module.exports = [MOB];
