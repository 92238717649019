
var _ = require('lodash');

function CoverageFactory($q, RequestFactory, UtilFactory, SALARY_FREQUENCIES) {
  /**
   * Tells us whether or not the coverage is for members
   * @param {Object} coverage Specific coverage
   * @return {Boolean} True if this coverage is for members
   */
  this.isMandatory = function CoverageFactory__isMandatory(coverage) {
    return _.isObject(coverage) && !!coverage.isMandatory;
  }

  /**
   * Tells us whether or not the coverage is an accident
   * @param {Object} selectedClass Specific coverage
   * @return {Boolean} True if this coverage is an accident
   */
  this.isAccident = function CoverageFactory__isAccident(selectedClass) {
    return _.isObject(selectedClass) && _.isString(selectedClass.productType) && selectedClass.productType.toLowerCase() === 'accident';
  }

  /**
   * Alias for `isAccident`
   */
  this.isAnAccident = this.isAccident;

  /**
   * Tells us whether or not the coverage is for the employee
   * @param {Object} selectedClass Specific coverage
   * @return {Boolean} True if this coverage is for the employee
   */
  this.isForEmployee = function CoverageFactory__isForEmployee(selectedClass) {
    return _.isObject(selectedClass) && _.isString(selectedClass.coveredPersonType) && selectedClass.coveredPersonType.toLowerCase() === 'employee';
  }

  /**
   * Tells us whether or not the coverage is for families
   * @param {Object} selectedClass Specific coverage
   * @return {Boolean} True if this coverage is for families
   */
  this.isForFamilies = function CoverageFactory__isForFamilies(selectedClass) {
    return _.isObject(selectedClass) && _.isString(selectedClass.coveredPersonType) && selectedClass.coveredPersonType.toLowerCase() === 'family';
  }

  /**
   * Tells us whether or not the coverage is for EmployeeDependents
   * @param {Object} selectedClass Specific coverage
   * @return {Boolean} True if this coverage is for EmployeeDependents
   */
  this.isForEmployeeDependents = function CoverageFactory__isForEmployeeDependents(selectedClass) {
    return _.isObject(selectedClass) && _.isString(selectedClass.coveredPersonType) && selectedClass.coveredPersonType.toLowerCase() === 'employeedependent';
  }

  /**
   * Tells us whether or not the coverage is for relatives or for the employee
   * @param {Object} selectedClass Specific coverage
   * @return {Boolean} True if this coverage is for dependents
   */
  this.isForRelatives = function CoverageFactory__isForRelatives(selectedClass) {
    selectedClass = _.isObject(selectedClass) ? selectedClass : {};
    var hasFields = selectedClass.allowChildren === 1 && selectedClass.allowSpouse === 1;

    if (!_.isString(selectedClass.coveredPersonType) && !hasFields) {
      return false;
    }

    var hasCoveredType = ['spouse', 'dependent', 'dependents', 'child', 'children'].indexOf((selectedClass.coveredPersonType || '').toLowerCase()) > -1;

    return hasCoveredType || hasFields;
  }

  /**
   * Tells us whether or not the coverage is for dependents or for the employee
   * @param {Object} selectedClass Specific coverage
   * @return {Boolean} True if this coverage is for dependents
   */
  this.isForDependents = function CoverageFactory__isForDependents(selectedClass) {
    if (!_.isObject(selectedClass) || !_.isString(selectedClass.coveredPersonType)) {
      return false;
    }

    return ['dependent', 'dependents'].indexOf(selectedClass.coveredPersonType.toLowerCase()) > -1;
  }

  /**
   * Tells us whether or not the coverage is for a children or for the employee
   * @param {Object} selectedClass Specific coverage
   * @return {Boolean} True if this coverage is for children
   */
  this.isForChildren = function CoverageFactory__isForChildren(selectedClass) {
    if (!_.isObject(selectedClass) || !_.isString(selectedClass.coveredPersonType)) {
      return false;
    }

    return selectedClass.coveredPersonType.toLowerCase() == "child" || selectedClass.coveredPersonType.toLowerCase() == "children";
  }

  /**
   * Tells us whether or not the coverage is for a spouse or for the employee
   * @param {Object} selectedClass Specific coverage
   * @return {Boolean} True if this coverage is for a spouse
   */
  this.isForSpouse = function CoverageFactory__isForSpouse(selectedClass) {
    if (!_.isObject(selectedClass) || _.isUndefined(selectedClass.coveredPersonType) || _.isNull(selectedClass.coveredPersonType)) {
      return false;
    }

    return selectedClass.coveredPersonType.toLowerCase() == "spouse";
  }

  /**
   * Tells us whether or not the coverage requires another coverage to be elected in order to be elected
   * @param {Object} coverage Specific coverage
   * @return {Boolean} True if this coverage requires another coverage to be elected into
   */
  this.isRequiresCoverage = function CoverageFactory__isForSpouse(coverage) {
    if (!_.isObject(coverage) || _.isUndefined(coverage.requiresCoverage) || _.isNull(coverage.requiresCoverage)) {
      return false;
    }

    return coverage.requiresCoverage.length > 0;
  }

  /**
   * Tells us whether or not the coverage is salary based
   * @param {Object} coverage Specific coverage
   * @return {Boolean} True if this coverage is salary based
   */
  this.isSalaryBased = function CoverageFactory__isSalaryBased(coverage) {
    if (!_.isString(coverage.volumeCalculationType)) return undefined;
    return _.includes(['S', 'G', 'T', 'H', 'K'], coverage.volumeCalculationType.toUpperCase());
  }

  /**
   * Returns the rounded salary for a specific benefit
   * @param   {Object} coverage Specific benefit
   * @param   {Number} multipliedSalary Salary multiplier
   * @return  {Number}
  */
  this.roundedSalary = function CoverageFactory__roundedSalary(coverage, multipliedSalary) {
    coverage = _.isObject(coverage) ? coverage : {};
    coverage.volumeRoundingAmount = coverage.volumeRoundingAmount || 1;
    coverage.volumeRoundingAmount = parseFloat(coverage.volumeRoundingAmount);

    // round appropriately based on volumeRoundingType and volumeRoundingAmount
    switch(coverage.volumeRoundingType) {
      // round to nearest
      case "N":
      case "n":
        var floor = Math.floor(multipliedSalary / coverage.volumeRoundingAmount) * coverage.volumeRoundingAmount,
          ceil = Math.ceil(multipliedSalary / coverage.volumeRoundingAmount) * coverage.volumeRoundingAmount,
          floorDiff = (multipliedSalary - floor).toFixed(2),
          ceilDiff = (ceil - multipliedSalary).toFixed(2);

        if (floorDiff < ceilDiff) {
          return parseFloat(roundDown(multipliedSalary, coverage.volumeRoundingAmount));
        } else {
          // this will also include the case that floorDiff and ceilDiff are equal (aka number is in the middle) - round up
          return parseFloat(roundUp(multipliedSalary, coverage.volumeRoundingAmount));
        }
        break;
      // round up
      case "U":
      case "u":
        return parseFloat(roundUp(multipliedSalary, coverage.volumeRoundingAmount));
      // round down
      case "D":
      case "d":
        return parseFloat(roundDown(multipliedSalary, coverage.volumeRoundingAmount));
      // Default is no rounding
      default:
        return parseFloat(multipliedSalary);
    }
  }

  /**
   * Gets volume options for VolumeSelect templates
   * @param  {Object}  coverage
   * @param  {Boolean} guaranteedIssue Whether or not the current coverage has a guaranteed issue limit
   * @return {Array}   array of volume options
   */
  this.getVolumes = function CoverageFactory__getVolumes(coverage, employee) {
    var result = [],
      volume = parseFloat(coverage.volumeMinimum) || 0,
      volumeCalcType = coverage.volumeCalculationType.toUpperCase(),
      step = coverage.volumeIncrement,
      max, cap;

    var deferred = $q.defer();

    coverage = this.cleanseVolume(coverage);

    // Determine if the guaranteed issue limit (if one exists) or the volume maximum should be the cap
    if (coverage.guaranteedIssue < coverage.volumeMaximum) {
      cap = coverage.guaranteedIssue;
    } else {
      cap = coverage.volumeMaximum;
    }

    if (volumeCalcType === "U") {
      max = parseFloat(cap);
    } else if (volumeCalcType === "M") {
      if (isNaN(parseFloat(coverage.salaryMultiplier))) {
        return null;
      }

      var multiplier = {A: 1, B: 26, H: 52 * employee.weeklyHours, M: 12, S: 24, W: 52 },
      annualSalary = (parseFloat(employee.salary) * multiplier[employee.salaryFreq]).toFixed(2);
      var multipliedSalary = parseFloat(annualSalary) * parseFloat(coverage.salaryMultiplier) || 0;

      var salaryMax = this.roundedSalary(coverage, multipliedSalary);

      // check to make sure cap is not exceeded
      if (cap && salaryMax > cap) {
        max = cap;
      } else {
        max = salaryMax;
      }
    } else {
      return null;
    }

    var worker = new Worker("/static/javascripts/services/Worker.js");
    worker.postMessage([volume, step, max]);
    worker.onmessage = function(e) {
      if (angular.isString(e.data) && e.data === 'done') {
        deferred.resolve(result);
      } else {
        result.push(e.data);
      }
    }

    return deferred.promise;
  };

  /**
   * Converts the volumes correctly (from string to integers, etc.) within certain fields for volumes
   * @param  {Object} volume The volume to transform or cleanse
   * @return {Object}        The transformed volume
   */
  this.cleanseVolume = function CoverageFactory__cleanseVolume(volume) {
    var floats = ['guaranteedIssue', 'volumeMaximum', 'salaryMultiplier'];

    floats.push({
      key: 'volumeMinimum',
      defaultVal: 0
    });

    floats.forEach(function (key) {
      if (_.isString(key)) {
        volume[key] = parseFloat(volume[key]);
      }
      else if (_.isObject(key)) {
        volume[key.key] = parseFloat(volume[key.key]);

        if (isNaN(volume[key.key])) {
          volume[key.key] = _.isUndefined(key.defaultVal) ? volume[key.key] : key.defaultVal;
        }
      }
    });

    return volume;
  }

  /**
   * Returns the volume amount for a specific benefit
   * @param   {Object} coverage Specific benefit
   * @param   {Number} multipliedSalary Salary multiplier
   * @return  {Number}
  */
  this.getVolume = function CoverageFactory__getVolume(coverage, multipliedSalary) {
    var volume;

    switch((coverage.volumeCalculationType || '').toUpperCase()) {
      case "F":
        volume = parseFloat(coverage.flatVolume || coverage.volume);
        break;
      case "S":
        volume = this.roundedSalary(coverage, multipliedSalary);
        break;
      case "G":
        volume = parseFloat(multipliedSalary + coverage.volumeMinimum);
        break;
      case "T":
        volume = parseFloat(multipliedSalary - coverage.volumeMinimum);
        break;
      case "H":
        volume = this.roundedSalary(coverage, multipliedSalary) + coverage.volumeMinimum;
        break;
      case "K":
        volume = this.roundedSalary(coverage, multipliedSalary) - coverage.volumeMinimum;
        break;
      default:
        return "";
    }

    return volume;
  };

  /**
   * Gets volume calculation for VolumeDisplay templates
   * @param  {Object}  coverage Benefit object
   * @param  {Object}  employee Current employee object
   * @return {Number}  calculated volume
   */
  this.findVolume = function CoverageFactory__findVolume(coverage, employee, originalCoverage) {
    var multipliedSalary, volume, cap;

    coverage = this.cleanseVolume(coverage);

    // If the coverage's the old volume is greater than the new GI, use the old volume for the GI.
    if (originalCoverage && originalCoverage.volume && originalCoverage.volume > coverage.guaranteedIssue) {
        cap = originalCoverage.volume;
    }

    // Determine if the guaranteed issue limit (if one exists) or the volume maximum should be the cap
    if (coverage.guaranteedIssue) {
      cap = coverage.guaranteedIssue;
      if (coverage.volumeMaximum && coverage.volumeMaximum < coverage.guaranteedIssue) {
        cap = coverage.volumeMaximum;
      }
    } else if(coverage.volumeMaximum){
      cap = coverage.volumeMaximum;
    }

    multipliedSalary = parseFloat(employee.salary) * parseFloat(coverage.salaryMultiplier) || 0;

    // if salary is recorded monthly, multiply by 12 to account for this
    if (employee.salaryFreq && employee.salaryFreq.toUpperCase() === 'M') {
      multipliedSalary *= 12;
    }

    volume = this.getVolume(coverage, multipliedSalary);
    if (_.isString(coverage.salaryCalculationType)) {
      volume = UtilFactory.convertSalaryFromAnnual(volume, coverage.salaryCalculationType, employee.weeklyHours);

      if (this.canRound(coverage)) {
        volume = this.roundedSalary(coverage, volume);
      }
    }

    var min = parseFloat(coverage.volumeMinimum || 0);
    if (!isNaN(min) && min > 0 && min > volume) {
      volume = min;
    }

    if (cap < volume) {
      volume = cap;
    }

    volume = this.canRound(coverage) ? volume : Math.floor(volume);

    return volume;
  };

  /**
   * Tells us whether or not we should be rounding...
   * @param  {Object} coverage Coverage's data class/object
   * @return {Bool}
   */
  this.canRound = function CoverageFactory__canRound(coverage) {
    return ['T','t','G','g'].indexOf(coverage.volumeCalculationType) === -1;
  }

  /**
   * Returns the coverage's display rules
   * @param  {Object} coverage      The coverage's data object
   * @return {Object}               Rulesets for what should be displayed
   */
  this.getCoverageDisplayRules = function CoverageFactory__getCoverageDisplayRules(coverage) {
    var results = {
      flat:                     false,
      showIncrement:            false,
      showDecimals:             false,
      roundBefore:              false,
      classOnly:                false,
      employeeCheckbox:         false,
      disableEmployeeCheckbox:  true,
      employeeCheckboxChecked:  false,
      isSpouseOnly:             false,
      salaryBasedMaximum:       false,
      qualifyingLifeEvent:      false
    };

    // F = Flat Amount  - show calculated volume
    // S = Salary Based - show calculated volume
    // G = Salary plus flat amount (no rounding) - show calculated volume
    // T = Salary minus flat amount (no rounding) - show calculated volume
    // H = Salary plus flat amount. Rounding is performed before adding the flat volume to the factored salary volume in order to obtain the billed amount. - show calculated volume
    // K = Salary minus flat amount. Rounding is performed before subtracting the flat volume from the factored salary volume in order to obtain the billed amount. - show calculated volume
    // U = Elected units - show volume dropdown with incremented option
    // M = Elected units with salary-based maximum - show volume dropdown with incremented option

    // if the coverage is a Family or EmployeeDependent type, then show the employee checkbox
    if (this.isForFamilies(coverage) || this.isForEmployeeDependents(coverage)) {
      results.employeeCheckbox = true;
      if (this.isForFamilies(coverage)) {
        results.disableEmployeeCheckbox = false;
      }
    }

    // If the coverage is of type 'employeeDependent' or the employee is in the coveredPeople array then have the employee checkbox checked
    var employee = _.filter(coverage.coveredPeople, function (obj) {
      return obj.relationship && (obj.relationship.toUpperCase() === "EM" || obj.relationship.toUpperCase() === "EE");
    });
    if (this.isForEmployeeDependents(coverage) || employee.length) {
      results.employeeCheckboxChecked = true;
    }

    if (this.isForSpouse(coverage)) {
      results.isSpouseOnly = true;
    }

    switch((coverage.volumeCalculationType || '').toUpperCase()) {
      case 'F':
      case 'S':
        results.flat = true;
        break;
      case 'H':
      case 'K':
        results.roundBefore = true;
        break;
      case 'U':
        results.showIncrement = true;
        break;
      case 'M':
        results.showIncrement = true;
        results.salaryBasedMaximum = true;
        break;
      case 'G':
      case 'T':
        results.showDecimals = true;
        break;
      default:
        results.classOnly = true;
        break;
    }

    // coverage.qualifyingEventRequired is determined by GroupCoverage.isQualifyingEventRequired in DB
    if (coverage.qualifyingEventRequired == 1) {
      results.qualifyingLifeEvent = true;
    }

    return results;
  }
  /**
   * Returns the salary type for a coverage
   * @param  {Object} coverage      Coverage object
   * @return {String}               Coverage's calculation type
   */
  this.getCoverageSalaryType = function CoverageFactory__getCoverageSalaryType(coverage) {
    switch (coverage.productType) {
      case "STD":
      return 'weekly';
      case "LTD":
      return 'monthly';
      default:
      return 'annually';
    }
  }

  /**
   * Returns coverage's name
   * @param  {Object} coverageClass Benefit entity
   * @return {String}               Returns the correct dropdown format/name for the benefit
  */
  this.getCoverageName = function CoverageFactory__getCoverageName(coverageClass) {
    // var coverage = populateClassNames(coverageClass);
    return coverageClass.dropdownText;
  }

  /**
   * @param {Array} riders Optional riders
   * @param {Array} autoRiders Riders that are auto-selected
   * @return {Array} List of all user-selected and auto-selected riders
  */
  this.getCheckedRiders = function CoverageFactory__getCheckedRiders(riders, autoRiders) {
    var checkedRiders = riders.filter(function (rider) {
      return rider.checked === true;
    });
    return checkedRiders.concat(autoRiders);
  }

  /**
   * @param  {Object} coverage Coverage object with riders
   * @return {Array}           Returns coverage riders
  */
  this.getRiders = function CoverageFactory__getRiders(coverage) {
    if (typeof coverage !== 'object' || !angular.isArray(coverage.riders)) {
      return [];
    }

    return coverage.riders.map(function (rider) {
      rider.checked = !!rider.autoSelect && (rider.autoSelect === true || rider.autoSelect === 1);

      if (!_.isUndefined(rider.name) && _.isUndefined(rider.type)) {
        rider.type = rider.name;
      }

      if (!_.isUndefined(rider.type) && _.isUndefined(rider.name)) {
        rider.name = rider.type;
      }

      return rider;
    });
  };

  /**
   * Returns the coverages for a dependent
   * @param  {Number} employeeId Employee's ID/username
   * @param  {Number} depId      Dependent's ID
   * @return {Object}            Response
   */
  this.findByDependent = function CoverageFactory__findByDependent(employeeId, depId) {
    return RequestFactory.send('/api/employee/' + employeeId + '/dependent/' + depId + '/coverages', 'data');
  };

  /**
   * Returns an employee's coverages
   * @param  {String|Number} employeeId Employee's ID
   * @return {Promise}                  Returns RequestFactory's `response.data` key
   */
  this.findByEmployee = function CoverageFactory__findByEmployee(employeeId) {
    return RequestFactory.send('/api/employee/' + employeeId, 'data.electedCoverages');
  };

  /**
  * Gets a list of pending changes for a specific employee
  * @param {Number} employeeID
  * @return {Array} List of pending changes
  */
  this.findPendingChanges = function CoverageFactory__findPendingChanges(employeeId) {
    return RequestFactory.send('/api/employee/' + employeeId + '/pendingChanges', 'data');
  };

  /**
  * Gets a list of available coverages for a specific employee
  * @param {Number} employeeID
  * @return {Array} List of coverages
  */
  this.findAvailableCoverages = function CoverageFactory__findAvailableCoverages(employeeId) {
    return RequestFactory.send('/api/employee/' + employeeId + '/availableCoverages', 'data.coverages');
  };

  /**
  * Gets a list of available coverages for a specific dependent
  * @param {Number} employeeID
  * @return {Array} List of coverages
  */
  this.findAvailableCoveragesByDependent = function CoverageFactory__findAvailableCoveragesByDependent(dependentId) {
    return RequestFactory.send('/api/employee/dependents/' + dependentId + '/availableCoverages', 'data.coverages');
  };

  /**
   * Returns information about a coverage via it's ID
   * @param  {String|Number} employeeId   Employee's ID
   * @param  {String}        coverageId Coverage's ID
   * @return {Promise}       Returns RequestFactory's `response.data` key
   */
  this.findByBenefitId = function CoverageFactory__findByCode(employeeId, benefitId) {
    return RequestFactory.send('/api/employee/' + employeeId + '/benefitOptions/' + benefitId, 'data');
  };

  /**
  * @param {Array} riders Optional riders
  * @param {Array} autoRiders Riders that are auto-selected
  * @return {Array} List of all user-selected and auto-selected riders
  */
  this.getCheckedRiders = function CoverageFactory__getCheckedRiders(riders, autoRiders) {
    var checkedRiders = riders.filter(function (rider) {
      return rider.checked === true;
    });

    return checkedRiders.concat(autoRiders);
  }

  this.getRidersAsString = function CoverageFactory__getRidersAsString(riders) {
    return _.map(riders, 'type').join(', ');
  }

  /**
  * Returns full relationship noun by looking at serverCode
  * @param  {String} serverCode
  * @return {String} A friendly noun representing the dependent's relationship to the policyholder.
  */
  this.getRelationshipByCode = function CoverageFactory__getRelationshipByCode(serverCode) {
    var lookupTable = {
      CH: 'Child',
      SP: 'Spouse'
    };

    return lookupTable[serverCode] || 'Other';
  };

  /**
   * removes a coverage
   *
   * @param {string} terminationDate YYYY-MM-DD format of the termination date
   * @param {integer} employeeId Employee's ID
   * @param {integer} coverageId Coverage's ID/numeric value
  */
  this.remove = function CoverageFactory__remove(terminationDate, employeeId, coverageId) {
    return RequestFactory.send({
      url: "/api/employee/" + employeeId + "/coverages/" + coverageId,
      method: 'PUT',
      data: {
        terminationDate: terminationDate,
        realMethod: 'delete'
      }
    });
  };

  /**
   * removes a coverage from a dependent
   *
   * @param {Number} coverageId Coverage's ID/numeric value
   * @param {Number} dependentId Dependent's ID
   * @param {String} terminationDate YYYY-MM-DD format of the termination date
   */
   this.removeDep = function CoverageFactory__removeDep(coverageId, dependentId, terminationDate) {
    return RequestFactory.send({
      url: "/api/employee/dependent/coverages/" + coverageId,
      // url: "/api/employee/coverages/" + coverageId,
      method: 'POST',
      data: {
        terminationDate: terminationDate,
        realMethod: 'delete'
      }
    });
   };

  /**
   * removes a pending benefit
   *
   * @param {integer} pendingId Pending Change's ID/numeric value
  */
  this.removePendingChange = function CoverageFactory__removePendingChange(pendingId) {
    return RequestFactory.send({
      url: "/api/pendingcoverages/" + pendingId,
      method: 'DELETE'
    });
  };

  /**
   * Adds a new coverage/coverages
   *
   * @param coverage
   * the object containing the required data to create a new coverage/coverages
   *
   * @param employeeId
   * the employee's ID
  */
  this.insert = function CoverageFactory__insert(coverage, employeeId) {
    Ctrl = this;
    employeeId = parseInt(employeeId, 10);
    var coveragesToInsert = [];

    if (!angular.isArray(coverage)) {
      coveragesToInsert = [coverage];
    } else {
      coveragesToInsert = coverage;
    }

    coveragesToInsert = coveragesToInsert.map(function(coverage) {
      var covData       = _.pick(angular.copy(coverage), ['enrollingType', 'benefitID', 'volume', 'effectiveDate', 'coveredPeople', 'riders']);
      var isForFamilies = Ctrl.isForFamilies(coverage);

      if (isNaN(parseFloat(covData.volume)) || covData.volume===0) {
        covData = _.omit(angular.copy(covData), ['volume']);
        //covData.volume = 0;
      }

      if (covData.dependents && !isForFamilies) {
        covData = _.omit(angular.copy(covData), ['dependents']);
      }
      else if (!covData.coveredPeople && isForFamilies) {
        covData.coveredPeople = [];
      }
      if (coverage.qualifyingEventTypeCode) {
      	covData.qualifyingEventTypeCode = coverage.qualifyingEventTypeCode;
      }

      if (_.isArray(covData.coveredPeople) && covData.coveredPeople.length > 0) {
        covData.coveredPeople = _.map(angular.copy(covData.coveredPeople), function (dep) {
          return {id: dep.id};
        });
      }

      if (covData.riders) {
        if (covData.riders.length) {
          covData.riders = _.map(angular.copy(covData.riders), function (rider) {
            return _.pick(rider, 'id');
          });
        } else {
          // We don't want to send an empty riders array to the backend when creating a new coverage
          delete covData.riders;
        }
      }

      return covData;
    });

    return RequestFactory.send({
      url: "/api/employee/" + (employeeId.toString()) + "/coverages",
      method: 'POST',
      data: { coverages: coveragesToInsert }
    });
  };

  /**
   * Adds a coverage to a dependent
   * @param {Number} employeeId Employee's ID/username
   * @param {Number} dependentId Dependent's ID
   * @param {Object} data Coverage data
   * @return {Object} Response from ajax request
   */
  this.insertDep = function CoverageFactory__insertDep(employeeId, dependentId, data) {

    var covData = _.pick(data, ['enrollingType', 'benefitID', 'volume', 'effectiveDate', 'coveredPeople', 'riders']);

    if (covData.volume === 0) {
    	delete covData.volume;
    }

    if (data.qualifyingEvent) {
    	covData.qualifyingEventTypeCode = data.qualifyingEvent;
    }

    if (covData.riders) {
      if (covData.riders.length) {
        covData.riders = _.map(angular.copy(covData.riders), function (rider) {
          return _.pick(rider, 'id');
        });
      } else {
        // We don't want to send an empty riders array to the backend when creating a new coverage
        delete covData.riders;
      }
    }

    return RequestFactory.send({
      url: "/api/employee/" + employeeId + "/dependent/" + dependentId + "/coverages",
      method: 'POST',
      data: covData
    });
  };

  /**
   * Updates a coverage for a dependent
   * @param {Number} coverageId Coverage's ID
   * @param {Number} dependentId Dependent's ID
   * @param {Object} coverage Coverage data
   * @return {Object} Response from ajax request
   */
  this.updateDep = function CoverageFactory__updateDep(coverageId, dependentId, coverage) {
    coverage.benefitId = coverage.benefitID;
    var covData = _.pick(coverage, ['enrollingType', 'benefitId', 'volume', 'effectiveDate', 'riders']);

    if (isNaN(parseFloat(covData.volume))) {
      covData.volume = 0;
    }

    if (covData.riders) {
      covData.riders = _.map(angular.copy(covData.riders), function (rider) {
        return _.pick(rider, 'id');
      });
    }

    return RequestFactory.send({
      url: "/api/employee/dependent/coverages/" + coverageId,
      method: 'PUT',
      data: covData
    });
  };

  /**
   * updates a coverage
   *
   * @param coverage
   * the object containing the required data to update a coverage
   *
   * @param employeeId
   * the employee's id number
   *
   * @param coverageId
   * the coverage's id number
   *
  */
  this.update = function CoverageFactory__update(coverage, employeeId, coverageId) {
    var covData = _.pick(coverage, ['enrollingType', 'benefitID', 'volume', 'effectiveDate', 'coveredPeople', 'removedPeople', 'riders', 'qualifyingEventTypeCode']);

    if (isNaN(parseFloat(covData.volume))|| covData.volume==0) {
      //covData.volume = 0;
      covData = _.omit(angular.copy(covData), ['volume']);
    }

    if (covData.coveredPeople && covData.coveredPeople.length) {
      covData.coveredPeople = _.map(covData.coveredPeople, formatEnrollee);
    }

    if (covData.removedPeople && covData.removedPeople.length) {
      covData.removedPeople = _.map(covData.removedPeople, formatEnrollee);
    }

    function formatEnrollee(enrollee) {
      var formattedEnrollee = {id: enrollee.id};

      if (enrollee.requestedChangeDate) {
        formattedEnrollee.requestedChangeDate = UtilFactory.dropdownValuesToDateString(enrollee.requestedChangeDate);
      }

      if (enrollee.qualifyingLifeEvent) {
        formattedEnrollee.qualifyingEventTypeCode = enrollee.qualifyingLifeEvent.eventCode;
      }

      return formattedEnrollee;
    }

    if(covData.coveredPeople && covData.coveredPeople.length==0){
    	covData = _.omit(angular.copy(covData), ['coveredPeople']);
    }
    if(covData.removedPeople && covData.removedPeople.length==0){
    	covData = _.omit(angular.copy(covData), ['removedPeople']);
    }

    if (covData.riders && covData.riders.length>0) {
      covData.riders = _.map(angular.copy(covData.riders), function (rider) {
        return _.pick(rider, 'id');
      });
    } else {
    	// don't remove riders because an empty array is what signified removing the riders.
    	//covData = _.omit(angular.copy(covData), ['riders']);
    }

    return RequestFactory.send({
      url: "/api/employee/" + employeeId + "/coverages/" + coverageId,
      method: 'PUT',
      data: covData
    });
  };

  /**
   * Formats a GET coverage's response for a friendly-to-read format
   * this is useful for places like the summary info pages within the wizard.
   * @param  {Object[]} coverages An array of coverage's data objects
   * @return {Object[]}           An array of new mapped coverage's data objects
   */
  this.formatResponse = function CoverageFactory__formatResponse(coverages) {
    if (!_.isArray(coverages)) {
      coverages = !_.isUndefined(coverages) ? [coverages] : [];
    }

    var self = this;

    return _.map(coverages, function CoverageFactory__formatResponse__map_coverages(coverage) {
      var enrollees = _.isArray(coverage.enrollees) && coverage.enrollees.length > 0 ? coverage.enrollees : coverage.dependents;

      volume = coverage.volume;

      if (coverage.coverageDetail) {
        if (coverage.coverageDetail.coveredPeople) {
          enrollees = coverage.coverageDetail.coveredPeople;
        }
        if (coverage.coverageDetail.volume) {
          volume = coverage.coverageDetail.volume;
        }
      }

      coverage.enrolled       = self.getDependentsAsString(enrollees, coverage);
      coverage.displayRules   = self.getCoverageDisplayRules(coverage);
      coverage.displayVolume  = UtilFactory.getFormattedCurrency(volume);

      return coverage;
    });
  };

  /**
  * @param {Array} dependents Data from the server.
  * @param {Object} Coverage Optional. Coverage's data object.
  * @return {String} Human-readable text such as "Employee + Spouse"
  */
  this.getDependentsAsString = function CoverageFactory__getDependentsAsString(dependents, coverage, isForPending) {
    var self = this;

    if (_.isObject(coverage) && _.isString(coverage.coveredPersonType)) {
      var text = '';
      text = fetchDependents();
      return text;
    }

    function fetchDependents() {
      if (!dependents) {
        return "Employee";
      }

      if (dependents.length === 0 && !isForPending) {
        return "N/A";
      } else if(dependents.length === 0 && isForPending){
        return "-";
      }

      var result = [];

      dependents = _.map(dependents, function CoverageFactory__getDependentsAsString__map_dependents(dependent) {
        dependent.relationshipCode = self.getRelationshipCodeByNoun(dependent.relationship || '');
        return dependent;
      });

      var foundEmployee = _.find(dependents, function CoverageFactory__getDependentsAsString__find_employee_dependents(dependent) {
        return dependent.relationshipCode.toLowerCase() === '' || dependent.relationshipCode.toLowerCase() === 'em' || dependent.relationshipCode.toLowerCase() === 'ee' || dependent.type === 'Employee';
      });

      var foundSpouse = _.find(dependents, function CoverageFactory__getDependentsAsString__find_spouse_dependents(dependent) {
        return ['w','h','sp'].indexOf(('' + dependent.relationshipCode).toLowerCase()) > -1 || dependent.type === 'Spouse';
      });

      var foundChildren = _.find(dependents, function CoverageFactory__getDependentsAsString__find_children_dependents(dependent) {
        return dependent.relationshipCode.toLowerCase() === 'ch' || dependent.type === 'Child';
      });

      if (!_.isUndefined(foundEmployee)) {
        result.push('Employee');
      }
      if (!_.isUndefined(foundSpouse)) {
        result.push('Spouse');
      }
      if (!_.isUndefined(foundChildren)) {
        result.push('Child(ren)');
      }

      return result.join(', ');
    }

    return fetchDependents();
  };

  /**
  * @param {Array} dependents Data from the server.
  * @param {Object} Coverage Optional. Coverage's data object.
  * @return {Array} Human-readable text in an array such as ["Employee", "Spouse: Janet", "Children: Sam, Alex, Grace"]
  */
  this.getFriendlyDependentsArray = function CoverageFactory__getFriendlyDependentsArray(dependents, isForPending) {
    var self = this;

    if (!dependents) {
      return ["Employee"];
    }

    if (dependents.length === 0 && !isForPending) {
      return ["N/A"];
    } else if(dependents.length === 0 && isForPending){
      return ["-"];
    }

    var result = [];

    dependents = _
      .chain(dependents)
      .uniqBy('id')
      .map(function CoverageFactory__getFriendlyDependentsArray__map_dependents(dependent) {
        dependent.relationshipCode = self.getRelationshipCodeByNoun(dependent.relationship || '');
        return dependent;
      })
      .value();


    var foundEmployee = _.find(dependents, function CoverageFactory__getFriendlyDependentsArray__find_employee_dependents(dependent) {
      return dependent.relationshipCode.toLowerCase() === '' || dependent.relationshipCode.toLowerCase() === 'em' || dependent.relationshipCode.toLowerCase() === 'ee' || dependent.type === 'Employee';
    });

    var foundSpouse = _.find(dependents, function CoverageFactory__getFriendlyDependentsArray__find_spouse_dependents(dependent) {
      return ['w','h','sp'].indexOf(('' + dependent.relationshipCode).toLowerCase()) > -1 || dependent.type === 'Spouse';
    });

    var foundChildren = _.filter(dependents, function CoverageFactory__getFriendlyDependentsArray__find_children_dependents(dependent) {
      return dependent.relationshipCode.toLowerCase() === 'ch' || dependent.type === 'Child';
    });

    if (!_.isUndefined(foundEmployee)) {
      result.push('Employee');
    }

    if (!_.isUndefined(foundSpouse)) {
      result.push('Spouse: ' + foundSpouse.firstName);
    }

    if (!_.isUndefined(foundChildren) && foundChildren.length) {
      if (foundChildren.length === 1) {
        result.push('Child: ' + foundChildren[0].firstName);
      } else {
        var childrenNames = _.map(foundChildren, function(childObj) {
          return childObj.firstName;
        });
        result.push('Children: ' + childrenNames.join(', '));
      }
    }

    return result;
  };

  /**
   * Gets relationship code by it's noun
   * @param  {String} noun The relationship noun
   * @return {String}      Returns the relationship code if the noun was found, otherwise returns the noun back
   */
  this.getRelationshipCodeByNoun = function CoverageFactory__getRelationshipCodeByNoun(noun) {
    var map = {
      spouse:   'SP',
      child:    'CH',
      other:    'O',
      daughter: 'D',
      son:      'S',
      husband:  'H',
      wife:     'W'
    };

    var value = map[noun.toLowerCase()];

    if (angular.isDefined(value)) {
      return value;
    }

    return  noun;
  };

  /**
   * Populates the disclaimers for a coverage
   * @param  {Object} coverage The coverage
   * @return {Object}          Returns an object with disclaimer flags
   */
  this.populateDisclaimers = function CoverageFactory__populateDisclaimers(coverage, addingEmployee) {
    var obj = {};

    if (this.isForRelatives(coverage) || this.isForFamilies(coverage)) {
      obj.addDependentNew = true;
    }
    var isAgeReduction = parseInt(coverage.ageReduction, 10);
    if (isAgeReduction) {
      obj.ageReduction = true;
    }
    if (coverage.guaranteedIssue) {
      obj.guaranteedIssue = true;
    }
    if (!addingEmployee && coverage.productType && coverage.productType.toLowerCase() === "dental") {
      obj.lateEnrollment = true;
    }
    if (coverage.productType && coverage.productType.toLowerCase() === "vision") {
      obj.isVision = true;
    }

    return obj;
  };

  /**
   * Checks to see if the user has added all the coverages necessary for the plan required relationships
   * @param  {Object} relationshipInfo The object that defined the parent/child(ren) relationships
   * @return {Object}          Returns true/false if all the plans have been defined
   */
  this.isAllRequiredSatisfied = function CoverageFactory__isAllRequiredSatisfied(relationshipInfo) {
  	var isAllCoveragesAdded = true;
		_.forEach(relationshipInfo.childCoverages, function(cov) {
			if (_.includes(['GPREQMCH', "GCREQ", "GPREQ", "GPREQMCH","GPREQVLLTE"], cov.relationshipType)) {
				if(!cov.isWaitingPlanCreated ) {
					isAllCoveragesAdded =  false;
				}
			}
		});
		return isAllCoveragesAdded;
  };

  // # private

  function roundUp(salary, amount) {
    return round('up', salary, amount);
  }

  function roundDown(salary, amount) {
    return round('down', salary, amount);
  }

  function round(direction, salary, amount) {
    amount = parseFloat(amount);
    amount = isNaN(amount) ? 1 : amount;
    return Math[direction === 'up' ? 'ceil' : 'floor'](salary / amount) * amount;
  }

  return this;
}

module.exports = ['$q', 'RequestFactory', 'UtilFactory', 'SALARY_FREQUENCIES', CoverageFactory];
