
var ALLOWED_KEYS = [
  9,   // Tab
  16,  // Shift
  17,  // Control
  18,  // Alt / Option
  27,  // Esc
  91,  // Command

  // F-Keys
  112, 113, 114, 115, 116, 117, 118, 119, 120, 121, 122, 123
]

/**
  * @ngdoc autoTabToId
  * @name any[autoTabToId]
  * @restrict A
  *
  * @description
  * Auto tabs to the next element (defined by auto-tab-to-id attribute)
  *
  *
  * @param {string} autoTabToId Element's ID
  * @param {number} maxlength Minimum # of characters before triggering tab
  *
  * @example
      <input type="text" auto-tab-to-id="#second-element" maxlength="3"> <input type="text" id="second-element">
*/

function autotabToId () {
  return {
    restrict: "A",
    link: function (scope, el, attrs) {
      el.keyup(function (event) {
        if (this.value && !~ALLOWED_KEYS.indexOf(event.which) && this.value.length === this.maxLength) {
          var element = document.getElementById(attrs.autotabToId);
          if (element) element.focus();
        }
      });
    }
  }
}

module.exports = autotabToId;
