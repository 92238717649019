
function ResetPasswordCtrl($rootScope, $cookieStore, $timeout, $location, $log, $routeParams, UserFactory) {
  var Ctrl = this;
  
  Ctrl.password         = "";
  Ctrl.confirmPassword  = "";
  Ctrl.friendlyError    = true;
  Ctrl.submitted        = false;
  Ctrl.errors           = {};

  UserFactory.checkResetLink($routeParams.token).then(function ResetPasswordCtrl__checkResetLink_success() {
    $timeout(function() {
      // if we're currently logged in as a user... log us out
      UserFactory.getActiveUser().then(function ResetPasswordCtrl__getActiveUser() {
        if (!$rootScope.currentUser || !$rootScope.currentUser.userName) {
          return;
        }

        UserFactory.logout($rootScope.currentUser.userName).then(function ResetPasswordCtrl__getActiveUser__logout_success() {
          $cookieStore.remove('access_token');
          $cookieStore.remove('refresh_token');
          $cookieStore.remove('expires_in');
          $rootScope.currentUser = null;
        }, $log.error);
      }, $log.error);
    }, 0);
  }, function ResetPasswordCtrl__checkResetLink_error(res) {
    $log.error(res);
    $location.url("/link/expired");
  });

  Ctrl.submitReset = function ResetPasswordCtrl__submitReset(password, confirmPassword) {
    Ctrl.errors = {};

    UserFactory.updatePassword($routeParams.token, password, confirmPassword)
    .then(function ResetPasswordCtrl__submitReset__updatePassword_success(resp) {
      Ctrl.submitted = true;
    }, function ResetPasswordCtrl__submitReset__updatePassword_error(resp) {
      var errors  = $log.errorHandler(resp, {allow409: true});
      if (errors.friendlyErrors.length > 0 && errors.status != '409') {
        Ctrl.friendlyError = true;
        Ctrl.errors = errors.errors
      } else {
        Ctrl.friendlyError = false;
        Ctrl.errors = resp.data
      }

      //Ctrl.errors = errors.errors || resp.data;
      console.log(Ctrl.errors);
    });
  };

  Ctrl.toLogin = function ResetPasswordCtrl__toLogin() {
    $location.url("/login");
  };

  Ctrl.removeError = function ResetPasswordCtrl__removeError(prop) {
    var errors = $log.removeErrors(Ctrl, prop);
    Ctrl.errors = errors.errors;
  };
}

module.exports = ["$rootScope", "$cookieStore", "$timeout", "$location", "LogFactory", "$routeParams", "UserFactory", ResetPasswordCtrl];
