
var _ = require('lodash');
var dottie = require('dottie');

function RegistrationFactory($rootScope, $q, $http, $log, $filter, RoleService, RequestFactory, UtilFactory) {
  var service = {};

  service.basicOptions = {
    headers: {},
    ignoreAuth: true
  };

  /**
   * Sends a request to retrieve registration information for a specific invitation
   * @param  {String} token Registration token
   * @return {Promise}      RequestFactory's `response` key
   */
  service.getRegistration = function RegistrationFactory__getRegistration(token) {
    var opts = this.basicOptions;
    opts.url = '/api/user/register/' + UtilFactory.encodeURLParam(token);

    return RequestFactory.send(opts);
  };

  /**
   * Sends a request to register a user
   * @param  {String}  token Registration token
   * @return {Promise}       RequestFactory's `response` key
   */
  service.register = function RegistrationFactory__register(token, userObj) {
    var opts = this.basicOptions;

    opts.method = 'POST';
    opts.url    = '/api/user/register/' + UtilFactory.encodeURLParam(token);
    opts.data   = userObj;

    return RequestFactory.send(opts);
  }

  return service;
}

module.exports = ['$rootScope', '$q', '$http', 'LogFactory', '$filter', 'RoleService', 'RequestFactory', 'UtilFactory', RegistrationFactory];
