
var _      = require('lodash');
var dottie = require('dottie');

function LogoutPageCtrl($location, $log, $rootScope, $cookieStore, UserFactory) {
  var userName = dottie.get($rootScope, 'currentUser.userName');

  $cookieStore.remove('expires_in');
  $cookieStore.remove('access_token');
  $cookieStore.remove('refresh_token');

  $rootScope.currentUser = null;

  $location.path("/login");

  // We don't need to wait for a valid response from the server do so anything.
  if (!_.isUndefined(userName)) {
    UserFactory.logout(userName).then(angular.noop, $log.error);
  }
}

module.exports = ["$location", "LogFactory", "$rootScope", "$cookieStore", "UserFactory", LogoutPageCtrl];
