
/**
  * @ngdoc genderSelect
  * @name genderSelect
  * @restrict E
  *
  * @description
  * Creates a template for an input group of two radio buttons: Male and Female
  *
  *
  * @param {object}   genderobj  The employee's object to associate the `gender` key to
  *
  * @example
      <gender-select genderobj="Ctrl.employee"></gender-select>
*/

function genderSelectDirective() {
  return {
    restrict: "E",
    templateUrl: "/partials/partials/genderSelect",
    scope: {
      genderobj:    "=",
      setDisabled:  "=",
      clearfn:      "&"
    }
  };
}

module.exports = genderSelectDirective;
