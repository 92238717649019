
var _ = require('lodash');

function EmployeeFactory(RequestFactory, UtilFactory, DependentFactory, $http) {
  var service = {};

  /**
   * Map some additional properties required for displaying data
   * @param  {Object} employeeObj Employee's data object
   * @return {Object}             New employee's data object
   */
  service.mapCensusProperties = function EmployeeFactory__mapCensusProperties(employeeObj) {
    employeeObj.status              = (employeeObj.status || '').toLowerCase();
    employeeObj.friendlyTobaccoUse  = employeeObj.tobaccoUse === "Y" ? 'Yes' : 'No';
    
    // Could use a simple ternary operator here, but better to be explicit to
    // avoid assigning someone the wrong gender if there's an unexpected backend value
    switch (employeeObj.gender) {
      case 'M': employeeObj.genderNoun = 'Male'; break;
      case 'F': employeeObj.genderNoun = 'Female'; break;
      case 'U': employeeObj.genderNoun = 'Other'; break;
    }
    
    employeeObj.friendlySalary      = UtilFactory.getFormattedSalary(employeeObj);
    employeeObj.friendlyDateOfHire  = UtilFactory.getFormattedCensusDate(employeeObj.hireDate);
    employeeObj.friendlyDob         = UtilFactory.getFormattedCensusDate(employeeObj.dob);
    employeeObj.friendlyTermDate    = UtilFactory.getFormattedCensusDate(employeeObj.terminationDate);
    employeeObj.friendlyPhone       = _.isArray(employeeObj.address) && _.isObject(employeeObj.address[0]) ? UtilFactory.getFormattedCensusPhone(employeeObj.address[0].phone) : '';
    employeeObj.ssnLast4Digits      = UtilFactory.getFormattedSSN(employeeObj.ssn || '');
    employeeObj.friendlyStatus      = service.getFormattedActivityStatus(employeeObj.status);
    employeeObj.friendlyWeeklyHours = employeeObj.weeklyHours + ' hrs.';

    employeeObj.dependents = _.isArray(employeeObj.dependents) ? employeeObj.dependents : [];
    employeeObj.dependents = _.map(employeeObj.dependents, DependentFactory.mapCensusProperties);

    return employeeObj;
  };

  /**
  * Returns a formatted employee status
  * @param {String} status
  * @return {Mixed} String on success; false on invalid request
  */
  service.getFormattedActivityStatus = function EmployeeFactory__getFormattedActivityStatus(status) {
     //IT-7136 Remove Proper Casing on Statuses in Roster & Dependent List
    //return typeof status === 'string' && typeof status[0] === 'string' && status[0].toUpperCase() + status.slice(1);
    return typeof status === 'string' && typeof status[0] === 'string';
  };

  /**
   * Returns an employee's information
   * @param  {String|Integer} employeeId Employee's ID
   * @return {Promise}                   Return's RequestFactory's response's `data` key
   */
  service.find = function EmployeeFactory__find(employeeId) {
    return RequestFactory.send('/api/employee/' + employeeId, 'data');
  };

  /**
   * Update's or delete's an employee based on it `inactive`
   * @param  {Object} data     Employee's data
   * @param  {Boolean} inactive   status == 'inactive' ?
   * @return {Promise}          Returns RequestFactory's `response.data` key
   */
  service.update = function EmployeeFactory__update(data, inactive, occupationalChanged) {
   var keys = ['id', 'hireDate','occupation', 'salary', 'salaryFreq', 'memberId', 'memberIdentifier', 'memberNumber', 'firstName', 'middleName1', 'lastName', 'ssn', 'dob', 'gender', 'tobaccoUse', 'weeklyHours', 'address', 'divisionId', 'classId', 'changeEffectiveDate'];
   var url = '/api/employee';
   if (inactive) {
     keys = ['terminationDate', 'terminationReason'];
   }
   
   postData = _.pick(data, keys);

   if (!inactive) {
     // for the PUT /employee/{id} blocker, the default values have to be null not ""
	 postData.occupationalChange = occupationalChanged;
     postData = _.mapValues(postData, function (value) {
       if (_.isString(value) && value.trim() === '') {
         return null;
       }

       return value;
     });
   } else {
     url += '/' + data.id;
     postData.realMethod = 'delete';
   }
   return RequestFactory.send({
     url: url,
     method: 'PUT',
     data: postData
   }, 'data');
  };

  /**
   * Sends a request to add an employee
   * @param {Object} data Employee's data
   */
  service.insert = function EmployeeFactory__insert(data) {
    if (_.isString(data.ssn) && data.ssn == '') {
      data = _.omit(data, 'ssn');
    }
    if (_.isString(data.enrollingType) && data.enrollingType.toUpperCase() != 'QE') {
      data = _.omit(data, ['effectiveDate', 'qualifyingEventTypeCode']);
    }
    return RequestFactory.send({
      url: '/api/employee',
      method: 'PUT',
      data: data
    }, 'data');
  };

  /**
   * Sends a request to reinstate an employee
   * @param  {Object} employee Employee's data
   * @param  {Object} postData Any required fields for reinstating
   * @return {Promise}         RequestFactory's `response.data.data` key
   */
  service.reinstate = function EmployeeFactory__reinstate(employee) {
    return RequestFactory.send({
      url: '/api/employee/' + employee.id + '/reinstate',
      method: 'POST'
    }, 'data');
  };

  /**
   * Sends a request to rehire an employee
   * @param  {Object} employee Employee's data
   * @param  {Object} postData Any required fields for reinstating
   * @return {Promise}         RequestFactory's `response.data.data` key
   */
  service.rehire = function EmployeeFactory__reinstate(employee, postData) {
    return RequestFactory.send({
      url: '/api/employee/' + employee.id + '/rehire',
      method: 'POST',
      data: postData
    }, 'data');
  };

  /**
   * Sends a request to update the pending salary change
   * @param  {String}   pendingId   Pending change ID
   * @param  {Object}   postData    {salary, salaryFreq, requestedChangeDate}
   * @return {Promise}              $http's promise
   */
  service.updatePendingSalaryChange = function EmployeeFactory__updatePendingSalaryChange(employeeId, pendingId, postData) {
    return RequestFactory.send({
      url: '/api/employee/' + employeeId + '/pendingChanges/salary/' + pendingId,
      method: 'PUT',
      data: postData
    }, 'data');
  };

  /**
   * Sends a request to delete the pending salary change
   * @param  {String}   pendingId   Pending change ID
   * @return {Promise}              $http's promise
   */
  service.deletePendingSalaryChange = function EmployeeFactory__deletePendingSalaryChange(employeeId, pendingId) {
    return RequestFactory.send({
      url: '/api/employee/' + employeeId + '/pendingChanges/salary/' + pendingId,
      method: 'PUT',
      data: {
        realMethod: 'delete'
      }
    });
  };

  /**
   * Sends a request to update the pending work hour change
   * @param  {String}   pendingId   Pending change ID
   * @param  {Object}   postData    {salary, salaryFreq, requestedChangeDate}
   * @return {Promise}              $http's promise
   */
  service.updatePendingWorkHourChange = function EmployeeFactory__updatePendingWorkHourChange(pendingId, postData) {
    return service.updatePendingChange('workhour', pendingId, postData);
  };

  /**
   * Sends a request to delete the pending work hour change
   * @param  {String}   pendingId   Pending change ID
   * @return {Promise}              $http's promise
   */
  service.deletePendingWorkHourChange = function EmployeeFactory__deletePendingWorkHourChange(pendingId) {
    return service.deletePendingChange('workhour', pendingId);
  };

  /**
   * Sends a request to update the pending division/class change
   * @param  {String}   pendingId   Pending change ID
   * @param  {Object}   postData    {divisionId, classId, requestedChangeDate}
   * @return {Promise}              $http's promise
   */
  service.updatePendingClassChange = function EmployeeFactory__updatePendingClassChange(pendingId, postData) {
    return service.updatePendingChange('divisionClass', pendingId, postData);
  };

  /**
   * Sends a request to delete the pending division/class change
   * @param  {String}   pendingId   Pending change ID
   * @return {Promise}              $http's promise
   */
  service.deletePendingClassChange = function EmployeeFactory__deletePendingClassChange(pendingId) {
    return service.deletePendingChange('divisionClass', pendingId);
  };
  
  /**
   * Sends a request to delete the pending occupation change
   * @param  {String}   pendingId   Pending change ID
   * @return {Promise}              $http's promise
   */
  service.deletePendingOccupation = function EmployeeFactory__deletePendingOccupationChange(pendingId) {
    return service.deletePendingChange('occupation', pendingId);
  };

  /**
   * Sends a request to update the pending Status/Member Terminated change
   * @param  {String}   pendingId   Pending change ID
   * @param  {Object}   postData    {terminationTypeCode, requestedChangeDate}
   * @return {Promise}              $http's promise
   */
  service.updatePendingMemberTerminatedChange = function EmployeeFactory__updatePendingMemberTerminatedChange(pendingId, postData) {
    return service.updatePendingChange('memberTerminated', pendingId, postData);
  };

  /**
   * Sends a request to delete the pending status/Member Terminated change
   * @param  {String}   pendingId   Pending change ID
   * @return {Promise}              $http's promise
   */
  service.deletePendingMemberTerminatedChange = function EmployeeFactory__deletePendingMemberTerminatedChange(pendingId) {
    return service.deletePendingChange('memberTerminated', pendingId);
  };

  /**
   * Sends a request to update the pending Coverage change
   * @param  {String}   pendingId   Pending change ID
   * @param  {Object}   postData    {terminationTypeCode, requestedChangeDate}
   * @return {Promise}              $http's promise
   */
  service.updatePendingCoverageChange = function EmployeeFactory__updatePendingCoverageChange(pendingId, postData) {
    return service.updatePendingChange('coverage', pendingId, postData);
  };
  
  /**
   * Sends a request to update the pending occupation change
   * @param  {String}   pendingId   Pending change ID
   * @param  {Object}   postData    {terminationTypeCode, requestedChangeDate}
   * @return {Promise}              $http's promise
   */
  service.updatePendingOccupationChange = function EmployeeFactory__updatePendingOccupationChange(pendingId, postData) {
    return service.updatePendingChange('occupation', pendingId, postData);
  };

  /**
   * Sends a request to delete the pending Coverage change
   * @param  {String}   pendingId   Pending change ID
   * @return {Promise}              $http's promise
   */
  service.deletePendingCoverageChange = function EmployeeFactory__deletePendingCoverageChange(pendingId) {
    return service.deletePendingChange('coverage', pendingId);
  };

  /**
   * Sends a request to update the pending Hire Date change
   * @param  {String}   employeeId  Employee's id
   * @param  {String}   pendingId   Pending change ID
   * @param  {Object}   postData    {hireDate}
   * @return {Promise}              $http's promise
   */
  service.updatePendingHireDateChange = function EmployeeFactory__updatePendingHireDateChange(employeeId, pendingId, postData) {
    return RequestFactory.send({
      url: '/api/employee/' + employeeId + '/pendingChanges/hiredate/' + pendingId,
      method: 'PUT',
      data: postData
    }, 'data');
  };

  /**
   * Sends a request to delete the pending Hire Date change
   * @param  {String}   employeeId  Employee's id
   * @param  {String}   pendingId   Pending change ID
   * @return {Promise}              $http's promise
   */
  service.deletePendingHireDateChange = function EmployeeFactory__deletePendingHireDateChange(employeeId, pendingId) {
    return RequestFactory.send({
      url: '/api/employee/' + employeeId + '/pendingChanges/hiredate/' + pendingId,
      method: 'PUT',
      data: {
        realMethod: 'delete'
      }
    });
  };

  /**
   * Sends a request to update a pending change
   * @param  {String}   type        The type of change to update
   * @param  {String}   pendingId   Pending change ID
   * @param  {Object}   postData    {salary, salaryFreq, requestedChangeDate}
   * @return {Promise}              $http's promise
   */
  service.updatePendingChange = function EmployeeFactory__updatePendingWorkHourChange(type, pendingId, postData) {
    return RequestFactory.send({
      url: '/api/employee/pendingChanges/' + type + '/' + pendingId,
      method: 'PUT',
      data: postData
    }, 'data');
  };

  /**
   * Sends a request to delete a pending change
   * @param  {String}   type        The type of change to delete
   * @param  {String}   pendingId   Pending change ID
   * @return {Promise}              $http's promise
   */
  service.deletePendingChange = function EmployeeFactory__deletePendingWorkHourChange(type, pendingId) {
    return RequestFactory.send({
      url: '/api/employee/pendingChanges/' + type + '/' + pendingId,
      method: 'PUT',
      data: {
        realMethod: 'delete'
      }
    });
  };

  /**
   * Sends a request to export the roster
   * @param  {String}   groupNum      Group's number
   * @param  {Object[]} employeeArray A list of employee objects
   * @return {Promise}                $http's promise
   */
  service.exportRoster = function EmployeeFactory__exportRoster(groupNum) {
    return $http.post('/api/employee/roster', {group: groupNum});
  };

  /**
   * Sends a request to retrieve an employee's claims
   * @param  {String} employeeId Employee's ID
   * @return {Promise}           RequestFactory's `response.data.claims` key
   */
  service.getClaims = function EmployeeFactory__getClaims(employeeId) {
    return RequestFactory.send('/api/employee/' + employeeId + '/claims', 'data');
  };

  /**
   * Get a division's details
   * @param  {String|Integer} id Coverage's ID
   * @return {Promise}           Returns `data` key from the RequestFactory response
   */
  service.getDivisionDetails = function EmployeeFactory__getDivisionDetails(id) {
    return RequestFactory.send('/api/coverages/' + id, 'data');
  };

  /**
   * Returns the qualifying life events
   * @return {Promise} Returns the `data.events` key from the response
   */
  service.getQualifyingEvents = function EmployeeFactory__getQualifyingEvents() {
    return RequestFactory.send('/api/qualifyingEvents', 'data.events');
  };

  /**
   * Returns termination codes
   * @return {Promise}   Returns RequestFactory's `response.data.codes` key
   */
  service.getReasonCodes = function EmployeeFactory__getReasonCodes() {
    return RequestFactory.send('/api/terminationCodes', 'data.codes');
  };

  return service;
}

module.exports = ['RequestFactory', 'UtilFactory', 'DependentFactory', '$http', EmployeeFactory];
