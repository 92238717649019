
var _ = require('lodash');
var dottie  = require('dottie');

function EditClassModalCtrl ($rootScope, $scope, $timeout, WizardService, $log, PendingChangeDecorator, FormFactory, EmployeeFactory, UtilFactory) {

  var Ctrl = this;
  PendingChangeDecorator(Ctrl, $rootScope, $scope);
  Ctrl.wizard = WizardService;
  Ctrl.wizard.modalTitle = "Employee Division/Class (Pending)";
  Ctrl.editTabType = "Change";
  Ctrl.headerType = "Division/Class";

  Ctrl.step = 0;
  Ctrl.onEditTab = true;
  Ctrl.originalFormData = {};

  /**
   * Reset the state of the modal and all data associated with modal forms
   */
  Ctrl.reset = function EditClassModalCtrl__reset() {
    WizardService.resetData();
    Ctrl.step = 0;
    Ctrl.onEditTab = true;
  };

  /**
   * Initializes data and gets qualifying events
   */
  Ctrl.init = function EditClassModalCtrl__init() {
    Ctrl.employeeInfo = Ctrl.wizard.data.employeeInfo;
    Ctrl.pendingChange = Ctrl.wizard.data.pendingChange.originalData;

    if(Ctrl.employeeInfo.divisionId){
      Ctrl.divisions = dottie.get($rootScope, 'currentUser.divisions', []);
    }

    Ctrl.groupClasses = dottie.get($rootScope, 'currentUser.group.class', []);

    var divisionObj = findDivisionObj(Ctrl.divisions, Ctrl.pendingChange.divisionId);
    Ctrl.classes = getClasses(divisionObj);

    Ctrl.formData = {
      divisionObj: divisionObj,
      classObj: findClassObj(Ctrl.classes, Ctrl.pendingChange.classId),
      requestedChangeDateDropDown: {}
    };

    Ctrl.cancelFormData = {
      cancelPendingChange: false
    };

    if (Ctrl.pendingChange.requestedChangeDate) {
      Ctrl.formData.requestedChangeDateDropDown = UtilFactory.dateStringToDropdownObj(Ctrl.pendingChange.requestedChangeDate);
    }

    Ctrl.originalFormData = _.cloneDeep(Ctrl.formData);
    Ctrl.originalCancelFormData = _.clone(Ctrl.cancelFormData);
  };

  function findDivisionObj(divisions, divisionId) {
    if (divisions && divisions.length) {
      return _.find(divisions, { 'id': divisionId });
    }
  }

  function findClassObj(classes, classId) {
    if (classes && classes.length) {
      return _.find(Ctrl.classes, { 'id': classId });
    }
  }

  function getClasses(divisionObj) {
    if (divisionObj && divisionObj["class"]) {
      return divisionObj["class"];
    }
    return Ctrl.groupClasses;
  }

  Ctrl.onDivisionSelect = function EditClassModalCtrl__onDivisionSelect() {
    if (Ctrl.formData.divisionObj && Ctrl.formData.divisionObj.id) {
      Ctrl.classes = getClasses(Ctrl.formData.divisionObj);
      if (Ctrl.classes && Ctrl.classes.length === 1) {
        Ctrl.formData.classObj = Ctrl.classes[0];
      } else {
        Ctrl.formData.classObj = {
          id: null,
          name: "Select Class",
          requireTobacco: null
        };
      }
    }
  }

  Ctrl.divisionOrClassChanged = function EditClassModalCtrl__divisionOrClassChanged() {
    return Ctrl.formData.divisionObj.id !== Ctrl.pendingChange.divisionId || Ctrl.formData.classObj.id !== Ctrl.pendingChange.classId;
  }

  Ctrl.showSummary = function EditClassModalCtrl__showSummary() {
    if (!Ctrl.cancelFormData.cancelPendingChange) {
      var errors = this.validateForm();

      if (_.keys(errors.errors).length > 0) {
        Ctrl.friendlyErrors  = errors.friendlyErrors;
        Ctrl.formData.errors = errors.errors;
        return false;
      }

      Ctrl.summaryObj = {
        class: Ctrl.formData.classObj.name,
        requestedChangeDate: UtilFactory.dropdownValuesToDateString(Ctrl.formData.requestedChangeDateDropDown)
      }

      if (Ctrl.formData.divisionObj) {
        Ctrl.summaryObj.division = Ctrl.formData.divisionObj.name;
      }
    }

    Ctrl.step = 1; // There are no form errors, so advance to the summary/confirmation
  }

  Ctrl.validateForm = function EditCoverageModalCtrl__validateForm() {
    return FormFactory.validate(Ctrl.form, {
      validations: [
        FormFactory.validations.class(Ctrl.formData.classObj.id, {condition: Ctrl.classes.length && Ctrl.classes.length > 1}),
        FormFactory.validations.date(Ctrl.formData.requestedChangeDateDropDown, {key: 'requestedChangeDate', after: 'rcdPast3Months', compareDate: moment().subtract(3, 'months'), condition: !Ctrl.isInEligiblePeriod}),
        FormFactory.validations.date(Ctrl.formData.requestedChangeDateDropDown, {key: 'requestedChangeDate', before: 'rcdPast', compareDate: moment().add(3, 'months'), condition: !Ctrl.isInEligiblePeriod})
      ]
    });
  }

  Ctrl.savePendingChange = function EditClassModalCtrl__savePendingChange() {
    if (Ctrl.cancelFormData.cancelPendingChange) {
      EmployeeFactory.deletePendingClassChange(Ctrl.pendingChange.id).then(onSuccess, populateErrors);
      return;
    }

    var postData = {
      classId: Ctrl.formData.classObj.id,
      requestedChangeDate: Ctrl.summaryObj.requestedChangeDate
    }

    if (Ctrl.formData.divisionObj) {
      postData.divisionId = Ctrl.formData.divisionObj.id;
    }

    EmployeeFactory.updatePendingClassChange(Ctrl.pendingChange.id, postData).then(onSuccess, populateErrors);

    function onSuccess(success) {
      Ctrl.step = 2; // The update was successfull, so advance to the final confirmation message
      $timeout(function() {
        Ctrl.closeModal();
      }, 5000);
    }

    function populateErrors(res) {
      var errors = $log.errorHandler(res);
      Ctrl.formData.errors = errors.errors;
    }
  }

  Ctrl.hasEditFormChanged = function EditClassModalCtrl__hasEditFormChanged() {
    if (!Ctrl.onEditTab) return false;

    if (Ctrl.formData.divisionObj) {
      if (Ctrl.formData.divisionObj.id !== Ctrl.originalFormData.divisionObj.id) return true;
    }

    if (Ctrl.formData.classObj) {
      if (Ctrl.formData.classObj.id !== Ctrl.originalFormData.classObj.id) return true;
    }

    if (Ctrl.formData.requestedChangeDateDropDown) {
      if (!UtilFactory.dateDropDownsAreEqual(Ctrl.formData.requestedChangeDateDropDown, Ctrl.originalFormData.requestedChangeDateDropDown)) {
        return true;
      }
    }

    return false;
  }

  Ctrl.init();
}

module.exports = ['$rootScope', '$scope', '$timeout', 'WizardService', 'LogFactory', 'PendingChangeDecorator', 'FormFactory', 'EmployeeFactory', 'UtilFactory', EditClassModalCtrl];
