
var _ = require('lodash');

function BulkOperationsFactory($http, RequestFactory, UtilFactory) {
  var service = {};

  /**
   * Sends a request to upload the salary file
   * @return {Promise}              Returns `data` key from the RequestFactory response
   */
  service.uploadSalaryFile = function BulkOperationsFactory__uploadSalaryFile(file) {
    var data = {
      template: file
    };
    
    return RequestFactory.send({
      url: '/api/employee/salary',
      method: 'POST',
      data: data
    }, 'data');

    /*return $http.post('/xlsx-upload/' + fileType, formData, {
      headers: {'Content-Type': undefined},
      transformRequest: angular.identity
    });*/
  };

  /**
   * Sends a request to export the salary
   * @param  {String}   groupNum      Group's number
   * @return {Promise}                $http's promise
   */
  service.exportSalary = function BulkOperationsFactory__exportSalary(groupNum) {
    return $http.post('/api/employee/salary/download', {group: groupNum});
  };

  /**
   * Sends a request to get the salary file
   * @return {Promise}              Returns `data` key from the RequestFactory response
   */
  service.getSalaryFile = function BulkOperationsFactory__getSalaryFile() {
    return RequestFactory.send('/api/employee/salary', 'data');
  };

  /**
   * Sends a request to delete the salary file
   * @return {Promise}              $http's promise
   */
  service.deleteSalaryFile = function BulkOperationsFactory__deleteSalaryFile() {
    return RequestFactory.send({
      url: '/api/employee/salary',
      method: 'PUT',
      data: {
        realMethod: 'delete'
      }
    });
  };

  return service;
}

module.exports = ['$http', 'RequestFactory', 'UtilFactory', BulkOperationsFactory];
