
/**
  * @ngdoc mobTooltipPassword
  * @name any[mobTooltipPassword]
  * @restrict A
  *
  * @description
  * Creates a tooltip specifically for the password requirements
  *
  *
  * @example
       <a mob-tooltip-password>?</a>
*/

var content = [
  '<ul>',
    '<li>Must contain <strong>12</strong> or more characters</li>',
    '<li>Must be different from your email</li>',
    '<li>Must be a combination of a letter, number and special characters</li>',
    '<li>Must be a combination of lower and upper case letters</li>',
    '<li>Allowed special characters: !&quot;#$%&amp;&apos;()*+,-./:;&lt;=&gt;?@[]^_&grave;{|}~</li>',
  '</ul>'
].join('');

function TooltipPasswordDirective() {
  return {
    restrict: 'A',
    scope: false,
    replace: true,
    template: '<div mob-tooltip="" mob-tooltip-title="Password Requirements" mob-tooltip-content="' + content + '"><img src="/static/images/QuestionMark_tooltip.png" alt="?" title="Password Requirements"></div>'
  };
}

module.exports = TooltipPasswordDirective;
