var _ = require('lodash');

function PendingChangeDecorator($log, $timeout, WizardService) {

  return function(PendingCtrl, $rootScope, $scope) {

    PendingCtrl.disableSaveBtn = function PendingChangeDecorator__disableSaveBtn() {
      var editFormPristine = PendingCtrl.onEditTab && PendingCtrl.form && PendingCtrl.form.$pristine;
      var cancelFormPristine = !PendingCtrl.onEditTab && PendingCtrl.cancelForm && PendingCtrl.cancelForm.$pristine;
      return editFormPristine || cancelFormPristine;
    }

    PendingCtrl.handleEditFormChange = function PendingChangeDecorator__handleEditFormChange() {
      conditionallySetEditFormPristine();
    }

    PendingCtrl.handleCancelFormChange = function PendingChangeDecorator__handleCancelFormChange() {
      conditionallySetCancelFormPristine();
    }

    PendingCtrl.closeModal = function PendingChangeDecorator__closeModal() {
      if ($scope.$close && _.isFunction($scope.$close)) {
        if (!_.isUndefined(WizardService.timeout)) {
          $timeout.cancel(WizardService.timeout);
          WizardService.timeout = undefined;
        }
        PendingCtrl.reset();
        $scope.$close();
      }
    };
    PendingCtrl.cancel = PendingCtrl.closeModal;

    var cleanUpFunc = $rootScope.$on("modal:hide", PendingChangeDecorator__modal_hide);

    // Prevent memory leaks caused by listening to $rootScope
    // (http://stackoverflow.com/questions/18856341/how-can-i-unregister-a-broadcast-event-to-rootscope-in-angularjs)
    $scope.$on("$destroy", function PendingChangeDecorator__destroy() {
      cleanUpFunc();
    });

    PendingCtrl.removeError = function() {
      for (var i = 0; i < arguments.length; i++)
        PendingCtrl.formData = $log.removeErrors(PendingCtrl.formData, arguments[i]);
    };

    function PendingChangeDecorator__modal_hide(e, employee) {
      PendingCtrl.closeModal();
    }

    function conditionallySetEditFormPristine() {
      var editFormChanged = PendingCtrl.hasEditFormChanged();
      if (!editFormChanged && !_.isEmpty(PendingCtrl.form)) {
        PendingCtrl.form.$setPristine();
      }
    }

    function conditionallySetCancelFormPristine() {
      var cancelFormChanged = hasCancelFormChanged();
      if (!cancelFormChanged && !_.isEmpty(PendingCtrl.cancelForm)) {
        PendingCtrl.cancelForm.$setPristine();
      }
    }

    function hasCancelFormChanged() {
      if (PendingCtrl.onEditTab) return false;

      if (PendingCtrl.cancelFormData.cancelPendingChange !== PendingCtrl.originalCancelFormData.cancelPendingChange) {
        return true;
      }

      return false;
    }

  }

}

module.exports = ['LogFactory', '$timeout', 'WizardService', PendingChangeDecorator];
