
/**
  * @ngdoc volumeSelect
  * @name volumeSelect
  * @restrict E
  *
  * @description
  * Creates a dropdown list of volumes
  *
  *
  * @param {string}   label         Text for the field's label
  * @param {object}   coverage      The coverage data/object
  * @param {object[]} volumes       List of coverage's volumes
  *
  * @example
      <volume-select label="* Volume:" coverage="Ctrl.coverage" volumes="Ctrl.volumes"></volume-select>
*/

function volumeSelectDirective() {
  return {
    restrict: "E",
    templateUrl: "/partials/partials/volumeSelect",
    scope: {
      label:    "@",
      coverage: "=",
      volumes:  "=",
      clearfn:  "&"
    }
  };
}

module.exports = volumeSelectDirective;
