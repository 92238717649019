
/**
  * @ngdoc divisionSelect
  * @name divisionSelect
  * @restrict E
  *
  * @description
  * Creates a dropdown list of divisions and triggers onDivisionSelect() method
  *
  *
  * @param {object}   divisionObj   The division data/object
  * @param {object[]} divisions     An array of divisions
  * @param {string}   label         Text for the field's label
  * @param {boolean}  required      Whether the select input is required
  * @param {boolean}  setDisabled   Whether the select input is disabled
  * @param {string}   divisionName  The current division name to display, if there are no divisions for selection
  * @param {function} onChange      Execute this function each time we change our division
  *
  * @example
      <division-select label="Division:" divisions="Ctrl.divisions" divisionObj="Ctrl.divisionObj" onSelect="Ctrl.onDivisionSelect()"></division-select>
*/

function divisionSelectDirective() {
  return {
    restrict: "E",
    templateUrl: "/partials/partials/divisionSelect",
    scope: {
      formData:         "=",
      divisions:        "=",
      required:         "=",
      setDisabled:      "=",
      divisionName:     "@",
      label:            "@",
      onDivisionChange: "&"
    }
  };
}

module.exports = divisionSelectDirective;
