
/**
  * @ngdoc onFormChange
  * @name onFormChange
  * @restrict A
  *
  * @description
  * Calls the passed function whenever the form becomes dirty.
*/

function watchFormModel($parse) {
  return {
    restrict: "A",
    require: "form",
    scope: {
      onFormChange: '&',
      watchFormModel: '='
    },
    link: function(scope, element, attrs, form) {
      scope.$watch('watchFormModel', function() {
        scope.onFormChange();
      }, true);
    }
  };
}

module.exports = watchFormModel;
