
MOB.app.factory('AuthInterceptor',      require('./AuthInterceptor'));
MOB.app.factory('BenefitsInfoFactory',  require('./BenefitsInfoFactory'));
MOB.app.factory('BillingFactory',       require('./BillingFactory'));
MOB.app.factory('BulkOperationsFactory', require('./BulkOperationsFactory'));
MOB.app.factory('CoverageFactory',      require('./CoverageFactory'));
MOB.app.factory('DependentFactory',     require('./DependentFactory'));
MOB.app.factory('EmployeeFactory',      require('./EmployeeFactory'));
MOB.app.factory('FormFactory',          require('./FormFactory'));
MOB.app.factory('HttpBuffer',           require('./HttpBuffer'));
MOB.app.factory('LoadInterceptor',      require('./LoadInterceptor'));
MOB.app.factory('LogFactory',           require('./LogFactory'));
MOB.app.factory('PendingChangeDecorator', require('./PendingChangeDecorator'));
MOB.app.factory('RegistrationFactory',  require('./RegistrationFactory'));
MOB.app.factory('RequestFactory',       require('./RequestFactory'));
MOB.app.service('RoleService',          require('./Role'));
MOB.app.factory('UserFactory',          require('./UserFactory'));
MOB.app.factory('UtilFactory',          require('./UtilFactory'));
MOB.app.service('WizardService',        require('./Wizard'));
