
var _ = require('lodash');

function EmployeeSummaryCtrl($filter, $rootScope, $scope, WizardService, EmployeeFactory, NgTableParams, $log, CoverageFactory) {
  var Ctrl = this;

  Ctrl.wizard = WizardService;
  Ctrl.wizard.data = Ctrl.wizard.data || {};
  Ctrl.wizard.data.employeeInfo = Ctrl.wizard.data.employeeInfo || {};
  Ctrl.wizard.data.employeeProfileId = Ctrl.wizard.data.employeeInfo.id;

  Ctrl.wizard.form = {};
  Ctrl.summaryData = [];

  // hide the required fields helper message
  Ctrl.wizard.hideReqHelper = true;

  EmployeeFactory.find(Ctrl.wizard.data.employeeInfo.id).then(function EmployeeSummaryCtrl__find(resp) {
    // set data
    Ctrl.wizard.data.employeeInfo = resp;
    Ctrl.wizard.data.employeeInfo.dependents = _.map(Ctrl.wizard.data.employeeInfo.dependents, EmployeeSummaryCtrl__find__map_dependent);

    function EmployeeSummaryCtrl__find__map_dependent(dependent) {
      dependent.type = CoverageFactory.getRelationshipByCode(dependent.relationship);
      return dependent;
    }

    // add employee
    Ctrl.summaryData.push({
      name: Ctrl.wizard.data.employeeInfo.firstName + ' ' + Ctrl.wizard.data.employeeInfo.lastName + (_.isString(Ctrl.wizard.data.employeeInfo.nameSuffix) ? ' ' + Ctrl.wizard.data.employeeInfo.nameSuffix : ''),
      relationship: 'Employee',
      dob: $filter('date')(Ctrl.wizard.data.employeeInfo.dob, 'MMM dd, yyyy')
    });

    // add dependents
    _.each(Ctrl.wizard.data.employeeInfo.dependents, function EmployeeSummaryCtrl__find__each_dependents(dependent) {
      if (_.isString(dependent.firstName) && dependent.firstName.trim() !== '') {
        Ctrl.summaryData.push({
          name: dependent.firstName + ' ' + dependent.lastName,
          relationship: (dependent.relationship === 'SP') ? 'Spouse' : 'Child',
          dob: dependent.dob
        });
      }
    });
  }, $log.error);

  // setup table for listing summary data
  Ctrl.tableParams = new NgTableParams({count: 0}, {
    counts: [],
    getData: function () {
      return Ctrl.summaryData;
    }
  });

  Ctrl.wizard.sidebar = [
    {name: 'Employee Information'},
    {name: 'Add Dependent(s)'},
    {name: 'Summary'}
  ];

  Ctrl.wizard.currentStep = this;
}

module.exports = ['$filter', '$rootScope', '$scope', 'WizardService', 'EmployeeFactory', 'NgTableParams', 'LogFactory', 'CoverageFactory', EmployeeSummaryCtrl];
