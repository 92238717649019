
/**
 * @ngdoc isEmptyObj
 * @name $filter:isEmptyObj
 *
 * @description
 * Flters for objects that are empty
 *
 *
 * @example
 <div ng-repeat="list | isEmptyObj"></div>
 */

function FOMF() {
  return function(date) {
    if (!date)
      return date;
    if (!date.setMonth) {
      date = new Date(date);
      if (!date.setMonth)
        return date;
    }
    date.setMonth(date.getMonth() + 1);
    date.setDate(1);
    return date;
  };
}

module.exports = FOMF;
