
var _ = require('lodash');

function Wizard(RequestFactory, EmployeeFactory, CoverageFactory, DependentFactory) {
  var Service = this;

  Service.stepIndex    = 0;
  Service.subStepIndex = 0;
  Service.steps        = [
    'Employee Information',
    'Product Selection',
    'Benefit Election'
  ];
  Service.data        = {};
  Service.currentStep = null;
  Service.skip        = false;
  Service.hasLoaded = false;

  /**
   * Resets the Wizard's data
   * @return {Void}
   */
  Service.resetData = function Wizard__resetData() {
    Service.data          = {};
    this.resetGUI();
  };

  /**
   * Resets the Wizard's GUI
   * @return {Void}
   */
  Service.resetGUI = function Wizard__resetGUI() {
    Service.stepIndex     = 0;
    Service.subStepIndex  = 0;
    Service.currentStep   = null;
    Service.skip          = false;
    Service.sidebar       = null;
    Service.addingNew     = null;
    Service.addingDependent   = null;
    Service.newDepEffectiveDate  = null;
    Service.hasRequiredChildCoverages = null;
  }

  /**
   * Get the current employee's ID
   * @return {Integer} Employee's ID
   */
  Service.getCurrentEmployeeID = function Wizard__getCurrentEmployeeID() {
    return this.data.employeeInfo.id;
  };

  /**
   * Adds a benefit... this is later used for summary pages etc.
   * @param {Object} benefit Benefit's information
   */
  Service.addBenefit = function Wizard__addBenefit(benefit) {
    if (this && this.data) {
      this.data.benefits = _.isArray(this.data.benefits) ? this.data.benefits : [];
      this.data.benefits.push(benefit);
    }
  };

  /**
  * Retrieve and format the current date for
  * use in certain API calls such as the one used
  * to add employee coverage.
  */
  Service.getEffectiveDate = function Wizard__getEffectiveDate() {
    var date = new Date(),
    year = date.getFullYear(),
    month = date.getMonth() + 1,
    days = date.getDate();

    month = (month<10?'0':'') + month;
    days = (days<10?'0':'') + days;

    var result = [year, month, days].join('-');

    return result;
  };

  /**
   * Gets the current coverage
   */
  Service.getCurrentCoverage = function Wizard__getCurrentCoverage() {
    return this.sidebar[this.subStepIndex].coverage;
  };

  return Service;
}

module.exports = ['RequestFactory', 'EmployeeFactory', 'CoverageFactory', 'DependentFactory', Wizard];
