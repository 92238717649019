
function ForgotPasswordCtrl($log, UserFactory) {
  var Ctrl = this;

  Ctrl.error = false;
  Ctrl.email = '';
  Ctrl.submitted = false;

  /**
   * Resets error to false (exposes this to view template)
   */
  Ctrl.reset = function ForgotPasswordCtrl__reset() {
    Ctrl.error = false;
  };

  /**
   * Submits form and handles response appropriately
   * @param  {String} email User's email address
   */
  Ctrl.submitForm = function ForgotPasswordCtrl__submitForm(email) {
    UserFactory.checkEmail(email).then(function ForgotPasswordCtrl__submitForm__checkEmail() {
      Ctrl.submitted = true;
    }, function ForgotPasswordCtrl__submitForm__checkEmail__error() {
      $log.error(arguments);
      Ctrl.error        = true;
      Ctrl.errorMessage = "An error has occurred, please try again later.";
    });
  };
}

module.exports = ["LogFactory", "UserFactory", ForgotPasswordCtrl];
