
var _ = require('lodash');

function DependentSummaryCtrl($filter, $rootScope, $scope, WizardService, NgTableParams) {
  var Ctrl = this;

  Ctrl.wizard       = WizardService;
  Ctrl.wizard.data  = Ctrl.wizard.data || {};
  Ctrl.wizard.data.employeeInfo = Ctrl.wizard.data.employeeInfo || {};

  Ctrl.wizard.form = {};
  Ctrl.summaryData = [];

  // hide the required fields helper message
  Ctrl.wizard.hideReqHelper = true;

  // add dependents
  var dependents = [Ctrl.wizard.data.newDep];

  _.each(dependents, function DependentSummaryCtrl__dependents_each(dependent) {
    Ctrl.summaryData.push({
      name: dependent.firstName + ' ' + dependent.lastName,
      relationship: (['H','W','spouse','SP'].indexOf(dependent.relationship) !== -1) ? 'Spouse' : 'Child',
      dob: dependent.dob
    });
  });

  // setup table for listing summary data
  Ctrl.tableParams = new NgTableParams({count: 0}, {
    counts: [],
    getData: function () {
      return Ctrl.summaryData;
    }
  });

  Ctrl.wizard.sidebar = [
    {name: 'Add Dependent'},
    {name: 'Summary'}
  ];

  Ctrl.wizard.currentStep = this;

  // If the employee is in the eligible period, show the final summary footer so that the benefit election section is skipped
  if (Ctrl.wizard.data.employeeInfo.isInEligiblePeriod) {
    Ctrl.wizard.showFinalSummaryFooter = true;
  }
}

module.exports = ['$filter', '$rootScope', '$scope', 'WizardService', 'NgTableParams', DependentSummaryCtrl];
