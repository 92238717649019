
var _ = require('lodash');
var dottie  = require('dottie');

function EmployeeProfileCtrl ($scope, $log, $location, $routeParams, $modal, $q, PENDING_CHANGE_METADATA, UserFactory, EmployeeFactory, WizardService, CoverageFactory, UtilFactory, DependentFactory, employee) {
  var Ctrl = this;
  var modalInstance;
  var coverageModalInstance;

  this.mainView = 'employee';
  this.employee = {};
  this.showAgeReductionDisclaimer = false;
  this.showCoverageDisclaimer = false;
  this.showDeletePendingChangeModal = false;
  this.coverageToEdit = null;

  // The dependent whose census data we are currently
  // viewing (only applicable if dependents tab is open)
  this.selectedDep = {};

  // Lines of coverage currently associated with the employee
  this.pendingChanges   = [];
  this.employeeCoverages  = [];
  this.availableCoverages = [];
  this.claims = [];

  Ctrl.wizard = WizardService;

  // If the browser back button is clicked, close the modal
  $scope.$on('$routeChangeStart', function() {
    if (modalInstance) {
      modalInstance.dismiss('cancel');
    }
    if (coverageModalInstance) {
      coverageModalInstance.dismiss('cancel');
    }
  });

  /**
   * Maps coverage for proper data within this controller
   * @param  {Object} coverage Coverage object
   * @return {Objectt}          New coverage
   */
  function mapCoverage(coverage) {
    coverage.friendlyVolume         = checkVolumeLTD(coverage);
    coverage.friendlyDependents     = CoverageFactory.getFriendlyDependentsArray(coverage.coveredPeople);
    coverage.friendlyEffectiveDate  = UtilFactory.getFormattedCensusDate(coverage.effectiveDate);

    return coverage;
  }

  function setDivisionAndClassNames(currentUser) {
    Ctrl.currentUser = currentUser;

    var divisionObj,
        classObj,
        divisionClasses = [];

    if (currentUser) {
      if (Ctrl.employee.divisionId && currentUser.divisions) {
        divisionObj = _.find(currentUser.divisions, { 'id': Ctrl.employee.divisionId });

        if (divisionObj) {
          if (divisionObj.name) Ctrl.employee.divisionName = divisionObj.name;
          if (divisionObj.description) Ctrl.employee.divisionDesc = divisionObj.description;
          divisionClasses = divisionObj['class'];
        }
      } else if (currentUser.group) {
        divisionClasses = currentUser.group['class'];
      }

      if (divisionClasses && divisionClasses.length && Ctrl.employee.classId) {
        classObj = _.find(divisionClasses, { 'id': Ctrl.employee.classId });
        if (classObj && classObj.name) Ctrl.employee.className = classObj.name;
      }
    }

    Ctrl.showTobacco = classObj.requireTobacco;

  }

  this.init = function EmployeeProfileCtrl__init(shouldRefreshEmployee, isForceReload) {
    var employeeId = $routeParams.id;

    // Get employee census info
    if (!_.isObject(employee) || parseInt(employee.id,10) !== parseInt(employeeId,10)) {
      $log.error("id of employee object returned by server doesn't match the id used " +
        "in the request. id ", employeeId, " was requested");

      $location.path('/manageEmployees');
      return false;
    }

    // NEED TO RELOAD THE PAGE TO PREVENT THE INVALID FORM ERROR IF REHIREING OUTSIDE OF 6 MONTHS and when cancelling a pending change
    if((employee.status.toLowerCase() === 'inactive' && WizardService.hasLoaded) || isForceReload){
      location.reload();
    }

    if (shouldRefreshEmployee) {
      var employeeId = employeeId || $routeParams.id;
      EmployeeFactory.find(employeeId).then(function (employee) {
        Ctrl.employee = EmployeeFactory.mapCensusProperties(employee);
      });
    } else {
      Ctrl.employee = EmployeeFactory.mapCensusProperties(employee);
    }

    UserFactory.getActiveUser().then(setDivisionAndClassNames);

    // Convenience booleans for rendering the view
    empStatusUpdate(employee);

    setAvailableCoverages(employeeId);
    setDependents();
//    setCurrentCoverages(employeeId);
//     setPendingChanges(employeeId);
    setClaims(employeeId);
  };

  this.init();

  function empStatusUpdate (employee) {
    // Convenience booleans for rendering the view
    Ctrl.hasWaiver = (employee.status.toLowerCase() === 'waiver');
    Ctrl.hasCobra = (employee.status.toLowerCase() === 'cobra');
    Ctrl.isActive = (employee.status.toLowerCase() === 'active');
    Ctrl.isInActive = (employee.status.toLowerCase() === 'inactive');
    Ctrl.isShowAvailableCoverages = !employee.terminationDate;
  }

  function setDependents(newDep) {
    if (Ctrl.employee.dependents.length > 0) {
      // Normalize the dependent properties
      Ctrl.employee.dependents = _.map(Ctrl.employee.dependents, function(dependent) {
        dependent.id = parseInt(dependent.id, 10);
        // IT-7136 Remove Proper Casing on Statuses in Roster & Dependent List
        // if (_.isString(dependent.status)) {
        //   dependent.status = dependent.status[0].toUpperCase() + dependent.status.slice(1).toLowerCase();
        // }
        return dependent;
      });

     //Ctrl.activeDepsCount = _.countBy(Ctrl.employee.dependents, 'status')['ACTIVE'] || 0;
     Ctrl.activeDepsCount = 0;
     _.countBy(Ctrl.employee.dependents, function(n){
        if (n.status == 'ACTIVE'){
          Ctrl.activeDepsCount++
        }
        if(n.status == 'COBRA'){
          Ctrl.activeDepsCount++
        }
      });


      // Sort dependents so active ones are first, inactive dependents come after
      Ctrl.employee.dependents = _.sortBy(Ctrl.employee.dependents, 'status');

      // Find the index of an active spouse dependent if one exists...
      var spouseIndex = _.findIndex(Ctrl.employee.dependents, function(dep) {
        if (dep.relationship && dep.status) {
          return ['H','W','SP'].indexOf(dep.relationship.toUpperCase()) > -1 && dep.status === 'ACTIVE';
        }
        return false;
      });
      // ... and swap it with the first dependent in the array if it's not already first
      if (spouseIndex > 0) {
        var firstDep = Ctrl.employee.dependents[0];
        var spouse = Ctrl.employee.dependents[spouseIndex];
        Ctrl.employee.dependents[0] = spouse;
        Ctrl.employee.dependents[spouseIndex] = firstDep;
      }
    }

    if (Ctrl.employee.dependents.length >= 1) {
      if (newDep) {
        Ctrl.selectedDep = newDep;
      } else {
        // Select the first dependent by default
        Ctrl.selectedDep = Ctrl.employee.dependents[0];
      }
    }
  }

  // Get the employee's current coverages
  function setCurrentCoverages(employeeId) {
    CoverageFactory.findByEmployee(employeeId).then(EmployeeProfileCtrl__setCurrentCoverages__findByEmployee, $log.error);

    function EmployeeProfileCtrl__setCurrentCoverages__findByEmployee(resp) {
      // fix for apiary and real backend API translation
      if (_.isObject(resp) && _.isArray(resp.coverages)) {
        resp = resp.coverages;
      }

      // convert `resp` into an array, make sure it's not empty
      resp = !_.isArray(resp) ? (_.isEmpty(resp) ? [] : [resp]) : resp;

      var cleanedCoverages = _.map(resp, mapCoverage);
      Ctrl.showAgeReductionDisclaimer = getAgeReduction(cleanedCoverages);
      Ctrl.employeeCoverages = cleanedCoverages;
    }
  }

  // Get changes that are currently pending to the current employee
  function setPendingChanges(employeeId) {
    CoverageFactory.findPendingChanges(employeeId).then(function (pendingChangesResp) {
      var pendingChanges = [];
      Ctrl.isPendingRehire = false;

      _.forOwn(pendingChangesResp, function(pChange) {
        if (_.isPlainObject(pChange)) {
          addPendingChange(pChange);
        } else if (_.isArray(pChange)) {
          _.each(pChange, function(change) {
            addPendingChange(change);
          });
        }
      });

      function addPendingChange(change) {
        var typeInfo = PENDING_CHANGE_METADATA[change.changeType];
        var typeTextToShow = change.changeTypeDescription;
        var isEditable = true;
        if (_.includes(["HDR"], change.changeSubType) ) {
          Ctrl.isPendingRehire= true;
        }
        if (_.includes(["CO", "CP", "CV"], change.changeType) && change.coverage && change.coverage.benefitDescription) {
          typeTextToShow = change.coverage.benefitDescription;
          isEditable = !change.coverage.isMandatory;
          if (change.hasParentPendingChange === 1 || (change.coverage.isRider && change.hasRiderParentPendingChange === 1)) {
            isEditable = false;
          } else if (_.includes(["CV", "CPA", "COD", "CPD"], change.changeSubType)){
            var curCov = _.find(Ctrl.allAvailableCoverages, {benefitID:change.coverage.benefitID});
            if (curCov && curCov.requiresCoverage.length) {
              var parentBenefitId = curCov.requiresCoverage[0].benefitID;
              var isPendingParentChange = false;

              var parentCovPending = _.forEach(pendingChangesResp.coverages, function(covPChange){
                if(covPChange.coverage.benefitID === parentBenefitId ){
                  isPendingParentChange = true;
                }
              });
            
              // Do not allow edits to volume changes that are due to an edit in the parent match relationship
              if(_.includes(["CV"], change.changeType) ){
                isEditable = curCov.requiresCoverage[0].relationshipTypeCode.indexOf("MCH") == -1 && !isPendingParentChange;
              } else {
                isEditable = !isPendingParentChange;
              }
            }
          }
        }
        if (typeInfo) {
          pendingChanges.push({
            originalData: change,
            type: typeInfo.description,
            typeToShow: typeTextToShow,
            changeDescription: change.changeSubTypeDescription,
            coveredPeople: CoverageFactory.getFriendlyDependentsArray(change.effectedPeople),
            effectiveDateFormatted: UtilFactory.getFormattedCensusDate(change.effectiveDate),
            isEditable: isEditable
          });
        }
      }

      Ctrl.pendingChanges = _.sortBy(pendingChanges, 'originalData.effectiveDate');
    });
  }

  // Get coverages available to (but not owned by) the current employee
  function setAvailableCoverages(employeeId) {
    CoverageFactory.findAvailableCoverages(employeeId).then(function (coverages) {
      if (!_.isUndefined(coverages)) {
        Ctrl.allAvailableCoverages = angular.copy(coverages);

        var spouse = DependentFactory.findSpouse(Ctrl.employee.dependents),
            children = DependentFactory.findChildren(Ctrl.employee.dependents),
            noSpouse = false,
            noChildren = false;

        if (spouse.length === 0) {
          noSpouse = true;
        }

        if (children.length === 0) {
          noChildren = true;
        }

        // Only show coverages that pertain to the dependents the employee has
        coverages = _.filter(coverages, function(coverage) {
          var includeCoverage = true;

          if (_.isNull(coverage.coveredPersonType) || _.isUndefined(coverage.coveredPersonType)) {
            return true;
          }

          if (coverage.coveredPersonType) {
            if (noSpouse && noChildren) {
              includeCoverage = ['dependent', 'dependents', 'spouse', 'child', 'children'].indexOf(coverage.coveredPersonType.toLowerCase()) === -1;
            } else if (noSpouse) {
              includeCoverage = coverage.coveredPersonType.toLowerCase() != 'spouse';
            } else if (noChildren) {
              includeCoverage = coverage.coveredPersonType.toLowerCase() != 'children';
            }
          }

          if(coverage.guaranteedIssue){
            coverage.guaranteedIssue = parseInt(coverage.guaranteedIssue, 10);
          }

          return includeCoverage;
        });

        // Generate array of parent coverages
        generateRequiredRelationships(coverages);

        // Only show coverages marked available
        coverages = _.filter(coverages, function(coverage) {
          return coverage.available;
        });

        Ctrl.showCoverageDisclaimer = _.some(coverages, {guaranteedIssue:0});

        Ctrl.availableCoverages = coverages;
      }
      setCurrentCoverages($routeParams.id);
      setPendingChanges($routeParams.id);

    });
  }

  function generateRequiredRelationships(availableCoverages) {
    Ctrl.wizard.data.coverageRelationships = [];

    _.forEach(availableCoverages, function(cov) {
      _.forEach(cov.requiresCoverage, function(reqCov) {
        var parentId = reqCov.id;
        cov.relationshipType = reqCov.relationshipTypeCode;
        cov.checked = true;

        if (_.isArray(Ctrl.wizard.data.coverageRelationships) && Ctrl.wizard.data.coverageRelationships.length > 0) {
          var parentRelationship = _.find(Ctrl.wizard.data.coverageRelationships, {parentId: parentId} );
          if (parentRelationship) {
            parentRelationship.childCoverages.push(cov);
          } else {
            Ctrl.wizard.data.coverageRelationships.push({parentId: parentId, childCoverages: [cov]});
          }
        } else {
          Ctrl.wizard.data.coverageRelationships = [{parentId: parentId, childCoverages: [cov]}];
        }
      });
    });
  }

  // Get claims from the current employee.
  function setClaims(employeeId) {
    EmployeeFactory.getClaims(employeeId).then(function (claims) {
      if (!_.isUndefined(claims)) {
        Ctrl.claims = claims;
      }
    });
  }

  this.navigateBack = function EmployeeProfileCtrl__navigateBack() {
    $location.path("/manageEmployees/")
  };

  this.showPendingChangeError = function EmployeeProfileCtrl__showPendingChangeError(){
    this.showDeletePendingChangeModal = true;
  };

  this.hidePendingDeleteModal =  function EmployeeProfileCtrl__hidePendingDeleteModal(){
    this.showDeletePendingChangeModal = false;
  }

  /**
   * Opens editing coverage modal
   * @param  {Object}  coverage    Coverage's data object
   * @param  {Boolean}  editMode   True for when we're editing a coverage, otherwise we're attempting to add
   * @param  {Object[]} dependents An array of the employee's dependents
   */
  this.openCoverageModal = function EmployeeProfileCtrl__navigateBack__openCoverageModal(coverage, editMode, dependents) {
    if (coverage.hasPendingChange) {
        Ctrl.showPendingChangeError();
        return;
    }
    var queryObj = {
      reasonCodes: EmployeeFactory.getReasonCodes(),
      qualifyingEvents: EmployeeFactory.getQualifyingEvents()
    }

    // To cover inconsistent naming of properties
    coverage.benefitId = coverage.benefitId ? coverage.benefitId : coverage.benefitID;

    if (editMode) {
      queryObj["_coverage"] = CoverageFactory.findByBenefitId(Ctrl.employee.id, coverage.benefitId);
      if (coverage.requiresCoverage && coverage.requiresCoverage.length) {
        var parentBenefitId = coverage.requiresCoverage[0].benefitID;
        var parentCoverage = _.find(Ctrl.employeeCoverages, {benefitID: parentBenefitId});
      }
    } else {
      // We are adding a new coverage. Check if it has any required child coverages and then send those along with it
      var coverageRelationships = _.find(Ctrl.wizard.data.coverageRelationships, {parentId: coverage.id});
      if (coverageRelationships) {
        Ctrl.wizard.data.childCoverages = _.filter(coverageRelationships.childCoverages, function(childCov) {
          return _.includes(['GPREQMCH', "GCREQ", "GPREQ", "GPREQMCH", "GPREQVLLTE"], childCov.relationshipType);
        });
      }

      if (coverage.requiresCoverage && coverage.requiresCoverage.length) {
        var parentBenefitId = coverage.requiresCoverage[0].benefitID;
        var parentCoverage = _.find(Ctrl.employeeCoverages, {benefitID: parentBenefitId});
        if (!parentCoverage) {
          parentCoverage = _.find(Ctrl.allAvailableCoverages, {benefitID: parentBenefitId});
          if (parentCoverage && parentCoverage.benefitID) {
            parentCovPendingChange = _.find(Ctrl.pendingChanges, function(pendingChange) {
              return (pendingChange && pendingChange.originalData && pendingChange.originalData.coverage) && pendingChange.originalData.coverage.benefitID === parentCoverage.benefitID;
            });
            if (parentCovPendingChange) {
              parentCoverage = parentCovPendingChange.originalData.coverage;
            }
          }
        }
      }
    }

    $q.all(queryObj).then(openModal, $log.error);

    function openModal(results) {
      var templateName = "coverages";
      if (editMode) {
        templateName = "edit-coverage";
        WizardService.data.coverage = results._coverage;
      } else {
        WizardService.data.coverage = coverage;
      }
      WizardService.data.originalCoverage = coverage;
      WizardService.data.parentCoverage   = parentCoverage;
      WizardService.data.employeeInfo     = angular.copy(Ctrl.employee);
      WizardService.data.editMode         = editMode ? editMode : false;
      WizardService.data.reasonCodes      = results.reasonCodes;
      WizardService.data.qualifyingEvents = results.qualifyingEvents;

      if (_.isArray(dependents)) {
        WizardService.data.originalCoverage.dependents = dependents;
      }
      else if (_.isUndefined(WizardService.data.originalCoverage.dependents) && _.isArray(Ctrl.employee.dependents)) {
        WizardService.data.originalCoverage.dependents = angular.copy(Ctrl.employee.dependents);
      }

      coverageModalInstance = $modal.open({
        backdrop: 'static',
        keyboard: false,
        templateUrl: '/partials/edit-employee/' + templateName,
        controller: 'AddEmployeeModalCtrl as ModalCtrl'
      });

      coverageModalInstance.result.then(function() {
        Ctrl.init();
      });
    }
  };

  this.openEditPendingChangeModal = function EmployeeProfileCtrl__openEditPendingChangeModal(pendingChange) {
    var changeType = pendingChange.originalData.changeType;
    var changeSubType = pendingChange.originalData.changeSubType;
    var needsBenefitInfo = _.includes(['COA', 'CPA', 'CPD', 'CV'], changeSubType);
    var needsQualifyingEvents = _.includes(['COD', 'COA', 'CPA', 'CPD'], changeSubType);
    var needsActiveBenefit = _.includes(['CPA', 'CPD'], changeSubType);
    var checkForParent = _.includes(['COA', 'CV'], changeSubType);
    var parentCoverage, activeOrPendingParentCoverage;
    var matchParentVolume = false;
    var capAtParentVolume = false;

    var queryObj = {
      reasonCodes: EmployeeFactory.getReasonCodes(),
    }

    if (needsBenefitInfo && pendingChange.originalData.coverage) {
      var benefitId = pendingChange.originalData.coverage.benefitId;
      var benefitID = pendingChange.originalData.coverage.benefitID;
      benefitId = benefitId || benefitID;
      queryObj.benefitInfo = CoverageFactory.findByBenefitId(Ctrl.employee.id, benefitId);
    }

    // If it's a pending coverage deletion, we need the qualifying events
    if (needsQualifyingEvents) {
      queryObj.qualifyingEvents = EmployeeFactory.getQualifyingEvents();
    }

    $q.all(queryObj).then(openModal, $log.error);

    function openModal(results) {
      WizardService.data.employeeInfo = Ctrl.employee;
      WizardService.data.pendingChange = pendingChange;
      WizardService.data.reasonCodes = results.reasonCodes;

      if (needsBenefitInfo) {
        WizardService.data.benefitInfo = results.benefitInfo;
      }

      if (needsQualifyingEvents) {
        WizardService.data.qualifyingEvents = results.qualifyingEvents;
      }

      if (needsActiveBenefit) {
        WizardService.data.activeBenefit = _.find(Ctrl.employeeCoverages, ['benefitID', pendingChange.originalData.coverage.benefitID]);
      }

      if (checkForParent) {
        _.forEach(WizardService.data.benefitInfo.requiresCoverage, function(requiredCoverage) {
          // Look in all available coverages for an unavailable coverage (has been added or is pending) with the required coverage id
          parentCoverage = _.find(Ctrl.allAvailableCoverages, {
            id: requiredCoverage.id,
            available: 0
          });

          if (parentCoverage) {
            if (_.includes(['GPREQMCH', "GPOPTMCH"], requiredCoverage.relationshipTypeCode)) {
              matchParentVolume = true;
            } else if (_.includes(['GPREQVLLTE', "GPOPTVLLTE"], requiredCoverage.relationshipTypeCode)) {
              capAtParentVolume = true;
            }

            // Look up the parent coverage in the active benefits and pending changes
            activeOrPendingParentCoverage = _.find(Ctrl.employeeCoverages, {benefitID: parentCoverage.benefitID});
            if (!activeOrPendingParentCoverage) {
              var parentPendingCoverage = _.find(Ctrl.pendingChanges, function(pendingChange) {
                return pendingChange.originalData && pendingChange.originalData.coverage &&
                       pendingChange.originalData.coverage.benefitID === parentCoverage.benefitID;
              });
              if(parentPendingCoverage) activeOrPendingParentCoverage = parentPendingCoverage.originalData.coverage;
            }

            return false; // We found the parent coverage, so end the forEach loop
          }
        });

        if (activeOrPendingParentCoverage) {
          WizardService.data.parentInfo = {
            volume: activeOrPendingParentCoverage.volume,
            matchParentVolume: matchParentVolume,
            capAtParentVolume: capAtParentVolume
          }
        }
      }

      pendingChangeModalInstance = $modal.open({
        backdrop: 'static',
        keyboard: false,
        templateUrl: '/partials/edit-employee/edit-pending-change/' + PENDING_CHANGE_METADATA[changeSubType].template,
        controller: PENDING_CHANGE_METADATA[changeSubType].controller + ' as ModalCtrl'
      });

      pendingChangeModalInstance.result.then(function() {
        Ctrl.init(true, true);
      });
    }
  }

  /**
   * Opens adding a new dependent modal
   * @return {[type]} [description]
   */
  this.openAddDependentModal = function EmployeeProfileCtrl__openAddDependentModal() {
    WizardService.data.employeeInfo = Ctrl.employee;
    WizardService.data.employeeName = Ctrl.employee.firstName + ' ' + Ctrl.employee.lastName;

    // don't resolve this since we want to refresh everytime
    EmployeeFactory.getQualifyingEvents().then(openModal, $log.error);

    function openModal(qualifyingEvents) {
      var currentDependents = angular.copy(Ctrl.employee.dependents, []);
      WizardService.data.qualifyingEvents = qualifyingEvents;

      modalInstance = $modal.open({
        backdrop: 'static',
        keyboard: false,
        templateUrl: '/partials/edit-employee/add-dependent-modal',
        controller: 'AddEmployeeModalCtrl as ModalCtrl'
      });

      modalInstance.result.then(function EmployeeProfileCtrl__openAddDependentModal__modalInstance() {
        EmployeeFactory.find($routeParams.id).then(function EmployeeProfileCtrl__openAddDependentModal__modalInstance__find(employee) {
          Ctrl.employee = EmployeeFactory.mapCensusProperties(employee);
          if (angular.isArray(employee.dependents) && currentDependents.length !== employee.dependents.length) {
            Ctrl.selectedDep = _.last(employee.dependents);
            setDependents(Ctrl.selectedDep);
          }
        }, $log.error);
      }, $log.error);
    }
  };

  /**
   * Opens editing a dependent modal
   * @param  {Object} dependent Dependent's data object
   */
  this.openEditDependentModal = function EmployeeProfileCtrl__openEditEmployeeModal(dependent) {
    WizardService.data.selectedDep = angular.copy(dependent);
    WizardService.data.selectedDep.status = dependent.status.toLowerCase();
    WizardService.data.employeeInfo = Ctrl.employee;

    modalInstance = $modal.open({
      backdrop:     'static',
      keyboard:     false,
      templateUrl:  '/partials/edit-employee/edit-dependent-modal',
      controller:   'EditDependentModalCtrl as ModalCtrl'
    });

    var onResolve = function () {
      /* removing this because if a user cancels the modal after re-activing the dependent, the profile is not updated
      if (!WizardService.terminatedDep && !_.isObject(WizardService.data.updatedDep)) {
        $log.log('Dependent update was canceled by the user');
        return false;
      }
      */

      refreshEmployee();
    };

    modalInstance.result.then(onResolve, $log.error);
  };

  /**
   * Opens editing the employee's information modal
   * @param  {String} employeeId Employee's username
   */
  this.openEditEmployeeModal = function EmployeeProfileCtrl__openEditEmployeeModal(employeeId) {
    if (_.isUndefined(employeeId)) {
      employeeId = $routeParams.id;
    }

    $q.all({
      employee: EmployeeFactory.find(employeeId),
      reasonCodes: EmployeeFactory.getReasonCodes(),
      qualifyingEvents: EmployeeFactory.getQualifyingEvents()
    }).then(openModal, $log.error);

    function openModal(results) {
      WizardService.addingEmployee = false;
      WizardService.currentUser = Ctrl.currentUser;
      WizardService.data.currentEmployee = results.employee;
      WizardService.data.currentEmployee.status = results.employee.status.toLowerCase();
      WizardService.data.reasonCodes = results.reasonCodes;
      WizardService.data.qualifyingEvents = results.qualifyingEvents;
      WizardService.data.isPendingRehire = Ctrl.isPendingRehire;
      WizardService.modalAction = 'edit';

      modalInstance = $modal.open({
        backdrop:     'static',
        keyboard:     false,
        templateUrl:  '/partials/edit-employee/index',
        controller:   'AddEmployeeModalCtrl as ModalCtrl'
      });

      modalInstance.result.then(function EmployeeProfileCtrl__openEditEmployeeModal__modalInstance() {
        refreshEmployee(employeeId);
      });
    }
  };

  /**
   * Refreshes the current employee
   */
  function refreshEmployee(empId) {
    var employeeId = empId || $routeParams.id;
    EmployeeFactory.find(employeeId).then(function (employee) {
      Ctrl.employee = EmployeeFactory.mapCensusProperties(employee);

      empStatusUpdate(employee);
      setDependents();
      //setCurrentCoverages($routeParams.id);
      setPendingChanges($routeParams.id);
      setAvailableCoverages($routeParams.id);
      setClaims($routeParams.id);
      setDivisionAndClassNames(Ctrl.currentUser);
    });
  }

  /**
   * Opens reactivating/deactivating an employee modal page
   */
  this.reactivateEmployee = function EmployeeProfileCtrl__reactivateEmployee() {
    var employeeId = $routeParams.id;

    $q.all({
      employee: EmployeeFactory.find(employeeId),
      qualifyingEvents: EmployeeFactory.getQualifyingEvents()
    }).then(openModal, function() {
      $log.error(arguments);
    });

    function openModal(results) {
      WizardService.reactivating  = true;
      WizardService.isProductsNext = true;
      WizardService.data.currentEmployee = results.employee;
      WizardService.data.qualifyingEvents = results.qualifyingEvents;
      WizardService.data.currentEmployee.status = results.employee.status.toLowerCase();
      WizardService.data.reasonCodes = [
        {
          code: "rehire",
          description: "Rehire"
        },
        {
          code: "reinstate",
          description: "Reinstate"
        }
      ];

      modalInstance = $modal.open({
        backdrop:     'static',
        keyboard:     false,
        templateUrl:  '/partials/edit-employee/index',
        controller:   'AddEmployeeModalCtrl as ModalCtrl'
      });

      modalInstance.result.then(openEmployeeWizard);
    }

    function openEmployeeWizard(currentUser) {
      WizardService.reinstated = false;

      if (currentUser && !angular.equals(currentUser, {})) {
        WizardService.data.currentUser = currentUser;
        if (!WizardService.startWizard) {
          refreshEmployee();
          return;
        }

        WizardService.reactivating = true;

        modalInstance = $modal.open({
          backdrop:     'static',
          keyboard:     false,
          templateUrl:  '/partials/roster/add-employee-modal',
          controller:   'AddEmployeeModalCtrl as ModalCtrl'
        });

        modalInstance.result.then(function () {
          WizardService.reactivating  = false;
          WizardService.startWizard   = false;

          refreshEmployee();
        });
      }
    }
  };

  /**
   * Removes a pending coverage
   * @param  {Object|String} coverage Coverage's data object or the pending coverage ID
   */
  this.removePendingCoverage = function EmployeeProfileCtrl__removePendingCoverage(pendingCoverage) {
    var pendingCoverageIDs;

    if (_.isObject(pendingCoverage)) {
      pendingCoverageIDs = pendingCoverage.ids;
    } else if (pendingCoverage) {
      pendingCoverageIDs = [pendingCoverage];
    }

    _.forEach(pendingCoverageIDs, function(pendingCoverageID) {
      CoverageFactory.removePendingCoverage(pendingCoverageID).then(function(response) {
        Ctrl.pendingChanges = _.reject(Ctrl.pendingChanges, function(rejectCoverage) {
          var diffs = _.difference(rejectCoverage.ids, pendingCoverageIDs);
          return diffs.length === 0;
        });
      },$log.error);
    });

    refreshEmployee();
  }

  function checkVolumeLTD (coverage, isForPending) {
    var newVolume
    if (coverage.productType == "LTD" && coverage.volumeCalculationType == "S") {
      newVolume = (coverage.volume)/(coverage.salaryMultiplier);
      newVolume = Math.ceil(newVolume);
    } else {
      newVolume = coverage.volume;
    }
    return UtilFactory.getFormattedCurrency(newVolume, isForPending);
  }

  function getAgeReduction(coverages) {
    var isAgeReduction = false;

    _.forEach(Ctrl.allAvailableCoverages, function(availableCoverage) {
      _.forEach(coverages, function(cov) {
        if (cov.benefitID == availableCoverage.benefitID && parseInt(availableCoverage.ageReduction, 10)) {
          isAgeReduction = true;
        }
      });
    });
    return isAgeReduction;
  }
}

module.exports = ["$scope", "LogFactory", "$location", "$routeParams", "$uibModal", "$q", "PENDING_CHANGE_METADATA", "UserFactory", "EmployeeFactory", "WizardService", "CoverageFactory", "UtilFactory", "DependentFactory", "employee", EmployeeProfileCtrl];
