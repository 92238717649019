
var _      = require('lodash');
var dottie = require('dottie');

function BulkOperationsCtrl($route, $rootScope, $scope, $location, $window, $log, $q, UserFactory, FormFactory, BulkOperationsFactory) {
  var Ctrl = this;

  Ctrl.$location = $location;
  Ctrl.$scope = $scope;
  Ctrl.$log = $log;
  Ctrl.formData = Ctrl.formData || {};
  Ctrl.formData.salary = Ctrl.formData.salary || {};

  Ctrl.exportSalary = function BulkOperationsCtrl__exportSalary() {
    UserFactory.getActiveUser().then(function BulkOperationsCtrl__exportSalary__getExportData_success(res) {
      BulkOperationsFactory.exportSalary(res).then(function BulkOperationsCtrl__exportSalary__getExportData__exportSalary_success(res) {
        window.location = "/downloadExport/salary/" + res.data;
      }, $log.error);
    }, $log.error);
  };

  Ctrl.init = function BulkOperationsCtrl__init() {
    getSalaryFileName();
  };

  Ctrl.uploadSalaryFile = function BulkOperationsCtrl__uploadSalaryFile() {
    var file = $scope.salaryFile;
    Ctrl.formData.salary.errors = {};

    BulkOperationsFactory.uploadSalaryFile(file).then(function BulkOperationsCtrl__uploadSalaryFile__uploadSalaryFile_success(data) {
      if (!!!_.isUndefined(data)) {
        var upload = data.filter(function (row) {
          return (row.failure === true);
        });

        if (upload.length) {
          Ctrl.formData.salary.errors.upload = upload;
        }
      }

      if (_.isEmpty(Ctrl.formData.salary.errors)) {
        $route.reload();
      }
    }, function BulkOperationsCtrl__uploadSalaryFile__uploadSalaryFile_error(data) {
      $log.error(data);

      Ctrl.formData.salary.errors.invalid = true;
    });
  }

  function getSalaryFileName() {
    BulkOperationsFactory.getSalaryFile().then(function BulkOperationsCtrl__getSalaryFileName__getSalaryFile_success(data) {
      if (!!!_.isUndefined(data.fileName)) {
        Ctrl.formData.salary.fileName = data.fileName;
      }
    }, $log.error);
  }

  Ctrl.deleteSalaryFile = function BulkOperationsCtrl__deleteSalaryFile() {
    BulkOperationsFactory.deleteSalaryFile().then(function BulkOperationsCtrl__deleteSalaryFile__deleteSalaryFile_success(res) {
      $route.reload();
    }, $log.error);
  }

  Ctrl.init();

  // until we have a proper 500 error page
  function error() {
    $log.error(arguments);
  }
};

module.exports = ['$route', '$rootScope', '$scope', '$location', '$window', 'LogFactory', '$q', 'UserFactory', 'FormFactory', 'BulkOperationsFactory', BulkOperationsCtrl];
