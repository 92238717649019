
var _ = require('lodash');

function MyAccountCtrl($rootScope, $routeParams, $log, UserFactory, RoleService) {
  var Ctrl = this;

  this.showSuccess  = false;
  this.modal        = '';
  this.errorMessage = '';
  this.errors       = {};

  Ctrl.isEditNameModalOpen      = false;
  Ctrl.isEditEmailModalOpen     = false;
  Ctrl.isEditPasswordModalOpen  = false;

  Ctrl.templates = {
    editNameModal:      '/partials/partials/editNameModal',
    editEmailModal:     '/partials/partials/editEmailModal',
    editPasswordModal:  '/partials/partials/editPasswordModal'
  };

  Ctrl.user = $rootScope.currentUser;

  Ctrl.nameModalDefaults = {
    title: 'Change Personal Information',
    form: {},
    user: {},
    submitted: false,
    step: 'enterNewName',
    okButtonText: 'Save',
    cancelButtonText: 'Cancel',
  };

  Ctrl.emailModalDefaults = {
    title: 'Change Your Email',
    form: {},
    user: {},
    submitted: false,
    step: 'enterNewEmail',
    okButtonText: 'Save',
    cancelButtonText: 'Cancel',
  };

  Ctrl.passwordModalDefaults = {
    title: 'Change Your Password',
    form: {},
    user: {},
    submitted: false,
    step: 'enterNewPassword',
    okButtonText: 'Save',
    cancelButtonText: 'Cancel',
  };

  Ctrl.resetNameForm();
  Ctrl.resetEmailForm();
  Ctrl.resetPasswordForm();

  // Placeholder until a process for persisting the
  // currentUser object is set up
  if (!_.isObject(Ctrl.user) || !_.isNumber(Ctrl.user.id)) {
    Ctrl.user = $rootScope.currentUser;

    UserFactory.getActiveUser().then(function (user) {
      Ctrl.user = $rootScope.currentUser = user;
    }, $log.error);
  }

  // Complete the edit email process if the user has arrived
  // through a confirm email link
  if ($routeParams.userid) {
    this.openEditEmailModal();
  }

  this.removeError = function MyAccountCtrl__removeError(property) {
    var Ctrl = $log.removeErrors(this, property);
    this.errors = Ctrl.errors;
  };

  this.nameFormSubmission = function MyAccountCtrl__nameFormSubmission() {
    // reset error fields
    this.errors = [];

    // Don't modify the real $rootScope.currentUser unless the new
    // user data saves successfully
    var user = {};

    user.firstName = Ctrl.nameModalOptions.user.firstName;
    user.lastName  = Ctrl.nameModalOptions.user.lastName;

    UserFactory.updateUser($rootScope.currentUser.userName, user).then(function MyAccountCtrl__nameFormSubmission__updateUser_success() {
      $rootScope.currentUser.firstName = Ctrl.nameModalOptions.user.firstName;
      $rootScope.currentUser.lastName  = Ctrl.nameModalOptions.user.lastName;
      Ctrl.editNameSuccess();
    }, function MyAccountCtrl__nameFormSubmission__updateUser_error(resp) {
      var errors = $log.errorHandler(resp, {allow409: true});
      Ctrl.errors = errors.errors;
    });
  };

  this.emailFormSubmission = function MyAccountCtrl__emailFormSubmission() {
    // reset error fields
    this.errors = [];

    var emailObj = {
      newEmail:     Ctrl.emailModalOptions.user.newEmail,
      confirmEmail: Ctrl.emailModalOptions.user.confirmEmail
    };

    // Send the user a link for email verification
    UserFactory.sendVerifyEmail(emailObj, Ctrl.user.userName).then(function MyAccountCtrl__emailFormSubmission__sendVerifyEmail_success() {
      Ctrl.editEmailSuccess();
    }, function MyAccountCtrl__emailFormSubmission__sendVerifyEmail_error(res) {
      var errors = $log.errorHandler(res, {allow409: true});
      Ctrl.errors = errors.errors;
      Ctrl.emailModalOptions.errorMessage = res.data.message;
    });
  };

  this.passwordFormSubmission = function MyAccountCtrl__passwordFormSubmission() {
    // reset error fields
    this.errors = [];

    // Don't modify the real $rootScope.currentUser unless the new
    // user data saves successfully
    var user = angular.copy($rootScope.currentUser);
    user.oldpassword      = Ctrl.passwordModalOptions.options.user.oldPass;
    user.password         = Ctrl.passwordModalOptions.options.user.newPassword;
    user.confirmPassword  = Ctrl.passwordModalOptions.options.user.confirmPassword;

    if (user.roleHuman) delete user.roleHuman;

    UserFactory.updateUserPassword(user).then(function MyAccountCtrl__passwordFormSubmission__updateUser_success() {
      Ctrl.editPasswordSuccess();
    }, function MyAccountCtrl__passwordFormSubmission__updateUser_error(resp) {
      var errors  = $log.errorHandler(resp, {allow409: true});
      Ctrl.errors = errors.errors;
    });
  };

  Ctrl.roleHuman = function MyAccountCtrl__roleHuman() {
    return RoleService.toHuman(Ctrl.user.role, Ctrl.user.multiUser);
  }
}

MyAccountCtrl.prototype.resetNameForm = function () {
  this.nameModalOptions = angular.copy(this.nameModalDefaults);
};

MyAccountCtrl.prototype.resetEmailForm = function () {
  this.emailModalOptions = angular.copy(this.emailModalDefaults);
};

MyAccountCtrl.prototype.resetPasswordForm = function () {
  this.passwordModalOptions = angular.copy(this.passwordModalDefaults);
};

MyAccountCtrl.prototype.hideNameForm = function () {
  return this.nameModalOptions.step === 'success' ||
    this.nameModalOptions.step === 'failure' ||
    this.nameModalOptions.submitted;
};

MyAccountCtrl.prototype.hideEmailForm = function () {
  return this.emailModalOptions.step === 'success' ||
    this.emailModalOptions.step === 'enterNewEmail2' ||
    this.emailModalOptions.step === 'failure' ||
    this.emailModalOptions.submitted;
};

MyAccountCtrl.prototype.hidePasswordForm = function () {
  return this.passwordModalOptions.step === 'success' ||
    this.passwordModalOptions.step === 'enterNewPassword2' ||
    this.passwordModalOptions.step === 'failure' ||
    this.passwordModalOptions.submitted;
};

MyAccountCtrl.prototype.editNameSuccess = function () {
  this.nameModalOptions.submitted     = true;
  this.nameModalOptions.message       = 'You have successfully changed your personal information.<br>' +
    'Click "Okay" to return to your account.';
  this.nameModalOptions.okButtonText  = 'Okay';
  this.nameModalOptions.step          = 'success';
};

MyAccountCtrl.prototype.editEmailSuccess = function () {
  this.emailModalOptions.submitted    = true;
  this.emailModalOptions.message      = 'Please check your email and click on the link to change your email address.';
  this.emailModalOptions.okButtonText = 'Okay';
  this.emailModalOptions.step         = 'enterNewEmail2';
};

MyAccountCtrl.prototype.editPasswordSuccess = function () {
  this.passwordModalOptions.submitted     = true;
  this.passwordModalOptions.message       = 'You have successfully changed your password.<br>Click "Okay" to return to your account.';
  this.passwordModalOptions.okButtonText  = 'Okay';
  this.passwordModalOptions.step          = 'enterNewPassword2';
};

MyAccountCtrl.prototype.editFailure = function () {
  this.nameModalOptions.step  = 'failure';
  this.nameModalOptions.title = "Invalid first and/or last name.";
};

MyAccountCtrl.prototype.openEditEmailModal = function() {
  this.emailModalOptions.user = angular.copy(this.user);
  this.isEditEmailModalOpen   = true;
};

MyAccountCtrl.prototype.closeEditEmailModal = function () {
  this.isEditEmailModalOpen = false;
  this.resetEmailForm();
};

MyAccountCtrl.prototype.openEditNameModal = function() {
  this.nameModalOptions.user = angular.copy(this.user);
  this.isEditNameModalOpen  = true;
};

MyAccountCtrl.prototype.closeEditNameModal = function () {
  this.isEditNameModalOpen = false;
  this.resetNameForm();
};

MyAccountCtrl.prototype.openEditPasswordModal = function() {
  this.passwordModalOptions.user  = angular.copy(this.user);
  this.isEditPasswordModalOpen    = true;
};

MyAccountCtrl.prototype.closeEditPasswordModal = function () {
  this.isEditPasswordModalOpen = false;
  this.resetPasswordForm();
};

MyAccountCtrl.prototype.nextStepName = function() {
  var Ctrl = this;
  if (Ctrl.nameModalOptions.step === 'enterNewName' && !Ctrl.nameModalOptions.submitted) {
    Ctrl.nameFormSubmission();
  } else {
    Ctrl.closeEditNameModal();
  }
};

MyAccountCtrl.prototype.nextStepEmail = function() {
  var Ctrl = this;
  if (Ctrl.emailModalOptions.step === 'enterNewEmail' && !Ctrl.emailModalOptions.submitted) {
    Ctrl.emailFormSubmission();
  } else {
    Ctrl.closeEditEmailModal();
  }
};

MyAccountCtrl.prototype.nextStepPassword = function() {
  var Ctrl = this;
  if (Ctrl.passwordModalOptions.step === 'enterNewPassword' && !Ctrl.passwordModalOptions.submitted) {
    Ctrl.passwordFormSubmission();
  } else {
    Ctrl.closeEditPasswordModal();
  }
};

module.exports = ['$rootScope', '$routeParams', 'LogFactory', 'UserFactory', 'RoleService', MyAccountCtrl];
